import VideoSrc from 'builder/views/Interview/assets/interview-start-page-video.mp4'
import { Right, Left, StyledVideo, Content, Row } from './styles'
import { InterviewStartView } from '../InterviewStartView/InterviewStartView'
import { behaviourType } from 'builder/modules/interview/types'
import { VideoWrapper } from '../styles'

export const StartContent = ({ behaviour }: { behaviour?: behaviourType }) => {
  return (
    <Content>
      <Row>
        <Left>
          <InterviewStartView behaviour={behaviour} />
        </Left>
        <Right>
          <VideoWrapper>
            <StyledVideo src={VideoSrc} key={0} autoPlay muted loop playsInline />
          </VideoWrapper>
        </Right>
      </Row>
    </Content>
  )
}
