import styled from 'styled-components'
import Sizes from 'builder/styles/sizes'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  justify-content: center;
  align-items: center;
  padding: 0 ${Sizes.S} ${Sizes.XL} ${Sizes.S};
  gap: ${Sizes.L};

  ${Media.AboveTablet`
    margin-top: 32px;
    padding: 0 32px;
  `};
`
