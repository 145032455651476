/* eslint-disable max-len */
import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 16px;
  max-width: 100vw;
  overflow-x: hidden;
  padding-bottom: 80px;

  ${Media.Phone`
    padding-top: 24px;
  `}
`

export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.Medium};
  text-align: center;
  width: 544px;
  margin-bottom: 24px;

  ${Media.Tablet`
    width: 552px;
  `}

  ${Media.Phone`
    width: 335px;
    ${Typography.S};
  `};
`

export const CountryBanner = styled.div`
  width: 928px;
  flex-shrink: 0;
  border-radius: 8px;
  background: ${Colors.Blue10};
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 16px;
  margin-bottom: 24px;
  gap: 12px;
  ${Typography.Body};
  ${FontWeights.Medium};

  ${Media.Tablet`
    width: 728px;
  `}

  ${Media.Phone`
    width: 335px;
    padding: 12px 16px;
  `};
`

export const CountryText = styled.div`
  ${Typography.Caption};
  color: ${Colors.Blue80};
`

export const CountryImage = styled.img`
  width: 39px;
  height: var(--M, 24px);
`

export const TwoGrid = styled.div`
  max-width: 928px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 32px;

  ${Media.Phone`
    flex-direction: column;
    align-items: center;
  `};
`

export const CardLabel = styled.label`
  width: 448px;
  height: 400px;
  flex-shrink: 0;
  border-radius: var(--S, 16px);
  border: 1px solid ${Colors.Neutral15};
  transition: box-shadow 0.2s ease-in;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;

  &:hover {
    box-shadow: 0px 16px 64px -8px rgba(15, 56, 113, 0.18), 0px 6px 8px -2px rgba(15, 56, 113, 0.02),
      0px 0px 1px 0px rgba(15, 56, 113, 0.32);
    transition: box-shadow 0.2s ease-in;
  }

  ${Media.Tablet`
    width: 352px;
    height: 400px;
  `}

  ${Media.Phone`
    width: 335px;
    height: 306px;
  `}
`

export const UploadImage = styled.img`
  width: 143.354px;
  height: 220px;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: 6px;
  background: linear-gradient(180deg, #f7f9fc 0%, #fff 100%);
  margin-top: 35px;

  ${Media.Phone`
    width: 115.074px;
    height: 172.872px;
    margin-top: 24px;
  `};
`

export const UploadCardTitle = styled.div`
  width: 272px;
  ${Typography.S};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  z-index: 22;
  margin-bottom: 8px;
  text-align: center;

  ${Media.Phone`
    margin-top: -12px;
  `};
`

export const CardText = styled.div`
  width: 256px;
  z-index: 22;
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  text-align: center;

  ${Media.Phone`
    width: 271px;
  `};
`

export const SpinnerWrapper = styled.div`
  margin: auto;
  padding: 16px;
  border-radius: 32px;
  box-shadow: 0px 8px 20px -4px rgba(15, 56, 113, 0.12), 0px 2px 4px -1px rgba(15, 56, 113, 0.08),
    0px 0px 1px 0px rgba(15, 56, 113, 0.32);
`
