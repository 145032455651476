import { RefObject, useEffect } from 'react'

type Props = {
  elementRef: RefObject<HTMLElement | null>
  isOpened?: boolean
  isDisabled?: boolean
}

export const useFoldableSectionScrollFix = (props: Props) => {
  const { elementRef, isOpened, isDisabled } = props

  useEffect(() => {
    const element = elementRef.current

    if (isDisabled || !element || isOpened) return

    const rect = element.getBoundingClientRect()

    if (rect.top >= 0) return
    const currentScroll = document.documentElement.scrollTop

    document.documentElement.scrollTo({
      top: currentScroll + rect.top,
      behavior: 'smooth',
    })
  }, [isDisabled, isOpened, elementRef])
}
