import styled from 'styled-components'
import { gradient } from 'builder/styles/keyframes'
import Colors from 'builder/styles/colors'

export const LoaderContainer = styled.div`
  animation: ${gradient} 1s linear infinite;
  background: ${Colors.Neutral10};
  background: linear-gradient(
    to right,
    ${Colors.Neutral10} 5%,
    ${Colors.Neutral15} 15%,
    ${Colors.Neutral10} 25%
  );
  width: 100%;
  margin-bottom: 32px;
`

export const Loader = styled.img`
  height: 100%;
  width: 100%;
`
