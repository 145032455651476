import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import ReadyImg from 'builder/views/Interview/assets/ready-to-start.png'
import { useQueryParam } from 'builder/hooks/useQueryParam'
import {
  PARAM_COMPANY_LOGO,
  PARAM_COMPANY_NAME,
  PARAM_IS_REAL_COMPANY,
  PARAM_JOB_TITLE_ID,
  PARAM_JOB_TITLE_TEXT,
  PARAM_TYPE_OF_QUESTION,
} from 'builder/views/Interview/constants'
import Button from 'builder/components/Button'
import { useUser } from 'builder/hooks/useUser'
import { useNewInterview } from 'builder/views/Interview/hooks/useNewInterview'
import { useMutateJobTitle } from 'builder/views/Interview/hooks/useMutateJobTitle'
import { Container, Wrapper, Content, JobTitle, PageTitle, ButtonContainer, Image } from './styles'

const TRANSLATION = 'builder.interview_prep_view.start_ready'

export const ReadyToStartView = () => {
  const { i18n } = useI18n()
  const [jobTitleId] = useQueryParam(PARAM_JOB_TITLE_ID)
  const [companyName] = useQueryParam(PARAM_COMPANY_NAME)
  const [jobTitleText] = useQueryParam(PARAM_JOB_TITLE_TEXT)
  const [companyLogo] = useQueryParam(PARAM_COMPANY_LOGO)
  const [typeOfQuestions] = useQueryParam(PARAM_TYPE_OF_QUESTION)
  const [isRealCompany] = useQueryParam(PARAM_IS_REAL_COMPANY)
  const navigate = useNavigate()
  const user = useUser()
  const { mutateAsync: postInterview, status: interviewStatus } = useNewInterview()
  const { mutateAsync: postJobTitle } = useMutateJobTitle()
  const intJobTitleId = parseInt(jobTitleId || '0')

  useEffect(() => {
    if (intJobTitleId) {
      // The user can only go to the dashboard if the job title is set.
      postJobTitle(intJobTitleId)
    }

    // free user can't start interview for the second time
    if (user?.currentJobTitleId && !user?.hasPremiumFeatures) {
      return navigate('/interview-preparation/dashboard')
    }

    if (!jobTitleId || !jobTitleText || !user) {
      return navigate('/interview-preparation/start')
    }
  }, [])

  const handleCreateInterviewClick = async () => {
    await postInterview({
      jobTitleCode: intJobTitleId,
      company: companyName || '',
      typeOfQuestions: typeOfQuestions || undefined,
      numberOfQuestions: 1,
    })
    trackInternalEvent('start_interview', {
      label: 'interview_prep',
      parameter: 'test',
    })

    navigate('/interview-preparation/interview-player', {
      state: {
        isRealCompany: isRealCompany === 'true',
        forceStartLoading: true,
        startPageType: typeOfQuestions,
        companyName: companyName,
      },
    })
  }

  return (
    <Wrapper>
      <Container>
        <Content>
          <Image src={ReadyImg} />
          <PageTitle>{i18n.t(`${TRANSLATION}.message`)}</PageTitle>
          <JobTitle>
            <strong>{jobTitleText}</strong>&nbsp;
            {companyLogo && (
              <>
                <span>{i18n.t(`${TRANSLATION}.at_preposition`)}</span>&nbsp;
                <img src={companyLogo} />
              </>
            )}
          </JobTitle>
          <ButtonContainer>
            <Button
              onClick={handleCreateInterviewClick}
              isLoading={interviewStatus === 'loading'}
              disabled={interviewStatus === 'loading'}
            >
              {i18n.t(`${TRANSLATION}.button`)}
            </Button>
          </ButtonContainer>
        </Content>
      </Container>
    </Wrapper>
  )
}
