import styled, { css } from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography from 'builder/styles/typography'
import Button, { ButtonSize } from 'builder/components/Button'
import ColorPicker from 'builder/components/ColorPicker'
import Icon from 'builder/components/Icon'
import { rotation } from 'builder/styles/keyframes'
import Select from 'builder/components/Select'
import Sizes from 'builder/styles/sizes'

type PropTypes = {
  isDisabled?: boolean
  isVisible?: boolean
  small?: boolean
  isTopbarLayout?: boolean
}

export const Container = styled.div<{ isNewCustControlsEnabled: boolean }>`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${({ isNewCustControlsEnabled }) =>
    isNewCustControlsEnabled ? Colors.Neutral80 : Colors.Neutral70};
`

export const Toolbar = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 32px;
  background-color: ${Colors.Neutral100};
  color: ${Colors.White};
`

const ToolbarSide = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  ${Media.Tablet`
    flex: initial;
  `}
`

export const ToolbarLeft = styled(ToolbarSide)``

export const ToolbarCenter = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const ToolbarRight = styled(ToolbarSide)`
  justify-content: flex-end;

  ${Media.Tablet`
    margin-left: 12px;
  `}
`

export const Updating = styled.div<PropTypes>(props => {
  const { isVisible } = props

  return css`
    align-items: center;
    display: flex;
    margin-right: 32px;
    transition: opacity 0.1s, visibility 0.1s;
    opacity: ${isVisible ? 1 : 0};
    visibility: ${isVisible ? 'visible' : 'hidden'};
    ${Media.Tablet`
      margin-right: 20px;
    `}
  `
})

export const UpdatingIcon = styled(Icon.Spinner)`
  animation: ${rotation} 0.5s infinite linear;
`

export const UpdatingText = styled.div`
  margin-left: 4px;
  ${Typography.Caption};
  ${Media.Tablet`
    display: none;
  `}
`

export const Back = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 32px;
  border-radius: 16px;
  padding-right: 14px;
  padding-left: 4px;
  margin-left: -10px;
  transition: background-color 0.15s;
  background-color: ${hexToRgba(Colors.Neutral80, 0)};

  &:hover {
    background-color: ${Colors.Neutral80};
  }
`

export const BackIcon = styled(Icon.Chevron)`
  margin-right: 2px;
  transform: rotate(180deg);
  transition: transform 0.15s;
  border-radius: 12px;
`

export const BackText = styled.div`
  padding-top: 2px;
`

export const Pagination = styled.div<PropTypes>(props => {
  const { isVisible } = props

  return `
  position: absolute;
  z-index: 3;
  left: 50%;
  bottom: 24px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  padding: 6px;
  background-color: ${Colors.Neutral100};
  border-radius: 18px;
  user-select: none;
  transition: opacity 0.2s;
  transition-delay: ${isVisible ? '0.2s' : 0};
  opacity: ${isVisible ? 1 : 0};
  pointer-events: ${isVisible ? 'auto' : 'none'};
`
})

export const PaginationButton = styled.div<PropTypes>(props => {
  const { isDisabled } = props

  return `
  border-radius: 50%;
  color: ${Colors.White};
  opacity: ${isDisabled ? 0.3 : 1};
  pointer-events: ${isDisabled ? 'none' : 'auto'};
  cursor: pointer;

  &:hover {
    background-color: ${Colors.Neutral80};
  }

  & svg {
    display: block;
  }
`
})

export const PaginationPrev = styled(PaginationButton)<PropTypes>`
  transform: rotate(180deg);
`

export const PaginationNext = styled(PaginationButton)<PropTypes>``

export const PaginationCounter = styled.div`
  flex-grow: 1;
  text-align: center;
  min-width: 30px;
  margin: 0 4px;
  color: ${Colors.White};
  ${Typography.Caption};
  font-variant-numeric: tabular-nums;
`

export const Main = styled.div`
  position: absolute;
  top: 64px;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
`

export const Sidebar = styled.div`
  width: 409px;
  flex-shrink: 0;
  border-right: 1px solid ${hexToRgba(Colors.White, 0.15)};

  ${Media.Laptop`
    width: 265px;
  `}

  ${Media.Tablet`
    width: 241px;
  `}
`

const Scroll = styled.div<{ isNewCustControlsEnabled?: boolean }>`
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ isNewCustControlsEnabled }) =>
      isNewCustControlsEnabled ? Colors.Neutral70 : Colors.Neutral90};
    border: 4px solid
      ${({ isNewCustControlsEnabled }) =>
        isNewCustControlsEnabled ? Colors.Neutral90 : Colors.Neutral70};
    border-radius: 6px;

    &:hover {
      background-color: ${({ isNewCustControlsEnabled }) =>
        isNewCustControlsEnabled ? Colors.Neutral60 : Colors.Neutral100};
    }
  }
`

export const SidebarInner = styled(Scroll)<{ isNewCustControlsEnabled: boolean }>`
  height: ${({ isNewCustControlsEnabled }) => (isNewCustControlsEnabled ? '95%' : '100%')};
  padding: 32px 32px 18px;

  ${Media.Laptop`
    padding-bottom: 22px;
  `}

  ${Media.Tablet`
    padding-left: 20px;
    padding-right: 20px;
  `}
`

export const Content = styled.div`
  position: relative;
  flex-grow: 1;
  min-width: 0;
`

export const ContentInner = styled(Scroll)<{ isNewCustControlsEnabled: boolean }>`
  padding: 0 32px;
`

export const PreviewBox = styled.div`
  max-width: 928px;
  margin: 32px auto 42px;
  transition: transform 0.4s ease, opacity 0.4s ease;
`

export const DownloadButton = styled(Button).attrs({
  size: ButtonSize.small,
})``

export const Divider = styled.div`
  width: 1px;
  height: 24px;
  margin: 0 24px;
  background-color: ${Colors.Neutral70};

  ${Media.Tablet`
    margin: 0 10px;
  `};
`

export const SpacingContainer = styled.div`
  position: relative;
  display: flex;
  padding: 4px 0;
  user-select: none;
  align-items: center;
`

export const SpacingIcon = styled.div`
  display: flex;
  margin: 0 4px;
`

export const SpacingButton = styled.div<PropTypes>(props => {
  const { isDisabled, isTopbarLayout } = props

  return css`
    position: relative;
    display: flex;
    border-radius: 50%;
    background-color: ${hexToRgba(Colors.Neutral80, 0)};
    cursor: pointer;
    transition: background-color 0.15s, color 0.15s;
    ${isTopbarLayout ? { height: '24px' } : {}}

    &:hover {
      background-color: ${isTopbarLayout ? hexToRgba(Colors.Neutral100, 0.15) : Colors.Neutral80};
    }

    &:active {
      background-color: ${Colors.Neutral70};
    }

    ${isDisabled &&
    css`
      pointer-events: none;
      color: ${Colors.Neutral70};
    `}

    &:before {
      content: '';
      position: absolute;
      left: -8px;
      right: -8px;
      top: -8px;
      bottom: -8px;
    }
  `
})

export const Tooltip = styled.div<PropTypes>(props => {
  const { small } = props

  return `
  position: absolute;
  z-index: 3;
  width: 140px;
  top: 100%;
  margin-top: 21px;
  left: 50%;
  transform: translate(-50%, 0px);
  padding: 5px 8px;
  border-radius: 3px;
  background-color: ${Colors.Neutral100};
  ${Typography.Tiny};
  text-align: center;
  color: ${Colors.White};
  opacity: 0;
  user-select: none;
  pointer-events: none;
  transition: opacity 0.2s ease, transform 0.2s ease;
  transition-delay: 0s;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 0 0 2px 0;
    transform: translate(-50%, 50%) rotateZ(45deg);
    background-color: ${Colors.Neutral100};
    bottom: 100%;
    left: 50%;
  }

  ${SpacingContainer}:hover & {
    opacity: 1;
    transform: translate(-50%, 0px);
    transition-delay: 0.2s;
  }

  ${
    small &&
    css`
      width: auto;
      white-space: nowrap;
    `
  }
`
})
export const TooltipValue = styled.div`
  font-variant-numeric: tabular-nums;
`

export const Actions = styled.div`
  position: relative;
  display: flex;
  margin-left: 8px;
`

export const ActionsButton = styled(Button)`
  width: 36px;
  height: 36px;
  padding: 0;
`

export const StyledColorPicker = styled(ColorPicker)<PropTypes>(props => {
  const { isVisible } = props

  return `
  position: absolute;
  z-index: 3;
  top: calc(100% + 16px);
  right: 16px;
  box-shadow: 0 12px 32px rgba(38, 43, 51, 0.32);
  transform: translate(50%, -10px) translateZ(0);
  opacity: 0;
  transition-property: opacity, transform;
  transition-timing-function: ease-out;
  transition-duration: 0.15s;

  ${
    isVisible &&
    css`
      transform: translate(50%, 0px) translateZ(0);
      opacity: 1;
    `
  }
`
})

export const CustomSelect = styled(Select)`
  width: 100%;
  margin-bottom: ${Sizes.M};

  scrollbar-color: #bec4d5 #5c6373;
  scrollbar-width: thin;

  button {
    background-color: #5c6373;
    color: white;
    border: 1px solid ${Colors.Neutral15};

    &:hover {
      background-color: ${Colors.Neutral60};
    }
  }

  div {
    background-color: #5c6373;
    color: white;
  }
`
