import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import { trackInternalEvent, trackMarketingEvent } from '@rio/tracking'
import { useLocation, useParams } from 'react-router-dom'
import { UserDocumentTemplateId } from 'packages/types'
import { bindActionToPromise } from 'builder/utils/bindActionToPromise'
import PreviewPanel, { CurrentDocument } from 'builder/components/PreviewPanel'
import DocumentPreviewModal from 'builder/components/DocumentPreviewModal'
import DocumentCustomizationModal from 'builder/components/DocumentCustomizationModal'
import FloatingPreviewButton from 'builder/components/FloatingPreviewButton'
import { actions } from 'builder/modules/resumeEditor'
import { actions as coverLetterActions } from 'builder/modules/coverLetterEditor'
import { actions as panelActions } from 'builder/modules/panel'
import { actions as renderingActions } from 'builder/modules/rendering'
import { actions as coRegActions } from 'builder/modules/coRegistration'
import { actions as generalEditorActions } from 'builder/modules/generalEditor'
import { navigate } from 'builder/modules/navigate'
import TunerPrePaywallModal from 'builder/components/TunerPrePaywallModal'
import { JobPosting } from 'builder/components/DocumentEditor/JobPosting/JobPosting'
import AiCoverletterTailoredView from 'builder/components/DocumentEditor/AICoverLetter/AiCoverletterTailoredView'
import SavingInfoBanner from 'builder/components/JobTracking/SavingInfoBanner/SavingInfoBanner'
import { AICoverLetter } from 'builder/components/DocumentEditor/JobPosting/AiCoverLetter'
import EditAIJobDetails from 'builder/components/DocumentEditor/AICoverLetter/EditAIJobDetails'
import { DocumentTypes, FetchStatuses } from 'builder/modules/constants'
import { ErrorState } from 'builder/components/DocumentEditor/AICoverLetter/ErrorState'
import { ButtonSize } from 'builder/components/Button'
import AbandonmentModal from 'builder/components/AbandonmentModal'
import AbandonmentResumeModal from 'builder/components/AbandonmentResumeModal'
import { HAS_ABANDONMENT_RESUME_MODAL_BEEN_SHOW } from 'builder/components/AbandonmentResumeModal/constants'
import { UserType } from 'builder/modules/user'
import Paywall from 'builder/components/AiFeatures/Paywall'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useConfig } from 'builder/hooks/useConfig'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { MouseClickEvent } from 'builder/modules/ui'
import { DesktopVirtualCoach, useVirtualCoachFeature } from 'builder/features/virtualCoach'
import { ResumeEditorSwitcher } from './ResumeEditorSwitcher'
import { Container, LeftSide } from './styles'

export const ResumeEditorView = () => {
  const location = useLocation()
  const routeParams = useParams()
  const mediaQueries = useMediaQueries()
  const config = useConfig()
  const dispatch = useDispatch()
  const user = useTypedSelector(state => state.user)
  const isDownloading = useTypedSelector(state => state.rendering.isDocumentDownloading)
  const editorState = useTypedSelector(state => state.generalEditor)
  const resumeState = useTypedSelector(state => state.resumeEditor)
  const coverLetterState = useTypedSelector(state => state.coverLetterEditor)
  const panelState = useTypedSelector(state => state.panel)
  const isPlansPageAbandonmentModalOpen = useTypedSelector(
    state => state.ui.isPlansPageAbandonmentModalOpen,
  )
  const [hasAbandonmentModalBeenShown, setHasAbandonmentModalBeenShown] = useState(false)
  const [isUserLeaving, setIsUserLeaving] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const isFoldableSectionsEnabled = !!config?.features?.foldSections

  const { isVirtualCoachEnabled } = useVirtualCoachFeature()
  const isResumeOptimizerFeatureOn = !!config?.features?.resumeOptimizer
  const resumeId = (routeParams.id && parseInt(routeParams.id)) || 0

  const abandonmentModalListener = useCallback(e => {
    if (e.pageY <= 5) {
      setIsUserLeaving(false)
    }
  }, [])

  const init = useCallback(() => {
    // fetch data and initialize related modules
    if (!resumeId) {
      return
    }

    dispatch(actions.fetchResumeRequest({ id: resumeId, isResumeOptimizerFeatureOn }))
    dispatch(generalEditorActions.fetchEditorPayloadRequest())
    dispatch(coRegActions.initialize())

    // show the preview modal on mobiles
    const params = queryString.parse(location.search, { parseNumbers: true })
    if ('preview' in params && !mediaQueries.isDesktop) {
      setIsFullScreen(true)
    }
    // track the page opening
    trackInternalEvent('visit_resume_editor', { resume_id: resumeId, editor: 'builder' })

    const isDataLayerPushed = localStorage.getItem('DATA_LAYER_PUSH')
    const hasAbandonmentModalBeenShown = localStorage.getItem(
      HAS_ABANDONMENT_RESUME_MODAL_BEEN_SHOW,
    )

    if (hasAbandonmentModalBeenShown === 'true') {
      setHasAbandonmentModalBeenShown(true)
    }

    if (user?.data?.socialAccount && !(isDataLayerPushed === 'true')) {
      localStorage.setItem('DATA_LAYER_PUSH', 'true')
      trackMarketingEvent('Sign up', 'Complete Registration')
    }

    if (panelState.documentsStatus !== 'LOADED') {
      // If someone has opened a resume directly, we call this action to get info of user documents
      dispatch(panelActions.fetchDocuments())
    }

    document.addEventListener('mousemove', abandonmentModalListener)
  }, [
    abandonmentModalListener,
    dispatch,
    isResumeOptimizerFeatureOn,
    location.search,
    mediaQueries.isDesktop,
    panelState.documentsStatus,
    resumeId,
    user?.data?.socialAccount,
  ])

  useEffect(() => {
    init()
  }, [routeParams.id])

  useEffectOnMount(() => {
    return () => {
      document.removeEventListener('mousemove', abandonmentModalListener)
    }
  })

  useEffect(() => {
    const {
      isClickedOnAiCVBanner,
      optimizerSection: {
        jobPostingAPIData: { recommendedJobTitle },
      },
      AICoverLetter: { isShowUntailoreView },
    } = resumeState

    const isJobPostingLinked = recommendedJobTitle !== null && recommendedJobTitle !== ''

    const AICoverLetterTailoredView =
      isClickedOnAiCVBanner && isJobPostingLinked && !isShowUntailoreView

    if (AICoverLetterTailoredView) {
      trackInternalEvent('see_job_posting_selection_screen')
    }

    if (resumeState.error) navigate('/resumes')
  }, [resumeState])

  const handleFullSizeOpen = useCallback((e: MouseClickEvent) => {
    e.preventDefault()
    setIsFullScreen(true)
  }, [])

  const handleFloatingButtonClick = useCallback(() => {
    setIsFullScreen(true)
    trackInternalEvent('open_full_screen_resume_preview')
  }, [])

  const handleFullSizeClose = useCallback(() => {
    setIsFullScreen(false)
  }, [])

  const handleBannerClose = useCallback(() => {
    dispatch(actions.setOpenSavingInfoBanner(false))
  }, [dispatch])

  const handleBannerOpen = useCallback(() => {
    navigate('/job-tracking')
    trackInternalEvent('open_job_tracking')
  }, [])

  const handleAbandonmentModalClose = useCallback(() => {
    setHasAbandonmentModalBeenShown(true)
    setIsUserLeaving(false)
  }, [])

  const handleTemplateUpdate = useCallback(
    (id: UserDocumentTemplateId) => {
      dispatch(actions.updateTemplate({ id }))
      trackInternalEvent('change_resume_template', { template: id })
    },
    [dispatch],
  )

  const resetGenerateAICoverLetterStatus = useCallback(() => {
    dispatch(actions.setGenerateAICoverLetterStatus(FetchStatuses.notAsked))
  }, [dispatch])

  const downloadResume = useMemo(
    () => bindActionToPromise(dispatch, renderingActions.download),
    [dispatch],
  )

  const onRetryAIGenerateErrorStateModal = useCallback(() => {
    resetGenerateAICoverLetterStatus()
    dispatch(actions.setIsClickedOnAiCVBanner(true))
  }, [dispatch, resetGenerateAICoverLetterStatus])

  const {
    resume,
    isSyncing,
    isOptimizerModalOpen,
    isOpenSavingInfoBanner,
    changeJobPostingLink,
    isClickedOnAiCVBanner,
    optimizerSection: {
      jobPostingAPIData: { recommendedJobTitle },
    },
    AICoverLetter: {
      generateAICoverLetterStatus,
      isOpenAICVOptimizerModal,
      isClickedOnAiCVEditModalIcon,
      isShowUntailoreView,
    },
    aiProfile,
  } = resumeState

  const handleSimpleFieldUpdate = useCallback(
    (name: any, value: any, debounce?: boolean) => {
      dispatch(actions.updateSimpleField({ name, value, debounce }))

      if (name === 'name') trackInternalEvent('rename_resume')
      if (name === 'color')
        trackInternalEvent('change_resume_color', { template: resume?.template })
      if (name === 'locale') trackInternalEvent('change_resume_locale', { locale: value })
    },
    [dispatch, resume?.template],
  )

  const {
    aiGenerationSection: { showPaywall: showAICoverLetterPaywall },
  } = coverLetterState

  const { showPaywall: showAIProfilePaywall } = aiProfile

  const showAIFeaturesPaywall = showAICoverLetterPaywall || showAIProfilePaywall

  const isDataLoaded = !!resume && editorState.isLoaded

  const showResumeOptimizerModal = isOptimizerModalOpen && resume && user.data
  const isJobPostingLinked = recommendedJobTitle !== null && recommendedJobTitle !== ''
  const showAICoverLetterTailoredView =
    isClickedOnAiCVBanner && isJobPostingLinked && !isShowUntailoreView
  const howAiCoverLetterNormalView =
    (isClickedOnAiCVBanner && !isJobPostingLinked) ||
    isOpenAICVOptimizerModal ||
    isShowUntailoreView

  const showGenerateCLErrorStateModal = generateAICoverLetterStatus === FetchStatuses.failed

  const errorStateButtonSize = !mediaQueries.isPhone ? ButtonSize.default : ButtonSize.small
  const inBuilderAbandonmentFlag = config?.features.inBuilderAbandonment || false
  const showAbandonmentModal =
    !hasAbandonmentModalBeenShown &&
    [UserType.free, UserType.postPremium].includes(user?.data?.billingInfo.userType as UserType) &&
    isUserLeaving &&
    inBuilderAbandonmentFlag &&
    user?.data?.showAbandonmentFlows

  const handleClosePaywall = useCallback(() => {
    if (showAICoverLetterPaywall) {
      dispatch(coverLetterActions.setShowAICoverLetterPaywall())
    } else {
      dispatch(actions.toggleAIProfileSummaryPaywall())
    }
  }, [dispatch, showAICoverLetterPaywall])

  return (
    <>
      <TunerPrePaywallModal />
      <Container {...{ isFoldableSectionsEnabled }}>
        <LeftSide>
          <ResumeEditorSwitcher
            editorState={editorState}
            resumeState={resumeState}
            isDataLoaded={isDataLoaded}
            user={user}
          />
        </LeftSide>

        {/*
        Unmount PreviewPanel when DocumentPreviewModal is visible. Reasons:
        - prevent render if block is invisible - reduce the load of user's device
        - prevent parallel tasks - pdfjs worker can't do more than one in parallel
        */}
        {!isFullScreen && mediaQueries.isDesktop && (
          <PreviewPanel
            type="resume"
            onFullSize={handleFullSizeOpen}
            documentId={resumeId}
            isSyncing={isSyncing}
            onDownload={downloadResume}
            isDownloading={isDownloading}
            currentDocument={resume}
            isFullScreen={isFullScreen}
            {...{ isFoldableSectionsEnabled }}
          />
        )}

        {isFullScreen &&
          resume && // TODO: remove it after DocumentPreviewModal speed up & refactoring.
          // Make sure nothing is rendered if panel isn't shown
          (mediaQueries.isAboveTablet ? (
            <DocumentCustomizationModal
              type={DocumentTypes.resume}
              isSyncing={isSyncing}
              isDownloading={isDownloading}
              currentDocument={resume as CurrentDocument}
              editorState={editorState}
              onClose={handleFullSizeClose}
              onDownload={downloadResume}
              updateSimpleField={handleSimpleFieldUpdate}
              updateTemplate={handleTemplateUpdate}
              config={config}
              registeredAt={user?.data?.registeredAt}
              showAbandonmentFlows={user?.data?.showAbandonmentFlows}
            />
          ) : (
            <DocumentPreviewModal
              type={DocumentTypes.resume}
              isDownloading={isDownloading}
              currentDocument={resume as CurrentDocument}
              editorState={editorState}
              onClose={handleFullSizeClose}
              onDownload={downloadResume}
              updateSimpleField={handleSimpleFieldUpdate}
              updateTemplate={handleTemplateUpdate}
            />
          ))}

        {mediaQueries.isLaptop && isDataLoaded && (
          <FloatingPreviewButton isSaving={isSyncing} onClick={handleFloatingButtonClick} />
        )}
      </Container>
      {showAICoverLetterTailoredView && <AiCoverletterTailoredView />}
      {showResumeOptimizerModal && <JobPosting resumeId={resumeId} />}
      {howAiCoverLetterNormalView && <AICoverLetter resumeId={resumeId} />}
      {isClickedOnAiCVEditModalIcon && <EditAIJobDetails />}
      {isOpenSavingInfoBanner && !mediaQueries.isPhone && !changeJobPostingLink && (
        <SavingInfoBanner onClose={handleBannerClose} onOpen={handleBannerOpen} />
      )}
      {showAIFeaturesPaywall && (
        <Paywall onClose={handleClosePaywall} isAiProfile={showAIProfilePaywall} />
      )}
      {isPlansPageAbandonmentModalOpen && <AbandonmentModal />}

      {showGenerateCLErrorStateModal && (
        <ErrorState
          onClose={resetGenerateAICoverLetterStatus}
          onTryAgain={onRetryAIGenerateErrorStateModal}
          ButtonSize={errorStateButtonSize}
        />
      )}
      {showAbandonmentModal && user.data && (
        <AbandonmentResumeModal
          handleClose={handleAbandonmentModalClose}
          hasUserVisitedPlans={user.data.visitedPlans}
          email={user.data.email}
          fullName={user.data.fullName}
        />
      )}

      {isVirtualCoachEnabled && (
        <DesktopVirtualCoach layoutVariant="floating" triggerVariant="minimal" />
      )}
    </>
  )
}
