import { useCallback, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ModalOverlay from 'builder/components/ModalOverlay'
import { Icon20, Icon24 } from 'builder/components/Icon'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import Colors from 'builder/styles/colors'
import { i18n as I18n } from 'builder/utils/i18n'
import { actions, AbandonmentLocalStorageKeys } from 'builder/modules/ui'
import {
  actions as editorActions,
  selectors as resumeSelectors,
} from 'builder/modules/resumeEditor'
import { selectors as panelSelectors } from 'builder/modules/panel'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/init'
import { actions as renderingActions } from 'builder/modules/rendering'
import { ABANDONMENT_FLOW_SERVICES, getServiceType } from './constants'
import TOP_RESUME from './assets/images/top_resume.svg'
import CAREER_IO from './assets/images/careerio.svg'

import {
  Container,
  Close,
  Back,
  InnerContainer,
  Content,
  Description,
  Header,
  Title,
  CardContainer,
  CardSection,
  Card,
  CardImage,
  CardTitle,
  TopResumeImg,
  CareerIoImg,
  PromotionContainer,
  PromotionContent,
  PromotionInnerContent,
  PromotionTitle,
  PromotionDescription,
  PromotionButton,
  PromotionButtonContainer,
  TopResumeContainer,
  TopResume,
  PromotionImage,
  BackBtnText,
  DownloadResumeActionWrapper,
  DownloadResumeAction,
  ArrowActionIcon,
} from './styles'

const AbandonmentModal = () => {
  const { isPhone, isTablet, isDesktop } = useMediaQueries()
  const [activeCard, setActiveCard] = useState<number>(0)
  const [isCardHover, setCardHover] = useState<number>(0)
  const [isCardClicked, setIsCardClicked] = useState<boolean>(false)
  const documentsList = useTypedSelector(panelSelectors.documents)
  const locale = useTypedSelector(selectors.locale)
  const resumeDownloadData = useTypedSelector(resumeSelectors.resumeDownloadData)
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const handleClose = () => {
    trackInternalEvent('show_abandonment_popup', { type: 'plans' })
    localStorage.setItem(AbandonmentLocalStorageKeys.SHOW_ABANDONMENT_MODAL_FLAG, 'true')
    dispatch(actions.closePlansPageAbandonmentModal())
  }

  const handleRedirect = () => {
    trackInternalEvent('show_abandonment_popup', { type: 'plans' })
    trackInternalEvent('promo_acceptance', {
      promo: getServiceType(activeCard, I18n.t)?.serviceTrackingAcceptedReason,
      where: 'plans_page_abandonment',
    })
    localStorage.setItem(AbandonmentLocalStorageKeys.SHOW_ABANDONMENT_MODAL_FLAG, 'true')
    dispatch(actions.closePlansPageAbandonmentModal())
    if (getServiceType(activeCard, I18n.t)?.serviceType === 'Start Service') {
      navigate(`/resumes/${documentsList[0].id}/edit?showAIResumeDraft=true`)
      dispatch(editorActions.resetAIResumeData())
      dispatch(editorActions.setShowAIResumeModal(true))
    } else if (getServiceType(activeCard, I18n.t)?.serviceType === 'Job Adjustment') {
      navigate(`/resumes/${documentsList[0].id}/edit`)
      dispatch(editorActions.setOpenOptimizerModal(true))
      dispatch(editorActions.startResumeOptimizerProcess())
    } else if (getServiceType(activeCard, I18n.t)?.serviceType === 'Resume Example') {
      dispatch(editorActions.fetchResumeExamples())
    } else {
      window.open(getServiceType(activeCard, I18n.t)?.serviceNavigateLink, '_blank')
    }
  }

  const arrowStyles = {
    fill: Colors.Blue50,
    marginLeft: isPhone && isTablet ? '12px' : '',
  }

  const handleFeatureCardClick = (cardId: number, trackingStatus: boolean) => {
    if (cardId && cardId !== -1) {
      setIsCardClicked(true)
      setActiveCard(cardId)
      if (trackingStatus) {
        trackInternalEvent('abandonment_reason_choice', {
          abandonment_reason: getServiceType(cardId, I18n.t)?.serviceTrackingReason,
        })
      }
    } else {
      setIsCardClicked(false)
      setActiveCard(0)
    }
  }

  const handleCardHover = (cardId: number) => {
    if (activeCard === cardId || cardId === -1) {
      setCardHover(-1)
    } else {
      setCardHover(cardId)
    }
  }

  const getImgUrl = (cardId: number) => {
    if (isCardClicked && !(isPhone || isTablet) && cardId === activeCard) {
      return getServiceType(activeCard, I18n.t)?.serviceLogoClicked
    } else if (isPhone || isTablet || isCardHover === cardId) {
      return getServiceType(cardId, I18n.t)?.serviceLogoClicked
    } else {
      return getServiceType(cardId, I18n.t)?.serviceLogo
    }
  }

  const goBackClick = useCallback(() => {
    setIsCardClicked(false)
    setActiveCard(0)
  }, [setIsCardClicked, setActiveCard])

  const getTitle = () => {
    if (isPhone || isTablet) {
      if (isCardClicked) {
        return getServiceType(activeCard, I18n.t)?.serviceTitle
      } else {
        return I18n.t('builder.plans_page_abandonment.title')
      }
    } else {
      return I18n.t('builder.plans_page_abandonment.title')
    }
  }

  if (isDesktop && activeCard === 0) {
    handleFeatureCardClick(1, false)
  }

  const handleDownloadDocument = useCallback(() => {
    localStorage.setItem(AbandonmentLocalStorageKeys.SHOW_ABANDONMENT_MODAL_FLAG, 'true')
    dispatch(actions.closePlansPageAbandonmentModal())
    dispatch(renderingActions.download(resumeDownloadData))
    dispatch(editorActions.resetResumeDownloadData())
  }, [dispatch, resumeDownloadData])

  return (
    <ModalOverlay
      onClick={handleClose}
      backgroundOpacity={0.6}
      overlayFadeDuration={350}
      contentSlideDuration={350}
    >
      <Container>
        <Close onClick={handleClose}>
          <Icon20.Close />
        </Close>
        {isCardClicked && (isPhone || isTablet) && (
          <Back onClick={() => goBackClick()}>
            <Icon24.ChevronLeft />
            <BackBtnText>{I18n.t('builder.plans_page_abandonment.back')}</BackBtnText>
          </Back>
        )}
        <InnerContainer>
          <Content isCardClicked={isCardClicked && (isPhone || isTablet)}>
            <Header isCardClicked={isCardClicked && (isPhone || isTablet)}>
              <Title>{getTitle()}</Title>
              <Description>
                {!isCardClicked && (isPhone || isTablet)
                  ? I18n.t('builder.plans_page_abandonment.subtitle')
                  : isCardClicked && (isPhone || isTablet)
                  ? ''
                  : I18n.t('builder.plans_page_abandonment.subtitle')}
              </Description>
            </Header>
            <CardContainer isCardClicked={!!activeCard}>
              {ABANDONMENT_FLOW_SERVICES(I18n.t).map(card => {
                return card.id === 3 && locale === 'nl-NL' ? null : (
                  <CardSection
                    onMouseOver={() => handleCardHover(card.id)}
                    onMouseOut={() => handleCardHover(-1)}
                    activeCard={activeCard}
                    key={card.id}
                    onClick={() =>
                      handleFeatureCardClick(activeCard === card.id ? -1 : card.id, true)
                    }
                    serviceType={card.id}
                  >
                    <Card activeCard={activeCard} serviceType={card.id}>
                      <CardImage src={getImgUrl(card.id)} />
                      <CardTitle
                        isActiveCard={isPhone || isTablet || card.id === activeCard}
                        cardHover={card.id === isCardHover}
                      >
                        {getServiceType(card.id, I18n.t)?.serviceTitle}
                      </CardTitle>
                      {(isPhone || isTablet) && <Icon24.ToggleArrow style={arrowStyles} />}
                    </Card>
                  </CardSection>
                )
              })}
            </CardContainer>
            {isCardClicked && (
              <>
                <PromotionContainer
                  activeCard={activeCard === 4 && locale === 'nl-NL' ? 3 : activeCard}
                  isNlLocale={locale === 'nl-NL'}
                >
                  {isPhone || isTablet ? (
                    <PromotionContent>
                      <PromotionImage activeCard={activeCard}>
                        <img src={getServiceType(activeCard, I18n.t)?.serviceDescImgMobile} />
                      </PromotionImage>
                      <PromotionInnerContent activeCard={activeCard}>
                        <PromotionTitle activeCard={activeCard}>
                          {getServiceType(activeCard, I18n.t)?.serviceDescTitle}
                        </PromotionTitle>
                        <PromotionDescription activeCard={activeCard}>
                          {getServiceType(activeCard, I18n.t)?.serviceDesc}
                        </PromotionDescription>
                        {getServiceType(activeCard, I18n.t)?.seviceAdditionalComponent && (
                          <TopResumeContainer>
                            <TopResume>
                              <TopResumeImg src={TOP_RESUME} />
                              <CareerIoImg src={CAREER_IO} />
                            </TopResume>
                          </TopResumeContainer>
                        )}
                        <PromotionButton activeCard={activeCard} onClick={handleRedirect}>
                          {getServiceType(activeCard, I18n.t)?.serviceRedirectBtnLogo && (
                            <Icon24.DoubleStars style={arrowStyles} />
                          )}
                          {getServiceType(activeCard, I18n.t)?.serviceRedirectBtnText}
                        </PromotionButton>
                      </PromotionInnerContent>
                    </PromotionContent>
                  ) : (
                    <>
                      <PromotionContent>
                        <PromotionTitle activeCard={activeCard}>
                          {getServiceType(activeCard, I18n.t)?.serviceDescTitle}
                        </PromotionTitle>
                        <PromotionDescription activeCard={activeCard}>
                          {getServiceType(activeCard, I18n.t)?.serviceDesc}
                        </PromotionDescription>
                        <PromotionButtonContainer>
                          <PromotionButton activeCard={activeCard} onClick={handleRedirect}>
                            {getServiceType(activeCard, I18n.t)?.serviceRedirectBtnLogo && (
                              <Icon24.DoubleStars style={arrowStyles} />
                            )}
                            {getServiceType(activeCard, I18n.t)?.serviceRedirectBtnText}
                          </PromotionButton>
                          {getServiceType(activeCard, I18n.t)?.seviceAdditionalComponent && (
                            <TopResume>
                              <TopResumeImg src={TOP_RESUME} />
                              <CareerIoImg src={CAREER_IO} />
                            </TopResume>
                          )}
                        </PromotionButtonContainer>
                      </PromotionContent>
                      <PromotionImage activeCard={activeCard}>
                        <img src={getServiceType(activeCard, I18n.t)?.serviceDescImg} />
                      </PromotionImage>
                    </>
                  )}
                </PromotionContainer>
              </>
            )}
          </Content>
          <DownloadResumeActionWrapper onClick={handleDownloadDocument}>
            <DownloadResumeAction>
              {I18n.t('builder.plans_page_abandonment.download_resume_button')}
              <ArrowActionIcon />
            </DownloadResumeAction>
          </DownloadResumeActionWrapper>
        </InnerContainer>
      </Container>
    </ModalOverlay>
  )
}
export default AbandonmentModal
