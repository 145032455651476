import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { FieldNoteProps } from 'builder/components/FieldNote'
import { SelectLayout } from './components/SelectLayout'
import { DesktopControl } from './components/DesktopControl'
import { MobileControl } from './components/MobileControl'
import { SelectOption, SelectValue } from './types'

type Props<V> = FieldNoteProps & {
  className?: string
  disabled?: boolean
  label?: string
  options?: SelectOption<V>[]
  selected: V
  onSelect?: (value: V) => void
  format?: (value?: V, text?: string) => string
  optionsListClassname?: string
  buttonOnClick?: () => void
  useNativeSelectOnMobile?: boolean
}

export const Select = <V extends SelectValue = SelectValue>({
  className,
  disabled: isDisabled = false,
  options = [],
  label,
  error,
  warning,
  description,
  // TODO: Rename to `value` everywhere
  selected: value,
  // TODO: Rename to `onChange` everywhere
  onSelect: onChange = () => {},
  format,
  optionsListClassname,
  buttonOnClick,
  useNativeSelectOnMobile = true,
}: Props<V>) => {
  const mediaQueries = useMediaQueries()
  const isMobile = !mediaQueries.hover && mediaQueries.isTablet
  const hasError = !!error
  const hasWarning = !!warning
  const isPlaceholder = value === null || value === ''
  const text = options.find(option => option.id === value)?.name
  const formattedVal = format ? format(value, text) : text
  const Control = isMobile && useNativeSelectOnMobile ? MobileControl : DesktopControl

  return (
    <SelectLayout
      className={className}
      label={label}
      error={error}
      warning={warning}
      description={description}
    >
      <Control
        value={value}
        options={options}
        onChange={onChange}
        isDisabled={isDisabled}
        isPlaceholder={isPlaceholder}
        hasError={hasError}
        hasWarning={hasWarning}
        optionsListClassname={optionsListClassname}
        icon={options.find(option => option.id === value)?.icon}
        buttonOnClick={buttonOnClick}
      >
        {formattedVal}
      </Control>
    </SelectLayout>
  )
}
