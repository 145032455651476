import { useQuery } from '@tanstack/react-query'
import { Resume } from '@rio/types'
import { apiClient } from 'builder/modules/apiClient'

const resumeQueryId = Symbol('resume')

export const useGetResume = (id: number | null) => {
  const res = useQuery(
    [resumeQueryId, id],
    async ({ signal }) => {
      const response = await apiClient.get<Resume>(`/resumes/${id}`, { signal })
      return response.data
    },
    {
      enabled: !!id,
      useErrorBoundary: false,
      cacheTime: 1,
    },
  )

  return res
}
