import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 32px 16px 16px;
  flex-shrink: 0;
  border-radius: var(--2XS, 8px);
  border: 1px solid ${Colors.Neutral15};
  justify-content: space-between;

  ${Media.Phone`
    position: relative;
    height: 128px;
  `};
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  flex-shrink: 0;
  max-width: 244px;
`

export const Title = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral90};
  margin-bottom: 2px;
`

export const ActionButton = styled(Button)`
  display: flex;
  width: fit-content;
  height: 36px;
  padding: var(--2XS, 8px) var(--XS, 12px);
  border-radius: var(--3XS, 4px);
  ${Typography.Caption};
  ${FontWeights.Medium};
  border: 1px solid ${Colors.Neutral20};
  transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
  background-color: ${Colors.White};
  color: ${Colors.Neutral90};

  &:hover {
    transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
    border: 1px solid ${Colors.Blue50};
    background-color: ${Colors.White};
    color: ${Colors.Blue50};
  }
`

export const RightContainer = styled.div`
  display: flex;
  text-align: center;
  position: relative;
  gap: 12px;
  overflow: hidden;
  flex-shrink: 0;

  ${Media.Phone`
      position: absolute;
      right: 30px;
  `};
`

export const PreviewContainer = styled.div<{ $src: string }>`
  width: 81px;
  height: 95px;
  background-size: contain;
  background-position: center;
  background-image: ${({ $src }) => `url(${$src})`};

  ${Media.Phone`
    width: 67px;
    height: 84px;
    margin-bottom: 0;
  `};
`
