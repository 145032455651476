import Media from 'builder/styles/media'
import styled from 'styled-components'

export const Content = styled.div`
  width: 1120px;
  margin: 0 auto;

  height: 100%;

  ${Media.AboveTablet`
    height: 585px;
  `};

  ${Media.Tablet`
    width: 100%;
  `};

  ${Media.Phone`
    width: 100%;
  `};
`

export const Row = styled.div`
  ${Media.AboveTablet`
    display: grid;
    grid-template-columns: 1fr 1fr;

    align-items: center;
    justify-content: space-between;
    margin: 0 32px;
  `};

  ${Media.Tablet`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding-bottom: 56px;
    height: 100%;

  `};

  ${Media.Phone`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
    height: 100%;
  `};
`

export const Left = styled.div`
  width: 448px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  ${Media.Tablet`
    width: 504px;
    padding: 32px 0;
    height: calc(100% - 100px);
    height: calc(100vh - 100px);
    height: calc(100svh - 100px);
  `};

  ${Media.Phone`
    width: 335px;
  `};
`

export const Right = styled.div`
  width: 544px;
  height: 560px;
  border: none;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  display: none;

  ${Media.AboveTablet`
    width: 504px;
    height: auto;
    display: unset;
  `};
`

export const StyledVideo = styled.video`
  width: 100%;
  height: 504px;
  border-radius: 16px;
`
