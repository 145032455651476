import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { useUser } from 'builder/hooks/useUser'
import { useConfig } from 'builder/hooks/useConfig'
import { ConfigScopesEnum } from 'builder/modules/init'

import { getHost } from '../../utils'
import Boston from '../../assets/Boston.png'
import Boston2 from '../../assets/Boston2.png'
import TrImage from '../../assets/TrImage.png'

import * as Styles from './styles'
import { TRANSLATION } from './constants'

type Props = {
  isLoading: boolean
  hasTrDocuments?: boolean
  setShowResumeSelect: (props: boolean) => void
}

export const ResumeSelection = ({ isLoading, hasTrDocuments, setShowResumeSelect }: Props) => {
  const user = useUser()
  const { i18n } = useI18n()
  const config = useConfig(ConfigScopesEnum.country)

  const onSelectResume = () => {
    setShowResumeSelect(true)
    trackInternalEvent('visit_app_resume_selection_popup', {
      label: 'total_job_search_solution',
    })
  }

  if (isLoading) {
    return (
      <Styles.Box>
        <Styles.SkeletonContainer>
          <Styles.SkeletonImageContainer>
            <Styles.SkeletonImage />
            <Styles.SkeletonFadeResume />
          </Styles.SkeletonImageContainer>
          <Styles.SkeletonInfo>
            <Styles.SkeletonHeader />
            <Styles.SkeletonDescription />
          </Styles.SkeletonInfo>
        </Styles.SkeletonContainer>
      </Styles.Box>
    )
  }

  return (
    <>
      {hasTrDocuments ? (
        <Styles.Box onClick={onSelectResume}>
          <Styles.FakeResume>
            <Styles.TrImage src={TrImage} />
            <Styles.FadeResume />
          </Styles.FakeResume>
          <Styles.SelectionTitle>{i18n.t(`${TRANSLATION}.select_from_tr`)}</Styles.SelectionTitle>
          <Styles.Description isTrDocument>
            {i18n.t(`${TRANSLATION}.choose_tr_resume`)}
          </Styles.Description>
          <Styles.TrResumeLabel>
            <Styles.TrResumeLabelIcon />
            <Styles.TrResumeLabelText>
              {i18n.t(`builder.resume_distribution.landing.modal.tr_banner`)}
            </Styles.TrResumeLabelText>
          </Styles.TrResumeLabel>
        </Styles.Box>
      ) : (
        <Styles.Box onClick={onSelectResume}>
          <Styles.FakeResume>
            <Styles.Image1 src={Boston} />
            <Styles.Image2 src={Boston2} />
            <Styles.FadeResume />
          </Styles.FakeResume>
          <Styles.SelectionTitle>
            {i18n.t(`${TRANSLATION}.select_from_builder`, {
              host: getHost(user?.scopes.kind, config?.name),
            })}
          </Styles.SelectionTitle>
          <Styles.Description>
            {i18n.t(`${TRANSLATION}.choose_resume`, {
              host: getHost(user?.scopes.kind, config?.name),
            })}
          </Styles.Description>
        </Styles.Box>
      )}
    </>
  )
}
