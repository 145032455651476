import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { i18n } from 'builder/utils/i18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useDocument } from 'builder/hooks/useDocument'
import { useGetResume } from 'builder/hooks/useGetResume'

import Icon24 from 'builder/components/Icon'
import { DocumentPreview } from 'builder/components/Panel/Document/styles'
import FieldSpinnerLoader from 'builder/views/ResumeDistributionView/components/FormLayout/FieldSpinnerLoader'

import { formatDate } from 'builder/utils/formatDate'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'

import {
  DESKTOP_PREVIEW_WIDTH,
  PHONE_PREVIEW_WIDTH,
} from 'builder/components/Panel/Document/constants'
import { DocumentTypes } from 'builder/modules/constants'
import { StartCompleteScreenRoute } from '../../constants'

import {
  ButtonsContainer,
  ChangeResumeButton,
  DocumentPreviewContainer,
  PreviewButton,
  ResumeSelectionContainer,
  RightBottonShadow,
  SelectLeft,
  SelectResumeDate,
  SelectResumeTitle,
  SelectRight,
} from './styles'

type Props = {
  upladedAt?: string
  uploadedResume?: File
  selectedResumeId: number | null
  selectedResumeType: DocumentTypes | null
  isParserLoading: boolean
  onClickPreview?: () => void
  handleBuilderResumeChange: () => void
}

const TRANSLATION = 'builder.auto_apply.form.personal_information'

export const ResumePreview = ({
  upladedAt,
  uploadedResume,
  selectedResumeId,
  selectedResumeType,
  isParserLoading,
  onClickPreview,
  handleBuilderResumeChange,
}: Props) => {
  const { isPhone } = useMediaQueries()
  const navigate = useNavigate()

  const getResumeId = () => {
    const isResume = selectedResumeType === DocumentTypes.resume
    const isTrDocument = selectedResumeType === DocumentTypes.trDocument

    return {
      [DocumentTypes.resume]: isResume ? selectedResumeId : null,
      [DocumentTypes.trDocument]: isTrDocument ? selectedResumeId : null,
    }
  }

  const resume = useGetResume(getResumeId()[DocumentTypes.resume])
  const document = useDocument(getResumeId()[DocumentTypes.trDocument])
  const selectedResume = resume?.data || document

  const previewUrl = useCallback(
    document => {
      return formatDocumentPreviewUrl(document, {
        size: isPhone ? PHONE_PREVIEW_WIDTH : DESKTOP_PREVIEW_WIDTH,
      })
    },
    [isPhone],
  )

  const getThumbnailImg = () => {
    if (selectedResume?.type === DocumentTypes.trDocument && selectedResume?.thumbnail?.url) {
      return selectedResume?.thumbnail?.url
    }
    return selectedResume ? previewUrl(selectedResume) : null
  }

  const getDocumentName = () => {
    if (selectedResume?.type === DocumentTypes.trDocument && selectedResume?.title) {
      return selectedResume?.title
    }

    const name = selectedResume?.name || i18n.t('builder.dashboard.untitled')
    return selectedResume?.jobTitle ? `${name}, ${selectedResume?.jobTitle}` : name
  }

  const formatUpdateDate = () => {
    const date = selectedResume?.updatedAt || selectedResume?.createdAt || new Date().toISOString()
    return upladedAt || formatDate(new Date(date), 'dd MMM, HH:mm')
  }

  const onPreview = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (onClickPreview) onClickPreview()
  }

  const navigateToResumeSelect = useCallback(() => {
    if (selectedResumeType === DocumentTypes.resume && selectedResumeId && resume.error) {
      navigate(`/auto-apply/${StartCompleteScreenRoute.RESUME_SELECT}`)
    }
  }, [navigate, resume.error, selectedResumeId, selectedResumeType])

  useEffect(() => {
    navigateToResumeSelect()
  }, [navigateToResumeSelect, resume])

  return (
    <ResumeSelectionContainer>
      <SelectLeft>
        <SelectResumeTitle>{getDocumentName() || uploadedResume?.name}</SelectResumeTitle>
        <SelectResumeDate>
          {i18n.t(`${TRANSLATION}.updated`, { date: formatUpdateDate() })}
        </SelectResumeDate>
        <ButtonsContainer>
          <PreviewButton onClick={onPreview}>
            <Icon24.View />
            {i18n.t(`${TRANSLATION}.preview.button`)}
          </PreviewButton>
          <ChangeResumeButton onClick={() => handleBuilderResumeChange()}>
            {isParserLoading ? <FieldSpinnerLoader /> : i18n.t(`${TRANSLATION}.change_resume`)}
          </ChangeResumeButton>
        </ButtonsContainer>
      </SelectLeft>
      <SelectRight>
        <DocumentPreviewContainer>
          <DocumentPreview showUnavailablePreview={false} src={getThumbnailImg()} />
        </DocumentPreviewContainer>
        <RightBottonShadow />
      </SelectRight>
    </ResumeSelectionContainer>
  )
}
