import { stringifyUrl } from 'query-string'

export type goToBillingPlanPageOptions = {
  referrerPage?: string
  upgradeURL?: string
  document?: string
  documentType?: string
  coverLetterId?: string
  resumeId?: string
  documentTemplate?: string
  documentFormat?: string
  onSuccessRedirect?: string
  plan?: string
  fullQueryString?: string
  forceReferrerPage?: string
}

export const billingPlanPageURL = (options?: goToBillingPlanPageOptions) => {
  let referrerPage = options?.referrerPage
  let basicURL = options?.upgradeURL ? options.upgradeURL : '/app/billing/plans'

  if (localStorage.getItem('provisioning-premium-with-3-auto-apply-credits')) {
    referrerPage = 'careerIOLanding'
  }

  const finalURL = stringifyUrl({
    url: basicURL,
    query: {
      referrerPage: options?.forceReferrerPage ? options.forceReferrerPage : referrerPage,
      on_success_redirect: options?.onSuccessRedirect,
      plan: options?.plan,
      resume_id: options?.resumeId,
      cover_letter_id: options?.coverLetterId,
      document: options?.document,
      document_type: options?.documentType,
      document_format: options?.documentFormat,
      document_template: options?.documentTemplate,
    },
  })

  return finalURL
}

export const goToBillingPlanPage = (options?: goToBillingPlanPageOptions) => {
  window.location.assign(billingPlanPageURL(options))
}
