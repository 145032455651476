import { useResume } from 'builder/hooks/useResume'
import { DocumentTypes } from 'builder/modules/constants'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import { useDocumentPreview } from '../hooks/useDocumentPreview'

import { EmptyPreview } from '../EmptyPreview/EmptyPreview'
import { DocumentHeader } from '../DocumentHeader/DocumentHeader'
import { DocumentLoader } from '../DocumentLoader/DocumentLoader'

import * as Styles from './styles'

type Props = {
  id: number
}

export const ResumePreview = ({ id }: Props) => {
  const resume = useResume(id)
  const { isResumeTailoringEnabled } = useAutoApply()
  const { pages, isLoading } = useDocumentPreview(resume)

  return (
    <Styles.Container>
      <DocumentHeader
        resumeId={id}
        type={DocumentTypes.resume}
        isResumeTailoringEnabled={isResumeTailoringEnabled}
      />
      {isLoading && <DocumentLoader />}
      {!isLoading && !pages.length && <EmptyPreview />}
      {!!pages.length && pages.map(page => <Styles.ResumeImg src={page} key={page} />)}
    </Styles.Container>
  )
}
