import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { usePatchForm } from 'builder/views/AutoApply/hooks/usePatchForm'
import { useGetCareerProfile } from 'builder/views/AutoApply/hooks/useGetCareerProfile'
import { actions as uiActions } from 'builder/modules/ui'
import { DocumentTypes } from 'builder/modules/constants'
import { queryClient } from 'builder/components/Providers/Providers'

import ToggleCard from 'builder/components/ToggleCard'
import AutoTailorIcon from 'builder/views/AutoApply/assets/AutoTailorIcon.png'

import * as Styles from './styles'

const TRANSLATION = 'builder.job_search.auto_apply.tailor_resume'

type Props = {
  showBanner: boolean
  hideBanner: () => void
}

export const TailorResume = ({ showBanner, hideBanner }: Props) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isPhone } = useMediaQueries()
  const { mutateAsync: patchForm } = usePatchForm()
  const { data: careerProfileData } = useGetCareerProfile()
  const [toggle, setToggle] = useState(true)

  const onToggle = (value: boolean) => {
    setToggle(value)
  }

  const onSave = async () => {
    if (toggle && !careerProfileData?.resumeId) {
      dispatch(uiActions.setTailorResumeModalOpen(true))
      return
    }
    await patchForm({ autoTailoring: toggle })
    await queryClient.invalidateQueries(['useGetCareerProfile'])

    trackInternalEvent('auto_tailoring_toggle', {
      label: 'questionnaire job search',
      parameter: toggle ? 'on' : 'off',
    })
    hideBanner()
  }

  const onClickPreview = () => {
    navigate(`/tr-documents/${careerProfileData?.resumeId}?edit=enabled`)
  }

  const getImageSize = () => {
    return {
      width: isPhone ? 110 : 130,
      height: isPhone ? 89 : 75,
    }
  }

  if (!showBanner) return null

  return (
    <Styles.Container>
      <Styles.Header>{i18n.t(`${TRANSLATION}.title`)}</Styles.Header>
      <Styles.ToggleContainer>
        <ToggleCard
          checked={toggle}
          onToggle={onToggle}
          icon={
            <Styles.ToggleCardImage
              $src={AutoTailorIcon}
              $size={getImageSize()}
              $isActive={toggle}
            />
          }
          header={i18n.t(`${TRANSLATION}.toggle_card.header.${toggle}`)}
          description={i18n.t(`${TRANSLATION}.toggle_card.description.${toggle}`)}
        />
      </Styles.ToggleContainer>
      <Styles.ActionContainer>
        <Styles.InfoContainer>
          {careerProfileData?.resumeType === DocumentTypes.trDocument && (
            <Styles.Info
              type="info"
              text={
                <Styles.InfoText>
                  {i18n.t(`${TRANSLATION}.info.first`)} {!isPhone && <br />}
                  <Styles.InfoAction onClick={onClickPreview}>
                    {i18n.t(`${TRANSLATION}.info.action`)}
                  </Styles.InfoAction>
                  {i18n.t(`${TRANSLATION}.info.second`)}
                </Styles.InfoText>
              }
            />
          )}
        </Styles.InfoContainer>
        <Styles.Action onClick={onSave}>{i18n.t(`${TRANSLATION}.action`)}</Styles.Action>
      </Styles.ActionContainer>
    </Styles.Container>
  )
}
