import { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import FocusManager from 'builder/services/FocusManager'
import { SectionNames, selectors, actions } from 'builder/modules/resumeEditor'
import RichTextArea from 'builder/components/RichTextArea'
import {
  SectionBodyFoldableContainer,
  SectionHeader,
  SectionHeaderFoldableContainer,
  SectionHint,
  useFoldableSectionScrollFix,
  WithFoldableSections,
} from 'builder/components/Section'
import { TunerSectionTooltips } from 'builder/components/Tuner'
import SectionTitle, { SectionTitleBadgeProps } from 'builder/components/SectionTitle'
import { useUpdateSimpleField } from 'builder/hooks/useUpdateSimpleField'
import { useRenameSection } from 'builder/hooks/useRenameSection'
import { isEmptyField } from 'builder/modules/resumeEditor/resumeScoreRules/sectionRule'
import { AiKeywordsToggler, useKeywords } from 'builder/components/AiFeatures/Keywords'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useTailoringBannerTriggerConfig } from 'builder/hooks/useTailoringBannerTriggerConfig'
import { RichTextEditorInstanceWrapper, SuggestionConfig } from '../RichTextArea/types'
import { TailorResumeBanner } from '../ImproveResumePanel'
import { SectionContainer, SectionContent } from './styles'

const OPTIMIZER_BANNER_LETTERS_THRESHOLD = 100

const ProfileSummary = (props: WithFoldableSections) => {
  const { isFoldableSectionsEnabled } = props
  const { i18n } = useI18n()
  const richEditor = useRef<RichTextEditorInstanceWrapper>(null)
  const dispatch = useDispatch()
  const { updateSimpleField } = useUpdateSimpleField()
  const { renameSection } = useRenameSection()
  const suggestionLocales = useTypedSelector(state => state.generalEditor.suggestionLocales)
  const { isKeywordsAvailable } = useKeywords()
  const resume = useSelector(selectors.resume)
  const { shouldAnimateEditorFieldContent } = useSelector(selectors.aiProfile)
  const { bannerTriggeredFrom } = useSelector(selectors.optimizerSection)
  const { profile: value, sectionTitles = {}, locale = '', skipOptimizerBanner } = resume || {}
  const customTitle = sectionTitles.profile || ''
  const isProfileEmpty = isEmptyField(resume, SectionNames.profile)
  const textValue = value || ''
  const { isBannerTriggerAvailable } = useTailoringBannerTriggerConfig()
  const openedSection = useTypedSelector(selectors.openedSection)
  const isOpened = openedSection === SectionNames.profile
  const foldableProps = { isFoldableSectionsEnabled, isOpened }
  const elementRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    FocusManager.subscribe(SectionNames.profile, () => {
      if (richEditor.current) richEditor.current.focusEditor()
    })

    return () => FocusManager.unsubscribe(SectionNames.profile)
  }, [])

  useFoldableSectionScrollFix({
    elementRef,
    isOpened,
    isDisabled: !isFoldableSectionsEnabled,
  })

  const handleDescriptionChange = useCallback(
    value => updateSimpleField({ name: 'profile', value, debounce: true }),
    [updateSimpleField],
  )

  const handleRename = useCallback(
    value => {
      renameSection({ id: SectionNames.profile, value })
    },
    [renameSection],
  )

  const suggestionProps = useMemo(() => {
    if (!suggestionLocales.profile || !suggestionLocales.profile.includes(locale)) return {}

    const suggestionConfig: SuggestionConfig = {
      mode: 'phrases',
      scope: 'profile',
      wide: true,
      onOpenAiProfileButtonClick: () => {
        trackInternalEvent('click_writing_help', {
          section: 'profile',
          profile_empty: isProfileEmpty,
        })
      },
      keywordsConfig: isKeywordsAvailable
        ? {
            section: SectionNames.profile,
            buttonText: i18n.t('builder.ai_profile_summary.assistance_modal.add_keywords'),
          }
        : null,
    }

    return { suggestionConfig }
  }, [locale, suggestionLocales.profile, isProfileEmpty, i18n, isKeywordsAvailable])

  const recommendedRangeProps = {
    ranges: {
      bad: {
        start: 1,
        end: 100,
      },
      good: {
        start: 400,
        end: 600,
      },
    },
    recommendedRangeLabel: i18n.t('builder.resume_editor.professional_summary.length_suggestion', {
      count: '400-600',
    }),
  }

  const badgeComponents = useMemo<FC<SectionTitleBadgeProps>[]>(
    () => [
      props => {
        return isKeywordsAvailable ? (
          <AiKeywordsToggler section={SectionNames.profile} {...props} />
        ) : null
      },
    ],
    [isKeywordsAvailable],
  )

  const onAiProfileSummaryAnimationEnd = useCallback(() => {
    dispatch(actions.resetAIProfileSummaryFlow())
  }, [dispatch])

  const onTextLengthChange = useCallback(
    (length: number) => {
      if (
        !isBannerTriggerAvailable ||
        skipOptimizerBanner ||
        (bannerTriggeredFrom && bannerTriggeredFrom !== 'profile')
      ) {
        return
      }

      if (length >= OPTIMIZER_BANNER_LETTERS_THRESHOLD) {
        dispatch(actions.setOptimizerBannerTriggeredFrom('profile'))
      } else {
        dispatch(actions.setOptimizerBannerTriggeredFrom(null))
      }
    },
    [dispatch, skipOptimizerBanner, bannerTriggeredFrom, isBannerTriggerAvailable],
  )

  const handleToggle = useCallback(() => {
    dispatch(actions.openSection(isOpened ? null : SectionNames.profile))
  }, [dispatch, isOpened])

  return (
    <SectionContainer data-highlight-id={SectionNames.profile} ref={elementRef} {...foldableProps}>
      <SectionHeader {...foldableProps} onClick={handleToggle}>
        <SectionTitle
          title={i18n.t('builder.resume_editor.professional_summary.title')}
          customTitle={customTitle}
          onRename={handleRename}
          renamable
          badgeComponents={badgeComponents}
          {...foldableProps}
        />
        <SectionHeaderFoldableContainer {...foldableProps}>
          <SectionHint>{i18n.t('builder.resume_editor.professional_summary.label')}</SectionHint>
        </SectionHeaderFoldableContainer>
      </SectionHeader>
      <SectionBodyFoldableContainer {...foldableProps}>
        <SectionContent>
          <RichTextArea
            instanceRef={richEditor}
            placeholder={i18n.t('builder.resume_editor.professional_summary.placeholder')}
            value={textValue}
            jobTitle={resume ? resume.position : null}
            onChange={handleDescriptionChange}
            locale={locale}
            isAnimating={shouldAnimateEditorFieldContent}
            onAnimationEnd={onAiProfileSummaryAnimationEnd}
            onTextLengthChange={onTextLengthChange}
            {...suggestionProps}
            {...recommendedRangeProps}
          />
        </SectionContent>

        <TunerSectionTooltips sectionId={SectionNames.profile} />

        <TailorResumeBanner
          isVisible={bannerTriggeredFrom === 'profile'}
          transitionProps={{ marginTop: 16 }}
        />
      </SectionBodyFoldableContainer>
    </SectionContainer>
  )
}

export default memo(ProfileSummary)
