import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'

import { useLocation } from 'react-router-dom'
import { useUser } from 'builder/hooks/useUser'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { actions } from 'builder/modules/ui'

import Menu from './Menu'
import { OpenIcon, CloseIcon, Container, CloseButton } from './styles'

interface IProps {
  isOpen: boolean
  setIsOpen: (prop: boolean) => void
}

export const SideMenu = ({ isOpen, setIsOpen }: IProps) => {
  const width = isOpen ? '236px' : '60px'
  const user = useUser()
  const dispatch = useDispatch()
  const { isTablet } = useMediaQueries()
  const containerRef = useRef<HTMLDivElement>(null)
  const { pathname } = useLocation()
  const [mousePosition, setMousePosition] = useState({ clientY: 0 })

  /**
   * Side menu should be closed on the dashboard if the user hasn't completed the stepper.
   * It should be open on all other pages.
   * On tablet, the side menu should always be closed.
   */
  const isOnDashboard = pathname === '/'
  const userHasCompletedTheStepper = user?.uiStates?.dashv2TourCompleted
  const shouldSideMenuBeOpen = useMemo(() => {
    if (isTablet) return false
    if (isOnDashboard && !userHasCompletedTheStepper) return false
    return true
  }, [isOnDashboard, userHasCompletedTheStepper, isTablet])

  const handleMouseMove = (event: React.MouseEvent) => {
    const bounds = containerRef.current?.getBoundingClientRect()
    setMousePosition({ clientY: event.clientY - (bounds?.top || 0) - 10 })
  }

  const openCloseMenu = () => {
    trackInternalEvent(!isOpen ? 'nav_expanded' : 'nav_collapsed')
    dispatch(actions.setSideMenuOpen(!isOpen))
    setIsOpen(!isOpen)
  }

  useEffect(() => setIsOpen(shouldSideMenuBeOpen), [shouldSideMenuBeOpen])

  return (
    <Container ref={containerRef} onMouseMove={handleMouseMove}>
      <Menu id="side-menu-desktop" width={width} isOpen={isOpen} />
      <CloseButton
        data-testid="side-menu-toggle"
        position={!isTablet ? mousePosition.clientY : undefined}
        onClick={openCloseMenu}
      >
        {isOpen ? <CloseIcon data-testid="close-icon" /> : <OpenIcon data-testid="open-icon" />}
      </CloseButton>
    </Container>
  )
}
