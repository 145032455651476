/* eslint-disable max-len */
import { useMemo } from 'react'
import { DocumentTypes } from 'builder/modules/constants'

export const useCoverLetterPreview = () => {
  const text = `
    <p>Dear Hiring Manager,</p>
    <p>I am writing to express my interest in the Manager, Sales and Business Development position at Salesforce. With over 8 years of experience in sales management, I have honed my skills in driving revenue growth and leading high-performing teams. My tenure at Oracle and PepsiCo has equipped me with the expertise necessary to contribute effectively to your esteemed organization.</p>
    <p>During my time as a Sales Manager at Oracle, I successfully achieved a 15% increase in sales revenue in my product pod within a year by establishing strategic sales goals and effectively monitoring competition. At PepsiCo, I managed a team of over 10 sales professionals, developing and implementing sales plans that expanded our B2B customer base and improved retention rates. Additionally, my early experience as a Sales Associate at Marriott International instilled in me a strong commitment to customer service, which I believe is crucial for fostering long-term client relationships.</p>
    <p>I am eager to bring my proven track record of success to Salesforce and would welcome the opportunity to discuss how my background and skills align with your needs. Please feel free to contact me to schedule an interview at your earliest convenience. Thank you for considering my application.</p>
    <p>Sincerely,</p>
    <p>Wes Turner</p>
  `

  return useMemo(() => {
    return {
      type: DocumentTypes.coverLetter,
      id: 0,
      name: null,
      locale: 'en',
      color: '#242935',
      template: 'dublin',
      company: 'Salesforce',
      address: '',
      city: null,
      phoneNumber: '(720) 315-8237',
      email: 'wes.turner@gmail.com',
      senderName: 'Wes Turner',
      companyRecipient: null,
      content: text.trim(),
      applyingPosition: 'Sales Manager',
      version: 'ae9af1426d',
      aiGenerated: false,
      openCl: false,
      sourceResumeId: null,
      spacing: 0,
    }
  }, [text])
}
