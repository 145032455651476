import styled from 'styled-components'
import Media from 'builder/styles/media'

export const ResumeImg = styled.img`
  margin-bottom: 32px;
  width: 100%;
  background-color: white;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 928px;

  ${Media.Tablet`
    width: 100%;
  `}
`
