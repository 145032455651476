import countriesArray from 'builderStatic/js/components/config/countriesArray'
import { AutoApplyForm, Profile } from 'builder/modules/autoApply/types'

const countries = countriesArray
  .sort((a, b) => (b.priority || 0) - (a.priority || 0))
  .map(c => ({ value: c.id, label: c.name }))

export const mapCareerResponse = (response: Profile): AutoApplyForm => {
  if (!response.jobPreferenceAttributes) {
    response.jobPreferenceAttributes = {
      currentSalary: undefined,
      currentCurrency: 'USD',
      currentSalaryPlan: 'annually',
      expectedSalary: undefined,
      expectedCurrency: 'USD',
      expectedSalaryPlan: 'annually',
      expectedWorkingTypes: [],
      willingToRelocate: false,
      willingToRelocateAtYourOwnCost: false,
      expectedStartWork: 'immediate',
      openForBusinessTrips: undefined,
      fullyVaccinatedAgreementAgainstCovid: undefined,
      hasRestrictionLimiting: undefined,
      openToRemoteOpportunities: undefined,
    }
  }

  if (!response.jobPreferenceAttributes.openToRemoteOpportunities) {
    response.jobPreferenceAttributes.openToRemoteOpportunities = false
  }

  if (!response.jobPreferenceAttributes.willingToRelocate) {
    response.jobPreferenceAttributes.willingToRelocate = false
  }

  if (!response.jobPreferenceAttributes?.expectedStartWork) {
    response.jobPreferenceAttributes.expectedStartWork = 'one_month'
  }

  if (response.countriesAuthorizedToWorkIn?.length) {
    const resCountries = response.countriesAuthorizedToWorkIn as unknown as string[]
    response.countriesAuthorizedToWorkIn = countries.filter(item =>
      resCountries.includes(item.value),
    )
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { profilePicture, userType, ...rest } = response

  return rest
}
