import { memo } from 'react'
import ReactMarkdown, { type Components } from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { trackInternalEvent } from '@rio/tracking'
import { Link, Strong, Heading, List } from './styles'

const markdownComponents: Partial<Components> = {
  ul: ({ children, ...props }) => {
    return (
      <List {...props} as="ul">
        {children}
      </List>
    )
  },
  ol: ({ children, ...props }) => {
    return (
      <List {...props} as="ol">
        {children}
      </List>
    )
  },
  strong: ({ children, ...props }) => {
    return <Strong {...props}>{children}</Strong>
  },
  a: ({ children, ...props }) => {
    const trackEvent = () => {
      const payload = {
        name: children.toString(),
        url: props.href,
      }
      trackInternalEvent('chat_click_link', payload)
    }
    return (
      <Link onClick={trackEvent} target="_blank" {...props}>
        {children}
      </Link>
    )
  },
  h1: ({ children, ...props }) => {
    return (
      <Heading {...props} as="h1">
        {children}
      </Heading>
    )
  },
  h2: ({ children, ...props }) => {
    return (
      <Heading {...props} as="h2">
        {children}
      </Heading>
    )
  },
  h3: ({ children, ...props }) => {
    return (
      <Heading {...props} as="h3">
        {children}
      </Heading>
    )
  },
}

const NonMemoizedMarkdown = ({ children }: { children: string }) => {
  return (
    <ReactMarkdown remarkPlugins={[remarkGfm]} components={markdownComponents}>
      {children}
    </ReactMarkdown>
  )
}

const Markdown = memo(
  NonMemoizedMarkdown,
  (prevProps, nextProps) => prevProps.children === nextProps.children,
)

export default Markdown
