import { UseFormReturn } from 'react-hook-form'

import { useI18n } from 'builder/hooks/useI18n'
import { AutoApplyForm } from 'builder/modules/autoApply/types'

import { ToggleCard } from 'builder/components/ToggleCard'
import * as SimpleForm from 'builder/components/SimpleForm'
import { CoverLetterPreview } from '../CoverLetterPreview/CoverLetterPreview'

import CoverLetterIcon from '../../assets/CoverLetterIcon.png'
import { ToggleCardImage } from './styles'

const TRANSLATION = 'builder.auto_apply.form.cover_letter'
type Props = {
  form: UseFormReturn<Pick<AutoApplyForm, 'autoTailoringCl'>>
}

export const CoverLetterForm = ({ form }: Props) => {
  const { register, watch, setValue } = form
  const { i18n } = useI18n()

  const getText = () => {
    const isChecked = watch('autoTailoringCl')
    const textPrefix = isChecked ? 'enabled' : 'disabled'

    return {
      header: i18n.t(`${TRANSLATION}.toggle_card.header.${textPrefix}`),
      description: i18n.t(`${TRANSLATION}.toggle_card.description.${textPrefix}`),
    }
  }

  const onSwitchToggle = (value: boolean) => {
    setValue('autoTailoringCl', value)
  }

  return (
    <>
      <SimpleForm.Row position="relative">
        <CoverLetterPreview />
      </SimpleForm.Row>

      <SimpleForm.Row position="relative">
        <ToggleCard
          {...register('autoTailoringCl')}
          checked={watch('autoTailoringCl')}
          onToggle={onSwitchToggle}
          icon={
            <ToggleCardImage
              $src={CoverLetterIcon}
              $size={{ width: 63, height: 49 }}
              $isActive={watch('autoTailoringCl')}
            />
          }
          header={getText().header}
          description={getText().description}
        />
      </SimpleForm.Row>
    </>
  )
}
