import { HTMLAttributes, ReactNode } from 'react'

import * as Styles from './styles'
import { InfoType } from './types'

type Props = {
  type: InfoType
  text: string | ReactNode
} & HTMLAttributes<HTMLDivElement>

const icons: Record<InfoType, React.ReactNode> = {
  info: <Styles.InfoIcon />,
  success: <Styles.SuccessIcon />,
  warning: <Styles.WarningIcon />,
  error: <Styles.ErrorIcon />,
}

export const InfoBox = ({ type, text, ...rest }: Props) => {
  return (
    <Styles.Container $type={type} {...rest}>
      <Styles.IconContainer>{icons[type]}</Styles.IconContainer>
      <Styles.TextContainer>
        <Styles.Text>{text}</Styles.Text>
      </Styles.TextContainer>
    </Styles.Container>
  )
}
