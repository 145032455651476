import { useQuery } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'
import { Interview } from 'builder/modules/interview/types'
import { POLLING_REFRESH_INTERVAL_IN_MILLIS } from '../constants'
import { getNumberOfQuestionOfInterview } from '../utils/getNumberOfQuestionOfInterview'
interface Props {
  interviewId?: number | string
}

export interface Ready<T> {
  isReady: boolean
  payload: T
}

export const useInterview = ({ interviewId }: Props) => {
  return useQuery<Ready<Interview>>(
    ['useInterview', interviewId],
    async () => {
      const response = await baseClient.get<Interview>(`/interview_prep/interviews/${interviewId}`)
      const maxQuestions = getNumberOfQuestionOfInterview(response.data)
      return {
        isReady: response.data.questions?.length === maxQuestions,
        payload: response.data,
      }
    },
    {
      enabled: !!interviewId,
      refetchInterval: (data?: Ready<Interview>) => {
        if (!data) {
          return POLLING_REFRESH_INTERVAL_IN_MILLIS
        }
        if (data.isReady) {
          return false
        }
        return POLLING_REFRESH_INTERVAL_IN_MILLIS
      },
      useErrorBoundary: false,
    },
  )
}
