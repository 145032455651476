import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { UseFormReturn } from 'react-hook-form'
import { trackInternalEvent } from '@rio/tracking'

import { useUser } from 'builder/hooks/useUser'
import { useI18n } from 'builder/hooks/useI18n'
import { useConfig } from 'builder/hooks/useConfig'
import { ConfigScopesEnum } from 'builder/modules/init'
import { useDocument } from 'builder/hooks/useDocument'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'

import { actions } from 'builder/modules/panel'
import { DocumentTypes } from 'builder/modules/constants'
import { AutoApplyForm } from 'builder/modules/autoApply/types'
import { selectors } from 'builder/modules/autoApply/autoApplyModule'

import { InfoBox } from 'builder/components/InfoBox'
import { ToggleCard } from 'builder/components/ToggleCard'
import * as SimpleForm from 'builder/components/SimpleForm'

import { getHost } from '../../utils'
import { Routes, StartCompleteScreenRoute, TAILOR_RESUME_KEY } from '../../constants'
import { ResumePreview } from '../ResumePreview/ResumePreview'
import AutoTailorIcon from '../../assets/AutoTailorIcon.png'
import { ErrorText, InfoAction, InfoText, Modal, ToggleCardImage } from './styles'

const TRANSLATION = 'builder.auto_apply.form.tailor_resume'
const MODAL_TRANSLATION = 'builder.auto_apply.form.personal_information.preview.modal'
type Props = {
  form: UseFormReturn<Pick<AutoApplyForm, 'autoTailoring'>>
}

export const TailorResumeForm = ({ form }: Props) => {
  const formState = useSelector(selectors.form)
  const { register, watch, setValue } = form

  const user = useUser()
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const uploadedResume = useSelector(selectors.uploadedResume)
  const config = useConfig(ConfigScopesEnum.country)

  const trDocument = useDocument(
    formState.resumeType === DocumentTypes.trDocument ? formState.resumeId : null,
  )

  const getText = () => {
    const isChecked = watch('autoTailoring')
    const textPrefix = isChecked ? 'enabled' : 'disabled'

    return {
      header: i18n.t(`${TRANSLATION}.toggle_card.header.${textPrefix}`),
      description: i18n.t(`${TRANSLATION}.toggle_card.description.${textPrefix}`),
    }
  }

  const onResumeSelect = () => {
    trackInternalEvent('aa_click_change_resume', { label: 'total_job_search_solution' })
    navigate(`/auto-apply/${StartCompleteScreenRoute.RESUME_SELECT}`)
  }

  const onSwitchToggle = (value: boolean) => {
    setValue('autoTailoring', value)
  }

  const getImageSize = () => {
    return {
      width: isPhone ? 110 : 130,
      height: isPhone ? 89 : 75,
    }
  }

  const onCreateResume = () => {
    // Key to use on JobSearchSolutionComponent and submit the resume to auto-apply
    localStorage.setItem(TAILOR_RESUME_KEY, 'true')
    trackInternalEvent('click_new_resume', {
      label: 'total_job_search_solution',
    })
    dispatch(actions.createDocument({ type: DocumentTypes.resume }))
  }

  const navigateToDocumentPreview = (id: number) => {
    const path = `?path=${Routes.TAILOR_RESUME}`
    navigate(`/documents/${id}/${DocumentTypes.resume}/preview${path}`)
  }

  const navigateToTrDocumentPreview = (id: number) => {
    navigate(`/tr-documents/${id}?edit=${watch('autoTailoring') ? 'enabled' : 'disabled'}`)
  }

  const onPreviewDocument = () => {
    const { resumeId, resumeType } = formState

    if (!resumeId || !resumeType) {
      setIsModalOpen(true)
      return
    }

    trackInternalEvent('open_full_screen_resume_preview', { label: 'auto_apply' })
    const isTrDocument = resumeType === DocumentTypes.trDocument

    if (isTrDocument) {
      if (trDocument?.resume_id) {
        navigateToDocumentPreview(trDocument?.resume_id)
      } else {
        navigateToTrDocumentPreview(resumeId)
      }
    } else {
      navigateToDocumentPreview(resumeId)
    }
  }

  useEffect(() => {
    if (formState.id && !formState?.workExperiencesAttributes?.length && watch('autoTailoring')) {
      trackInternalEvent('missing_info_resume')
    }
  }, [])

  return (
    <>
      <ResumePreview
        upladedAt={formState.resumeUploadedAt}
        isParserLoading={false}
        uploadedResume={uploadedResume}
        selectedResumeId={formState.resumeId}
        selectedResumeType={formState.resumeType}
        onClickPreview={onPreviewDocument}
        handleBuilderResumeChange={onResumeSelect}
      />

      {!!formState.id && formState.resumeType === DocumentTypes.trDocument && (
        <SimpleForm.Row position="relative">
          <InfoBox type="info" text={i18n.t(`${TRANSLATION}.info_text`)} />
        </SimpleForm.Row>
      )}

      {!!formState.id && !formState.resumeId && (
        <SimpleForm.Row position="relative">
          <InfoBox
            type="info"
            text={
              <InfoText>
                {i18n.t(`${TRANSLATION}.info.first`, {
                  host: getHost(user?.scopes.kind, config?.name),
                })}{' '}
                <InfoAction onClick={onCreateResume}>
                  {i18n.t(`${TRANSLATION}.info.second`)}
                </InfoAction>{' '}
                {i18n.t(`${TRANSLATION}.info.third`)}
              </InfoText>
            }
          />
        </SimpleForm.Row>
      )}

      <SimpleForm.Row position="relative">
        <ToggleCard
          {...register('autoTailoring')}
          checked={watch('autoTailoring')}
          onToggle={onSwitchToggle}
          icon={
            <ToggleCardImage
              $src={AutoTailorIcon}
              $size={getImageSize()}
              $isActive={watch('autoTailoring')}
            />
          }
          header={getText().header}
          description={getText().description}
        />

        {!!formState.id &&
          !!formState.resumeId &&
          !formState?.workExperiencesAttributes?.length &&
          watch('autoTailoring') && (
            <ErrorText>{i18n.t(`${TRANSLATION}.job_experince_error`)}</ErrorText>
          )}

        {!!formState.id && !formState.resumeId && watch('autoTailoring') && (
          <ErrorText>
            {i18n.t(`${TRANSLATION}.upload_file_error`, {
              host: getHost(user?.scopes.kind, config?.name),
            })}
          </ErrorText>
        )}
      </SimpleForm.Row>
      {isModalOpen && (
        <Modal
          title={i18n.t(`${MODAL_TRANSLATION}.title`)}
          description={i18n.t(`${MODAL_TRANSLATION}.content`)}
          cancelButton={i18n.t(`${MODAL_TRANSLATION}.cancel`)}
          successButton={i18n.t(`${MODAL_TRANSLATION}.confirm`)}
          onClose={() => setIsModalOpen(false)}
          onCancel={() => setIsModalOpen(false)}
          onSuccess={onResumeSelect}
        />
      )}
    </>
  )
}
