import { FormEvent, useEffect, useRef, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { baseClient } from 'builder/modules/apiClient'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { State, VirtualCoach, LayoutVariant } from './types'
import { DEFAULT_STATE, CLIENT_STORAGE_KEY, API_ENDPOIT, ERROR_MESSAGE } from './constants'

export function usePersistedCoachState<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch {
      return initialValue
    }
  })

  useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(storedValue))
    } catch (err) {
      console.error(`Error setting localStorage for ${key}:`, err)
    }
  }, [key, storedValue])

  return [storedValue, setStoredValue] as const
}

export function useVirtualCoach(): VirtualCoach {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [state, setState] = usePersistedCoachState<State>(CLIENT_STORAGE_KEY, DEFAULT_STATE)

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setLoading(true)
    setError(null)

    const previousState = [...state]

    try {
      const form = e.currentTarget
      const formData = new FormData(form)
      const prompt = formData.get('prompt') as string

      setState(prev => [...prev, { role: 'user', content: prompt }])
      trackInternalEvent('chat_send_question', { question: prompt })

      form.reset()
      const lastTenMessages = state.slice(-10)
      const { data } = await baseClient.post(API_ENDPOIT, {
        prompt,
        chat_history: JSON.stringify(lastTenMessages),
      })

      if (!data.success) throw new Error(data.body)

      const answer = data.body.response
      setState(prev => [...prev, { role: 'assistant', content: answer }])
      trackInternalEvent('chat_answer_received', { answer: answer })
    } catch (err) {
      console.error(err)
      setError(ERROR_MESSAGE)
      setState(previousState)
      trackInternalEvent('chat_error')
    } finally {
      setLoading(false)
    }
  }

  return { state, handleSubmit, loading, error }
}

export function useChatScroll(props: { open: boolean; message: State }) {
  const [showScrollIndicator, setShowScrollIndicator] = useState(false)
  const chatBodyRef = useRef<HTMLDivElement | null>(null)

  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTo({
        top: chatBodyRef.current.scrollHeight,
        behavior: 'smooth',
      })
    }
  }

  const trackScroll = () => {
    if (chatBodyRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatBodyRef.current
      const isAtBottom = scrollTop + clientHeight > scrollHeight - 100

      setShowScrollIndicator(!isAtBottom)
    }
  }

  useEffect(() => {
    if (props.open && chatBodyRef.current) {
      scrollToBottom()
    }
  }, [props.message, props.open])

  return { chatBodyRef, showScrollIndicator, trackScroll, scrollToBottom }
}

export function useLayoutVariant(variant: LayoutVariant) {
  const [layoutVariant, setLayoutVariant] = useState<LayoutVariant>(variant)
  const prevLayoutRef = useRef<LayoutVariant | null>(null)

  const toggleFullScreen = () => {
    if (layoutVariant === 'fullscreen') {
      setLayoutVariant(prevLayoutRef.current || 'default')
      trackInternalEvent('chat_collapsed')
      prevLayoutRef.current = null
    } else {
      setLayoutVariant('fullscreen')
      trackInternalEvent('chat_expanded')
      prevLayoutRef.current = layoutVariant
    }
  }

  return {
    layoutVariant,
    toggleFullScreen,
  }
}

export function useVirtualCoachFeature() {
  const { features } = useFeaturesConfig()
  const isVirtualCoachEnabled = Boolean(features.aiCoach)

  return { isVirtualCoachEnabled }
}
