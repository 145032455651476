import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

import { ResumeDistributionView } from './ResumeDistributionView'
import { StartScreenView } from './components/StartScreen/StartScreenView'
import { ResumeSelectView } from './components/ResumeSelect/ResumeSelectView'
import { CoverLetterContainer } from './components/Form/CoverLetterContainer'
import { TailorResumeContainer } from './components/Form/TailorResumeContainer'
import { ContactInfoFormContainer } from './components/Form/ContactInfoFormContainer'
import { PersonalInfoFormContainer } from './components/Form/PersonalInfoFormContainer'
import { JobPreferencesForm1Container } from './components/Form/JobPreferencesForm1Container'
import { JobPreferencesForm2Container } from './components/Form/JobPreferencesForm2Container'
import { AutoApplyCompletedView } from './components/AutoApplyCompleted/AutoApplyCompleted'

import { useGetCareerProfile } from './hooks/useGetCareerProfile'
import {
  Routes as AutoApplyRoutes,
  StartCompleteScreenRoute,
  AUTO_APPLY_VISITED_KEY,
} from './constants'

export const AutoApplyView = () => {
  useGetCareerProfile()

  useEffect(() => {
    localStorage.setItem(AUTO_APPLY_VISITED_KEY, 'true')
  }, [])

  return (
    <Routes>
      <Route path={`${StartCompleteScreenRoute.START_SCREEN}`} element={<StartScreenView />} />
      <Route path={`${StartCompleteScreenRoute.RESUME_SELECT}`} element={<ResumeSelectView />} />
      <Route path={`${AutoApplyRoutes.TAILOR_RESUME}`} element={<TailorResumeContainer />} />
      <Route path={`${AutoApplyRoutes.COVER_LETTER}`} element={<CoverLetterContainer />} />
      <Route path={`${AutoApplyRoutes.PERSONAL_INFO}`} element={<PersonalInfoFormContainer />} />
      <Route path={`${AutoApplyRoutes.CONTACT_INFO}`} element={<ContactInfoFormContainer />} />
      <Route
        path={`${AutoApplyRoutes.JOB_PREFERENCES_1}`}
        element={<JobPreferencesForm1Container />}
      />
      <Route
        path={`${AutoApplyRoutes.JOB_PREFERENCES_2}`}
        element={<JobPreferencesForm2Container />}
      />
      <Route path={`${StartCompleteScreenRoute.COMPLETED}`} element={<AutoApplyCompletedView />} />

      <Route
        path={`${StartCompleteScreenRoute.RESUME_DISTRIBUTION}/*`}
        element={<ResumeDistributionView />}
      />
      <Route path="*" element={<div></div>} />
    </Routes>
  )
}
