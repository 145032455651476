import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Wrapper = styled.div`
  background: ${Colors.White};
  z-index: 1;

  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`

export const Content = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 20px;
`

export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.Medium};
  margin-top: 30px;
  margin-bottom: 15px;
  max-width: 70%;
  text-align: center;
`

export const Description = styled.div<{ $width?: number }>`
  color: ${Colors.Neutral50};
  max-width: ${({ $width }) => $width || 420}px;
  text-align: center;
`
