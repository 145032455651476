import { trackInternalEvent } from '@rio/tracking'
import { SPACING } from 'builder/modules/constants'
import { useUpdateSimpleField } from 'builder/hooks/useUpdateSimpleField'
import { TooltipV2 } from 'builder/components/Tooltip'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTemplateCustomization } from '../../hooks/useTemplateCustomization'
import { useTextCustomization } from '../../hooks/useTextCustomization'
import { useUpdateTemplateSettings } from '../../hooks/useUpdateTemplateSettings'
import { SliderContainer, Slider, ValueContainer, Value } from './styles'
import { SliderHandle } from './SliderHandle'

const normalize = (value: number) => Math.min(Math.max(value, SPACING.min), SPACING.max)
// Converts the value to a user-friendly format [50%, 150%]
const prettify = (value: number) => `${100 + Math.round(50 * value)}%`

export const SpacingSlider = () => {
  const { supportsNewCustomization, templatesSettings, templateName } = useTemplateCustomization()
  const { supportsSpacing, spacing } = useTextCustomization()
  const { updateSimpleField } = useUpdateSimpleField()
  const { updateTemplateSettings } = useUpdateTemplateSettings()
  const { i18n } = useI18n()
  const { isAboveTablet } = useMediaQueries()

  const handleSpacingChange = (spacingNew: number) => {
    if (supportsNewCustomization) {
      if (!templateName || typeof spacingNew !== 'number' || !templatesSettings) return
      updateTemplateSettings({
        templatesSettings,
        templateName,
        settingsName: 'lineSpacing',
        value: spacingNew,
      })
    } else {
      updateSimpleField({ name: 'spacing', value: normalize(spacingNew), debounce: true })
    }
    trackInternalEvent('change_line_spacing', {
      label: 'templates',
      direction: spacingNew > spacing ? 'increase' : 'decrease',
      old_value: spacing,
      new_value: spacingNew,
    })
  }

  return (
    <TooltipV2
      value={i18n.t(
        'builder.resume_editor.document_customization.sidebar.text_tab.spacing_customization_warning',
      )}
      width={250}
      multiline={true}
      position="bottom"
      isActive={!supportsSpacing}
    >
      <SliderContainer>
        {!isAboveTablet && (
          <ValueContainer>
            <Value>{prettify(SPACING.min)}</Value>
          </ValueContainer>
        )}
        <Slider
          disabled={!supportsSpacing}
          defaultValue={0}
          value={spacing as number}
          step={SPACING.step}
          min={SPACING.min}
          max={SPACING.max}
          handle={SliderHandle}
          onChange={handleSpacingChange}
          dots
        />
        {!isAboveTablet && (
          <ValueContainer>
            <Value>{prettify(SPACING.max)}</Value>
          </ValueContainer>
        )}
        {isAboveTablet && (
          <ValueContainer>
            <Value>{prettify(spacing as number)}</Value>
          </ValueContainer>
        )}
      </SliderContainer>
    </TooltipV2>
  )
}
