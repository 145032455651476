import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const ColorsContainer = styled.div<{ isScrolled: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ isScrolled }) => (isScrolled ? '0' : '16px')};
  margin-top: ${({ isScrolled }) => (isScrolled ? '12px' : '32px')};
  transition: all 0.3s ease-in-out;
`

export const ColorsLabel = styled.p<{ isScrolled: boolean }>`
  opacity: ${({ isScrolled }) => (isScrolled ? 0 : 1)};
  visibility: ${({ isScrolled }) => (isScrolled ? 'hidden' : 'visible')};
  height: ${({ isScrolled }) => (isScrolled ? 0 : 'auto')};
  overflow: hidden;
  color: ${Colors.Neutral50};
  ${Typography.Caps};
  ${FontWeights.DemiBold};
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, height 0.3s ease-in-out;
`
