import noPreviewImg from '../assets/no-preview.svg'
import * as Styles from './styles'

export const EmptyPreview = () => {
  return (
    <Styles.NoPreviewContainer>
      <Styles.NoPreviewBackground>
        <Styles.NoPreviewImg src={noPreviewImg} />
        <Styles.NoPreviewText>Sorry, we can’t show preview of this file</Styles.NoPreviewText>
      </Styles.NoPreviewBackground>
    </Styles.NoPreviewContainer>
  )
}
