import { useNavigate, useSearchParams } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import Logo from 'builder/components/Logo'
import Icon24 from 'builder/components/Icon'

import { useI18n } from 'builder/hooks/useI18n'
import { DocumentTypes } from 'builder/modules/constants'
import { Routes, TAILOR_RESUME_KEY } from 'builder/views/AutoApply/constants'
import { useStepsConfig } from 'builder/views/AutoApply/hooks/useStepsConfig'

import * as Styles from './styles'

type Props = {
  type: DocumentTypes
  resumeId?: number
  isResumeTailoringEnabled?: boolean
}

export const DocumentHeader = ({ type, resumeId, isResumeTailoringEnabled }: Props) => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const stepsConfig = useStepsConfig()
  const [searchParams] = useSearchParams()
  const path = searchParams.get('path')

  const onClickBack = () => {
    navigate(path ? `/auto-apply/${path}` : '/')
  }

  const onClickContinue = () => {
    if (isResumeTailoringEnabled && resumeId) {
      trackInternalEvent('visit_resume_editor', { label: 'auto_apply' })
      localStorage.setItem(TAILOR_RESUME_KEY, 'true')
      navigate(`/resumes/${resumeId}/edit`)
      return
    }
    if (!path) return
    navigate(`/auto-apply/${stepsConfig[path as Routes].next}`)
  }

  return (
    <Styles.Header>
      <Styles.BackButton onClick={onClickBack}>
        <Styles.ChevronLeft>
          <Icon24.Chevron />
        </Styles.ChevronLeft>
        {i18n.t('builder.auto_apply.preview_document.back')}
      </Styles.BackButton>
      <Logo />
      <Styles.ButtonContainer>
        <Styles.ActionButton onClick={onClickContinue}>
          {isResumeTailoringEnabled && resumeId
            ? i18n.t(`builder.auto_apply.preview_document.edit_resume`)
            : i18n.t(`builder.auto_apply.preview_document.continue_${type}`)}
        </Styles.ActionButton>
      </Styles.ButtonContainer>
    </Styles.Header>
  )
}
