import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { AutoApplyForm } from 'builder/modules/autoApply/types'

import * as SimpleForm from 'builder/components/SimpleForm'
import { Routes, TotalPageCount } from '../../constants'
import { Container, FillWarning, LayoutContainer, Title, TitleWrapper } from '../styles'
import { ConfirmModal } from '../ConfirmModal/ConfirmModal'
import { usePatchForm } from '../../hooks/usePatchForm'
import { usePrompt } from '../../hooks/usePrompt'
import { useStepsConfig } from '../../hooks/useStepsConfig'
import { CoverLetterForm } from './CoverLetterForm'
import { FormContainer } from './styles'

const TRANSLATION = 'builder.auto_apply.form.cover_letter'
type TailorResume = Pick<AutoApplyForm, 'autoTailoringCl'>

export const CoverLetterContainer = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const stepsConfig = useStepsConfig()

  const form = useForm<TailorResume>({ mode: 'onChange', defaultValues: { autoTailoringCl: true } })
  const { mutateAsync: patchForm, isLoading } = usePatchForm()
  const { isPromptVisible, handleCancel, handleConfirm } = usePrompt({
    when: true,
    ignoreParentRoute: ['auto-apply', 'preview'],
  })

  const onNextStep = async () => {
    const payload = form.getValues()
    await patchForm(payload)

    trackInternalEvent('cover_letter_toggle', {
      label: payload.autoTailoringCl ? 'on' : 'off',
    })

    navigate(`/auto-apply/${stepsConfig[Routes.COVER_LETTER].next}`)
  }

  const onBackClick = () => {
    form.clearErrors()
    navigate(`/auto-apply/${stepsConfig[Routes.COVER_LETTER].prev}`)
  }

  return (
    <LayoutContainer>
      <Container>
        <TitleWrapper>
          <Title>{i18n.t(`${TRANSLATION}.title`)}</Title>
          <FillWarning>{i18n.t(`${TRANSLATION}.sub_title`)}</FillWarning>
        </TitleWrapper>
        <FormContainer>
          <SimpleForm.Form
            submitButtonText={i18n.t(`${TRANSLATION}.next`)}
            onBackClick={onBackClick}
            onSubmit={form.handleSubmit(onNextStep)}
            isPending={isLoading}
            processIndicator={{
              totalStep: TotalPageCount,
              currentStep: stepsConfig[Routes.COVER_LETTER].order,
            }}
          >
            <CoverLetterForm form={form} />
          </SimpleForm.Form>
        </FormContainer>
      </Container>
      {isPromptVisible && <ConfirmModal onClose={handleCancel} onConfirm={handleConfirm} />}
    </LayoutContainer>
  )
}
