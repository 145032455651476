import React, { useState } from 'react'
import { Toggle, ToggleProps } from '../Toggle/Toggle'
import * as Styles from './styles'

type Ref = React.ForwardedRef<HTMLInputElement | null>
type Props = {
  icon: React.ReactNode
  header: string | React.ReactNode
  description: string | React.ReactNode
  onToggle: (value: boolean) => void
} & ToggleProps

export const ToggleCard = React.forwardRef(
  ({ icon, header, description, onToggle, ...rest }: Props, ref: Ref) => {
    const [checked, setChecked] = useState(rest.checked)

    const onClickCard = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault()
      onToggle(!checked)
      setChecked(!checked)
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onToggle(e.target.checked)
      setChecked(e.target.checked)
    }

    return (
      <Styles.Conatiner onClick={onClickCard}>
        <Styles.InfoContainer>
          <Styles.IconContainer key={`icon_${checked?.toString()}`}>{icon}</Styles.IconContainer>
          <Styles.TextContainer key={`text_${checked?.toString()}`}>
            <Styles.Header>{header}</Styles.Header>
            <Styles.Description>{description}</Styles.Description>
          </Styles.TextContainer>
        </Styles.InfoContainer>
        <Styles.ToggleContainer>
          <Toggle ref={ref} {...rest} checked={checked} onChange={onChange} />
        </Styles.ToggleContainer>
      </Styles.Conatiner>
    )
  },
)
