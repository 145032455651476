import { useDispatch } from 'react-redux'

import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions as uiActions, selectors as uiSelectors } from 'builder/modules/ui'
import { Modal } from './styles'

const TRANSLATION = 'builder.job_search.auto_apply.tailor_resume'

export const ConfirmModal = () => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()

  const isTailorResumeModalOpen = useTypedSelector(uiSelectors.isTailorResumeModalOpen)

  const onCloseModal = () => {
    dispatch(uiActions.setTailorResumeModalOpen(false))
  }

  const onSuccessModal = () => {
    dispatch(uiActions.setTailorResumeModalOpen(false))
    dispatch(uiActions.setTailorResumeSelectModal(true))
  }

  if (!isTailorResumeModalOpen) return null

  return (
    <Modal
      title={i18n.t(`${TRANSLATION}.modal.title`)}
      description={i18n.t(`${TRANSLATION}.modal.description`)}
      cancelButton={i18n.t(`${TRANSLATION}.modal.cancel`)}
      successButton={i18n.t(`${TRANSLATION}.modal.success`)}
      onClose={onCloseModal}
      onCancel={onCloseModal}
      onSuccess={onSuccessModal}
    />
  )
}
