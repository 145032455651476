import { useCallback } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { TooltipV2 } from 'builder/components/Tooltip'
import { SelectValue } from 'builder/components/Select/types'
import { SelectContainer, SelectLabel, CustomSelect } from '../../styles'
import { useTemplateCustomization } from '../../hooks/useTemplateCustomization'
import { useTextCustomization } from '../../hooks/useTextCustomization'
import { useUpdateTemplateSettings } from '../../hooks/useUpdateTemplateSettings'
import { SpacingSlider } from '../CustomizationSlider/SpacingSlider'
import { SizeSlider } from '../CustomizationSlider/SizeSlider'
import { FontType } from '../../constants'
import { Container, SliderContainer, SliderLabel, FontContainer } from './styles'

const TRANSLATION = 'builder.resume_editor.document_customization.sidebar.text_tab'

export const TextCustomization = () => {
  const { i18n } = useI18n()
  const { templateName, templatesSettings } = useTemplateCustomization()
  const {
    primaryFonts,
    secondaryFonts,
    tertiaryFonts,
    isPrimaryFontCustomizationEnabled,
    isSecondaryFontCustomizationEnabled,
    isTertiaryFontCustomizationEnabled,
    selectedPrimaryFont,
    selectedSecondaryFont,
    selectedTertiaryFont,
  } = useTextCustomization()
  const { updateTemplateSettings } = useUpdateTemplateSettings()

  const handleFontChange = useCallback(
    (value: SelectValue, fontType) => {
      if (!templateName || typeof value !== 'string' || !templatesSettings || !fontType) return

      const oldValue =
        fontType === 'primaryFont'
          ? selectedPrimaryFont
          : fontType === 'secondaryFont'
          ? selectedSecondaryFont
          : selectedTertiaryFont

      const eventFontType =
        fontType === 'primaryFont'
          ? 'primary'
          : fontType === 'secondaryFont'
          ? 'secondary'
          : 'tertiary'

      updateTemplateSettings({
        templatesSettings,
        templateName,
        settingsName: fontType,
        value,
      })

      trackInternalEvent('change_resume_font', {
        label: eventFontType,
        old_value: oldValue,
        new_value: value,
        template: templateName,
      })
    },
    [
      templateName,
      templatesSettings,
      selectedPrimaryFont,
      selectedSecondaryFont,
      selectedTertiaryFont,
      updateTemplateSettings,
    ],
  )

  const handleFontSelectionClick = (fontType: FontType): void => {
    trackInternalEvent('click_resume_font_menu', { label: fontType, template: templateName })
  }

  return (
    <Container>
      <FontContainer>
        {primaryFonts?.length > 0 && (
          <TooltipV2
            value={i18n.t(`${TRANSLATION}.font_customization_warning`)}
            width={210}
            multiline={true}
            position="bottom"
            isActive={!isPrimaryFontCustomizationEnabled}
          >
            <SelectContainer>
              <SelectLabel>
                {i18n.t(`${TRANSLATION}.${secondaryFonts?.length > 0 ? 'primary_font' : 'font'}`)}
              </SelectLabel>
              <CustomSelect
                selected={selectedPrimaryFont}
                onSelect={value => handleFontChange(value, 'primaryFont')}
                options={primaryFonts}
                buttonOnClick={() => handleFontSelectionClick('primary')}
                disabled={!isPrimaryFontCustomizationEnabled}
                useNativeSelectOnMobile={false}
                optionsListClassname="dark"
              />
            </SelectContainer>
          </TooltipV2>
        )}
        {secondaryFonts?.length > 0 && (
          <TooltipV2
            value={i18n.t(`${TRANSLATION}.font_customization_warning`)}
            width={210}
            multiline={true}
            position="bottom"
            isActive={!isSecondaryFontCustomizationEnabled}
          >
            <SelectContainer>
              <SelectLabel>{i18n.t(`${TRANSLATION}.secondary_font`)}</SelectLabel>
              <CustomSelect
                selected={selectedSecondaryFont}
                onSelect={value => handleFontChange(value, 'secondaryFont')}
                options={secondaryFonts}
                disabled={!isSecondaryFontCustomizationEnabled}
                buttonOnClick={() => handleFontSelectionClick('secondary')}
                useNativeSelectOnMobile={false}
              />
            </SelectContainer>
          </TooltipV2>
        )}
        {tertiaryFonts?.length > 0 && (
          <TooltipV2
            value={i18n.t(`${TRANSLATION}.font_customization_warning`)}
            width={210}
            multiline={true}
            position="bottom"
            isActive={!isTertiaryFontCustomizationEnabled}
          >
            <SelectContainer>
              <SelectLabel>{i18n.t(`${TRANSLATION}.tertiary_font`)}</SelectLabel>
              <CustomSelect
                selected={selectedTertiaryFont}
                onSelect={value => handleFontChange(value, 'tertiaryFonts')}
                options={tertiaryFonts}
                disabled={!isTertiaryFontCustomizationEnabled}
                buttonOnClick={() => handleFontSelectionClick('tertiary')}
                useNativeSelectOnMobile={false}
              />
            </SelectContainer>
          </TooltipV2>
        )}
      </FontContainer>
      <SliderContainer>
        <SliderLabel>{i18n.t(`${TRANSLATION}.size`)}</SliderLabel>
        <SizeSlider />
      </SliderContainer>
      <SliderContainer>
        <SliderLabel>{i18n.t(`${TRANSLATION}.spacing`)}</SliderLabel>
        <SpacingSlider />
      </SliderContainer>
    </Container>
  )
}
