import styled, { css } from 'styled-components'
import Sizes from 'builder/styles/sizes'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

export const Container = styled.div<{ $setCenter?: boolean }>`
  display: flex;
  gap: 4px;
  justify-content: center;
  margin: 0 0 ${Sizes.M};

  ${({ $setCenter }) =>
    $setCenter &&
    css`
      align-items: center;
    `}
`

export const IconContainer = styled.div``
export const AutoApplyInfoText = styled.p`
  ${Typography.Caption};
  ${FontWeights.Regular}
  color: ${Colors.Neutral80};
`

export const AutoApplyInfoLink = styled.span`
  ${Typography.Caption};
  ${FontWeights.Regular}
  color: ${Colors.Blue50};
  cursor: pointer;
`
