import styled from 'styled-components'

import InfoBox from 'builder/components/InfoBox'
import ModalComponent from 'builder/components/ConfirmModal'
import Button, { ButtonSize } from 'builder/components/Button'

import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import DefaultIcon from 'builder/views/AutoApply/assets/DefaultIcon.png'

type ToggleIconType = {
  $isActive?: boolean
  $src?: string
  $size: {
    width: number
    height: number
  }
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 -32px 32px;
  padding: 20px 32px;
  background-color: ${Colors.Neutral5};
`

export const Header = styled.div`
  ${Typography.Subhead}
  ${FontWeights.Medium}

  color: ${Colors.Neutral90};
`

export const ToggleContainer = styled.div``
export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;

  ${Media.Phone`
    gap: 8px;
    flex-direction: column;
    align-items: flex-end;
  `}
`

export const InfoContainer = styled.div`
  width: 100%;
`

export const Info = styled(InfoBox)`
  padding: 4px 8px;
  width: 100%;
`

export const InfoText = styled.p`
  ${Typography.Tiny}
  ${FontWeights.Regular}

  color: ${Colors.Blue80};
`

export const InfoAction = styled.span`
  margin-right: 3px;
  cursor: pointer;

  ${Typography.Tiny}
  ${FontWeights.Regular}

  color: ${Colors.Blue50};
`

export const Action = styled(Button).attrs({ size: ButtonSize.small })`
  height: fit-content;
  width: fit-content;
  flex: end;
`

export const ToggleCardImage = styled.div<ToggleIconType>`
  width: ${({ $isActive, $size }) => ($isActive ? `${$size.width}px` : '65px')};
  height: ${({ $isActive, $size }) => ($isActive ? `${$size.height}px` : '56px')};

  background-size: contain;
  background-position: center;
  background-image: url(${({ $isActive, $src }) => ($isActive ? $src : DefaultIcon)});
`

export const Modal = styled(ModalComponent)`
  padding: 32px;
  gap: 32px;

  ${Media.Phone`
    width: 100%;
    padding: 20px;
  `}
`
