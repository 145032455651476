import styled from 'styled-components'
import Colors from 'builder/styles/colors'

export const Container = styled.div<{ isScrolled: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 88vh;
  gap: ${({ isScrolled }) => (isScrolled ? '12px' : '32px')};
`

const Scroll = styled.div`
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.Neutral80};
    border: 4px solid transparent;
    border-radius: 4px;

    &:hover {
      background-color: ${Colors.Neutral100};
    }
  }
`

export const TemplateSelectionContainer = styled(Scroll)`
  height: 100%;
  padding: 0 32px;
  margin-right: 5px;
`
