import { Routes, StartCompleteScreenRoute, StepsConfig } from '../constants'

export const useStepsConfig = (): StepsConfig => {
  const stepsConfig: StepsConfig = {
    [StartCompleteScreenRoute.RESUME_SELECT]: {
      next: Routes.TAILOR_RESUME,
      prev: StartCompleteScreenRoute.RESUME_SELECT,
      order: 0,
    },
    [Routes.TAILOR_RESUME]: {
      next: Routes.COVER_LETTER,
      prev: StartCompleteScreenRoute.RESUME_SELECT,
      order: 0,
    },
    [Routes.COVER_LETTER]: {
      next: Routes.PERSONAL_INFO,
      prev: Routes.TAILOR_RESUME,
      order: 1,
    },
    [Routes.PERSONAL_INFO]: {
      next: Routes.CONTACT_INFO,
      prev: Routes.COVER_LETTER,
      order: 2,
    },
    [Routes.CONTACT_INFO]: {
      next: Routes.JOB_PREFERENCES_1,
      prev: Routes.PERSONAL_INFO,
      order: 3,
    },
    [Routes.JOB_PREFERENCES_1]: {
      next: Routes.JOB_PREFERENCES_2,
      prev: Routes.CONTACT_INFO,
      order: 4,
    },
    [Routes.JOB_PREFERENCES_2]: {
      next: StartCompleteScreenRoute.COMPLETED,
      prev: Routes.JOB_PREFERENCES_1,
      order: 5,
    },
  }

  return stepsConfig
}
