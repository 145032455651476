import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import { DocumentTypes, FetchStatuses } from 'builder/modules/constants'
import { selectors, actions as documentActions } from 'builder/modules/panel'

import TrResume from '../../assets/img/TrImage.png'
import ResumeOneImg from '../../assets/img/main_resume.png'
import ResumeTwoImg from '../../assets/img/slant_resume.png'
import { TRANSLATION } from '../../ResumeDistributionView'

import * as Styles from './styles'
import { LoadingCard } from './LoadingCard'

type Props = {
  onCardClick: () => void
}

export const ResumeSelect = ({ onCardClick }: Props) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()

  const documentsStatus = useTypedSelector(selectors.documentsStatus)
  const trDocuments = useTypedSelector(store =>
    selectors.documentsByType(store, DocumentTypes.trDocument),
  )

  useEffect(() => {
    dispatch(documentActions.fetchDocuments())
  }, [dispatch])

  if (documentsStatus === FetchStatuses.loading) {
    return <LoadingCard />
  }

  return (
    <>
      {trDocuments.length ? (
        <Styles.Card onClick={onCardClick}>
          <Styles.FakeResume>
            <Styles.TrImage src={TrResume} />
            <Styles.FadeResume />
          </Styles.FakeResume>
          <Styles.CardTitle>{i18n.t(`${TRANSLATION}.select_from_tr`)}</Styles.CardTitle>
          <Styles.CardText $isTrCard>
            {i18n.t(`${TRANSLATION}.select_from_tr_text`)}
          </Styles.CardText>
          <Styles.TrResumeLabel>
            <Styles.TrResumeLabelIcon />
            <Styles.TrResumeLabelText>
              {i18n.t(`builder.resume_distribution.landing.modal.tr_banner`)}
            </Styles.TrResumeLabelText>
          </Styles.TrResumeLabel>
        </Styles.Card>
      ) : (
        <Styles.Card onClick={onCardClick}>
          <Styles.SelectResumeOneImage src={ResumeOneImg} />
          <Styles.SelectResumeTwoImage src={ResumeTwoImg} />
          <Styles.HollowPlaceholder />
          <Styles.BlurRegion />
          <Styles.WhiteRegion />
          <Styles.CardTitle>{i18n.t(`${TRANSLATION}.select_from_builder`)}</Styles.CardTitle>
          <Styles.CardText>{i18n.t(`${TRANSLATION}.select_from_text`)}</Styles.CardText>
        </Styles.Card>
      )}
    </>
  )
}
