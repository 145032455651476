import { memo } from 'react'
import TemplateSettingsSheet from 'builder/components/TemplateSettingsSheet'
import { DocumentTypes } from 'builder/modules/constants'
import { TemplateCustomizationSheet } from '../DocumentCustomizationModal/components/TemplateCustomizationSheet'
import { Container } from './styles'
import { useDocumentPreview } from './useDocumentPreview'
import PreviewToolbar from './PreviewToolbar'
import PreviewContent from './PreviewContent'
import { DocumentPreviewModalProps } from './types'

export const DocumentPreviewModal = memo((props: DocumentPreviewModalProps) => {
  const {
    updateTemplate,
    editorState,
    currentDocument,
    type,
    isDownloading,
    onClose,
    updateSimpleField,
    onDownload,
  } = props

  const {
    isReadyToCoReg,
    isCoRegPopupOpen,
    toggleCoRegPopup,
    openCoReg,
    currentPage,
    totalPagesNumber,
    isSheetVisible,
    isActionMenuOpen,
    width,
    height,
    contentRef,
    previewBoxRef,
    previewRef,
    handlePreviewResize,
    handleDownloadDocument,
    handleColorSelect,
    handlePaginationStateChange,
    handleSpacingChange,
    handlePreviousPageClick,
    handleNextPageClick,
    handleSheetOpen,
    handleSheetClose,
    handleActionMenuOpen,
    handleActionMenuClose,
    handleShareClick,
    isResume,
    isDataLoaded,
    templates,
    currentTemplate,
    colorSettings,
    showUpdatedMenuLayout,
    isNewCustControlsEnabled,
  } = useDocumentPreview({
    type,
    currentDocument,
    editorState,
    onDownload,
    updateSimpleField,
    onClose,
  })

  return (
    <Container>
      <PreviewToolbar
        {...{
          type,
          isResume,
          isDownloading,
          currentDocument,
          handleSheetOpen,
          handleDownloadDocument,
          handleActionMenuClose,
          handleShareClick,
          handleActionMenuOpen,
          isReadyToCoReg,
          isActionMenuOpen,
          isCoRegPopupOpen,
          openCoReg,
          toggleCoRegPopup,
          onClose,
          showUpdatedMenuLayout,
        }}
      />

      <PreviewContent
        {...{
          contentRef,
          previewBoxRef,
          previewRef,
          type,
          width,
          height,
          isDataLoaded,
          currentDocument,
          currentTemplate,
          currentPage,
          totalPagesNumber,
          handlePreviousPageClick,
          handleNextPageClick,
          handlePaginationStateChange,
          handlePreviewResize,
        }}
      />

      {isDataLoaded &&
        isSheetVisible &&
        (isNewCustControlsEnabled && type === DocumentTypes.resume ? (
          <TemplateCustomizationSheet onClose={handleSheetClose} type={type} />
        ) : (
          <TemplateSettingsSheet
            type={type}
            isSpacingSupported={currentTemplate?.supportsSpacing}
            spacing={currentDocument.spacing}
            templates={templates}
            selectedTemplate={currentDocument.template as string}
            onColorChange={handleColorSelect}
            onTemplateChange={updateTemplate}
            onSpacingChange={handleSpacingChange}
            onClose={handleSheetClose}
            {...colorSettings}
          />
        ))}
    </Container>
  )
})
