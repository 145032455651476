import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'

import Icon24 from 'builder/components/Icon'
import * as Styles from './styles'

const documentSectionId = 'auto-apply-documents'
const TRANSLATION = 'builder.job_search.auto_apply.action_buttons'

type Props = {
  jobId: number
  isResumeTailoringEnabled?: boolean
}

export const InfoSection = ({ jobId, isResumeTailoringEnabled }: Props) => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const textSuffix = isResumeTailoringEnabled ? '_tailor_resume' : ''

  const onNavigate = () => {
    if (isResumeTailoringEnabled) {
      trackInternalEvent('click_documents_section')
    }
    const queryParams = isResumeTailoringEnabled ? `?card_id=${jobId}#${documentSectionId}` : ''
    navigate(`/job-tracking${queryParams}`)
  }

  return (
    <Styles.Container $setCenter={!isResumeTailoringEnabled}>
      <Styles.IconContainer>
        <Icon24.Info />
      </Styles.IconContainer>
      <Styles.AutoApplyInfoText>
        {i18n.t(`${TRANSLATION}.info_text${textSuffix}`)}{' '}
        <Styles.AutoApplyInfoLink onClick={onNavigate}>
          {i18n.t(`${TRANSLATION}.link_text${textSuffix}`)}
        </Styles.AutoApplyInfoLink>
      </Styles.AutoApplyInfoText>
    </Styles.Container>
  )
}
