// @ts-expect-error there is no TS type
import pdfjs from 'pdfjs-dist'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { CoverLetter, Resume } from 'packages/types'
import { bindActionToPromise } from 'builder/utils/bindActionToPromise'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'

import { actions as renderingActions } from 'builder/modules/rendering'

export const useDocumentPreview = (document: Resume | CoverLetter | null) => {
  const dispatch = useDispatch()
  const [pages, setPages] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const getDocumentUrl = bindActionToPromise(dispatch, renderingActions.fetchClientPreview)

  const loadPdfPages = async (url: string) => {
    const worker = new pdfjs.PDFWorker({ name: 'pdfjs-preview-worker' })

    try {
      const loadingTask = pdfjs.getDocument({ url, worker })
      const pdf = await loadingTask.promise
      return pdf._pdfInfo.numPages
    } catch (error) {
      console.error('Failed to load the PDF:', error)
    } finally {
      worker.destroy()
      console.log('Worker terminated')
    }
  }

  const generatePagesUrl = (number: number, document: Resume) => {
    return Array.from({ length: number }, (_, index) =>
      formatDocumentPreviewUrl(document, { size: 1200, page: index + 1 }),
    )
  }

  const onLoad = async () => {
    try {
      if (!document) return

      const url = await getDocumentUrl({ document })
      const pageNumbers = await loadPdfPages(url)
      // @ts-expect-error support both types
      const pagesUrl = generatePagesUrl(pageNumbers, document)

      setPages(pagesUrl)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error(`Pdf page was not loaded, error: ${error}`)
    }
  }

  useEffect(() => {
    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document])

  return { pages, isLoading }
}
