import type { UserDocumentType } from '@rio/types'
import { PremiumServiceModalData } from '../user'

export enum SnackbarTypes {
  loading = 'loading',
  warning = 'warning',
  cancelable = 'cancelable',
  success = 'success',
  failure = 'failure',
  simple = 'simple',
  openable = 'openable',
}

export enum AbandonmentLocalStorageKeys {
  SHOW_ABANDONMENT_MODAL_FLAG = 'abandonmentModalFlag',
}

export interface UiStore {
  // resume sharing modal
  sharingModalResumeId: number | null
  // trDoc sharing modal
  sharingModalTrDocId: number | null
  // docx downloading modal
  docxModalDocumentId: number | null
  docxModalDocumentType: UserDocumentType | null
  // trustpilot review modal
  isRateModalOpen: boolean
  // limit of documents on the free plan
  limitModalType: UserDocumentType | null
  // cover letter after download experience
  coverLetterModalId: number | null
  // prefill modal
  prefillModalDocumentId: number | null
  // snackbar
  isSnackBarOpen: boolean
  isSnackBarShaking: boolean
  snackBarType: SnackbarTypes
  snackBarText: string
  snackBarUrl: string
  // merge modal
  mergeModalResumeId: number | null
  mergeModalCoverLetterId: number | null
  isSuggestionsOpen: boolean
  isCreateResumeOpen: boolean
  trackJobPaywallModal: boolean
  showUpcomingCareersModal: boolean
  showPremiumServiceModal: PremiumServiceModalData
  isPlansPageAbandonmentModalOpen: boolean
  // TR edit modal
  isTREditModalOpen: boolean
  editModalTrDocId: number | null
  isSideMenuOpen: boolean
  isTailorResumeModalOpen: boolean
  isTailorResumeModalSelectOpen: boolean
}

export type MouseClickEvent = React.MouseEvent<HTMLElement, MouseEvent>

export type InputChangeEvent = React.ChangeEvent<HTMLInputElement>

export type TextAreaChangeEvent = React.ChangeEvent<HTMLTextAreaElement>

export type MouseEventHandler<V> =
  | ((e: MouseClickEvent) => V)
  | ((e: MouseClickEvent) => Promise<V>)

export type InputChangeHandler<V> =
  | ((e: InputChangeEvent) => V)
  | ((e: InputChangeEvent) => Promise<V>)

export type TextAreaChangeHandler<V> =
  | ((e: TextAreaChangeEvent) => V)
  | ((e: TextAreaChangeEvent) => Promise<V>)

export type EventHandlerType<V> =
  | MouseEventHandler<V>
  | InputChangeHandler<V>
  | TextAreaChangeHandler<V>

export type MediaQueriesObject = {
  mediaQueries: {
    hover: boolean
    isPhone: boolean
    isTablet: boolean
    isLaptop: boolean
    isDesktop: boolean
  }
}
