import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Route, Navigate, Routes } from 'react-router-dom'
import { navigate } from 'builder/modules/navigate'
import { i18n as I18n } from 'builder/utils/i18n'
import queryString from 'query-string'
import getByPath from 'lodash/get'
import RouteTransitionSwitch from 'builder/components/RouteTransitionSwitch'
import { actions } from 'builder/modules/signUp'
import { trackMarketingEvent, trackInternalEvent } from '@rio/tracking'

import {
  Layout,
  Templates,
  SocialProfile,
  Introduction,
  ContactInfo,
  SignUpStepManager,
  Illustrations,
  STEPS,
} from 'builder/components/SignUp'
import { ConfigScopesEnum, selectors as appSelectors } from 'builder/modules/init'
import { withRouter } from 'builder/hocs/withRouter'
import { DocumentTypes } from 'builder/modules/constants'
import { filterGreekTemplates } from 'builder/utils/filterGreekTemplates'
import { ATS_TEMPLATES, JAPANESE_TEMPLATES } from 'builder/components/Helper/constants'
import { GuestResumeContext } from 'builder/context/GuestResumeContext'

class SignUpView extends Component {
  static propTypes = {
    resumeTemplates: PropTypes.array.isRequired,
    coverLetterTemplates: PropTypes.array.isRequired,
    heroTemplateName: PropTypes.string,
    signUp: PropTypes.func.isRequired,
    location: PropTypes.object,
    persistSignUpInfo: PropTypes.func,
    loadSignUpInfo: PropTypes.func,
    getSignUpPayload: PropTypes.func,
    signUpInfo: PropTypes.object,
    config: PropTypes.object.isRequired,
    documentType: PropTypes.oneOf(Object.values(DocumentTypes)),
    locale: PropTypes.string,
  }

  static contextType = GuestResumeContext

  constructor(props) {
    super(props)

    // Preserve initial query parameters
    this.urlParams = queryString.parse(this.props.location.search)
    this.regionPrefix = this.urlParams.current_region_prefix || ''

    // Read social authorization data from the config passed from the backend
    const socialProfile = getByPath(props, 'config.socialProfile')
    props.loadSignUpInfo({ type: this.props.documentType, socialProfile })

    // Config funnel steps
    this.stepManager = new SignUpStepManager()

    // Initial sign up flow state
    this.state = {
      location: props.location,
      movingDirection: 'forward',
      introductionErrors: {
        firstName: '',
        lastName: '',
      },
      contactInfoErrors: {
        email: '',
      },
      componentWasMounted: false,
    }

    trackMarketingEvent('Sign up', 'Start Funnel')
  }

  navigateWithScope = (url, navigateOptions) => {
    const documentType =
      this.props.documentType === DocumentTypes.coverLetter ? 'cover-letter' : 'resume'
    navigate(`/create-${documentType}/${url}`, navigateOptions)
  }

  componentDidMount() {
    this.props.getSignUpPayload()
    const currentStepName = this.stepManager.getStepByUrl(window.location.pathname)
    const socialProfile = getByPath(this.props, 'config.socialProfile')

    if (this.urlParams.template) {
      const template = this.urlParams.template || this.props.heroTemplateName
      this.handleChooseTemplate(template, { replace: true })
    }

    if (socialProfile && currentStepName !== STEPS.introduction) {
      this.navigateWithScope(STEPS.introduction)
    }

    this.setState({ componentWasMounted: true })
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location !== state.location) {
      const staticStepManager = new SignUpStepManager()
      const nextStep = staticStepManager.getStepByUrl(props.location.pathname)
      const currentStep = staticStepManager.getStepByUrl(state.location.pathname)
      const direction = staticStepManager.getDirection(currentStep, nextStep)
      let movingDirection = direction

      return {
        location: props.location,
        movingDirection,
      }
    }

    return null
  }

  get currentNavbarIndex() {
    const url = this.props.location.pathname
    const currentStep = this.stepManager.getStepByUrl(url)
    const infoSteps = [STEPS.socialProfile, STEPS.introduction, STEPS.contactInfo]

    // 1 and 0 are indices, not boolean
    if (infoSteps.includes(currentStep)) {
      return 1
    }

    return 0
  }

  handleChooseTemplate = (template, navigateOptions) => {
    this.props.persistSignUpInfo({ type: this.props.documentType, template })

    if (this.props.config?.features.guestSignUp) {
      this.context.updateResumeFields({ template })
      navigate(`/guest-builder`)
    } else {
      this.moveToNextPageOrFinish(STEPS.templates, navigateOptions)
    }
  }

  handleSocialProfileSkip = () => {
    this.moveToNextPageOrFinish(STEPS.socialProfile)
    trackInternalEvent('skip_social_sign_up')
  }

  handleIntroductionContinue = () => {
    this.props.persistSignUpInfo({ type: this.props.documentType })
    this.moveToNextPageOrFinish(STEPS.introduction)
  }

  handleContactInfoContinue = () => {
    this.moveToNextPageOrFinish(STEPS.contactInfo)
  }

  handleBackClick = currentStep => {
    const previousStep = this.stepManager.previous(currentStep)

    if (currentStep !== previousStep) {
      navigate(-1)
    }
  }

  handleIntroductionChange = event => {
    this.setState({
      introductionErrors: {
        ...this.state.introductionErrors,
        [event.target.name]: '',
      },
    })
  }

  handleContactInfoChange = event => {
    this.setState({
      contactInfoErrors: {
        ...this.state.introductionErrors,
        [event.target.name]: '',
      },
    })
  }

  moveToNextPageOrFinish = (currentStep, navigateOptions) => {
    const { signUp, signUpInfo } = this.props
    const nextStep = this.stepManager.next(currentStep)

    if (nextStep !== currentStep) {
      this.navigateWithScope(nextStep, navigateOptions)
    } else {
      const isEmptyFirstName = !signUpInfo.firstName.trim().length
      const isEmptyLastName = !signUpInfo.lastName.trim().length
      const isEmptyEmail = !signUpInfo.email.trim().length

      if (isEmptyFirstName || isEmptyLastName) {
        const errorString = I18n.t('builder.sign_up.field_is_required')
        this.setState({
          introductionErrors: {
            firstName: isEmptyFirstName ? errorString : '',
            lastName: isEmptyLastName ? errorString : '',
          },
        })
        this.navigateWithScope(STEPS.introduction)
        return
      }

      if (isEmptyEmail) {
        this.setState({
          contactInfoErrors: {
            email: isEmptyEmail ? I18n.t('builder.sign_up.field_is_required') : '',
          },
        })
        this.navigateWithScope(STEPS.contactInfo)
        return
      }

      signUp({
        type: this.props.documentType,
        isSuperApp: this.props.config?.features?.superApp,
        ...signUpInfo,
      })
    }
  }

  render() {
    const { movingDirection } = this.state
    const {
      resumeTemplates,
      coverLetterTemplates,
      location,
      heroTemplateName,
      documentType,
      locale,
    } = this.props
    const { template } = this.props.signUpInfo
    const templateId = template || heroTemplateName
    const isResumeFunnel = documentType === 'resume'
    const currentStepName = this.stepManager.getStepByUrl(location.pathname)

    const NAVBAR_STEPS = [
      I18n.t('builder.sign_up.choose_template'),
      I18n.t('builder.sign_up.enter_details'),
      isResumeFunnel
        ? I18n.t('builder.sign_up.download_resume')
        : I18n.t('builder.sign_up.download_cover_letter'),
    ]

    const templatesList = isResumeFunnel
      ? resumeTemplates
      : [
          // TODO after Greek templates release: Remove the condition
          ...(locale === 'gr'
            ? filterGreekTemplates(coverLetterTemplates, locale)
            : coverLetterTemplates),
        ]
    // locale check added to show japanese template
    const isJapaneseLocale = locale === 'ja-JP'
    let templates = templatesList
    if (!isJapaneseLocale) {
      templates = templatesList.filter(value => !JAPANESE_TEMPLATES.includes(value.id))
    } else {
      // TODO: Remove this condition after fixing ATS templates
      templates = templatesList.filter(value => !ATS_TEMPLATES.includes(value.id))
    }

    return (
      <Layout
        onClose={this.handleExitClick}
        steps={NAVBAR_STEPS}
        currentTemplate={templates.find(item => item.id === template)}
        currentStepName={currentStepName}
        currentStepNumber={this.currentNavbarIndex}
        regionPrefix={this.regionPrefix}
      >
        <RouteTransitionSwitch direction={movingDirection}>
          <Route
            path={`/${STEPS.templates}`}
            element={
              <Templates
                recommended={heroTemplateName}
                templates={templates}
                currentTemplate={template}
                onChoose={this.handleChooseTemplate}
                documentType={documentType}
              />
            }
          />

          <Route
            path={`/${STEPS.socialProfile}`}
            element={
              <SocialProfile
                onSkip={this.handleSocialProfileSkip}
                onBack={() => this.handleBackClick(STEPS.socialProfile)}
                documentType={documentType}
                templateId={templateId}
              />
            }
          />

          <Route
            path={`/${STEPS.introduction}`}
            element={
              <Introduction
                onBack={() => this.handleBackClick(STEPS.introduction)}
                onContinue={this.handleIntroductionContinue}
                firstNameError={this.state.introductionErrors.firstName}
                lastNameError={this.state.introductionErrors.lastName}
                onChange={this.handleIntroductionChange}
                documentType={this.props.documentType}
                templateId={templateId}
              />
            }
          />

          <Route
            path={`/${STEPS.contactInfo}`}
            element={
              <ContactInfo
                onBack={() => this.handleBackClick(STEPS.contactInfo)}
                onContinue={this.handleContactInfoContinue}
                documentType={documentType}
                onChange={this.handleContactInfoChange}
                outsideEmailError={this.state.contactInfoErrors.email}
                templateId={templateId}
              />
            }
          />
          {/* <Route path="*" element={<Navigate to={`/${STEPS.templates}`} replace />} /> */}
          <Route path="*" element={
              <Templates
                recommended={heroTemplateName}
                templates={templates}
                currentTemplate={template}
                onChoose={this.handleChooseTemplate}
                documentType={documentType}
              />}
              />
        </RouteTransitionSwitch>

        <Routes>
          {this.state.componentWasMounted && (
            <Route path="" element={<Navigate replace to="templates" />} />
          )}
        </Routes>

        <Illustrations currentStepName={currentStepName} />
      </Layout>
    )
  }
}

// ---
// Connect to data
// ---
const mapStateToProps = state => ({
  config: appSelectors.config(state, ConfigScopesEnum.signUp),
  resumeTemplates: state.signUp.resumeTemplates,
  coverLetterTemplates: state.signUp.coverLetterTemplates,
  heroTemplateName: state.signUp.heroTemplateName,
  signUpInfo: state.signUp.signUpInfo,
  locale: state.application.configs.country.locale,
})

const mapDispatchToProps = {
  signUp: actions.signUpRequest,
  persistSignUpInfo: actions.persistSignUpInfo,
  loadSignUpInfo: actions.loadSignUpInfo,
  getSignUpPayload: actions.getSignUpPayload,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUpView))
