import { useState } from 'react'
import { addDays, isAfter } from 'date-fns'

const TAILOR_RESUME_BANNER_JOB_DETAILS_KEY = 'TAILOR_RESUME_BANNER_JOB_DETAILS'
const shouldShowBanner = (key: string) => {
  const time = localStorage.getItem(key)
  if (!time) return true

  const oneWeekAfter = addDays(new Date(Number(time)), 7)
  return isAfter(new Date(), oneWeekAfter)
}

export const useTailorResumeBanner = (isBannerVisible = false) => {
  const bannerState = shouldShowBanner(TAILOR_RESUME_BANNER_JOB_DETAILS_KEY)
  const [showBanner, setShowBanner] = useState(bannerState && isBannerVisible)

  const hideBanner = () => {
    localStorage.setItem(TAILOR_RESUME_BANNER_JOB_DETAILS_KEY, String(+new Date()))
    setShowBanner(false)
  }

  return { showBanner, hideBanner }
}
