import React from 'react'

export default function SendIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M16 1H1L5.5 5.5V12L16 1Z"
        stroke="#828BA2"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
