import { useMemo } from 'react'
import styled, { css } from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'
import { getLineHeightRatio } from 'builder/components/SubscriptionCancellationFormNew/common/utils'
import Shadows from 'builder/styles/shadows'
import Sizes from 'builder/styles/sizes'
import Icon24 from '../Icon'
import { getServiceType } from './constants'

// in pixels
const CARD_SECTION_DESKTOP_VIEW_WIDTH = 187

interface Props {
  isCardClicked: boolean
}
interface CardSectionProps {
  serviceType: number
  activeCard: number
}

interface CardProps {
  activeCard: number
}

interface CardItemProps {
  isActiveCard: boolean
  cardHover: boolean
}

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 928px;
  background-color: ${Colors.White};
  border-radius: 16px;

  ${Media.Tablet`
    max-width: 604px;
    height: 664px;
  `};

  ${Media.Phone`
    max-width: 343px;
    height: 570px;
  `};
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 42px;
  margin-bottom: 32px;
  margin-left: 93px;
  margin-right: 93px;

  ${Media.Tablet`
    max-width: 604px;
  `};

  ${Media.Phone`
    max-width: 343px;
  `};
`

export const Close = styled.div`
  position: absolute;
  width: 28px;
  height: 28px;
  top: 28px;
  right: 28px;
  border-radius: 32px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.Neutral50};
  cursor: pointer;
  transition: color 0.1s;
  background-color: ${Colors.Neutral10};
  z-index: 999;

  &:hover {
    color: ${Colors.Blue50};
  }

  ${Media.Phone`
    top: 20px;
    right: 20px;
    padding: 2px;
  `};
`

export const Back = styled.div`
  position: absolute;
  width: 73px;
  height: 28px;
  top: 32px;
  left: 32px;
  padding: 4px 12px 4px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.Neutral50};
  cursor: pointer;
  transition: color 0.1s;
  background-color: ${Colors.Neutral10};
  z-index: 999;
  border-radius: 20px;

  &:hover {
    color: ${Colors.Blue50};
  }

  ${Media.Phone`
    top: 20px;
    left: 14px;
  `};
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${Colors.Neutral50};
  text-align: center;

  ${Media.Phone`
    padding-left: 2px;
    padding-right: 2px;
  `};
`

export const Header = styled.div<Props>(({ isCardClicked }) => {
  return css`
    display: flex;
    gap: 6px;
    flex-direction: column;
    align-items: center;

    ${Media.Tablet`
      gap: 24px;
  `}

    ${Media.Phone`
      gap: ${isCardClicked ? '24px' : '16px'};
    `};
  `
})

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold};

  ${Media.Tablet`
    text-align: center;
  `}

  ${Media.Phone`
    text-align: center;
  `};
`

export const BackBtnText = styled.div`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral50};
`

export const Service = styled.div`
  ${Typography.Caption};
`

export const Content = styled.div<Props>(({ isCardClicked }) => {
  return css`
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${Media.Tablet`
      align-items: center;
      margin-top: 32px;
    `}

    ${Media.Phone`
      min-width: 311px;
      margin-top:  ${isCardClicked ? '20px' : '8px'};
    `}
  `
})

export const CardContainer = styled.div<Props>(({ isCardClicked }) => {
  return css`
    display: flex;
    ${Shadows.light.low};
    border-radius: 16px;
    margin-top: 20px;

    & > div:nth-child(-n + 3) {
      border-right: 1px solid ${Colors.Neutral15};
    }
    & > div:first-child {
      width: 187px;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    }
    & > div:last-child {
      width: 187px;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }

    ${Media.Tablet`
      display: grid;
      width: 540px;
      border: 1px solid ${Colors.Neutral15};
      & > div:first-child {
        border-bottom-left-radius: 0px;
      }
      & > div:last-child {
        border-bottom-left-radius: 16px;
      }
      & > div:nth-child( -n + 3) {
        border-right: 0px;
        border-bottom: 1px solid ${Colors.Neutral15};
      }
    `};

    ${Media.Phone`
      display: grid;
      width: 311px;
      margin-top: 24px;
    `};

    ${isCardClicked &&
    css`
      ${Media.Tablet`
        display: none;
      `}

      ${Media.Phone`
        display: none;
      `}
    `}
  `
})

export const CardSection = styled.div<CardSectionProps>(({ activeCard, serviceType }) => {
  const cardItem = getServiceType(serviceType)
  const isCardClicked = activeCard === serviceType
  const isOtherCardClicked = activeCard && !isCardClicked

  return css`
    box-sizing: border-box;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: ${Colors.Neutral15};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 186px;
    height: 160px;
    background: ${isCardClicked ? cardItem?.backgroundColor : ''};

    & img {
      filter: ${isOtherCardClicked ? 'grayscale(1)' : ''};
    }

    & > div {
      border-radius: 16px;
    }

    &:hover {
      & img {
        filter: grayscale(0);
      }

      & > div {
        background-color: ${cardItem?.backgroundColor};
        border-radius: 12px;
        width: calc(100% - 8px);
        height: 152px;
        margin: 4px;
      }
    }

    ${Media.Tablet`
      width: 540px !important;
      height: 88px;
      border: none;
      &:hover {
        & > div {
          background-color: inherit;
        }
      }
    `}

    ${Media.Phone`
      display: grid;
      width: 311px !important;
      height: 80px;
      border: none;
      &:hover {
        & > div {
          background-color: inherit;
        }
      }
    `};
  `
})

export const Card = styled.div<CardSectionProps>(({ activeCard, serviceType }) => {
  const imageFilter = activeCard && activeCard !== serviceType ? 'grayscale(1)' : ''

  return css`
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & img {
      filter: ${imageFilter};
    }

    ${Media.Tablet`
      flex-direction: row;
      margin: 0px !important;
      justify-content: unset;
      width: 540px !important;
      height: 88px !important;
    `}

    ${Media.Phone`
      flex-direction: row;
      justify-content: unset;
      margin: 0px !important;
      width: 311px !important;
      height: 80px !important;
    `}
  `
})

export const CardImage = styled.img`
  display: flex;
  justify-content: center;
  width: 50px;
  height: 50px;

  ${Media.Tablet`
    width: 44px;
    height: 44px;
    margin: 22px 24px !important;
  `}

  ${Media.Phone`
    width: 40px;
    height: 40px;
    margin: 20px 16px 20px 16px !important;
  `}
`

export const TopResumeImg = styled.img`
  width: 88px;
  height: 15px;

  ${Media.Tablet`
    width: 88px;
    height: 15px;
  `}

  ${Media.Phone`
    width: 70px;
    height: 12px;
  `}
`

export const CareerIoImg = styled.img`
  width: 138px;
  height: 12px;

  ${Media.Tablet`
    width: 138px;
    height: 12px;
  `}

  ${Media.Phone`
    width: 138px;
    height: 12px;
  `}
`

export const CardTitle = styled.p<CardItemProps>(({ isActiveCard, cardHover }) => {
  return css`
    ${Typography.M};
    ${FontWeights.Medium};
    width: 155px;
    font-size: 19px;
    line-height: ${getLineHeightRatio(24, 19)};
    text-align: center;
    color: ${isActiveCard || cardHover ? Colors.Neutral90 : Colors.Neutral50};
    margin-top: 9px;

    ${Media.Tablet`
      width: 396px;
      text-align: left;
      margin: 0px;
    `}

    ${Media.Phone`
      width: 190px;
      font-size: 16px;
      line-height: ${getLineHeightRatio(20, 16)};
      text-align: left;
      margin: 0px;
    `}
  `
})

export const PromotionContainer = styled.div<CardProps & { isNlLocale: boolean }>(
  ({ activeCard, isNlLocale }) => {
    const cardItem = getServiceType(activeCard)
    const bubbleArrowOffset = isNlLocale
      ? CARD_SECTION_DESKTOP_VIEW_WIDTH + (activeCard - 1) * CARD_SECTION_DESKTOP_VIEW_WIDTH
      : CARD_SECTION_DESKTOP_VIEW_WIDTH / 2 + (activeCard - 1) * CARD_SECTION_DESKTOP_VIEW_WIDTH

    return css`
      display: flex;
      width: 742px;
      height: 260px;
      background-color: ${cardItem?.backgroundColor};
      border-radius: 16px;
      justify-content: center;
      align-items: center;
      position: relative;

      ${Media.AboveTablet`
      margin-top: 16px;
      &:after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: ${bubbleArrowOffset}px;
        width: 8px;
        margin-left: -8px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid ${cardItem?.backgroundColor};
      }
    `}

      ${Media.Tablet`
      width: 540px;
      height: 420px;
      flex-direction: column-reverse;
    `}

    ${Media.Phone`
      width: 311px;
      height: ${getServiceType(activeCard)?.phoneContentCardHeight};
      flex-direction: column-reverse;
    `}
    `
  },
)

export const PromotionContent = styled.div`
  width: 377px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  padding-left: 40px;
  height: 204px;

  ${Media.Tablet`
    width: 476px;
    height: 436px;
    padding-left: unset !important;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 32px;
  `}

  ${Media.Phone`
    width: 279px;
    height: 396px;
    padding-left: unset !important;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: center;
  `}
`

export const TopResumeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const PromotionInnerContent = styled.div<CardProps>(({ activeCard }) => {
  return css`
    width: 476px;
    height: 198px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${Media.Tablet`
      width: 476px;
      height: ${getServiceType(activeCard)?.tabletContentHeight};
      margin-top: ${getServiceType(activeCard)?.tabletContentMarginTop};
      align-items: center
    `}

    ${Media.Phone`
      width: 279px;
      margin-top:  ${getServiceType(activeCard)?.phoneContentMarginTop};
      height: ${getServiceType(activeCard)?.phoneContentHeight};;
    `}
  `
})

export const PromotionTitle = styled.p<CardProps>(({ activeCard }) => {
  const cardItem = getServiceType(activeCard)
  return css`
    width: 408px;
    ${FontWeights.DemiBold};
    font-size: 27px;
    line-height: ${getLineHeightRatio(28, 27)};
    color: ${cardItem?.noticeTextColor};

    ${Media.Tablet`
        width: 476px;
        text-align: center;
    `}

    ${Media.Phone`
        width: 279px;
        font-size: 23px;
        line-height: ${getLineHeightRatio(24, 23)};
        text-align: center;
    `}
  `
})

export const PromotionDescription = styled.p<CardProps>(({ activeCard }) => {
  const cardItem = getServiceType(activeCard)

  return css`
    ${FontWeights.Regular};
    font-size: 19px;
    line-height: ${getLineHeightRatio(24, 19)};
    width: 373px;
    color: ${cardItem?.noticeTextColor};
    & a {
      color: ${Colors.Blue60};
    }

    ${Media.Tablet`
      text-align: center;
      width: 448px;
    `}

    ${Media.Phone`
      font-size: 16px;
      line-height: ${getLineHeightRatio(20, 16)};
      text-align: center;
      width: 279px;
    `}
  `
})

export const PromotionButton = styled(Button)<CardProps>(({ activeCard }) => {
  const cardItem = getServiceType(activeCard)

  const phoneViewHeight = '48px'
  const phoneViewFontSize = '18px'
  const phoneViewLineHeight = getLineHeightRatio(22, 18)

  return css`
    ${Typography.ButtonM};
    ${FontWeights.DemiBold};
    display: flex;
    font-size: 18px;
    align-items: center;
    justify-content: center;
    line-height: ${getLineHeightRatio(22, 18)};
    color: ${Colors.White};
    background-color: ${cardItem?.buttonColor};
    border-radius: 4px;
    height: 48px;
    width: fit-content;
    padding-left: 24px;
    padding-right: 24px;

    &:hover {
      background-color: ${cardItem?.buttonHoverColor};
    }

    ${Media.Tablet`
        width: 216px;
        padding-left: unset !important;
        padding-right: unset !important;
    `}

    ${Media.Phone`
        height: ${phoneViewHeight};
        font-size: ${phoneViewFontSize};
        line-height: ${phoneViewLineHeight};
        width: 279px;
        padding-left: unset !important;
        padding-right: unset !important;
    `}
  `
})

export const PromotionButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${Media.Tablet`
    flex-direction: column;
    align-items: none;
  `}

  ${Media.Phone`
    flex-direction: column;
    align-items: none;
  `};
`

export const TopResume = styled.div`
  display: flex;
  flex-direction: column;
  width: 138px;
  height: 33px;
  justify-content: space-between;
  margin-left: 19px;

  ${Media.Tablet`
    flex-direction: row;
    width: 232px;
    height: 15px;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-left: 0px;
  `}

  ${Media.Phone`
    flex-direction: row;
    width: 214px;
    height: 12px;
    justify-content: center;
    margin-left: 0px;
  `};
`

export const PromotionImage = styled.div<CardProps>(({ activeCard }) => {
  const imageStyles = useMemo(() => {
    return css`
      width: 220px;
      height: 184px;

      ${Media.Tablet`
        width: ${getServiceType(activeCard)?.tabletContentImgWidth};
        height: ${getServiceType(activeCard)?.tabletContentImgHeight};
      `}

      ${Media.Phone`
        width: ${getServiceType(activeCard)?.phoneContentImgWidth};
        height: ${getServiceType(activeCard)?.phoneContentImgHeight};
      `}
    `
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 384px;

    ${Media.Tablet`
      width: 100%;
      height: ${getServiceType(activeCard)?.tabletContentImgHeight};
    `}

    ${Media.Phone`
      width: 100%;
      height: ${getServiceType(activeCard)?.phoneContentImgHeight};
    `}

    & img {
      ${imageStyles}
    }
  `
})

export const DownloadResumeActionWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
`

export const DownloadResumeAction = styled.div`
  ${Typography.ButtonV2M}
  ${FontWeights.Medium};
  color: ${Colors.Blue50};
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: ${Sizes['2XS']};

  ${Media.Phone`
    ${Typography.ButtonS}
  `};
`

export const ArrowActionIcon = styled(Icon24.RightArrow)`
  color: inherit;
`
