import { Resume, UserDocumentTemplateId } from 'packages/types'
import { A4_FACTOR, DocumentTypes, US_LETTER_FACTOR } from 'builder/modules/constants'
import { CurrentDocument } from 'builder/components/PreviewPanel'
import { LETTER_FORMAT_TEMPLATES, TemplateName } from '../constants'

function templateHeightFactorSelector(currentDocument: CurrentDocument) {
  if (
    currentDocument?.type === DocumentTypes.resume &&
    (currentDocument as Resume)?.templateSettings?.[
      currentDocument?.template as UserDocumentTemplateId
    ]
  ) {
    return (currentDocument as Resume)?.templateSettings?.[
      currentDocument?.template as UserDocumentTemplateId
    ]?.format === 'US_LETTER'
      ? US_LETTER_FACTOR
      : A4_FACTOR
  }

  const isLetterFormat = LETTER_FORMAT_TEMPLATES?.includes(
    currentDocument?.template as TemplateName,
  )
  return isLetterFormat ? US_LETTER_FACTOR : A4_FACTOR
}

export default templateHeightFactorSelector
