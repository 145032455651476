import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { Track, Thumb, HiddenCheckbox, Label } from './styles'

type Ref = React.ForwardedRef<HTMLInputElement | null>
export type ToggleProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  label?: string | React.ReactNode
  checked?: boolean
  tabIndex?: number
  invalid?: boolean
  disabled?: boolean
  testId?: string
} & React.HTMLAttributes<HTMLInputElement>

export const Toggle = React.forwardRef(
  (
    {
      label,
      onChange,
      tabIndex,
      checked = false,
      invalid = false,
      disabled = false,
      testId,
      ...rest
    }: ToggleProps,
    ref: Ref,
  ) => {
    const [checkedState, setChecked] = useState(checked)

    useEffect(() => {
      setChecked(checked)
    }, [checked])

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e)
      }
      setChecked(!checkedState)
    }

    return (
      <ThemeProvider theme={{ disabled, checked: checkedState, invalid }}>
        <Label data-testid={testId}>
          <HiddenCheckbox
            disabled={disabled}
            tabIndex={tabIndex}
            type="checkbox"
            onChange={onChangeHandler}
            checked={checkedState}
            ref={ref}
            {...rest}
          />
          <Track>
            <Thumb />
          </Track>
          {label}
        </Label>
      </ThemeProvider>
    )
  },
)
