import styled from 'styled-components'
import Icon24, { Icon20 } from 'builder/components/Icon'
import CloseIcon from './CloseIcon'
import ExpandIcon from './ExpandIcon'
import AiCoachIcon from './AiCoach'
import SendIcon from './SendIcon'
import AssistantIcon from './AssistantIcon.svg'
import LoadingIcon from './LoadingIcon'

export const ArrowDown = styled(Icon20.ArrowLeft)`
  transform: rotate(-90deg);
`

export const Icon = {
  Close: CloseIcon,
  Expand: ExpandIcon,
  AiCoach: AiCoachIcon,
  Send: SendIcon,
  Loading: LoadingIcon,
  ArrowDown: ArrowDown,
  CircleError: Icon24.CircleError,
  Assistant: (props: React.ComponentProps<'img'>) => (
    <img src={AssistantIcon} alt="Assistant" {...props} />
  ),
}
