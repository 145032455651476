import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useI18n } from 'builder/hooks/useI18n'
import CheckInboxImg from 'builder/views/Interview/assets/check-inbox.png'
import { useQueryParam } from 'builder/hooks/useQueryParam'
import { PARAM_EMAIL } from 'builder/views/Interview/constants'
import { Navbar } from '../Navbar/Navbar'
import { Title, Message, Confirm, Wrapper, Container, Content } from './styles'

const TRANSLATION = 'builder.interview_prep_view.start_check_inbox'

export const CheckYourInboxView = () => {
  const { i18n } = useI18n()
  const [email] = useQueryParam(PARAM_EMAIL)
  const navigate = useNavigate()

  useEffect(() => {
    if (!email) {
      navigate('/interview-preparation/start')
    }
  }, [])

  return (
    <Wrapper>
      <Navbar currentStep={1} />
      <Container>
        <Content>
          <img src={CheckInboxImg} />
          <Title>{i18n.t(`${TRANSLATION}.title`)}</Title>
          <Message
            dangerouslySetInnerHTML={{
              __html: i18n.t(`${TRANSLATION}.message`, {
                email,
              }),
            }}
          />
          <Confirm>
            {i18n.t(`${TRANSLATION}.confirm_message`)}{' '}
            <a href="/contact" target="_blank" rel="noreferrer noopener">
              {i18n.t(`${TRANSLATION}.confirm_link_text`)}
            </a>
          </Confirm>
        </Content>
      </Container>
    </Wrapper>
  )
}
