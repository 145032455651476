export type FontType = 'primary' | 'secondary' | 'tertiary'

export const resumeTemplateFontMapping: Record<
  string,
  Partial<Record<FontType, { name: string; id: string }>>
> = {
  amsterdam: {
    primary: { name: 'Montserrat', id: 'MontserratFamily' },
  },
  athens: {
    primary: { name: 'Source Sans', id: 'SourceSansFamily' },
    secondary: { name: 'IBM Plex Sans', id: 'IbmPlexSansFamily' },
  },
  barcelona: {
    primary: { name: 'PT Serif', id: 'PTSerifFamily' },
    secondary: { name: 'Ubuntu', id: 'UbuntuFamily' },
  },
  berlin: {
    primary: { name: 'Montserrat', id: 'MontserratFamily' },
    secondary: { name: 'Montserrat', id: 'MontserratFamily' },
  },
  boston: {
    primary: { name: 'Norms', id: 'NormsFamily' },
    secondary: { name: 'Travels', id: 'TravelsFamily' },
  },
  brussels: {
    primary: { name: 'IBM Plex Sans', id: 'IbmPlexSansFamily' },
  },
  cape_town: {
    primary: { name: 'Inter', id: 'InterFamily' },
  },
  chicago: {
    primary: { name: 'Lato', id: 'LatoFamily' },
    secondary: { name: 'Playfair Display', id: 'PlayfairDisplayFamily' },
  },
  copenhagen: {
    primary: { name: 'Lato', id: 'LatoFamily' },
  },
  dublin: {
    primary: { name: 'Lato', id: 'LatoFamily' },
    secondary: { name: 'PT Serif', id: 'PTSerifFamily' },
  },
  geneva: {
    primary: { name: 'Arimo', id: 'ArimoFamily' },
    secondary: { name: 'Nimbus Roman', id: 'NimbusRoman' },
  },
  lisbon: {
    primary: { name: 'Norms', id: 'NormsFamily' },
    secondary: { name: 'Travels', id: 'TravelsFamily' },
  },
  london: {
    primary: { name: 'TimesNewRoman', id: 'TimesNewRomanFamily' },
  },
  madrid: {
    primary: { name: 'Lato', id: 'LatoFamily' },
  },
  milan: {
    primary: { name: 'Lato', id: 'LatoFamily' },
  },
  moscow: {
    primary: { name: 'Inter', id: 'InterFamily' },
  },
  new_york: {
    primary: { name: 'Source Sans', id: 'SourceSansFamily' },
    secondary: { name: 'Oswald', id: 'OswaldFamily' },
  },
  oslo: {
    primary: { name: 'Open Sans', id: 'OpensansFamily' },
    secondary: { name: 'Playfair Display', id: 'PlayfairDisplayFamily' },
  },
  paris: {
    primary: { name: 'Garamond', id: 'GaramondFamily' },
  },
  prague: {
    primary: { name: 'Roboto', id: 'RobotoFamily' },
    secondary: { name: 'Georgia', id: 'GeorgiaFamily' },
  },
  rio: {
    primary: { name: 'Jost', id: 'JostFamily' },
    secondary: { name: 'Le Murmure', id: 'LeMurmureFamily' },
  },
  rirekisho: {
    primary: { name: 'Noto Serif JP', id: 'NotoSerifJP' },
  },
  rome: {
    primary: { name: 'Montserrat', id: 'MontserratFamily' },
    secondary: { name: 'Zangezi', id: 'ZangeziFamily' },
  },
  santiago: {
    primary: { name: 'Garamond', id: 'GaramondFamily' },
  },
  shanghai: {
    primary: { name: 'IBM Plex Sans', id: 'IbmPlexSansFamily' },
    secondary: { name: 'Roboto New', id: 'RobotoNewFamily' },
  },
  shokumukeirekisho: {
    primary: { name: 'Noto Serif JP', id: 'NotoSerifJP' },
  },
  singapore: {
    primary: { name: 'Liberation Sans', id: 'LiberationSansFamily' },
  },
  stockholm: {
    primary: { name: 'Source Sans', id: 'SourceSansFamily' },
  },
  sydney: {
    primary: { name: 'Lato', id: 'LatoFamily' },
    secondary: { name: 'Oswald', id: 'OswaldFamily' },
  },
  tokyo: {
    primary: { name: 'Lato', id: 'LatoFamily' },
    secondary: { name: 'Roboto New', id: 'RobotoNewFamily' },
    tertiary: { name: 'Open Sans', id: 'OpensansFamily' },
  },
  toronto: {
    primary: { name: 'IBM Plex Sans', id: 'IbmPlexSansFamily' },
    secondary: { name: 'IBM Plex Mono', id: 'IBMPlexMonoFamily' },
  },
  vancouver: {
    primary: { name: 'Ubuntu', id: 'UbuntuFamily' },
  },
  vienna: {
    primary: { name: 'IBM Plex Sans', id: 'IbmPlexSansFamily' },
  },
}

export enum LayoutFormats {
  A4 = 'A4',
  US_LETTER = 'US_LETTER',
}

export type ResumeTemplateSettings = Partial<{
  primaryFont: string
  secondaryFont: string
  tertiaryFont?: string
}>
