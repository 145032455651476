import { useState, useEffect, useMemo } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { delay } from 'builder/views/Interview/utils/delay'
import { behaviourType } from 'builder/modules/interview/types'
import { LoadingScreen } from '../../LoadingScreen/LoadingScreen'

const TRANSLATION = 'builder.interview_prep_view.interview_player.loading_start_screen'

interface Props {
  onCompletedSteps: () => void
  forceStartLoading: boolean
  startPageType?: behaviourType
  companyName?: string
  isRealCompany?: boolean
}

export const StartLoading = ({
  onCompletedSteps,
  forceStartLoading,
  startPageType,
  companyName,
  isRealCompany,
}: Props) => {
  const { i18n } = useI18n()
  const [counter, setCounter] = useState(0)
  const items = useMemo(() => {
    const texts = {
      default: [
        {
          title: i18n.t(`${TRANSLATION}.default.step1.title`),
          content: i18n.t(`${TRANSLATION}.default.step1.subtitle`),
        },
        {
          title: i18n.t(`${TRANSLATION}.default.step2.title`),
          content: i18n.t(`${TRANSLATION}.default.step2.subtitle`),
        },
        {
          title: i18n.t(`${TRANSLATION}.default.step3.title`),
          content: i18n.t(`${TRANSLATION}.default.step3.subtitle`),
        },
      ],
      ambitious: [
        {
          title: i18n.t(`${TRANSLATION}.ambitious.step1.title`),
          content: i18n.t(`${TRANSLATION}.ambitious.step1.subtitle`, {
            companyName: isRealCompany ? companyName : '',
          }),
        },
        {
          title: i18n.t(`${TRANSLATION}.ambitious.step2.title`),
          content: i18n.t(`${TRANSLATION}.ambitious.step2.subtitle`, {
            companyName: isRealCompany ? companyName : '',
          }),
        },
        {
          title: i18n.t(`${TRANSLATION}.ambitious.step3.title`),
          content: i18n.t(`${TRANSLATION}.ambitious.step3.subtitle`, {
            companyName: isRealCompany ? companyName : '',
          }),
        },
      ],
      confident: [
        {
          title: i18n.t(`${TRANSLATION}.confident.step1.title`),
          content: i18n.t(`${TRANSLATION}.confident.step1.subtitle`),
        },
      ],
      nervous: [
        {
          title: i18n.t(`${TRANSLATION}.nervous.step1.title`),
          content: i18n.t(`${TRANSLATION}.nervous.step1.subtitle`),
        },
      ],
    }

    return texts[startPageType || 'default']
  }, [startPageType, i18n])

  useEffect(() => {
    let isMounted = true

    async function wait() {
      await delay(3000)
      if (isMounted) {
        const newCounter = (counter + 1) % items.length
        if (forceStartLoading && counter === items.length - 1 && newCounter === 0) {
          return onCompletedSteps()
        }
        setCounter(newCounter)
      }
    }
    wait()

    return () => {
      isMounted = false
    }
  }, [counter, items])

  if (items.length === counter) {
    return null
  }
  return <LoadingScreen content={items[counter]} />
}
