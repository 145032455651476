import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Icon24 from 'builder/components/Icon'
import Media from 'builder/styles/media'

export const Wrapper = styled.div`
  padding: 20px;
  background-color: ${Colors.White};
  width: 544px;
  border-radius: 12px;

  ${Media.Phone`
    max-width: none;
    width: 343px;
    height: 100%;
    padding: 20px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  `};
`

export const CloseIconContainer = styled.div`
  height: 24px;
  width: 24px;
  flex: 0 0 auto;
  background-color: ${Colors.Neutral10};
  color: ${Colors.Neutral50};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: ${Colors.Blue50};
    background-color: ${Colors.Blue10};
  }
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CloseIcon = styled(Icon24.Close)`
  height: 20px;
  width: 20px;
`

export const Title = styled.p`
  ${Typography.S};
  ${FontWeights.DemiBold};
`

export const Description = styled.div`
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  padding: 12px 0 32px;

  ${Media.Phone`
    padding: 8px 0 20px;
  `}
`

export const Bottom = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`
