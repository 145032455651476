import styled, { css } from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Media from 'builder/styles/media'

import Sizes from 'builder/styles/sizes'

import Tabs, { Tab } from 'builder/components/Tabs'

import Typography, { FontWeights } from 'builder/styles/typography'
import Select from 'builder/components/Select'

const hideScroll = css`
  -ms-overflow-style: none; // Internet Explorer 10+
  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
`

export const Container = styled.div`
  width: 409px;
  flex-shrink: 0;
  border-right: 1px solid ${hexToRgba(Colors.White, 0.15)};

  ${Media.Laptop`
    width: 265px;
  `}

  ${Media.Tablet`
    width: 241px;
  `}
`

export const CustomTabs = styled(Tabs)`
  border-bottom: 1px solid ${Colors.Neutral70};
  justify-content: space-between;
  gap: ${Sizes.L};
  width: fit-content;
`

export const TabContainer = styled.div`
  padding: 0 32px;
  overflow-x: scroll;
  ${hideScroll};
`

export const ContentContainer = styled.div``

export const CustomTab = styled(Tab) <{ isActive: boolean }>`
  padding: ${Sizes.S} 0;
  margin-right: unset;
  color: ${({ isActive }) => (isActive ? Colors.White : Colors.Neutral50)};

  &:hover {
    color: ${Colors.Blue40};
  }
`

export const TabLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Sizes['2XS']};
  align-items: center;
  justify-content: center;
  color: inherit;
`

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  color: inherit;
`

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${Sizes['3XS']};
  width: 100%;
`

export const SelectLabel = styled.p`
  color: ${Colors.Neutral50};
  ${Typography.Caption};
  ${FontWeights.Regular};
`

export const CustomSelect = styled(Select)`
  width: 100%;
  margin-bottom: unset;

  /* Custom scrollbar */
  scrollbar-color: ${Colors.Neutral30} ${Colors.Neutral60};
  scrollbar-width: thin;

  button {
    background-color: #454c5b;
    color: ${Colors.Neutral5};
    border: unset;

    &:hover {
      background-color: #454c5b;
    }

    svg {
      fill: ${Colors.Neutral5};
    }

    &:disabled {
      color: ${Colors.Neutral50};

      svg {
        fill: ${Colors.Neutral50};
      }
    }
  }

  div {
    color: ${Colors.Neutral5};
  }

  div[role='listbox'] {
    background-color: #454c5b;
    border-radius: ${Sizes['3XS']};
    text-align: left;
  }

  div[role='option'] {
    div {
      color: ${Colors.Neutral5};

      &:hover {
        background-color: ${Colors.Neutral70} !important;
        color: ${Colors.Neutral30} !important;
      }
    }

    &[aria-selected='true'] {
      div {
        color: ${Colors.Neutral40};
        background-color: #454c5b;
      }
    }

    &[aria-disabled='true'] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`
