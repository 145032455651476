import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectors } from 'builder/modules/interview/interviewModule'
import { behaviourType } from 'builder/modules/interview/types'
import { StartContent } from './StartContent/StartContent'
import { trackInternalEvent } from '@rio/tracking'
export interface Params {
  behaviour?: behaviourType
}
export const StartAuth = () => {
  const { behaviour }: Params = useParams()
  const jobTitleId = useSelector(selectors.jobTitleId)
  const navigate = useNavigate()
  useEffect(() => {
    if (jobTitleId) {
      return navigate('/interview-preparation/dashboard')
    }

    trackInternalEvent('visit_test_interview', {
      label: 'interview_prep',
      parameter: behaviour || 'default',
    })
  }, [])

  return <StartContent behaviour={behaviour} />
}
