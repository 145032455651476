import loaderImage from 'images/builder/resume-editor/resume-loader.svg'
import * as Styles from './styles'

export const DocumentLoader = () => {
  return (
    <Styles.LoaderContainer>
      <Styles.Loader src={loaderImage} />
    </Styles.LoaderContainer>
  )
}
