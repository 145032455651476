import { useMemo, useCallback } from 'react'
import { DocumentTemplateSettings } from 'packages/types'
import camelCase from 'lodash/camelCase'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/resumeEditor'
import { SelectOption, SelectValue } from 'builder/components/Select/types'
import { FontType, resumeTemplateFontMapping } from '../constants'

export const useTextCustomization = () => {
  const templates = useTypedSelector(selectors.templates)
  const resume = useTypedSelector(selectors.resume)
  const currentTemplate = templates.find(template => template.id === resume?.template)
  const templateSettings: Partial<DocumentTemplateSettings> | undefined =
    currentTemplate?.settings ?? undefined
  const supportsFontSizing = currentTemplate?.supportsFontSizing
  const supportsSpacing = currentTemplate?.supportsSpacing
  const supportsNewCustomization = currentTemplate?.supportsNewCustomization
  const resumeSpecificSettings = resume?.templateSettings?.[camelCase(resume?.template)]

  const convertToSelectOption = useCallback(
    <V extends SelectValue>(
      data: Record<string, { id: V; name: string }> | { id: V; name: string }[],
    ): SelectOption<V>[] => {
      const arrayData = Array.isArray(data) ? data : Object.values(data)
      return arrayData.map(({ id, name }) => ({ id, name }))
    },
    [],
  )

  const getFontOptions = useCallback(
    (fontType: FontType) => {
      const template = resume?.template
      if (!template) return []

      const fonts = templateSettings?.font?.[fontType]
      const defaultFont = resumeTemplateFontMapping[template]?.[fontType]
      return fonts ? convertToSelectOption(fonts) : defaultFont ? [defaultFont] : []
    },
    [convertToSelectOption, resume?.template, templateSettings?.font],
  )

  const getSelectedFont = useCallback(
    (fontType: FontType) => {
      const template = resume?.template
      if (!template) return null

      return (
        resumeSpecificSettings?.[`${fontType}Font`] ??
        (resumeTemplateFontMapping[template]?.[fontType]?.id as SelectValue)
      )
    },
    [resumeSpecificSettings, resume?.template],
  )

  const primaryFonts = useMemo(() => getFontOptions('primary'), [getFontOptions])

  const secondaryFonts = useMemo(() => getFontOptions('secondary'), [getFontOptions])

  const tertiaryFonts = useMemo(() => getFontOptions('tertiary'), [getFontOptions])

  const selectedFonts = useMemo(
    () => ({
      primary: getSelectedFont('primary'),
      secondary: getSelectedFont('secondary'),
      tertiary: getSelectedFont('tertiary'),
    }),
    [getSelectedFont],
  )

  const isFontCustomizationEnabled = !!(
    templateSettings?.font && currentTemplate?.supportsNewCustomization
  )

  const handleChangeLineSpacing = useCallback(() => {}, [])

  const spacing = useMemo(() => {
    if (supportsNewCustomization) {
      return resumeSpecificSettings?.lineSpacing || 0
    } else {
      return resume?.spacing || 0
    }
  }, [resume?.spacing, resumeSpecificSettings?.lineSpacing, supportsNewCustomization])

  const size = useMemo(() => {
    if (supportsNewCustomization) {
      return resumeSpecificSettings?.fontSize || 0
    } else {
      return 0
    }
  }, [resumeSpecificSettings?.fontSize, supportsNewCustomization])

  return {
    primaryFonts,
    secondaryFonts,
    tertiaryFonts,
    isPrimaryFontCustomizationEnabled: isFontCustomizationEnabled,
    isSecondaryFontCustomizationEnabled: isFontCustomizationEnabled,
    isTertiaryFontCustomizationEnabled: isFontCustomizationEnabled,
    supportsFontSizing,
    selectedPrimaryFont: selectedFonts.primary,
    selectedSecondaryFont: selectedFonts.secondary,
    selectedTertiaryFont: selectedFonts.tertiary,
    spacing,
    handleChangeLineSpacing,
    supportsSpacing,
    size,
  }
}
