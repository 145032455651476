import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { FORMATS } from 'builder/modules/constants'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { actions as panelActions } from 'builder/modules/panel'

import Icon from 'builder/components/Icon'
import { ButtonV2 } from 'builder/components/Button'
import TRDocumentActionMenu from 'builder/components/TRDocumentActionMenu'
import { Props as TRDocumentActionProps } from 'builder/components/TRDocumentActionMenu/TRDocumentActionMenu'

import { Routes, TAILOR_RESUME_KEY } from '../AutoApply/constants'
import * as Styles from './styles'

type Props = {
  documentId: number
  edit?: 'enabled' | 'disabled'
  isPdfFormat: boolean
  isDownloading: boolean
  trActionProps: TRDocumentActionProps
  downloadPdf: ({ format }: { format?: FORMATS }) => void
}

export const ActionSection = ({
  edit,
  documentId,
  isPdfFormat,
  isDownloading,
  trActionProps,
  downloadPdf,
}: Props) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { panel } = useAppSelector(store => store)
  const { duplicationUrl, isDuplicationLoading } = panel
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false)

  const onEditResume = () => {
    localStorage.setItem(TAILOR_RESUME_KEY, 'true')
    trackInternalEvent('visit_resume_editor', { label: 'auto_apply' })

    dispatch(panelActions.duplicateTRDocument({ id: documentId }))
  }

  const onDisabledResume = () => {
    navigate(`/auto-appply/${Routes.PERSONAL_INFO}`)
  }

  useEffect(() => {
    if (duplicationUrl) {
      navigate(duplicationUrl.replace('app', ''))
      dispatch(panelActions.setDuplicationUrl(''))
    }
  }, [duplicationUrl, navigate, dispatch, documentId])

  if (edit === 'disabled') {
    return <Styles.ActionButton onClick={onDisabledResume}>Continue</Styles.ActionButton>
  } else if (edit === 'enabled') {
    return isDuplicationLoading ? (
      <ButtonV2 isLoading />
    ) : (
      <Styles.ActionButton onClick={onEditResume}>Edit Resume</Styles.ActionButton>
    )
  } else {
    return (
      <Styles.DownloadMenu>
        {isPdfFormat && (
          <Styles.DownloadButton
            onClick={() => downloadPdf({ format: FORMATS.tr_pdf })}
            isDisabled={isDownloading}
          >
            {i18n.t('builder.resume_editor.download')}
          </Styles.DownloadButton>
        )}
        <Styles.Actions>
          <Styles.ActionsButton
            onClick={() => setIsActionMenuOpen(true)}
            isDisabled={isDownloading}
          >
            <Icon.More />
          </Styles.ActionsButton>
          {isActionMenuOpen && (
            <TRDocumentActionMenu
              {...{
                ...trActionProps,
                onClose: () => setIsActionMenuOpen(false),
              }}
            />
          )}
        </Styles.Actions>
      </Styles.DownloadMenu>
    )
  }
}
