import Icon24, { Icon20 } from 'builder/components/Icon'
import Button, { ButtonTheme } from 'builder/components/Button'
import { ApplyButtonIcon } from 'builder/components/FindJob/JobDetails/styles'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import PropTypes from 'prop-types'
import { useI18n } from 'builder/hooks/useI18n'
import { useFields } from 'builder/hooks/useFields'
import { ColumnName, actions } from 'builder/modules/jobTracking'
import Field from './Field'
import EditIcon from './Icons/Edit.svg'
import LinkIcon from './icons/link.svg'
import JobIcon from './icons/job.svg'
import SalaryIcon from './icons/salary.svg'
import LocationIcon from './icons/location.svg'
import DocumentIcon from './Icons/ResumeThumbnail.png'
import { StatusSelect } from '../CardStatusDrawer/StatusSelect'
import JobTrackerExtensionBanner from '../../Panel/CareerGoal/components/JobTrackerExtensionBanner'
import { groupBy, get } from 'lodash'
import { Status } from './Status'
import { HeaderClose, DrawerActionContainer } from '../CardDrawer/styles'
import {
  AutoApplyStatusQuestions,
  AutoApplyTailorResumeError,
  Container,
  PaddedContainer,
  Header,
  CompanyLogoContainer,
  CompanyNameContainer,
  DeleteIconContainer,
  CompanyLogo,
  CompanyLabel,
  CompanyName,
  ButtonContainer,
  PrimaryButton,
  InformationContainer,
  Title,
  Information,
  InformationDiv,
  InformationIcon,
  Document,
  ResumeMatch,
  ResumeDocumentIcon,
  ResumePercentage,
  ResumeMatchText,
  Actions,
  IrrelevantBox,
  IrrelevantBoxSubtitle,
  IrrelevantBoxTitle,
  IrrelevantButton,
  IrrelevantDeleteButton,
  TickIcon,
  TextAreaContainer,
  FieldLabel,
  ResumeTitle,
  ScrollContainer,
  ExternalLink,
  Chevron,
  DescriptionImage,
  DocumentsLineHead,
  DocumentsLineTitle,
  DocumentsLineButton,
  DocumentsLineImg,
  DocumentsContent,
  DocumentDownload,
  DocumentThumbnail,
  DocumentsList,
  DocumentsListItem,
  DocumentDownloadPlaceholder,
  DocumentErrorLine,
  DocumentsInfoContainer,
  DocumentsInfoSpinner,
  DocumentsInfoText,
  DropSection,
} from './styles'
import { useLocation, useNavigate } from 'react-router-dom'
import trashIcon from './Icons/trash.svg'
import briefcaseBlackIcon from './Icons/briefcaseBlack.svg'
import documentIcon from './Icons/document.svg'
import JobSearchInfo from './JobSearchInfo'
import AttachIcon from './Icons/Attach.svg'
import AttachIconBlue from './Icons/AttachBlue.svg'
import { apiClient } from 'builder/modules/apiClient'
import { DocumentThumbnailPlaceholder } from './DocumentThumbnailPlaceholder'
import { AutoApplyStatusLabel } from '../AutoApplyStatusLabel'
import { DocumentDeleteButton } from './DocumentDeleteButton'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useScrollToAnchor } from 'builder/hooks/useScrollToAnchor'
import { stripHtml } from 'builder/utils/stripHTML'

export const documentSectionId = 'auto-apply-documents'

const Sidebar = ({
  job,
  onSubmit,
  onClose,
  onDelete,
  handleSetInterviewJob,
  isDashboard,
  columnNames,
  stateChangeRef,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const routeLocation = useLocation()

  const { isPhone } = useMediaQueries()
  const { i18n } = useI18n()
  const ref = useRef(null)
  const {
    id,
    company,
    title,
    salary,
    link,
    location,
    description,
    notes,
    status,
    logo,
    resumeScore,
    isIrrelevant,
    stillRelevant,
    externalSlugId,
    positionNumber,
    resumeId,
  } = job

  const [fields, handleUpdate] = useFields({
    company,
    logo,
    title,
    salary,
    link,
    location,
    description,
    notes,
    status,
    isIrrelevant,
    stillRelevant,
  })

  const {
    isUserOnAutoApplyPlan,
    redirectToBillingPlans,
    redirectToAutoApplyQuestionnaire,
    isAutoApplyLimitReached,
  } = useAutoApply()
  useScrollToAnchor()

  const [companyState, setCompanyState] = useState(company)
  const [titleState, setTitleState] = useState(title)
  const [statusListOpen, setStatusListOpen] = useState(false)
  const [locationState, setLocationState] = useState(location)
  const [linkState, setLinkState] = useState(link)
  const [salaryState, setSalaryState] = useState(salary)
  const [notesState, setNotesState] = useState(notes)
  const [descriptionState, setDescriptionState] = useState(description)
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false)
  const [isNotesVisible, setIsNotesVisible] = useState(false)
  const [isDocumentsVisible, setIsDocumentsVisible] = useState(false)
  const [relevant, setRelevant] = useState(false)
  const [uploadingFile, setUplodingFile] = useState(null)
  const [uploadError, setUploadError] = useState(null)
  const [isDragging, setIsDragging] = useState(false)

  const showApply = externalSlugId && [ColumnName.wishlist, ColumnName.recommended].includes(status)
  const poorResumeScore = !!(resumeScore && resumeScore < 70)

  const handleResumeLink = () => {
    trackInternalEvent('click_resume_link')
    const editorUrl = `/resumes/${resumeId}/edit`
    navigate(editorUrl)
  }

  const handleBlur = () => {
    if (relevant) {
      trackInternalEvent('mark_job_relevant')
      onSubmit({ isIrrelevant: false, stillRelevant: true, id })
    } else {
      onSubmit({ ...fields, id })
    }
  }

  const handleApplyClick = () => {
    window.open(fields.link, '_blank')

    const payload = {
      source: {
        droppableId: status,
        index: positionNumber,
      },
      destination: {
        droppableId: 'applied',
        index: 0,
      },
      jobId: Number(id),
    }

    dispatch(actions.setApplyTrackingDialog({ show: true, moveData: payload }))
  }

  const handleAutoApplyClick = () => {
    if (redirectToBillingPlans()) return
    if (redirectToAutoApplyQuestionnaire()) return
    if (isAutoApplyLimitReached()) return

    const payload = {
      source: {
        droppableId: status,
        index: positionNumber,
      },
      destination: {
        droppableId: ColumnName.auto_apply,
        index: 0,
      },
      jobId: Number(id),
    }
    dispatch(actions.moveRequest(payload))
    trackInternalEvent('auto_apply_for_job', {
      label: 'job_tracker',
      parameter: 'sidebar',
    })
  }

  const handleUpdateNote = () => {
    trackInternalEvent('update_note')
    handleBlur()
  }

  const openDocumentSection = useCallback(() => {
    if (routeLocation.hash === `#${documentSectionId}`) {
      setIsDocumentsVisible(true)
    }
  }, [routeLocation.hash])

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        (!stateChangeRef?.current || !stateChangeRef.current.contains(event.target))
      ) {
        onClose && onClose()
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [onClose, stateChangeRef])

  useEffect(() => {
    setTitleState(job.title)
    setLocationState(job.location)
    setNotesState(job.notes)
    setDescriptionState(job.description ? stripHtml(job.description) : job.description)
    setRelevant(job.isIrrelevant)
  }, [job])

  useEffect(() => {
    job.isIrrelevant && trackInternalEvent('view_irrelevantjob_notification')
  }, [job.isIrrelevant])

  useEffect(() => {
    openDocumentSection()
  }, [openDocumentSection])

  const autoApplyDocuments = groupBy(job.autoApplyDocuments, ({ documentType }) => documentType)

  const fileInputRefResume = useRef(null)
  const handleButtonClickResume = event => {
    event.preventDefault()
    fileInputRefResume.current.click()
  }

  const fileInputRefCoverLetter = useRef(null)
  const handleButtonClickCoverLetter = event => {
    event.preventDefault()
    fileInputRefCoverLetter.current.click()
  }

  const resetFileInputSelection = () => {
    fileInputRefResume.current.value = ''
    fileInputRefCoverLetter.current.value = ''
  }

  function handleChange(event, documentType) {
    handleSubmit(event.target.files[0], documentType)
  }

  function handleSubmit(file, documentType) {
    trackInternalEvent('document_upload', { label: 'job_tracker', parameter: documentType })

    const formData = new FormData()
    formData.append('auto_apply_document[document_type]', documentType)
    formData.append('auto_apply_document[document_file]', file)

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    setUplodingFile({ file, documentType })

    apiClient
      .post(`/job-cards/${job.id}/auto-apply-documents`, formData, config)
      .then(response => {
        dispatch(
          actions.updateJob({
            ...job,
            autoApplyDocuments: [...job.autoApplyDocuments, response.data],
          }),
        )
        setUplodingFile(null)
      })
      .catch(error => {
        console.error('Error uploading file: ', error)
        setUplodingFile(null)
        const errMsg = get(error, ['response', 'data', 'documentFile', '0'])
        if (errMsg) {
          setUploadError({ error: errMsg, documentType, delete: false })
        }
      })
  }

  function handleDeleteDocument(documentId, docType) {
    // optimistic update
    trackInternalEvent('document_removal', { label: 'job_tracker', parameter: docType })
    const autoApplyDocuments = job.autoApplyDocuments.filter(document => document.id !== documentId)
    dispatch(
      actions.updateJob({
        ...job,
        autoApplyDocuments,
      }),
    )
    apiClient
      .delete(`/job-cards/${job.id}/auto-apply-documents/${documentId}`)
      .then(() => {})
      // eslint-disable-next-line n/handle-callback-err
      .catch(err => {
        dispatch(
          actions.updateJob({
            ...job,
          }),
        )
        setUploadError({
          error: i18n.t('builder.job_search.delete_error'),
          documentType: docType,
          delete: true,
        })
      })
    resetFileInputSelection()
  }

  const autoApplyDocumentSections = {}
  const docTypes = ['resume', 'cover_letter', 'auto_applied']
  docTypes.forEach(docType => {
    if (!autoApplyDocuments[docType]) {
      return
    }
    autoApplyDocumentSections[docType] = autoApplyDocuments[docType].map(({ id, documentFile }) => {
      if (!documentFile) {
        return null
      }
      const { filename, url } = documentFile
      return (
        <DocumentsListItem key={`resume-file-${id}`} href={url} target="_blank">
          <DocumentThumbnail src={DocumentIcon} />
          <DocumentDownload target="_blank" href={url}>
            {filename}
          </DocumentDownload>
          {docType !== 'auto_applied' && (
            <DocumentDeleteButton handleAction={() => handleDeleteDocument(id, docType)} />
          )}
        </DocumentsListItem>
      )
    })
    if (uploadingFile && uploadingFile.documentType === docType) {
      autoApplyDocumentSections[docType].push(
        <DocumentsListItem key="resume-file-placeholder">
          <DocumentThumbnailPlaceholder src={DocumentIcon} />
          <DocumentDownloadPlaceholder>{uploadingFile.filename}</DocumentDownloadPlaceholder>
        </DocumentsListItem>,
      )
    }
  })

  const containerRef = useRef(null)
  const handleDragLeave = e => {
    e.preventDefault()
    const container = containerRef.current
    if (!container.contains(e.relatedTarget)) {
      setIsDragging(false)
    }
  }

  const handleStatusChangeClick = () => {
    if (isPhone) {
      dispatch(actions.setOpenStatusChangeDrawer(true))
    } else {
      setStatusListOpen(!statusListOpen)
    }
  }

  const handleDrop = (event, docType) => {
    event.preventDefault()
    const droppedFiles = event.dataTransfer.files
    if (droppedFiles.length) {
      handleSubmit(droppedFiles[0], docType)
    }
    setIsDragging(false)
  }

  if (isDragging) {
    autoApplyDocumentSections.resume = (
      // <DropSection onDrop={event => handleDrop(event, 'resume')}>
      <DropSection
        onDrop={event => handleDrop(event, 'resume')}
        onDragOver={e => {
          e.preventDefault()
        }}
      >
        <DocumentsLineImg src={AttachIconBlue} />
        <span>{i18n.t('builder.job_search.auto_apply.action_buttons.file_attach')}</span>
      </DropSection>
    )
    autoApplyDocumentSections.cover_letter = (
      <DropSection
        onDrop={event => handleDrop(event, 'cover_letter')}
        onDragOver={e => {
          e.preventDefault()
        }}
      >
        <DocumentsLineImg src={AttachIconBlue} />
        <span>{i18n.t('builder.job_search.auto_apply.action_buttons.file_attach')}</span>
      </DropSection>
    )
  }

  const applyButtons = isUserOnAutoApplyPlan ? (
    <>
      <Button style={{ width: '50%' }} onClick={handleAutoApplyClick}>
        <Icon24.Simple style={{ marginRight: '4px', width: '18px' }} />
        {i18n.t('builder.job_search.auto_apply.action_buttons.auto_apply')}
      </Button>
      <Button style={{ width: '50%' }} onClick={handleApplyClick} theme={ButtonTheme.secondary}>
        <ApplyButtonIcon />
        {i18n.t('builder.job_search.auto_apply.action_buttons.manual_apply')}
      </Button>
    </>
  ) : (
    <PrimaryButton onClick={handleApplyClick}>
      <ExternalLink />
      Apply
    </PrimaryButton>
  )

  const buttonActions = showApply ? (
    <ButtonContainer fromDashboard={isDashboard}>{applyButtons}</ButtonContainer>
  ) : (
    !isPhone && <ButtonContainer />
  )

  const isRecommendedJob = status === ColumnName.recommended
  return (
    <Container ref={ref} isDashboard={isDashboard}>
      <PaddedContainer>
        {job.isIrrelevant && (
          <IrrelevantBox>
            <IrrelevantBoxTitle>Is this job still relevant?</IrrelevantBoxTitle>
            <IrrelevantBoxSubtitle>
              The job hasn&apos;t been updated for quite some time. This job might be outdated and
              no longer relevant.
            </IrrelevantBoxSubtitle>
            <Actions>
              <IrrelevantButton
                onClick={() => {
                  setRelevant(true)
                  handleBlur()
                }}
              >
                <TickIcon /> Still Relevant
              </IrrelevantButton>

              <IrrelevantDeleteButton onClick={() => onDelete(id)}>Delete</IrrelevantDeleteButton>
            </Actions>
          </IrrelevantBox>
        )}
        <Header>
          <CompanyLogoContainer>
            <CompanyLogo size={48} alt={fields.company} src={fields.logo} />
          </CompanyLogoContainer>
          <CompanyNameContainer>
            <CompanyLabel>{i18n.t('builder.job_tracking.edit_modal.company_label')}</CompanyLabel>
            <CompanyName
              value={companyState || ''}
              name="company"
              data-testid="companyInput"
              onBlur={handleBlur}
              onChange={e => {
                setCompanyState(e.target.value)
                handleUpdate(e)
              }}
            />
          </CompanyNameContainer>
          {status !== ColumnName.recommended && !isPhone && (
            <DeleteIconContainer>
              <img src={trashIcon} onClick={() => onDelete(id)} />
            </DeleteIconContainer>
          )}
          {isPhone && (
            <>
              <DrawerActionContainer>
                <Icon20.More onClick={() => dispatch(actions.setOpenStatusChangeDrawer(true))} />
              </DrawerActionContainer>
              <DrawerActionContainer>
                <HeaderClose onClick={onClose} />
              </DrawerActionContainer>
            </>
          )}
        </Header>
        <JobTrackerExtensionBanner componentType="job_tracker_sidebar" />
        {!isPhone && buttonActions}
      </PaddedContainer>
      <ScrollContainer>
        <PaddedContainer>
          <InformationContainer>
            <Title>
              <Icon20.Complitness />
              {i18n.t('builder.job_tracking.sidebar.fields.status')}
            </Title>
            <Status onClick={handleStatusChangeClick} status={status}>
              {statusListOpen && (
                <StatusSelect
                  columnNames={columnNames.filter(name => name !== ColumnName.deleted)}
                  selectedJob={job}
                  editJob={onSubmit}
                  closeSelect={() => setStatusListOpen(false)}
                  drawer={false}
                />
              )}
            </Status>
          </InformationContainer>
          <InformationContainer>
            <Title>
              <InformationIcon src={JobIcon} alt="job" />
              <span>Job Title</span>
            </Title>
            <Information
              placeholder="Empty"
              name="title"
              data-testid="titleInput"
              onBlur={handleBlur}
              onChange={e => {
                setTitleState(e.target.value)
                handleUpdate(e)
              }}
              value={titleState || ''}
            />
          </InformationContainer>
          <InformationContainer>
            <Title>
              <InformationIcon src={LocationIcon} alt="location" />
              Location
            </Title>
            <Information
              name="location"
              placeholder="Empty"
              data-testid="locationInput"
              value={locationState || ''}
              onBlur={handleBlur}
              onChange={e => {
                setLocationState(e.target.value)
                handleUpdate(e)
              }}
            />
          </InformationContainer>
          <InformationContainer>
            <Title>
              <InformationIcon src={LinkIcon} alt="link" />
              URL
            </Title>
            <Information
              name="link"
              placeholder="Empty"
              data-testid="urlInput"
              value={linkState || ''}
              onBlur={handleBlur}
              onChange={e => {
                setLinkState(e.target.value)
                handleUpdate(e)
              }}
            />
          </InformationContainer>
          {!isRecommendedJob && (
            <>
              <InformationContainer>
                <Title>
                  <InformationIcon src={SalaryIcon} alt="salary" />
                  Salary
                </Title>
                <Information
                  name="salary"
                  placeholder="Empty"
                  data-testid="salaryInput"
                  value={salaryState || ''}
                  onBlur={handleBlur}
                  onChange={e => {
                    setSalaryState(e.target.value)
                    handleUpdate(e)
                  }}
                />
              </InformationContainer>
            </>
          )}
          {resumeId && (
            <InformationContainer>
              <Title>
                <ResumeDocumentIcon src={documentIcon} />
                <span>Resume</span>
              </Title>
              <ResumeTitle onClick={handleResumeLink}>{job.resumeName}</ResumeTitle>
            </InformationContainer>
          )}
          {resumeId && (
            <InformationContainer>
              <Title>
                <Document />
                <ResumeMatch>Resume Match</ResumeMatch>
              </Title>
              <InformationDiv>
                <ResumePercentage poorResumeScore={poorResumeScore}>
                  {resumeScore}%
                </ResumePercentage>
                <ResumeMatchText>
                  {poorResumeScore
                    ? i18n.t(`builder.job_tracking.resume_match.poor`)
                    : i18n.t(`builder.job_tracking.resume_match.perfect`)}
                </ResumeMatchText>
              </InformationDiv>
            </InformationContainer>
          )}
          {isUserOnAutoApplyPlan && job.autoApplyStatus && (
            <>
              <InformationContainer style={{ alignItems: 'center', paddingBottom: '0' }}>
                <Title shortWidth>
                  <Icon24.Simple style={{ opaciy: 0.5, width: '15px' }} />
                  {i18n.t(`builder.job_tracking.edit_modal.auto_apply_label`)}
                </Title>
                <AutoApplyStatusLabel autoApplyStatus={job.autoApplyStatus} />
              </InformationContainer>

              {job?.autoTailoringStatus === 'failed' ? (
                <AutoApplyStatusQuestions>
                  <AutoApplyTailorResumeError>
                    {i18n.t(`builder.job_tracking.edit_modal.auto_apply_resume_tailoring_error`)}
                  </AutoApplyTailorResumeError>
                  <span>
                    {i18n.t(`builder.job_tracking.edit_modal.auto_apply_resume_tailoring_info`)}
                  </span>{' '}
                  <a href="mailto:autoapply@career.io">autoapply@career.io</a>
                </AutoApplyStatusQuestions>
              ) : (
                <AutoApplyStatusQuestions>
                  <span>{i18n.t(`builder.job_tracking.edit_modal.auto_apply_question`)}</span>{' '}
                  <a href="mailto:autoapply@career.io">autoapply@career.io</a>
                </AutoApplyStatusQuestions>
              )}
            </>
          )}
        </PaddedContainer>
        {!isRecommendedJob && (
          <>
            <TextAreaContainer
              ref={containerRef}
              onDragEnter={() => {
                setIsDocumentsVisible(true)
                setIsDragging(true)
              }}
              onDragLeave={handleDragLeave}
            >
              <FieldLabel
                id={documentSectionId}
                onClick={() => {
                  setIsDocumentsVisible(!isDocumentsVisible)
                }}
              >
                <DescriptionImage src={AttachIcon} />
                {i18n.t('builder.job_tracking.edit_modal.sections.documents')}
                <Chevron collapsed={+isDocumentsVisible} />
              </FieldLabel>
              {isDocumentsVisible && (
                <DocumentsContent>
                  {job?.autoTailoringStatus === 'processing' && (
                    <DocumentsInfoContainer>
                      <DocumentsInfoSpinner />
                      <DocumentsInfoText>
                        {i18n.t('builder.job_tracking.edit_modal.sections.documents_info')}
                      </DocumentsInfoText>
                    </DocumentsInfoContainer>
                  )}
                  <DocumentsLineHead>
                    <DocumentsLineTitle>
                      {i18n.t('builder.job_tracking.edit_modal.documents_titles.resume')}
                    </DocumentsLineTitle>
                    <form>
                      <input
                        type="file"
                        onChange={ev => handleChange(ev, 'resume')}
                        ref={fileInputRefResume}
                        style={{ display: 'none' }}
                      />
                      <DocumentsLineButton onClick={handleButtonClickResume}>
                        <DocumentsLineImg src={AttachIcon} />
                      </DocumentsLineButton>
                    </form>
                  </DocumentsLineHead>
                  <DocumentsList>{autoApplyDocumentSections.resume}</DocumentsList>
                  {uploadError && uploadError.documentType === 'resume' && (
                    <DocumentErrorLine onClick={() => setUploadError(null)}>
                      <p>
                        {!uploadError.delete &&
                          i18n.t('builder.job_tracking.edit_modal.upload_error')}
                      </p>
                      <p>{uploadError.error}</p>
                    </DocumentErrorLine>
                  )}
                  <DocumentsLineHead>
                    <DocumentsLineTitle>
                      {i18n.t('builder.job_tracking.edit_modal.documents_titles.cover_letter')}
                    </DocumentsLineTitle>
                    <form>
                      <input
                        type="file"
                        onChange={ev => handleChange(ev, 'cover_letter')}
                        ref={fileInputRefCoverLetter}
                        style={{ display: 'none' }}
                      />
                      <DocumentsLineButton onClick={handleButtonClickCoverLetter}>
                        <DocumentsLineImg src={AttachIcon} />
                      </DocumentsLineButton>
                    </form>
                  </DocumentsLineHead>
                  <DocumentsList>{autoApplyDocumentSections.cover_letter}</DocumentsList>
                  {uploadError && uploadError.documentType === 'cover_letter' && (
                    <DocumentErrorLine onClick={() => setUploadError(null)}>
                      <p>
                        {!uploadError.delete &&
                          i18n.t('builder.job_tracking.edit_modal.upload_error')}
                      </p>
                      <p>{uploadError.error}</p>
                    </DocumentErrorLine>
                  )}
                  {/* eslint-disable-next-line max-len */}
                  {(job.status === ColumnName.auto_apply || job.autoApplyStatus) &&
                    isUserOnAutoApplyPlan && (
                      <>
                        <DocumentsLineHead>
                          <DocumentsLineTitle>
                            {i18n.t(
                              'builder.job_tracking.edit_modal.documents_titles.application_confirmation',
                            )}
                          </DocumentsLineTitle>
                        </DocumentsLineHead>
                        <DocumentsList>{autoApplyDocumentSections.auto_applied}</DocumentsList>
                      </>
                    )}
                </DocumentsContent>
              )}
            </TextAreaContainer>
            <TextAreaContainer>
              <FieldLabel
                onClick={() => {
                  setIsNotesVisible(!isNotesVisible)
                }}
              >
                <DescriptionImage src={EditIcon} />
                {i18n.t(`builder.job_tracking.edit_modal.sections.notes`)}
                <Chevron collapsed={+isNotesVisible} />
              </FieldLabel>
              {isNotesVisible && (
                <Field
                  multiline
                  Icon={<img src={EditIcon} />}
                  name="notes"
                  value={notesState || ''}
                  onChange={e => {
                    setNotesState(e.target.value)
                    handleUpdate(e)
                  }}
                  onBlur={handleUpdateNote}
                />
              )}
            </TextAreaContainer>
          </>
        )}
        <TextAreaContainer>
          <FieldLabel
            onClick={() => {
              setIsDescriptionVisible(!isDescriptionVisible)
            }}
          >
            <DescriptionImage src={briefcaseBlackIcon} />
            {i18n.t('builder.job_tracking.edit_modal.sections.job_description')}
            <Chevron collapsed={+isDescriptionVisible} />
          </FieldLabel>

          {isDescriptionVisible && (
            <Field
              multiline
              name="description"
              value={descriptionState || ''}
              onBlur={handleBlur}
              onChange={e => {
                setDescriptionState(e.target.value)
                handleUpdate(e)
              }}
            />
          )}
        </TextAreaContainer>
        <JobSearchInfo job={job} handleSetInterviewJob={handleSetInterviewJob} />
      </ScrollContainer>
      {isPhone && buttonActions}
    </Container>
  )
}

export default Sidebar

Sidebar.propTypes = {
  job: PropTypes.object,
  columnNames: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  handleSetInterviewJob: PropTypes.func.isRequired,
  isDashboard: PropTypes.bool,
  toggleChangeStatusDrawer: PropTypes.func,
  stateChangeRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any,
    }),
  ]),
}

Sidebar.defaultProps = {
  job: {},
  columnNames: [],
}
