import * as Styles from './styles'

export const LoadingCard = () => {
  return (
    <Styles.Card>
      <Styles.SkeletonContainer>
        <Styles.SkeletonImageContainer>
          <Styles.SkeletonImage />
          <Styles.SkeletonFadeResume />
        </Styles.SkeletonImageContainer>
        <Styles.SkeletonInfo>
          <Styles.SkeletonHeader />
          <Styles.SkeletonDescription />
        </Styles.SkeletonInfo>
      </Styles.SkeletonContainer>
    </Styles.Card>
  )
}
