import { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { UserDocumentType } from '@rio/types'
import { useI18n } from 'builder/hooks/useI18n'

import {
  BriefResume,
  BriefLetter,
  BriefExpertDoc,
  selectors as panelSelectors,
  typeToSlug,
} from 'builder/modules/panel'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'
import { DocumentLoaderTooltip } from 'builder/components/LoaderTooltip/DocumentLoaderTootip'
import { ResumeIDStatuses } from 'builder/modules/constants'
import { useConfig } from 'builder/hooks/useConfig'
import { navigate } from 'builder/modules/navigate'
import { ListContainer, ListAnimatedGrid, ListExpand, ListCreateDocument } from './styles'
import { DocumentListItem } from './DocumentListItem'

interface Props {
  type: UserDocumentType
  documents: Array<BriefResume | BriefLetter | BriefExpertDoc>
  critiqueReviewLoaderVisibility: boolean
  fromDashboard?: boolean
  isAutoTailored: boolean
  isPaginationDisabled?: boolean
}

export const DocumentsList = ({
  type,
  documents,
  critiqueReviewLoaderVisibility,
  fromDashboard,
  isAutoTailored,
  isPaginationDisabled = false,
}: Props) => {
  const resumeStatus = useTypedSelector(panelSelectors.resumeStatus)
  const location = useLocation()
  const { i18n } = useI18n()
  const { isPhone, isTablet } = useMediaQueries()
  const userType = useTypedSelector(userSelectors.postPremium)

  const getInitalDocumentLimit = useMemo(
    () => (isPaginationDisabled ? Infinity : fromDashboard ? 2 : isTablet ? 2 : 4),
    [fromDashboard, isTablet, isPaginationDisabled],
  )

  const [limit, setLimit] = useState(getInitalDocumentLimit)
  const visible = useMemo(() => documents.slice(0, limit), [documents, limit])

  const queryParams = new URLSearchParams(location.search)
  const key = queryParams.get('tr_promo_banner')
  const isListNotEmpty = (documents?.length ?? 0) > 0
  const isSkipped = resumeStatus === ResumeIDStatuses.skipped

  const config = useConfig()
  const isSuperApp = config?.features.superApp

  const [isHovering, setIsHovering] = useState(false)

  const handleShowMore = () => {
    if (fromDashboard) {
      navigate(`/${typeToSlug(type, isAutoTailored)}`)
      return
    }
    setLimit(Infinity)
  }

  return (
    <ListContainer>
      <ListAnimatedGrid appearAnimation="fade" duration={300} staggerDelayBy={50}>
        {visible.map((document, index) => (
          <DocumentListItem
            document={document}
            setIsHovering={setIsHovering}
            key={document.type + document.id}
            critiqueReviewLoaderVisibility={critiqueReviewLoaderVisibility}
            shouldShowLoaderContainer={isListNotEmpty && isSkipped && key !== null}
            isSuperApp={isSuperApp}
            documentType={type}
            isPostPremium={userType === 'post_premium' && (index !== 0 || isAutoTailored)}
            isFirstDocumentFree={userType === 'post_premium' && (index !== 0 || isAutoTailored)}
            isAutoTailored={isAutoTailored}
          />
        ))}
        {documents.length === 1 &&
          !isPhone &&
          !isAutoTailored &&
          (type === 'resume' || type === 'cover_letter') && (
            <ListCreateDocument key={`create-${type}`} type={type} />
          )}
        <>{isHovering && <DocumentLoaderTooltip />}</>
      </ListAnimatedGrid>
      {documents.length > visible.length && (
        <ListExpand onClick={handleShowMore}>
          {i18n.t(`builder.panel.documents.show_more.${type}`, {
            count: documents.length - visible.length,
          })}
        </ListExpand>
      )}
    </ListContainer>
  )
}
