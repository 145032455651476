import { useRef, useState, useEffect } from 'react'
import throttle from 'lodash/throttle'
import { TemplateCategories } from 'builder/modules/resumeEditor'
import { TemplatesPreviewList } from 'builder/components/TemplatesList/TemplatesPreviewList'
import { DocumentTypes } from 'builder/modules/constants'
import { ColorSelection } from '../ColorSelection/ColorSelection'
import { Container, TemplateSelectionContainer } from './styles'

interface TemplateSelectionProps {
  type: DocumentTypes
}

export const TemplateSelection = ({ type }: TemplateSelectionProps) => {
  const templateListRef = useRef<HTMLDivElement | null>(null)
  const [isScrolled, setIsScrolled] = useState(false)

  const handleScroll = throttle(() => {
    if (templateListRef.current) {
      setIsScrolled(templateListRef.current.scrollTop > 0)
    }
  }, 200)

  useEffect(() => {
    const element = templateListRef.current
    if (element) {
      element.addEventListener('scroll', handleScroll)
      return () => element.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <Container isScrolled={isScrolled}>
      <ColorSelection type={type} isScrolled={isScrolled} />
      <TemplateSelectionContainer ref={templateListRef}>
        <TemplatesPreviewList
          type={type}
          selectedCategory={TemplateCategories.all}
          selectedTemplates={[]}
        />
      </TemplateSelectionContainer>
    </Container>
  )
}
