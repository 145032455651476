import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { DocumentTypes } from 'builder/modules/constants'
import { Routes } from '../../constants'

import * as Styles from './styles'
import Icon from './assets/icon.png'

const TRANSLATION = 'builder.auto_apply.form.cover_letter.preview'

export const CoverLetterPreview = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()

  const onActionClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    trackInternalEvent('open_full_screen_cover_letter_preview', { label: 'auto_apply' })
    navigate(`/documents/0/${DocumentTypes.coverLetter}/preview?path=${Routes.COVER_LETTER}`)
  }

  return (
    <Styles.Container>
      <Styles.LeftContainer>
        <Styles.Title>{i18n.t(`${TRANSLATION}.header`)}</Styles.Title>
        <Styles.ActionButton onClick={onActionClick}>
          {i18n.t(`${TRANSLATION}.action`)}
        </Styles.ActionButton>
      </Styles.LeftContainer>
      <Styles.RightContainer>
        <Styles.PreviewContainer $src={Icon} />
      </Styles.RightContainer>
    </Styles.Container>
  )
}
