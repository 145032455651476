import React from 'react'

export default function LoadingIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="20"
      viewBox="0 0 32 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="6" cy="10" r="3.25" fill="#7A82F5" stroke="#D9DEEB" strokeWidth="0.5" />
      <circle cx="16" cy="10" r="3.25" fill="#7A82F5" stroke="#D9DEEB" strokeWidth="0.5" />
      <circle cx="26" cy="10" r="3.25" fill="#7A82F5" stroke="#D9DEEB" strokeWidth="0.5" />
    </svg>
  )
}
