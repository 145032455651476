import styled, { keyframes } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

const toggleIn = keyframes`
  0% { opacity: 0.1; }
  100% { opacity: 1; }
`

export const Conatiner = styled.div`
  display: flex;
  gap: 20px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid ${Colors.Neutral15};
  height: 202px;
  cursor: pointer;
  justify-content: space-between;

  &:hover {
    border-color: ${Colors.Blue50};
  }

  ${Media.Phone`
    height: 179px;
  `}
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: ${toggleIn} 1s 1;
`

export const TextContainer = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: column;
  animation: ${toggleIn} 1s 1;
`

export const ToggleContainer = styled.div`
  margin-top: 10px;
`

export const IconContainer = styled.div``
export const Header = styled.h2`
  color: ${Colors.Neutral90};

  ${Typography.Subhead}
  ${FontWeights.Medium}
`

export const Description = styled.p`
  color: ${Colors.Neutral50};

  ${Typography.Caption}
  ${FontWeights.Regular}
`
