import { useEffect, useRef, useState } from 'react'

import { DocumentTypes } from 'builder/modules/constants'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'

import Preview from 'builder/components/Preview'
import { DocumentHeader } from '../DocumentHeader/DocumentHeader'
import { DocumentLoader } from '../DocumentLoader/DocumentLoader'

import { useCoverLetterPreview } from './hooks/useCoverLetterPreview'
import * as Styles from './styles'

export const CoverLetterPreview = () => {
  const [width, setWidth] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(true)

  const { isTablet, isPhone } = useMediaQueries()
  const containerRef = useRef<HTMLDivElement>(null)
  const coverLetter = useCoverLetterPreview()

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth)
    }
  }, [containerRef, isTablet, isPhone])

  return (
    <Styles.Container ref={containerRef}>
      <DocumentHeader type={DocumentTypes.coverLetter} />
      {isLoading && <DocumentLoader />}
      <Preview
        documentId={0}
        document={coverLetter}
        type={DocumentTypes.coverLetter}
        width={isTablet || isPhone ? width : 928}
        height={isTablet || isPhone ? width * 1.4 : 1300}
        onPaginationChange={() => {
          setIsLoading(false)
        }}
      />
    </Styles.Container>
  )
}
