import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { TRLogo } from 'builder/components/Icon/20x20'
import { Icon20 } from 'builder/components/Icon'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 64px;
  gap: 32px;
  overflow: auto;

  ${Media.Phone`
    padding: 20px;
    padding-bottom: 0;
  `};
`

export const BoxWrapper = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-around;

  ${Media.Phone`
    flex-direction: column;
    align-items: center;
  `};
`

export const Box = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  max-width: 448px;
  width: 448px;
  height: 400px;

  border: 1px solid ${Colors.Neutral15};
  border-radius: 16px;
  gap: 8px;
  cursor: pointer;

  ${Media.Tablet`
    width: 352px;
    height: 400px;
  `}

  ${Media.Phone`
    padding-bottom: 20px;
    width: 100%;
    height: 306px;
  `}

  &:hover {
    box-shadow: 0px 16px 64px -8px rgba(15, 56, 113, 0.18), 0px 6px 8px -2px rgba(15, 56, 113, 0.02),
      0px 0px 1px 0px rgba(15, 56, 113, 0.32);
    transition: box-shadow 0.2s ease-in;
  }
`

export const UploadFromDevice = styled.label`
  width: 100%;
  height: 100%;
  cursor: pointer;
`

export const UploadResumeImage = styled.div`
  background: linear-gradient(180deg, #f7f9fc 0%, #ffffff 100%);
  box-shadow: 0px 2px 4px rgba(15, 56, 113, 0.08), 0px 0px 1px rgba(15, 56, 113, 0.4);
  width: 155px;
  height: 235px;
  border-radius: 6px;
  margin: 40px auto 0;
  padding-top: 40px;
  position: relative;
  svg {
    margin: auto;
    display: block;
  }

  ${Media.Phone`
    margin: 25px auto 0;
    padding-top: 25px;
    width: 122px;
    height: 172px;
  `};
`

export const UploadResumeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 238px;
  overflow: hidden;

  ${Media.Phone`
    height: 204px;
  `};
`

export const SelectionTitle = styled.div<{ withMargin?: boolean }>`
  color: ${Colors.Neutral90};
  ${Typography.S};
  ${FontWeights.Medium};
  text-align: center;
  max-width: 256px;
  ${props => props.withMargin && 'margin: 8px auto 0;'}

  ${Media.Phone`
    width: 100%;
    max-width: 100%;
  `}
`

export const Description = styled.div<{ withMargin?: boolean; isTrDocument?: boolean }>`
  color: ${Colors.Neutral50};
  ${Typography.Body};
  ${FontWeights.Regular};
  text-align: center;
  max-width: 256px;
  margin: 0 auto;
  ${props => props.withMargin && 'margin-top: 8px;'}
  ${props => props.isTrDocument && 'max-width: 190px;'}

  ${Media.Phone`
    width: 100%;
    max-width: 100%;
    padding: 0 24px;
  `}
`

export const FadeResume = styled.div`
  position: absolute;
  width: 400px;
  height: 88px;
  bottom: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  z-index: 3;
  left: 50%;
  transform: translateX(-50%);

  ${Media.Phone`
    width: 300px;
    height: 68px;
  `};
`

export const FadeUpload = styled.div`
  position: absolute;
  width: 400px;
  left: 24px;
  height: 108px;
  top: 148px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  z-index: 3;

  ${Media.Phone`
    width: 238px;
    height: 84px;
    left: 48px;
    top: 118px;
  `};
`

export const FakeResume = styled.div`
  position: relative;
  padding-top: 40px;
  width: 100%;
  height: 238px;
  overflow: hidden;

  ${Media.Phone`
    padding-top: 25px;
    height: 180px;
  `};
`

export const Image1 = styled.img`
  width: 155px;
  height: 220px;
  position: absolute;
  left: 32%;
  transform: translateX(-32%);
  top: 44px;
  filter: drop-shadow(0px 2px 4px rgba(15, 56, 113, 0.08))
    drop-shadow(0px 1px 2px rgba(15, 56, 113, 0.12))
    drop-shadow(0px 0px 1px rgba(15, 56, 113, 0.32));

  ${Media.Phone`
    right: 52px;
    top: 30px;
    width: 129px;
    height: 165.061px;
  `};
`

export const Image2 = styled.img`
  width: 155px;
  height: 220px;
  z-index: 2;
  position: absolute;
  left: 58%;
  transform: translateX(-58%);
  box-shadow: 0px 16px 64px rgba(15, 56, 113, 0.18), 0px 6px 8px rgba(15, 56, 113, 0.02),
    0px 0px 1px rgba(15, 56, 113, 0.32);

  ${Media.Phone`
    width: 121px;
    height: 171px;
  `};
`

export const TrImage = styled.img`
  width: 201px;
  height: 284.641px;
  border-radius: 4px;
  box-shadow: 0px 16px 64px rgba(15, 56, 113, 0.18), 0px 6px 8px rgba(15, 56, 113, 0.02),
    0px 0px 1px rgba(15, 56, 113, 0.32);

  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  ${Media.Tablet`
    width: 166px;
  `};

  ${Media.Phone`
    width: 162px;
  `};
`

export const TrResumeLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: ${Colors.Neutral5};
  border: 1px solid ${Colors.Blue40};
  padding: 8px;
  border-radius: 8px;

  ${Media.Phone`
    padding: 7px 8px;
  `}
`

export const TrResumeLabelIcon = styled(TRLogo)``
export const TrResumeLabelText = styled.div`
  ${Typography.Caption}
  ${FontWeights.Regular}
`

export const InfoSection = styled.div`
  width: inherit;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 40px;

  ${Media.Phone`
    bottom: 5px;
  `}
`
export const InfoSectionIcon = styled(Icon20.Time)`
  margin-top: -2px;
  margin-right: 2px;
  fill: white;
  color: ${Colors.Neutral50};
`
export const InfoSectionDescription = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Caption}
  ${FontWeights.Regular}
`
export const InfoSectionDescriptionBold = styled.span`
  color: ${Colors.Neutral90};
  ${Typography.Caption}
  ${FontWeights.Medium}
`

export const SkeletonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

export const SkeletonImageContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: -15px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${Media.Phone`
    margin-top: 27px;
  `};
`

export const SkeletonImage = styled.div`
  height: 270px;
  width: 201px;
  background: ${Colors.Neutral5};

  ${Media.Tablet`
    width: 166px;
  `};

  ${Media.Phone`
    width: 162px;
    height: 257px;
  `};
`

export const SkeletonInfo = styled.div`
  position: absolute;
  bottom: 75px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: var(--Spacing-S, 16px);
  background: ${Colors.White};

  ${Media.Phone`
    bottom: 0;
  `};
`

export const SkeletonHeader = styled.div`
  height: 44px;
  width: 248px;
  background: ${Colors.Neutral5};
`

export const SkeletonDescription = styled.div`
  width: 145px;
  height: 24px;
  background: ${Colors.Neutral5};
`

export const SkeletonFadeResume = styled.div`
  position: absolute;
  width: 250px;
  height: 88px;
  margin-top: 22px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  z-index: 3;
  left: 50%;
  transform: translateX(-50%);

  ${Media.Phone`
    margin-top: 0;
  `};
`
