import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'
import Badges from 'builder/components/Badges'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: '20px';
  margin-top: 24px;
  padding: 0 ${Sizes.S} ${Sizes.XL} ${Sizes.S};

  ${Media.AboveTablet`
    margin-top: 32px;
    padding: 0 32px;
  `};
`

export const TemplateSelectionMobileContainer = styled.div`
  height: 100%;
`

export const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
`

export const Template = styled.div`
  position: relative;
  flex-shrink: 0;
  color: ${Colors.Neutral70};
  white-space: nowrap;
  transition: color 0.2s;
  font-size: 15px;
  line-height: 18px;

  .swiper-slide-active & {
    color: ${Colors.White};
  }
`

export const Templates = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 8px;

  .swiper-container {
    position: relative;
    overflow: visible;
  }

  .swiper-wrapper {
    display: flex;
    position: relative;
    transition-property: transform;
    align-items: flex-end;
  }
`

export const TemplatePreview = styled.div<{
  image?: string
  isActive?: boolean
}>`
  position: relative;
  width: 118px;
  height: ${({ isActive }) => (isActive ? `164px` : '124px')};
  background-color: ${Colors.White};
  background-image: ${({ image }) => (image ? `url(${image})` : 'none')};
  background-size: cover;

  ${Media.Hover`
    ${Template}:hover &:after {
      opacity: 1;
    }
  `}
`

export const FormatBadges = styled(Badges)``

export const PlanBadges = styled(Badges).attrs({ topic: 'plan' })``

export const BadgeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Sizes['3XS']};
  align-items: center;
  justify-content: center;
`
