import { InterviewBehaviour, behaviourType } from 'builder/modules/interview/types'
import { useI18n } from 'builder/hooks/useI18n'
import { InterviewStartPage } from './InterviewStartPage'

const TRANSLATION = 'builder.interview_prep_view.start_screen'

export const InterviewStartView = ({ behaviour }: { behaviour?: behaviourType }) => {
  const { i18n } = useI18n()

  switch (behaviour) {
    // company/industry/ambitious
    case InterviewBehaviour.AMBITIOUS:
      return (
        <InterviewStartPage
          titleLabel={i18n.t(`${TRANSLATION}.ambitious.title`)}
          subtitleLabel={i18n.t(`${TRANSLATION}.ambitious.subtitle`)}
          confirmButtonLabel={i18n.t(`${TRANSLATION}.create_interview`)}
          behaviour={InterviewBehaviour.AMBITIOUS}
        />
      )

    // tough q/confident
    case InterviewBehaviour.CONFIDENT:
      return (
        <InterviewStartPage
          titleLabel={i18n.t(`${TRANSLATION}.confident.title`)}
          subtitleLabel={i18n.t(`${TRANSLATION}.confident.subtitle`)}
          confirmButtonLabel={i18n.t(`${TRANSLATION}.create_interview`)}
          behaviour={InterviewBehaviour.CONFIDENT}
        />
      )

    // anxiety/nervous/easy/Stress Relief
    case InterviewBehaviour.NERVOUS:
    default:
      return (
        <InterviewStartPage
          titleLabel={i18n.t(`${TRANSLATION}.nervous.title`)}
          subtitleLabel={i18n.t(`${TRANSLATION}.nervous.subtitle`)}
          confirmButtonLabel={i18n.t(`${TRANSLATION}.create_interview`)}
          behaviour={InterviewBehaviour.NERVOUS}
        />
      )
  }
}
