import { useNavigate } from 'react-router-dom'
import { LoadingAnimation } from 'builder/components/LoadingAnimation/LoadingAnimation'
import { LoadingScreenItem } from 'builder/modules/interview/types'
import { NavbarWithoutMenu } from '../Navbar/NavbarWithoutMenu/NavbarWithoutMenu'
import { Wrapper, Title, Description, Content } from './styles'

type LoadingScreenProps = {
  content: LoadingScreenItem
}

export const LoadingScreen = ({ content }: LoadingScreenProps) => {
  const navigate = useNavigate()
  return (
    <Wrapper>
      <NavbarWithoutMenu onCloseClick={() => navigate('/interview-preparation/dashboard')} />
      <Content>
        <LoadingAnimation />
        <Title>{content.title}</Title>
        <Description $width={content.customContentWidth}>{content.content}</Description>
      </Content>
    </Wrapper>
  )
}
