import { State } from './types'

export const API_ENDPOIT = `/ai_coach/v1/ai_coach/prompt`
export const CLIENT_STORAGE_KEY = 'virtual_coach_state'
export const ERROR_MESSAGE = 'builder.virtual_coach.error_message'
export const DEFAULT_STATE: State = [
  {
    role: 'assistant',
    content:
      'Hello and welcome! I’m your AI coach, here to support you on your journey to finding your next opportunity. Whether you’re polishing your resume, preparing for interviews, or looking for personalized job recommendations, I’m here to assist you in landing the job that’s right for you. Let’s start this journey together and make your job search a success!',
  },
]
