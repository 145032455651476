import styled, { FlattenSimpleInterpolation } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: radial-gradient(56.94% 56.94% at 50% 50%, #f5f8ff 0%, #dcdfe7 100%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1551;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 30px;
  overflow: hidden;
  ${Media.Phone`
    padding: 5px 30px;
  `};
`
export const Top = styled.div`
  display: flex;
  justify-content: space-between;

  ${Media.Desktop`
    width: 300px;
    flex-shrink: 0;
  `};

  ${Media.Phone`
    flex-direction: column;
    gap: 20px;
  `};
  ${Media.AbovePhone`
    flex-direction: row;
  `};
`

export const BodyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;

  ${Media.Phone`
    flex-direction: column;
    gap: 20px;
  `};

  ${Media.AbovePhone`
    flex-direction: column;
    gap: 20px;
  `};

  ${Media.Desktop`
    flex-direction: row;
  `};
`

export const TitleContainer = styled.div``

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  ${Media.Phone`
      text-align: center;
  `};
  ${Media.Desktop`
    white-space: nowrap;
  `};
`

export const MidContainer = styled.div`
  height: 100%;
  width: 100%;
  align-items: center;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 15%;

  ${Media.Phone`
    padding: 20px 0;
    position: unset;
  `};

  ${Media.Tablet`
    padding-top: 5%;
  `};
  ${Media.Laptop`
    padding-top: 5%;
  `};
`

export const VideoSpeechTextContainer = styled.div`
  ${Media.Phone`
    position: unset;
    display: flex;
    justify-content: end;
  `};
`

export const QuestionStatementText = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};
  color: ${Colors.Neutral50};
  text-align: center;
  margin-bottom: 20px;
`

const decreaseFont = (font: FlattenSimpleInterpolation) => {
  if (font === Typography.XL) {
    return Typography.L
  }
  if (font === Typography.L) {
    return Typography.M
  }

  if (font === Typography.M) {
    return Typography.S
  }

  if (font === Typography.S) {
    return Typography.Subhead
  }

  return Typography.Body
}

let currentFont: FlattenSimpleInterpolation = Typography.XL
const determineFont = (element: HTMLElement | null) => {
  if (!element) return currentFont

  if (element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth) {
    currentFont = decreaseFont(currentFont)
  }
  return currentFont
}

export const Question = styled.div<{ $ref: HTMLDivElement | null }>`
  ${({ $ref }) => determineFont($ref)}
  ${FontWeights.Medium};
  color: ${Colors.Neutral60};
  margin: auto;
  text-align: center;
  flex: 1;
  overflow: hidden;
`

export const InterviewDurationText = styled.div`
  bottom: 40px;
  left: 40px;
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Neutral50};
`

export const InterviewDurationTime = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
`

export const NextControlButtonContainer = styled.div<{ $show: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')};
`

export const YourAnswerTitle = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  margin-top: 24px;
  margin-bottom: 8px;

  color: ${Colors.Neutral50};

  ${Media.Desktop`
    margin-left: 0;
  `};

  ${Media.Tablet`
    text-align: center;
    margin-left: 0;
    margin-top: 56px;
  `};

  ${Media.Phone`
    display: none;
  `};
`

export const SpeechContainer = styled.div`
  flex-direction: column;
  align-items: center;
  right: 40px;
  overflow: hidden;
  padding: 0 56px;

  ${Media.Desktop`
    padding: 0;
  `}

  ${Media.Tablet`
    position: unset;
    width: 100%;
    padding: 0 56px;
  `}

  ${Media.Phone`
    position: unset;
    padding: 0 20px;
    width: 100%;
  `}
`

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const FooterContainerItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`
export const MobileSideBarButton = styled.div`
  margin-left: auto;
  border-radius: 44px;
  background-color: ${Colors.White};
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;

  & path {
    color: ${Colors.Blue50};
    stroke: currentColor;
  }

  ${Media.Hover`
    &:hover {
      cursor: pointer;
    }
  `}
`

export const Note = styled.div`
  padding-top: 16px;
  text-align: center;
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral30};
`

export const VoiceIndicatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
