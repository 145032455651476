import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import { ButtonsContainer, BottomContainer } from 'builder/components/SimpleForm/styles'

export const LayoutContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${Colors.White};
  border-radius: 16px;
  padding: 32px;
  padding-bottom: 0;

  ${Media.Phone`
    padding: 20px;
    padding-bottom: 0;
  `};
`

export const FormContainer = styled.div`
  width: 100%;
  padding-bottom: 100px;

  ${ButtonsContainer} {
    margin-top: 0;
  }

  ${BottomContainer} {
    position: fixed;
    bottom: var(--size-m);
    width: 940px;
    background-color: ${Colors.White};

    ${Media.Phone`
      bottom: 71px;
      width: 90vw;
      padding-right: 20px;
      padding-bottom: 10px;
   `};
  }
`

export const Container = styled.div`
  width: 100%;
  max-width: 416px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${Media.Phone`
    width: 100%;
  `};
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 512px;
`

export const Title = styled.div`
  text-align: center;
  ${Typography.M};
  ${FontWeights.Medium};
`

export const FillWarning = styled.div`
  text-align: center;
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
`
