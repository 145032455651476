import Icon from 'builder/components/Icon'
import { Handle } from './styles'

type SliderHandleProps = {
  value: number
  disabled?: boolean | undefined
}

export const SliderHandle = ({ value, disabled, ...rest }: SliderHandleProps) => {
  return (
    <Handle disabled={disabled} value={value} {...rest}>
      {disabled && <Icon.Lock />}
    </Handle>
  )
}
