import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'

export const Container = styled.div<{ $isSafari?: boolean }>`
  width: 100%;
  bottom: 0;
  position: fixed;
  z-index: 1000;
  background: #fff;

  ${({ $isSafari }) =>
    $isSafari &&
    css`
      padding-bottom: 10px;
    `}
`

export const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${Colors.Neutral15};
`

export const MenuItem = styled(Link)<{ $active?: boolean }>`
  position: relative;
  display: inline-block;
  padding: 12px 6px 14px;
  min-width: 75px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 2px solid ${({ $active }) => ($active ? Colors.Blue50 : Colors.White)};
`

export const Text = styled.div<{ $active?: boolean }>`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.3px;
  ${FontWeights.Medium};
  color: ${({ $active }) => ($active ? Colors.Blue50 : Colors.Neutral50)};
`

export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-right: 2.5px;
  padding-bottom: 2px;
`

export const CrownIcon = styled(Icon20.Crown).attrs({
  width: 16,
  height: 16,
})`
  position: absolute;
  right: 26px;
  bottom: 26px;
  background-color: ${Colors.White};
  border-radius: 40%;
  color: ${Colors.Indigo70} !important;
`
