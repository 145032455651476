import { useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Icon24 } from 'builder/components/Icon'

import { actions as uiActions } from 'builder/modules/ui'
import { selectors, actions } from 'builder/modules/careerProfile'
import { selectors as userSelectors, UserType } from 'builder/modules/user'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useI18n } from 'builder/hooks/useI18n'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { MobileVirtualCoach, useVirtualCoachFeature } from 'builder/features/virtualCoach'

import { useMenuItems } from '../../hooks/useMenuItems'
import { isSafari } from '../../utils'

import { MobileDrawerMenu } from './MobileMoreMenu/MobileMoreMenu'
import { Container, CrownIcon, IconWrapper, Menu, MenuItem, Text } from './styles'

export const MobileMenu = () => {
  const { i18n } = useI18n()
  const location = useLocation()
  const dispatch = useDispatch()
  const containerRef = useRef<HTMLDivElement>(null)
  const { isActiveNestedMenu } = useMenuItems(true)

  const [drawerMenu, setDrawerMenu] = useState(false)
  const isMobileProfileMenuOpen = useTypedSelector(selectors.isMobileProfileMenuOpen)
  const userType = useTypedSelector(userSelectors.postPremium)
  const isPostPremiumActive = userType === UserType.postPremium

  const { isVirtualCoachEnabled } = useVirtualCoachFeature()

  const handleMobileMenu = (state: boolean) => {
    setDrawerMenu(state)
    if (isMobileProfileMenuOpen !== state) {
      dispatch(actions.setMobileProfileMenu(state))
    }
  }

  const handlePostPremium = useCallback(() => {
    if (isPostPremiumActive) dispatch(uiActions.setTrackJobPaywall(true))
  }, [dispatch, isPostPremiumActive])

  useClickOutside(containerRef, () => handleMobileMenu(false))

  const isMenuActive = (to: string) => location.pathname === to || isActiveNestedMenu(to)

  return (
    <>
      {!drawerMenu && (
        <Container $isSafari={isSafari()} id="bottom-menu-mobile">
          <Menu>
            {/* Dashboard */}
            <MenuItem to="/" $active={isMenuActive('/')}>
              <IconWrapper>
                {isMenuActive('/') ? <Icon24.DashboardActive /> : <Icon24.Dashboard />}
              </IconWrapper>
              <Text $active={isMenuActive('/')}>
                {i18n.t('builder.side_menu.mobile.dashboard')}
              </Text>
            </MenuItem>

            {/* Documents */}
            <MenuItem to="/resumes" $active={isMenuActive('/resumes')}>
              <IconWrapper>
                {isMenuActive('/resumes') ? <Icon24.DocumentsActive /> : <Icon24.Documents />}
              </IconWrapper>
              <Text $active={isMenuActive('/resumes')}>
                {i18n.t('builder.side_menu.mobile.documents')}
              </Text>
            </MenuItem>

            {/* Virtual Coach */}
            {isVirtualCoachEnabled && (
              <MenuItem as="div">
                <MobileVirtualCoach />
              </MenuItem>
            )}

            {/* Job Search */}
            <MenuItem to="/job-search?view=recommendation" $active={isMenuActive('/job-search')}>
              <IconWrapper>
                {isMenuActive('/job-search') ? <Icon24.JobsActive /> : <Icon24.Jobs />}
              </IconWrapper>
              <Text $active={isMenuActive('/job-search')}>
                {i18n.t('builder.side_menu.mobile.jobs')}
              </Text>
            </MenuItem>

            {/* Job Tracker (Post Premium)
             *
             * Hide this menu item if Virtual Coach is enabled.
             * This is to make space for the Virtual Coach menu item above.
             * Later one this might be removed entirely. And it
             * will be available only on the view more drawer menu.
             */}
            {!isVirtualCoachEnabled && (
              <MenuItem
                to={isPostPremiumActive ? location : '/job-tracking'}
                $active={isMenuActive('/job-tracking')}
                onClick={isPostPremiumActive ? handlePostPremium : undefined}
              >
                {isPostPremiumActive && <CrownIcon />}
                <IconWrapper>
                  {isMenuActive('/job-tracking') ? <Icon24.TrackerActive /> : <Icon24.Tracker />}
                </IconWrapper>
                <Text $active={isMenuActive('/job-tracking')}>
                  {i18n.t('builder.side_menu.mobile.tracker')}
                </Text>
              </MenuItem>
            )}

            {/* More Menu */}
            <MenuItem to="#" onClick={() => setDrawerMenu(true)}>
              <IconWrapper>
                <Icon24.MoreMenu />
              </IconWrapper>
              <Text>{i18n.t('builder.side_menu.mobile.more')}</Text>
            </MenuItem>
          </Menu>
        </Container>
      )}

      <MobileDrawerMenu
        ref={containerRef}
        open={drawerMenu || isMobileProfileMenuOpen}
        setOpen={handleMobileMenu}
      />
    </>
  )
}
