import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import Colors from 'builder/components/DocumentCustomizationModal/Colors'
import { actions, selectors } from 'builder/modules/resumeEditor'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useI18n } from 'builder/hooks/useI18n'
import { DocumentTypes } from 'builder/modules/constants'
import {
  actions as coverLetterAction,
  selectors as coverLetterSelectors,
} from 'builder/modules/coverLetterEditor'
import { useTemplateCustomization } from '../../hooks/useTemplateCustomization'
import { useUpdateTemplateSettings } from '../../hooks/useUpdateTemplateSettings'
import { ColorsContainer, ColorsLabel } from './styles'

interface ColorSelectionProps {
  type: DocumentTypes
  isScrolled?: boolean
}

export const ColorSelection = ({ isScrolled = true, type }: ColorSelectionProps) => {
  const dispatch = useDispatch()
  const { i18n } = useI18n()
  const resume = useSelector(selectors.resume)
  const coverLetter = useSelector(coverLetterSelectors.coverLetter)
  const templates = useTypedSelector(selectors.templates)
  const {
    templateName,
    templatesSettings,
    supportsNewCustomization,
    resumeSpecificTemplateSettings,
    supportsCustomColor,
  } = useTemplateCustomization()
  const { updateTemplateSettings } = useUpdateTemplateSettings()
  const currentDocument = type === DocumentTypes.resume ? resume : coverLetter
  const currentTemplate = templates.find(template => template.id === currentDocument?.template)

  const handleColorSelect = useCallback(
    (color: string) => {
      if (type === DocumentTypes.resume) {
        if (
          color &&
          templatesSettings &&
          supportsNewCustomization &&
          templateName &&
          supportsCustomColor
        ) {
          updateTemplateSettings({
            templatesSettings,
            templateName,
            settingsName: 'color',
            value: color,
          })
        } else {
          dispatch(
            actions.updateSimpleField({
              name: 'color',
              value: color,
              debounce: false,
            }),
          )
        }
        trackInternalEvent('change_resume_color', { template: templateName })
      } else {
        dispatch(
          coverLetterAction.updateSimpleField({
            name: 'color',
            value: color,
            debounce: false,
          }),
        )
      }
    },
    [
      type,
      templatesSettings,
      supportsNewCustomization,
      templateName,
      supportsCustomColor,
      updateTemplateSettings,
      dispatch,
    ],
  )

  const colorSettings = useMemo(() => {
    const templateSettings = currentTemplate?.settings
    const templateColor = templateSettings?.color
    const supportsCustomColor = currentTemplate?.supportsCustomColor

    const getSelectedColor = () => {
      if (currentDocument) {
        if (currentDocument.type === DocumentTypes.resume && supportsNewCustomization) {
          return resumeSpecificTemplateSettings?.color || templateColor?.default
        }
        return currentDocument.color || templateColor?.default
      }
      return templateColor?.default || null
    }

    const selected = getSelectedColor()
    const options = templateColor?.values || []

    return { selected, options, supportsCustomColor }
  }, [
    currentTemplate?.settings,
    currentTemplate?.supportsCustomColor,
    currentDocument,
    supportsNewCustomization,
    resumeSpecificTemplateSettings?.color,
  ])

  return (
    <ColorsContainer isScrolled={isScrolled}>
      <ColorsLabel isScrolled={isScrolled}>
        {i18n.t('builder.resume_editor.document_customization.sidebar.templates_tab.colors')}
      </ColorsLabel>
      <Colors onSelect={handleColorSelect} {...colorSettings} />
    </ColorsContainer>
  )
}
