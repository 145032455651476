import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions as renderingActions } from 'builder/modules/rendering'
import { useUser } from 'builder/hooks/useUser'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as jobSearchSelectors } from 'builder/modules/jobSearch'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { navigate } from 'builder/modules/navigate'
import { useGetCareerProfile } from 'builder/views/AutoApply/hooks/useGetCareerProfile'
import { useAutoApplyUpsellModal } from 'builder/components/AutoApplyUpsellModal/useAutoApplyUpsellModal'
import { selectors as profileSelectors } from 'builder/modules/careerProfile'
import { StartCompleteScreenRoute } from '../constants'
const AUTOAPPLY_3_CREDIT = 3

export const useAutoApply = () => {
  const user = useUser()
  const dispatch = useDispatch()
  const { handleOpenModal: openUpsellModal } = useAutoApplyUpsellModal()
  const autoApplyBubbles = useTypedSelector(jobSearchSelectors.autoApplyBubbles)
  const isAutoApplyComplete = useTypedSelector(profileSelectors.isAutoApplyComplete)
  const { data: careerProfileData, isLoading: isCareerProfileDataLoading } = useGetCareerProfile()
  const { features } = useFeaturesConfig()

  const isAutoApplyFeatureEnabled = features?.autoApply
  const isResumeTailoringEnabled = careerProfileData?.autoTailoring
  const isUserOnAutoApplyPlan = isAutoApplyFeatureEnabled && !!user?.scopes?.autoApply
  const hasUserCompletedQuestionnaire = careerProfileData?.autoApplyDataCompleted

  const getAutoApplyCredit = useCallback(() => {
    if (typeof user?.scopes?.autoApply === 'object') {
      return user?.scopes?.autoApply
    }
    return { creditCount: 0, creditCountUsed: 0, creditCountMax: 0 }
  }, [user])

  const getCreditCount = () => {
    if (user?.scopes?.autoApply === true) return '∞'

    const { creditCount, creditCountUsed } = getAutoApplyCredit()
    return `${creditCountUsed}/${creditCount}`
  }

  const isAutoApplyLimitReached = useCallback(
    (count = 1) => {
      if (autoApplyBubbles.running) return false
      const { creditCount, creditCountUsed, creditCountMax } = getAutoApplyCredit()
      const usedCredits = creditCountUsed + count
      const has3AutoapplyCredits =
        creditCount === AUTOAPPLY_3_CREDIT && creditCountMax === AUTOAPPLY_3_CREDIT

      if (creditCount && usedCredits > creditCount) {
        if (
          has3AutoapplyCredits ||
          (!user?.scopes?.chargebeeCustomer && user?.scopes.kind === 'TR')
        ) {
          dispatch(renderingActions.showRDUpsellModal())
          return true
        } else {
          dispatch(renderingActions.showRDNotEnoughCreditsModal())
          return true
        }
      }
      return false
    },
    [
      autoApplyBubbles.running,
      dispatch,
      getAutoApplyCredit,
      user?.scopes?.chargebeeCustomer,
      user?.scopes?.kind,
    ],
  )

  const redirectToBillingPlans = useCallback(() => {
    if (!autoApplyBubbles.running && isAutoApplyFeatureEnabled && !isUserOnAutoApplyPlan) {
      openUpsellModal()
      return true
    }
  }, [autoApplyBubbles.running, isAutoApplyFeatureEnabled, isUserOnAutoApplyPlan, openUpsellModal])

  const redirectToAutoApplyQuestionnaire = useCallback(() => {
    if (!hasUserCompletedQuestionnaire) {
      navigate(`/auto-apply/${StartCompleteScreenRoute.START_SCREEN}`)
      return true
    }
    return false
  }, [hasUserCompletedQuestionnaire])

  return {
    getAutoApplyCredit,
    isAutoApplyLimitReached,
    redirectToBillingPlans,
    redirectToAutoApplyQuestionnaire,
    getCreditCount,
    isAutoApplyFeatureEnabled,
    isUserOnAutoApplyPlan,
    isResumeTailoringEnabled,
    hasUserCompletedQuestionnaire,
    isCareerProfileDataLoading,
    isAutoApplyComplete,
  }
}
