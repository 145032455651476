import { useNavigate } from 'react-router-dom'
import SwiperCore, { Navigation, Virtual } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Job } from 'builder/modules/jobTracking'
import * as Styles from './styles'

interface IProps {
  jobs: Job[]
}

export const JobsSlider: React.FC<IProps> = ({ jobs }) => {
  SwiperCore.use([Navigation, Virtual])
  const navigate = useNavigate()

  const onCardClick = (id: number) => {
    navigate(`job-tracking?card_id=${id}`)
  }

  return (
    <Styles.Container>
      <Swiper
        virtual
        navigation
        spaceBetween={12}
        slidesPerView={2}
        pagination={{ clickable: true }}
      >
        {jobs?.map(job => (
          <SwiperSlide key={job.id}>
            <Styles.CardContainer onClick={() => onCardClick(job.id)}>
              <Styles.Card>
                <Styles.CardHeader>
                  <Styles.HeaderInfo>
                    <Styles.Logo src={job.logo || undefined} />
                  </Styles.HeaderInfo>
                </Styles.CardHeader>
                <Styles.TextContainer>
                  <Styles.Title>{job.title}</Styles.Title>
                  <Styles.Company>{job.company}</Styles.Company>
                </Styles.TextContainer>
              </Styles.Card>
            </Styles.CardContainer>
          </SwiperSlide>
        ))}
      </Swiper>
    </Styles.Container>
  )
}
