import styled from 'styled-components'
import ReactSlider from 'rc-slider'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

const handleSize = 24
const handleExtraSpace = 16

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;

  ${Media.AboveTablet`
    gap: 10px;
  `};
`

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
`

export const Value = styled.p`
  color: ${Colors.White};
  ${Typography.Body};
  ${FontWeights.Regular};
`

export const Slider = styled(ReactSlider)`
  position: relative;
  flex-grow: 1;
  margin: 0 ${handleSize / 3 + 2}px;
  height: ${handleSize}px;

  & .rc-slider-rail {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
    margin-top: -1px;
    background-color: ${Colors.Neutral70};
  }

  & .rc-slider-dot {
    position: absolute;
    top: 50%;
    width: 2px;
    height: 8px;
    margin: -4px 0 0 -1px;
    background-color: ${Colors.Neutral70};
  }
`

export const Handle = styled(ReactSlider.Handle)`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  width: ${handleSize}px;
  height: ${handleSize}px;

  border-radius: 50%;
  background-color: ${props => (props.disabled ? Colors.Neutral70 : Colors.White)};
  outline: none;
  touch-action: pan-x;
  cursor: grab;

  /* Extend draggable area */
  &:before {
    content: '';
    position: absolute;
    top: ${-handleExtraSpace}px;
    left: ${-handleExtraSpace}px;
    right: ${-handleExtraSpace}px;
    bottom: ${-handleExtraSpace}px;
  }

  & svg {
    width: 16px;
    height: 16px;
    fill: ${Colors.White};
  }
`
