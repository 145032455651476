import React, { useEffect, useRef } from 'react'

import ModalOverlay from 'builder/components/ModalOverlay'
import Button, { ButtonSize, ButtonTheme } from 'builder/components/Button'

import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useClickOutside } from 'builder/hooks/useClickOutside'

import * as Styles from './styles'

type ModalProps = {
  title: string
  description: string
  cancelButton: string
  successButton: string
  onInit?: () => void
  onClose: () => void
  onCancel: () => void
  onSuccess: () => void
} & React.HtmlHTMLAttributes<HTMLDivElement>

export const ConfirmModal = ({
  title,
  description,
  cancelButton,
  successButton,
  onInit,
  onClose,
  onCancel,
  onSuccess,
  ...rest
}: ModalProps) => {
  const { isPhone } = useMediaQueries()
  const modalRef = useRef<HTMLDivElement>(null)

  useClickOutside(modalRef, onClose)

  useEffect(() => {
    if (onInit) onInit()
  }, [onInit])

  return (
    <ModalOverlay overlayFadeDuration={350} contentSlideDuration={350} onClose={onClose}>
      <Styles.Wrapper {...rest} ref={modalRef}>
        <Styles.TitleContainer>
          <Styles.Title>{title}</Styles.Title>
          <Styles.CloseIconContainer onClick={onClose}>
            <Styles.CloseIcon />
          </Styles.CloseIconContainer>
        </Styles.TitleContainer>

        <Styles.Description>{description}</Styles.Description>

        <Styles.Bottom>
          <Button
            theme={ButtonTheme.ghost}
            size={isPhone ? ButtonSize.small : ButtonSize.default}
            onClick={onCancel}
          >
            {cancelButton}
          </Button>
          <Button
            theme={ButtonTheme.default}
            size={isPhone ? ButtonSize.small : ButtonSize.default}
            onClick={onSuccess}
          >
            {successButton}
          </Button>
        </Styles.Bottom>
      </Styles.Wrapper>
    </ModalOverlay>
  )
}
