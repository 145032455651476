import { useMemo } from 'react'
import camelCase from 'lodash/camelCase'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/resumeEditor'
import { SelectOption, SelectValue } from 'builder/components/Select/types'
import { useI18n } from 'builder/hooks/useI18n'
import { LETTER_FORMAT_TEMPLATES, TemplateName } from 'builder/components/Helper/constants'
import { A4_FACTOR, US_LETTER_FACTOR } from 'builder/modules/constants'
import { LayoutFormats } from '../constants'

export const useTemplateCustomization = () => {
  const templates = useTypedSelector(selectors.templates)
  const resume = useTypedSelector(selectors.resume)
  const { i18n } = useI18n()
  const currentTemplate = templates.find(template => template.id === resume?.template)
  const supportsLayoutFormats = currentTemplate?.supportsLayoutFormats
  const templatesSettings = resume?.templateSettings
  const resumeSpecificTemplateSettings = templatesSettings?.[camelCase(resume?.template)]

  const supportsNewCustomization = currentTemplate?.supportsNewCustomization
  const supportsCustomColor = currentTemplate?.supportsCustomColor

  const LAYOUT_FORMAT_OPTIONS = useMemo(() => {
    return [
      {
        id: LayoutFormats.A4,
        name: i18n.t(
          'builder.resume_editor.document_customization.sidebar.layout_tab.layout_format_a4',
        ),
        factor: A4_FACTOR,
      },
      {
        id: LayoutFormats.US_LETTER,
        name: i18n.t(
          'builder.resume_editor.document_customization.sidebar.layout_tab.layout_format_us_letter',
        ),
        factor: US_LETTER_FACTOR,
      },
    ]
  }, [i18n])

  const convertToSelectOption = <V extends SelectValue>(
    data: Record<string, { id: V; name: string }> | { id: V; name: string }[],
  ): SelectOption<V>[] => {
    const arrayData = Array.isArray(data) ? data : Object.values(data)
    return arrayData.map(item => ({
      id: item.id,
      name: item.name,
    }))
  }

  const letterFormatOptions = useMemo(() => {
    return convertToSelectOption(LAYOUT_FORMAT_OPTIONS)
  }, [LAYOUT_FORMAT_OPTIONS])

  const selectedLayoutFormatOption = useMemo((): SelectValue => {
    if (!resumeSpecificTemplateSettings?.format) {
      return LETTER_FORMAT_TEMPLATES.includes(resume?.template as TemplateName)
        ? (LAYOUT_FORMAT_OPTIONS.find(option => option.id === 'US_LETTER')?.id as SelectValue)
        : (LAYOUT_FORMAT_OPTIONS.find(option => option.id === 'A4')?.id as SelectValue)
    }
    return LAYOUT_FORMAT_OPTIONS.find(option => option.id === resumeSpecificTemplateSettings.format)
      ?.id as SelectValue
  }, [LAYOUT_FORMAT_OPTIONS, resume?.template, resumeSpecificTemplateSettings?.format])

  return {
    resume,
    templates,
    currentTemplate,
    letterFormatOptions,
    supportsLayoutFormats,
    selectedLayoutFormatOption,
    templateName: resume?.template,
    templatesSettings,
    resumeSpecificTemplateSettings,
    supportsCustomColor,
    supportsNewCustomization,
  }
}
