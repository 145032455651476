import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { Step, selectors } from 'builder/modules/dashboard'

import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { useChromeExtension } from 'builder/hooks/useChromeExtension'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'

import { PanelProps } from 'builder/components/Panel/types'
import DocumentsSection from 'builder/components/Panel/DocumentsSection'
import JobTrackingView from 'builder/views/JobTrackingView'
import RecommendedJobs from 'builder/components/FindJob/RecommandationsSlider/RecommendedJobs'
import AutoApply from './JobSearchHelp/AutoApply/AutoApply'
import ResumeDistribution from './JobSearchHelp/ResumeDistribution/ResumeDistribution'

import {
  Container,
  DocumentsContainer,
  JobSearchBox,
  JobSearchContainer,
  JobTrackerButton,
  JobTrackerContainer,
  JobTrackerContent,
  JobTrackerShadow,
  Label,
  ListExpand,
  MenuTabs,
  NavTab,
  RecommendedJobsContainer,
  TabContainer,
} from './styles'
import { ActiveTab, ActiveTabs, Tabs } from './types'

const USER_TAB_SELECTION = 'dashboard_widget_tab'
const DEFAULT_TABS_STATE: ActiveTabs = {
  documents: false,
  job_tracker: false,
  job_search_help: false,
}

export const DashboardWidget = (props: PanelProps) => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const feature = useFeaturesConfig()
  const { getHost } = useWebsiteHost()
  const { isAutoApplyFeatureEnabled } = useAutoApply()
  const { isPhone, isTablet } = useMediaQueries()
  const { isInstalled, canInstallExtension } = useChromeExtension()
  const [activeTab, setActiveTab] = useState<ActiveTabs>(DEFAULT_TABS_STATE)
  const stepsData = useTypedSelector(selectors.stepsData)
  const stepsAreLoaded = useTypedSelector(selectors.isLoaded)
  const isAutoApplyOrRDEnabled = feature.showResumeDistribution() || isAutoApplyFeatureEnabled

  const TABS: Tabs[] = [
    { id: 'documents', name: 'Documents', enabled: true },
    { id: 'job_tracker', name: 'Job Tracker', enabled: true },
    {
      id: 'job_search_help',
      name: 'Job Search Help',
      label: 'NEW',
      enabled: !isPhone && !isTablet && isAutoApplyOrRDEnabled,
    },
  ]

  const handleTabsSwitch = (tab: ActiveTab) => {
    trackInternalEvent('documents_section_tab_click', {
      website_host: getHost,
      label: tab,
    })
    setActiveTab({ ...DEFAULT_TABS_STATE, [tab]: true })
  }

  const handleOnClickTab = (tab: ActiveTab) => {
    handleTabsSwitch(tab)
    localStorage.setItem(USER_TAB_SELECTION, tab)
  }

  const handleOnClickViewMore = () => {
    navigate('/job-tracking')
  }

  const selectedDefaultTab = useCallback(() => {
    const userSelection = localStorage.getItem(USER_TAB_SELECTION)
    const isJobTracker = !userSelection && stepsData[Step.PERSONALIZED_JOBS].completed
    const userSelectedTab = (userSelection as keyof ActiveTabs | null) || 'documents'

    handleTabsSwitch(isJobTracker ? 'job_tracker' : userSelectedTab)
  }, [stepsData])

  useEffect(() => {
    if (!isPhone && !isTablet) {
      selectedDefaultTab()
    }
  }, [stepsAreLoaded, selectedDefaultTab])

  useEffect(() => {
    if (isPhone || isTablet) {
      handleTabsSwitch('documents')
    }
  }, [isPhone, isTablet])

  return (
    <Container>
      <RecommendedJobsContainer>
        <RecommendedJobs />
      </RecommendedJobsContainer>

      <MenuTabs disableSelector>
        {TABS.filter(item => item.enabled).map(tab => (
          <NavTab
            id={tab.id}
            key={tab.id}
            isActive={activeTab[tab.id]}
            onClick={() => handleOnClickTab(tab.id)}
          >
            {tab.name} {tab.label && <Label>{tab.label}</Label>}
          </NavTab>
        ))}
      </MenuTabs>

      <TabContainer>
        {activeTab.documents && (
          <DocumentsContainer>
            <DocumentsSection
              title=""
              type={props.documentType}
              onTypeChange={props.onDocumentTypeChange}
              isAutoTailored={props.isAutoTailored}
              fromDashboard
              newDashHeader
            />
          </DocumentsContainer>
        )}

        {activeTab.job_tracker && (
          <JobTrackerContainer>
            <JobTrackerContent withBanner={!isInstalled && canInstallExtension}>
              <JobTrackingView fromDashboard />
            </JobTrackerContent>
            <JobTrackerShadow />
            <JobTrackerButton>
              <ListExpand onClick={handleOnClickViewMore}>
                {i18n.t('builder.dashboard_v2.job_tracker_widget.view_more')}
              </ListExpand>
            </JobTrackerButton>
          </JobTrackerContainer>
        )}

        {activeTab.job_search_help && (
          <JobSearchContainer $reverseRow={!feature.showResumeDistribution()}>
            <JobSearchBox>
              {feature.showResumeDistribution() && <ResumeDistribution />}
            </JobSearchBox>
            <JobSearchBox>{isAutoApplyFeatureEnabled && <AutoApply />}</JobSearchBox>
          </JobSearchContainer>
        )}
      </TabContainer>
    </Container>
  )
}
