import { Fragment, useCallback, useEffect, useMemo } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { Step, StepsData } from 'builder/modules/dashboard'
import { FeaturesConfig } from 'builder/hooks/featureConfig/FeaturesConfig'
import { useUser } from 'builder/hooks/useUser'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import { FetchStatuses } from 'builder/modules/constants'
import { CreateResumeStep } from './Steps/CreateResume/CreateResume'
import { HelpYouStep } from './Steps/HelpYou/HelpYou'
import { SendResumeStep } from './Steps/SendResume'
import { ExploreJobs } from './Steps/ExploreJobs/ExploreJobs'
import { SaveJobs } from './Steps/SaveJobs/SaveJobs'
import { CareerProfileStep } from './Steps/CareerProfile/CareerProfile'
import { MergedStepData } from './types'

interface Props {
  stepsData: StepsData
  activeStep: Record<Step, boolean>
  handleActiveStep: (step: Step) => void
  showCompletedSteps: boolean
  stepsAreLoaded: boolean
  fetchIsAutoApplyCompleteStatus: FetchStatuses
  features: FeaturesConfig | Record<PropertyKey, never>
  setMappedSteps: React.Dispatch<React.SetStateAction<MergedStepData[]>>
}

export const useOnboardingSteps = ({
  stepsData,
  activeStep,
  handleActiveStep,
  showCompletedSteps,
  features,
  setMappedSteps,
  stepsAreLoaded,
  fetchIsAutoApplyCompleteStatus,
}: Props) => {
  const user = useUser()
  const { isAutoApplyFeatureEnabled, isAutoApplyComplete } = useAutoApply()

  const steps = useMemo(() => {
    const sortedSteps: JSX.Element[] = []

    const stepList: {
      element: JSX.Element
      isCompleted: boolean
      displayIfNotCompleted: boolean
    }[] = [
      {
        element: (
          <CreateResumeStep
            isCompleted={stepsData.resume_creation.completed}
            isOpen={activeStep[Step.RESUME_CREATION]}
            setIsOpen={() => handleActiveStep(Step.RESUME_CREATION)}
            score={stepsData.resume_creation.score}
            resumeId={stepsData.resume_creation.resume_id}
          />
        ),
        isCompleted: stepsData.resume_creation.completed,
        displayIfNotCompleted: true,
      },
      {
        element: (
          <HelpYouStep
            isCompleted={isAutoApplyComplete}
            isOpen={activeStep[Step.APPLY_FOR_YOU]}
            setIsOpen={() => handleActiveStep(Step.APPLY_FOR_YOU)}
          />
        ),
        isCompleted: isAutoApplyComplete && isAutoApplyFeatureEnabled,
        displayIfNotCompleted: isAutoApplyFeatureEnabled,
      },
      {
        element: (
          <SendResumeStep
            isCompleted={stepsData.resume_distribution.completed}
            isOpen={activeStep[Step.RESUME_DISTRIBUTION]}
            setIsOpen={() => handleActiveStep(Step.RESUME_DISTRIBUTION)}
          />
        ),
        isCompleted:
          stepsData.resume_distribution.completed && !!features?.showResumeDistribution(),
        displayIfNotCompleted: features?.showResumeDistribution() && features?.isUkOrUs(),
      },
      {
        element: (
          <ExploreJobs
            isCompleted={stepsData.personalized_jobs.completed}
            isOpen={activeStep[Step.PERSONALIZED_JOBS]}
            setIsOpen={() => handleActiveStep(Step.PERSONALIZED_JOBS)}
          />
        ),
        isCompleted: stepsData.personalized_jobs.completed,
        displayIfNotCompleted: true,
      },
      {
        element: (
          <SaveJobs
            isCompleted={stepsData.job_tracker_wishlist.completed}
            isOpen={activeStep[Step.JOB_TRACKER_WISHLIST]}
            setIsOpen={() => handleActiveStep(Step.JOB_TRACKER_WISHLIST)}
            jobsNeeded={stepsData.job_tracker_wishlist.needed}
          />
        ),
        isCompleted: stepsData.job_tracker_wishlist.completed && !isAutoApplyFeatureEnabled,
        displayIfNotCompleted: !isAutoApplyFeatureEnabled,
      },
      {
        element: (
          <CareerProfileStep
            isCompleted={stepsData.career_profile.completed}
            score={stepsData.career_profile.current_score || 0}
            isOpen={activeStep[Step.CAREER_PROFILE]}
            setIsOpen={() => handleActiveStep(Step.CAREER_PROFILE)}
          />
        ),
        isCompleted: stepsData.career_profile.completed && features.features.careerProfile,
        displayIfNotCompleted: features.features.careerProfile && user?.scopes.kind !== 'TR',
      },
    ].sort((a, b) => Number(b.isCompleted) - Number(a.isCompleted))

    stepList.forEach(({ element, displayIfNotCompleted, isCompleted }) => {
      if (isCompleted) {
        if (showCompletedSteps) {
          sortedSteps.push(element)
        }
      } else if (displayIfNotCompleted) {
        sortedSteps.push(element)
      }
    })

    return sortedSteps.map((element, index) => <Fragment key={index}>{element}</Fragment>)
  }, [
    stepsData,
    activeStep,
    handleActiveStep,
    isAutoApplyComplete,
    isAutoApplyFeatureEnabled,
    showCompletedSteps,
    features,
  ])

  const mapStepsData = useCallback(() => {
    const steps = {
      [Step.RESUME_CREATION]: { enabled: true, order: 0 },
      [Step.APPLY_FOR_YOU]: { enabled: isAutoApplyFeatureEnabled, order: 1 },
      [Step.RESUME_DISTRIBUTION]: {
        enabled: features?.showResumeDistribution() && features?.isUkOrUs(),
        order: 2,
      },
      [Step.PERSONALIZED_JOBS]: { enabled: true, order: 3 },
      [Step.JOB_TRACKER_WISHLIST]: { enabled: !isAutoApplyFeatureEnabled, order: 4 },
      [Step.CAREER_PROFILE]: {
        enabled: features.features.careerProfile && user?.scopes.kind !== 'TR',
        order: 5,
      },
    }

    const mappedData = Object.keys(stepsData)
      .map(step => ({
        id: step as Step,
        ...steps[step as Step],
        ...stepsData[step as Step],
      }))
      .filter(step => step.enabled)
      .sort((a, b) => a.order - b.order)

    return mappedData
  }, [stepsData]) // eslint-disable-line react-hooks/exhaustive-deps

  const getCompletedStepsCount = useCallback(
    (stepsData: MergedStepData[]) => {
      return (
        (stepsData.filter(step => step.completed).length || 0) +
        (isAutoApplyFeatureEnabled && isAutoApplyComplete ? 1 : 0)
      )
    },
    [isAutoApplyFeatureEnabled, isAutoApplyComplete],
  )

  const setDefaultActiveStep = useCallback(
    (stepsData: MergedStepData[]) => {
      const firstIncompleteStep = stepsData.find(step =>
        step.id === Step.APPLY_FOR_YOU ? !isAutoApplyComplete : !step.completed,
      )
      if (firstIncompleteStep) {
        handleActiveStep(firstIncompleteStep.id)
      }
    },
    [isAutoApplyComplete],
  )

  const initStepsData = useCallback(() => {
    const mappedData = mapStepsData()
    setDefaultActiveStep(mappedData)

    if (getCompletedStepsCount(mappedData) !== mappedData.length) {
      trackInternalEvent('see_stepper_widget')
    }

    setMappedSteps(mappedData)
  }, [getCompletedStepsCount, mapStepsData, setDefaultActiveStep])

  useEffect(() => {
    if (stepsAreLoaded && fetchIsAutoApplyCompleteStatus === FetchStatuses.loaded) initStepsData()
  }, [initStepsData, stepsAreLoaded, fetchIsAutoApplyCompleteStatus])

  return {
    steps,
    getCompletedStepsCount,
    initStepsData,
  }
}
