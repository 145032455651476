import { useQuery } from '@tanstack/react-query'
import { ExpertDoc } from '@rio/types'
import { baseClient } from 'builder/modules/apiClient'

const resumeQueryId = Symbol('trDocuments')

export const useDocument = (id: number | null): ExpertDoc | null => {
  // Fetch the tr documents from the API
  const { data: fetchedResume } = useQuery(
    [resumeQueryId, id],
    async ({ signal }) => {
      const response = await baseClient.get<ExpertDoc>(`/documents/${id}`, { signal })
      return response.data
    },
    {
      enabled: !!id,
      cacheTime: 1,
      useErrorBoundary: false,
    },
  )

  return fetchedResume
    ? {
        ...fetchedResume,
        type: 'tr_document',
        createdAt: fetchedResume.created_at as unknown as string,
      }
    : null
}
