import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  justify-content: center;
  align-items: center;
  padding: 0 ${Sizes.S} ${Sizes.XL} ${Sizes.S};
  gap: ${Sizes.XL};

  ${Media.AbovePhone`
    gap: ${Sizes.M};
  `};

  ${Media.AboveTablet`
      margin-top: 32px;
      padding: 0 32px;
  `};
`

export const FontContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${Sizes.L};

  ${Media.AbovePhone`
    gap: ${Sizes.M};
  `};

  ${Media.AboveTablet`
    gap: ${Sizes.L};
  `};
`

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;

  ${Media.AboveTablet`
    align-items: flex-start;
  `};
`

export const SliderLabel = styled.p`
  color: ${Colors.Neutral50};
  ${Typography.Caps};
  ${FontWeights.DemiBold};
`
