import { Optional } from 'packages/types'
import { ValidInput } from 'builder/utils/formatDate'
import { Primitive } from '../DocumentCustomizationModal/Constants'

export enum MODES {
  editor = 'editor',
  wizard = 'wizard',
}

export type CurrentDocument = {
  updatedAt?: ValidInput
  template?: Optional<Primitive | Primitive[]>
  id: number
  color: string
  spacing: number
  type: string
}

export type PreviewRefProps = {
  reset: () => void
  previousPage: () => void
  nextPage: () => void
  resize: (width: number) => void
}
