import { DocumentTypes } from 'builder/modules/constants'

export enum TabType {
  templates = 'templates',
  text = 'text',
  layout = 'layout',
}

export interface Tab {
  label: React.ReactElement
  id: TabType
  content: React.ReactElement
}

export interface SidebarProps {
  type: DocumentTypes
}
