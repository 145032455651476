import React from 'react'

export default function ExpandIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_903_851)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0005 5.64355C11.6139 5.64355 11.3005 5.95696 11.3005 6.34355C11.3005 6.73015 11.6139 7.04355 12.0005 7.04355L15.9674 7.04356L14.3512 8.65976C14.3452 8.66538 14.3392 8.67113 14.3334 8.677L7.04307 15.9673L7.04307 12.0004C7.04307 11.6138 6.72967 11.3004 6.34307 11.3004C5.95647 11.3004 5.64307 11.6138 5.64307 12.0004V17.6573C5.64307 18.0439 5.95647 18.3573 6.34307 18.3573L11.9999 18.3573C12.3865 18.3573 12.6999 18.0439 12.6999 17.6573C12.6999 17.2707 12.3865 16.9573 11.9999 16.9573L8.03302 16.9573L9.64922 15.341C9.65525 15.3354 9.6612 15.3297 9.66707 15.3238L16.9574 8.0335L16.9574 12.0004C16.9574 12.387 17.2708 12.7004 17.6574 12.7004C18.044 12.7004 18.3574 12.387 18.3574 12.0004L18.3574 6.34355C18.3574 5.95696 18.044 5.64355 17.6574 5.64355H12.0005Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_903_851">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
