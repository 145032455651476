import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { actions } from 'builder/modules/interview/interviewModule'
import { ButtonTheme } from 'builder/components/Button'
import { Icon20 } from 'builder/components/Icon'
import { Interview } from 'builder/modules/interview/types'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelector } from 'builder/modules/user'
import { queryClient } from 'builder/components/Providers/Providers'
import { useJobTitle } from 'builder/views/Interview/hooks/useJobTitle'
import { getDefaultLogoForCompany } from 'builder/components/FindJob/utils/getDefaultLogoForCompany'
import { useIsCompanyInSuggestion } from 'builder/views/Interview/hooks/useIsCompanyInSuggestion'
import { TRANSLATION_KEY } from '../ActiveInterviews'
import { CircularProgressBar } from '../CircularProgressBar/CircularProgressBar'
import { RestartModal } from '../../Modals/RestartModal/RestartModal'
import { DeleteModal } from '../../Modals/DeleteModal/DeleteModal'
import { InterviewStatus } from '../../../InterviewStatus/InterviewStatus'
import { Logo } from '../../Logo/Logo'

import {
  JobTitle,
  Container,
  Header,
  ButtonContainer,
  CustomButton,
  Title,
  TitleContainer,
  TrashIconContainer,
  Bottom,
  CompanyName,
  LogoWrapper,
  InterviewStatusWrapper,
} from './style'

interface CardProps {
  interview: Interview
  onDispose: () => void
}
export const Card = ({ interview, onDispose }: CardProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { i18n } = useI18n()
  const { isValidSuggestionCompany } = useIsCompanyInSuggestion(interview.company_name)
  const { data: jobTitleRes } = useJobTitle(interview.data_api_job_title_id)
  const [showRestart, setShowRestart] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const isPremiumUser = useTypedSelector(userSelector.premiumAccess)
  const onOpenReport = () => {
    dispatch(actions.setInterviewId(interview.id))
    trackInternalEvent('open_report', {
      label: 'interview_prep',
    })
    navigate('/interview-preparation/feedback')
  }

  const onContinue = () => {
    dispatch(actions.setInterviewId(interview.id))
    queryClient.invalidateQueries(['useInterview', interview.id])
    trackInternalEvent('continue_interview', {
      label: 'interview_prep',
    })
    navigate('/interview-preparation/interview-player')
  }

  const inProgress = interview.status !== 'completed'

  const renderStatus = () => {
    if (inProgress)
      return (
        <CustomButton onClick={onContinue}>{i18n.t(`${TRANSLATION_KEY}.continue`)}</CustomButton>
      )
    return (
      <>
        <CustomButton onClick={() => setShowRestart(true)}>
          <Icon20.Refresh />
          {i18n.t(`${TRANSLATION_KEY}.restart`)}
        </CustomButton>
        <CustomButton theme={ButtonTheme.ghost} onClick={onOpenReport}>
          {i18n.t(`${TRANSLATION_KEY}.open_report`)}
        </CustomButton>
      </>
    )
  }
  // The backend can take some time to process a complete answer,
  // so validate if it has at least uploaded the audio content
  const completedSteps = interview.questions.filter(question => question.answer?.audio).length
  return (
    <Container data-testid={interview.id}>
      <div>
        <Header>
          <TitleContainer>
            <CircularProgressBar
              totalSteps={interview.questions_count}
              completedSteps={completedSteps}
              radius={8}
              size={20}
              strokeWidth={4}
            />
            <Title>
              {`${completedSteps} ${i18n.t(`${TRANSLATION_KEY}.of_preposition`)} ${
                interview.questions_count
              } ${i18n.t(`${TRANSLATION_KEY}.questions`)}`}
            </Title>
          </TitleContainer>
          {isPremiumUser && (
            <TrashIconContainer onClick={() => setShowDelete(true)}>
              <Icon20.TrashIcon />
            </TrashIconContainer>
          )}
        </Header>
      </div>
      <Bottom>
        <LogoWrapper>
          <Logo src={interview.logo_url || getDefaultLogoForCompany()} />
        </LogoWrapper>
        {!inProgress && (
          <InterviewStatusWrapper>
            <InterviewStatus interview={interview} />
          </InterviewStatusWrapper>
        )}
        <JobTitle>{jobTitleRes?.jobTitle}</JobTitle>
        {isValidSuggestionCompany && interview.company_name && (
          <CompanyName>{`${i18n.t(`${TRANSLATION_KEY}.at_preposition`)} ${
            interview.company_name
          }`}</CompanyName>
        )}
        <ButtonContainer>{renderStatus()}</ButtonContainer>
      </Bottom>
      {showRestart && <RestartModal onClose={() => setShowRestart(false)} interview={interview} />}
      {showDelete && (
        <DeleteModal
          onClose={() => setShowDelete(false)}
          onDelete={() => {
            onDispose()
            setShowDelete(false)
          }}
          interview={interview}
        />
      )}
    </Container>
  )
}
