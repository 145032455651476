import { useEffect, useState } from 'react'
import { useCompanyNames } from './useCompanyNames'
export interface CompanyName {
  company: string
  link: string
  location: string
  logo: string
}

export const useIsCompanyInSuggestion = (companyName?: string) => {
  const { mutateAsync: getCompanyName } = useCompanyNames()
  const [isValidSuggestionCompany, setIsValidSuggestionCompany] = useState<boolean>()

  useEffect(() => {
    async function requesting() {
      if (!companyName || typeof isValidSuggestionCompany === 'boolean') return

      const res = await getCompanyName(companyName)
      const isValid = res.find(
        suggestion =>
          suggestion.company.toLocaleLowerCase().trim() === companyName.toLocaleLowerCase().trim(),
      )
      setIsValidSuggestionCompany(!!isValid)
    }

    requesting()
  }, [companyName])

  return { isValidSuggestionCompany }
}
