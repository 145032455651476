import styled from 'styled-components'
import Media from 'builder/styles/media'
import ModalComponent from 'builder/components/ConfirmModal'

export const Modal = styled(ModalComponent)`
  padding: 32px;
  gap: 32px;

  ${Media.Phone`
    width: 100%;
    padding: 20px;
  `}
`
