import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'
import Typography, { FontWeights } from 'builder/styles/typography'
import { InfoType } from './types'

type ColorType = {
  icon: string
  text: string
  background: string
}

const colors: Record<InfoType, ColorType> = {
  error: {
    icon: Colors.Red50,
    text: Colors.Red80,
    background: Colors.Red10,
  },
  info: {
    icon: Colors.Blue50,
    text: Colors.Blue80,
    background: Colors.Blue10,
  },
  success: {
    icon: Colors.Green50,
    text: Colors.Green80,
    background: Colors.Green10,
  },
  warning: {
    icon: Colors.Orange50,
    text: Colors.Orange80,
    background: Colors.Orange10,
  },
}

export const IconContainer = styled.div`
  margin: -2px 2px 0 0;
`

export const TextContainer = styled.div``

export const Text = styled.div`
  ${Typography.Caption}
  ${FontWeights.Regular}
`

export const InfoIcon = styled(Icon20.InfoRegular)`
  fill: transparent;
  color: ${colors.info.icon};
`

export const SuccessIcon = styled(Icon20.Complitness)`
  color: ${colors.success.icon};
`

export const WarningIcon = styled(Icon20.WarningCircle)`
  color: ${colors.warning.icon};
`

export const ErrorIcon = styled(Icon20.RejectIcon)`
  color: ${colors.error.icon};
`

export const Container = styled.div<{ $type: InfoType }>`
  display: flex;
  padding: 16px;
  border-radius: 8px;
  background: ${({ $type }) => colors[$type].background};

  ${Text} {
    color: ${({ $type }) => colors[$type].text};
  }
`
