import React from 'react'

export default function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_903_856)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.30005 11.9998C6.30005 11.6132 6.61345 11.2998 7.00005 11.2998L17 11.2998C17.3866 11.2998 17.7 11.6132 17.7 11.9998C17.7 12.3864 17.3866 12.6998 17 12.6998L7.00005 12.6998C6.61345 12.6998 6.30005 12.3864 6.30005 11.9998Z"
          fill="#495163"
        />
      </g>
      <defs>
        <clipPath id="clip0_903_856">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
