import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Wrapper = styled.div`
  background: ${Colors.White};
  z-index: 1;

  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1001;
`
export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  width: 439px;
  height: 502px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.h2`
  ${Typography.M};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
`

export const Message = styled.p`
  ${Typography.M};
  margin-bottom: 20px;
  margin-top: 20px;
  color: ${Colors.Neutral50};
  ${FontWeights.Medium};
  text-align: center;

  & b {
    color: ${Colors.Neutral90};
  }
`

export const Confirm = styled.p`
  color: ${Colors.Neutral50};
  ${Typography.Body};

  & a {
    color: ${Colors.Blue50};
  }
`
