import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useSelectResume } from 'builder/views/AutoApply/hooks/useSelectResume'

import { DocumentTypes } from 'builder/modules/constants'
import { actions as uiActions, selectors as uiSelectors } from 'builder/modules/ui'
import { BriefDocumentType } from 'builder/modules/panel'
import SelectResumeModal from 'builder/views/ResumeDistributionView/components/SelectResumeModal'
import { TAILOR_RESUME_KEY } from 'builder/views/AutoApply/constants'

export const TRANSLATION = `builder.auto_apply.resume_select`

export const ResumeSelectModal = () => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onResumeUploadSuccess = () => {
    navigate('/auto-apply/tailor-resume')
  }

  const { mutateAsync: uploadSelectedResume } = useSelectResume(onResumeUploadSuccess)
  const isTailorResumeModalSelectOpen = useTypedSelector(uiSelectors.isTailorResumeModalSelectOpen)

  const onBuilderResumeSelect = (resumeDetails: BriefDocumentType) => {
    if (resumeDetails.type === DocumentTypes.trDocument) {
      uploadSelectedResume({ trResumeId: resumeDetails.id })
    } else {
      uploadSelectedResume({ resumeId: resumeDetails.id })
    }
  }

  const onCloseModal = () => {
    dispatch(uiActions.setTailorResumeSelectModal(false))
  }

  const onCreateResumeBuilder = () => {
    // Key to use on JobSearchSolutionComponent and submit the resume to auto-apply
    localStorage.setItem(TAILOR_RESUME_KEY, 'true')
  }

  if (!isTailorResumeModalSelectOpen) return null

  return (
    <SelectResumeModal
      title={i18n.t(`${TRANSLATION}.modal_title`)}
      onClose={onCloseModal}
      onCreateResume={onCreateResumeBuilder}
      submitSelectedResume={onBuilderResumeSelect}
      isTotalJobSearchFlow
    />
  )
}
