import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

export const useScrollToAnchor = () => {
  const location = useLocation()
  const previousHash = useRef('')

  useEffect(() => {
    const hash = location.hash.slice(1) // Remove the '#' from the hash

    if (hash && hash !== previousHash.current) {
      const targetElement = document.getElementById(hash)

      if (targetElement) {
        setTimeout(() => {
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
          // Clean the hash from the URL
          const newUrl = `${window.location.pathname}${window.location.search}`
          window.history.replaceState(null, '', newUrl)
        }, 100)
      }

      previousHash.current = hash
    }
  }, [location])

  return null
}
