import { DocumentTypes } from 'builder/modules/constants'

export interface TemplateCustomizationSheetProps {
  onClose: () => void
  type: DocumentTypes
}

export enum TabType {
  templates = 'templates',
  text = 'text',
  layout = 'layout',
}

export interface Tab {
  label: React.ReactElement
  id: TabType
  content: React.ReactElement
}
