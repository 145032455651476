import { useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { Icon20 } from 'builder/components/Icon'
import { useConfig } from 'builder/hooks/useConfig'
import Button from 'builder/components/Button'
import { CareerGoalSlider } from 'builder/components/Panel/CareerGoal/components/CareerGoalSlider'
import { useServicesData } from 'builder/components/CIO-Dasboard-2.0/hooks/useServiceData'
import UpcomingCareersModal from 'builder/components/UpcomingCareersModal'
import { CareerGoalSliderPlaceholder } from 'builder/components/Panel/CareerGoal/components/CareerGoalSliderPlaceholder'
import { EDashboardLocalStoreKeys } from 'builder/components/CIO-Dasboard-2.0/types'
import { PREMIUM_TRIAL_ACTIVATED_KEY } from 'builder/modules/rendering'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'
import { capitalize } from 'builder/utils/capitalize'
import Logo from 'builder/components/Logo'
import { useI18n } from 'builder/hooks/useI18n'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import {
  Container,
  Title,
  Text,
  Anchor,
  PremiumTextContainer,
  PremiumText,
  Icon,
  CardsAutoSliderContainer,
  WhiteShadowBottom,
  FooterTextContainer,
  FooterText,
  FooterLink,
} from './styles'

const TRANSLATION = `builder.premium_trial_activate`

const PremiumTrialActivated = () => {
  const { i18n } = useI18n()
  const location = useLocation()
  const navigate = useNavigate()
  const config = useConfig()
  const { getHost } = useWebsiteHost()
  const contactSupportURL = getHost === 'career.io' ? 'https://career.io/contact' : '/contact'

  const queryParams = new URLSearchParams(location.search)
  const premiumDays = queryParams?.get('premium_days') || 7
  const refreshResume = queryParams?.get('refresh_resume')

  const features = useFeaturesConfig()
  const trWelcomeModal = features?.features?.trWelcomeModal

  const getHostName = () => {
    return getHost.includes('topresume') ? 'TopResume Career Platform' : getHost
  }

  const handleLeave = useCallback(() => {
    sessionStorage.setItem(PREMIUM_TRIAL_ACTIVATED_KEY, 'true')

    if (trWelcomeModal === 'test_v1' || trWelcomeModal === 'test_v2') {
      window.location.href = `/app/resumes?onboarding=false&tr_welcome=true`
      return
    }

    if (refreshResume) {
      navigate('/premium-trail-onboarding')
      return
    }

    window.location.href = `/app?onboarding=false`
  }, [navigate, refreshResume])

  const extractUtms = useCallback(() => {
    const queryParams = new URLSearchParams(location.search)
    const utms: { [key: string]: string } = {}

    for (const [key, value] of queryParams.entries()) {
      utms[key] = value
    }

    return utms
  }, [location.search])

  useEffect(() => {
    const utms = extractUtms()

    if (config?.features.superApp) {
      trackInternalEvent('cio_provisioning_welcome', utms)
    }
    trackInternalEvent('provisioning_welcome', utms)
    localStorage.setItem(EDashboardLocalStoreKeys.IS_WELCOME_MODAL_SHOWN, 'true')
  }, [config?.features.superApp, extractUtms])

  const { isLoaded, services, onServiceCardClick, isComingSoonModal, comingSoonService } =
    useServicesData(-1)

  return (
    <>
      <Container>
        <Anchor aria-label="Logo" href="/">
          <Logo />
        </Anchor>
        <Title>{i18n.t(`${TRANSLATION}.title`, { domain: capitalize(getHostName()) })}</Title>
        <Text>{i18n.t(`${TRANSLATION}.text`, { days: premiumDays })}</Text>
        <Button onClick={handleLeave}>{i18n.t(`${TRANSLATION}.button`)}</Button>
        <PremiumTextContainer>
          <Icon>
            <Icon20.Crown height={28} width={28} />
          </Icon>
          <PremiumText>{i18n.t(`${TRANSLATION}.premium_text`)}</PremiumText>
        </PremiumTextContainer>
        <CardsAutoSliderContainer>
          {isLoaded ? (
            <CareerGoalSlider
              services={services}
              onServiceCardClick={onServiceCardClick}
              marqueeEffect={true}
              unClickable={true}
              showNavigationButton={false}
            />
          ) : (
            <CareerGoalSliderPlaceholder />
          )}
          <WhiteShadowBottom />
        </CardsAutoSliderContainer>
        <FooterTextContainer>
          <FooterText>{i18n.t(`${TRANSLATION}.question`)}</FooterText>
          <FooterLink href={contactSupportURL} data-testid="contact-support-id">
            {i18n.t(`${TRANSLATION}.support`)}
          </FooterLink>
        </FooterTextContainer>
      </Container>
      {isComingSoonModal && (
        <UpcomingCareersModal
          visual={comingSoonService?.modalVisual || ''}
          trackingLabel="execute_a_job_search"
          title={comingSoonService?.modalTitle || ''}
          text={comingSoonService?.modalDescription || ''}
          onClose={onServiceCardClick}
        />
      )}
    </>
  )
}

export default PremiumTrialActivated
