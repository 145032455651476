import { useDispatch } from 'react-redux'

import { useUser } from 'builder/hooks/useUser'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'

import { actions as uiActions } from 'builder/modules/ui'
import { actions as renderingActions } from 'builder/modules/rendering'

export const useResumeDistribution = () => {
  const user = useUser()
  const dispatch = useDispatch()
  const { features } = useFeaturesConfig()

  const isUserRdPromotedWithoutPlan =
    features?.rdPromo?.rdPromoPlan && !user?.scopes.resumeDistribution

  const showRDUpsellPromoModal = () => {
    if (user?.isPostPremium) {
      dispatch(uiActions.setTrackJobPaywall(true))
      return true
    }

    if (isUserRdPromotedWithoutPlan) {
      dispatch(renderingActions.toggleRDUpsellPromoModal(true))
      return true
    }
  }

  return {
    showRDUpsellPromoModal,
    isUserRdPromotedWithoutPlan,
  }
}
