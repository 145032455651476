import { ExpertDoc } from 'packages/types'

import { useI18n } from 'builder/hooks/useI18n'
import { FetchStatuses, FORMATS } from 'builder/modules/constants'
import loaderImage from 'images/builder/resume-editor/resume-loader.svg'

import * as Styles from './styles'
import noPreviewImg from './no-preview.svg'

type Props = {
  trDocument: ExpertDoc | null
  trDocumentStatus: FetchStatuses | null
  isDownloading: boolean
  hasPdfFormat: boolean
  downloadPdf: ({ format }: { format?: FORMATS }) => void
}

export const DocumentSection = ({
  trDocument,
  trDocumentStatus,
  hasPdfFormat,
  isDownloading,
  downloadPdf,
}: Props) => {
  const { i18n } = useI18n()
  const isLoading = trDocumentStatus === FetchStatuses.loading
  const isEmpty =
    (trDocumentStatus === FetchStatuses.loaded && !trDocument?.previews.length) ||
    trDocumentStatus === FetchStatuses.failed

  return (
    <>
      {isLoading && (
        <Styles.LoaderContainer>
          <Styles.Loader src={loaderImage} />
        </Styles.LoaderContainer>
      )}
      {isEmpty && (
        <Styles.NoPreviewContainer>
          <Styles.NoPreviewBackground>
            <Styles.NoPreviewImg src={noPreviewImg} />
            <Styles.NoPreviewText>Sorry, we can’t show preview of this file</Styles.NoPreviewText>
            {!!trDocument && hasPdfFormat && (
              <Styles.NoPreviewDownload
                onClick={() => downloadPdf({ format: FORMATS.tr_pdf })}
                isDisabled={isDownloading}
              >
                {i18n.t('builder.resume_editor.download')}
              </Styles.NoPreviewDownload>
            )}
          </Styles.NoPreviewBackground>
        </Styles.NoPreviewContainer>
      )}
      {!!trDocument?.previews?.length &&
        trDocument?.previews?.map(preview => (
          <Styles.ResumeImg src={preview.url} key={preview.url} />
        ))}
    </>
  )
}
