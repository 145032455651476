import { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { ExpertDoc } from 'packages/types'

import Logo from 'builder/components/Logo'
import { Icon24 } from 'builder/components/Icon'
import TREditModal from 'builder/components/TREditModal'
import { ResumeDistributionGetStartedModal } from 'builder/components/ResumeDistributionGetStartedModal'

import { useAppSelector } from 'builder/hooks/useAppSelector'
import { actions } from 'builder/modules/panel'
import { actions as uiActions } from 'builder/modules/ui'
import { actions as renderingActions } from 'builder/modules/rendering'
import { DocumentTypes, FORMATS, FetchStatuses } from 'builder/modules/constants'
import { Routes } from '../AutoApply/constants'

import { PageContainer, Container, Header, BackButton, ChevronLeft } from './styles'
import { DocumentSection } from './DocumentSection'
import { ActionSection } from './ActionSection'

export const TRDocumentView = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [query] = useSearchParams()

  const { panel, rendering, ui } = useAppSelector(store => store)
  const { isTREditModalOpen } = ui
  const { trDocuments, trDocumentsStatus } = panel
  const { isDocumentDownloading } = rendering

  const pathId = window.location.pathname.split('/')[3] // get document id from pathname
  const isValidId = /^\d+$/.test(pathId) // matches one or more occurences of [0-9]
  const documentId = parseInt(pathId)

  const [trDocument, setTrDocument] = useState<ExpertDoc | null>(null)
  const [trDocumentStatus, setTrDocumentStatus] = useState<FetchStatuses | null>(null)

  useEffect(() => {
    if (!trDocument && trDocuments[documentId]) {
      setTrDocument({ ...trDocuments[documentId] })
    }
  }, [trDocument, trDocuments, documentId])

  useEffect(() => {
    setTrDocumentStatus(trDocumentsStatus[documentId])
  }, [trDocumentsStatus, documentId])

  useEffect(() => {
    if (!isValidId) {
      navigate('/tr-documents')
    } else {
      dispatch(actions.fetchTRDocument({ id: documentId }))
    }
  }, [dispatch, documentId, isValidId, navigate])

  const getDocType = useCallback(() => {
    if (trDocument?.doc_type === 'resume') {
      return DocumentTypes.resume
    } else if (trDocument?.doc_type === 'cover_letter') {
      return DocumentTypes.coverLetter
    }

    return DocumentTypes.resume
  }, [trDocument?.doc_type])

  const hasDocxFormat = useCallback(
    () =>
      trDocument?.files?.some(
        file => file?.filename?.endsWith('.docx') || file?.filename?.endsWith('.doc'),
      ) || false,
    [trDocument?.files],
  )

  const hasPdfFormat = useCallback(
    () => trDocument?.files?.some(file => file?.filename?.endsWith('.pdf')) || false,
    [trDocument?.files],
  )

  const handleDownloadDocument = useCallback(
    ({ format = FORMATS.tr_pdf }) => {
      dispatch(
        renderingActions.download({
          id: documentId,
          type: getDocType(),
          format,
          source: 'tr_doc',
          trDocFiles: trDocument?.files ?? [],
        }),
      )
      trackInternalEvent('click_download_resume_button', format)
    },
    [dispatch, documentId, getDocType, trDocument?.files],
  )

  const handleEdit = useCallback(() => {
    dispatch(uiActions.openTREditModal(documentId))
    trackInternalEvent('click_edit_resume')
  }, [dispatch, documentId])

  const onBackClick = () => {
    const redirectPath = query.get('path') ? query.get('path') : Routes.TAILOR_RESUME
    const path = query.get('edit') ? `/auto-apply/${redirectPath}` : '/tr-documents'
    navigate(path)
  }

  return (
    <PageContainer>
      <Container>
        <Header>
          <BackButton onClick={onBackClick}>
            <ChevronLeft>
              <Icon24.Chevron />
            </ChevronLeft>
            {query.get('edit') ? 'Back' : 'My Documents'}
          </BackButton>
          <Logo />
          <ActionSection
            documentId={documentId}
            edit={query.get('edit') as 'enabled' | 'disabled'}
            trActionProps={{
              position: 'bottom',
              documentId,
              isTRdocument: true,
              isResumeType: trDocument?.doc_type === 'resume',
              isDocxFormat: hasDocxFormat(),
              onDownload: handleDownloadDocument,
              documentType: getDocType(),
              onClose: () => {},
              onEdit: handleEdit,
            }}
            isPdfFormat={hasPdfFormat()}
            isDownloading={isDocumentDownloading}
            downloadPdf={handleDownloadDocument}
          />
        </Header>
        <DocumentSection
          trDocument={trDocument}
          trDocumentStatus={trDocumentStatus}
          isDownloading={isDocumentDownloading}
          hasPdfFormat={hasPdfFormat()}
          downloadPdf={handleDownloadDocument}
        />
      </Container>
      {isTREditModalOpen && <TREditModal id={documentId} />}
      <ResumeDistributionGetStartedModal />
    </PageContainer>
  )
}
