import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Sizes from 'builder/styles/sizes'
import Tabs, { Tab } from 'builder/components/Tabs'

export const Overlay = styled.div`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-tap-highlight-color: transparent;
`

export const Sheet = styled.div<{ isVisible: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${Colors.Neutral100};
  color: ${Colors.White};
  text-align: center;
  transition: transform 0.2s, opacity 0.2s;
  border-bottom: 0 solid ${Colors.Neutral100};
  border-bottom-width: env(safe-area-inset-bottom);
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: translateY(${({ isVisible }) => (isVisible ? '0%' : '100%')});
  height: 432px;
`

export const TabLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Sizes['2XS']};
  align-items: center;
  justify-content: center;
  color: inherit;
`

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  color: inherit;
`

export const TabContainer = styled.div`
  border-bottom: 1px solid ${Colors.Neutral70};
`

export const CustomTab = styled(Tab)<{ isActive: boolean }>`
  padding: ${Sizes.S} 0;
  margin-right: unset;
  color: ${({ isActive }) => (isActive ? Colors.White : Colors.Neutral50)};

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const ContentContainer = styled.div``

export const CustomTabs = styled(Tabs)`
  border-bottom: 0;
  justify-content: center;
  gap: ${Sizes.XL};
  width: 100%;
`
