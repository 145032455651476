import { Fragment, useEffect, useState } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { createPortal } from 'react-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import Colors from 'builder/styles/colors'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import Markdown from './Markdown'
import { Icon } from './assets'

import {
  Avatar,
  ChatBotBody,
  ChatBotForm,
  ChatBotFooter,
  ChatBotHeader,
  FlexBox,
  Message,
  PromptInput,
  SubmitButton,
  IconWrapper,
  SpinAnimation,
  ChatBotContainer,
  ScrollIndicator,
  Title,
  Trigger,
  ErrorMessage,
} from './styles'
import { useChatScroll, useLayoutVariant, useVirtualCoach } from './hooks'
import { LayoutVariant, TriggerVariant } from './types'

type Props = {
  layoutVariant: LayoutVariant
  triggerVariant: TriggerVariant
}

export function VirtualCoach(props: Props) {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const { state, error, loading, handleSubmit } = useVirtualCoach()

  const [isOpen, setIsOpen] = useState(false)
  const { layoutVariant, toggleFullScreen } = useLayoutVariant(props.layoutVariant)
  const { triggerVariant } = props

  const scrollUtils = useChatScroll({ open: isOpen, message: state })
  const { chatBodyRef, trackScroll, showScrollIndicator, scrollToBottom } = scrollUtils

  const toggleOpenState = () => {
    setIsOpen(isOpen => !isOpen)
    trackInternalEvent(isOpen ? 'chat_closed' : 'chat_open')
  }

  useEffect(() => {
    /**
     * Make sure the body is scrollable when the chatbot is open in fullscreen mode.
     * This is necessary because the chatbot is a modal that covers the entire screen.
     * Also toogle it back to normal when the chatbot is closed.
     */
    if (isOpen && layoutVariant === 'fullscreen') {
      disableBodyScroll(document.body)
    } else {
      enableBodyScroll(document.body)
    }
  }, [isOpen, layoutVariant])

  return (
    <Fragment>
      {isOpen ? (
        createPortal(
          <ChatBotContainer className={layoutVariant}>
            <ChatBotHeader>
              <FlexBox gap="10px" justifyContent="flex-start">
                <Icon.AiCoach />
                <Title>{i18n.t('builder.virtual_coach.title')}</Title>
              </FlexBox>
              <FlexBox>
                <IconWrapper
                  as="button"
                  type="button"
                  fill={Colors.Neutral100}
                  onClick={toggleFullScreen}
                  hidden={isPhone}
                >
                  <Icon.Expand />
                </IconWrapper>

                <IconWrapper
                  as="button"
                  type="button"
                  fill={Colors.Neutral100}
                  onClick={toggleOpenState}
                >
                  <Icon.Close />
                </IconWrapper>
              </FlexBox>
            </ChatBotHeader>
            <ChatBotForm onSubmit={handleSubmit}>
              <ChatBotBody ref={chatBodyRef} onScroll={trackScroll}>
                {state?.map((chat, index) => (
                  <Message key={index}>
                    <Avatar visibility={chat.role === 'assistant' ? 'visible' : 'hidden'}>
                      <SpinAnimation>
                        <Icon.Assistant width={24} height={24} />
                      </SpinAnimation>
                    </Avatar>
                    <div className={chat.role}>
                      {chat.role === 'assistant' && <Markdown>{chat.content}</Markdown>}
                      {chat.role === 'user' && chat.content}
                    </div>
                  </Message>
                ))}

                {error && (
                  <ErrorMessage>
                    <Icon.CircleError /> {i18n.t(error)}
                  </ErrorMessage>
                )}

                {loading && (
                  <FlexBox gap="10px" alignItems="center">
                    <Avatar>
                      <SpinAnimation>
                        <Icon.Assistant width={24} height={24} />
                      </SpinAnimation>
                    </Avatar>
                    <Icon.Loading />
                  </FlexBox>
                )}

                {showScrollIndicator && (
                  <ScrollIndicator type="button" onClick={scrollToBottom}>
                    <IconWrapper>
                      <Icon.ArrowDown />
                    </IconWrapper>
                  </ScrollIndicator>
                )}
              </ChatBotBody>

              <ChatBotFooter>
                <div style={{ position: 'relative' }}>
                  <PromptInput
                    name="prompt"
                    autoComplete="off"
                    autoFocus={true}
                    placeholder={i18n.t('builder.virtual_coach.input_placeholder')}
                    required
                  />

                  <SubmitButton type="submit" disabled={loading}>
                    <IconWrapper
                      stroke={loading ? Colors.Blue50 : Colors.Neutral50}
                      fill={loading ? Colors.Blue50 : Colors.White}
                    >
                      <Icon.Send />
                    </IconWrapper>
                  </SubmitButton>
                </div>
              </ChatBotFooter>
            </ChatBotForm>
          </ChatBotContainer>,
          document.body,
        )
      ) : (
        <Trigger className={triggerVariant} onClick={toggleOpenState}>
          <FlexBox gap="15px" justifyContent="flex-start">
            <SpinAnimation>
              <Icon.Assistant />
            </SpinAnimation>
            {triggerVariant === 'default' && (
              <span>{i18n.t('builder.virtual_coach.trigger_label')}</span>
            )}
          </FlexBox>
        </Trigger>
      )}
    </Fragment>
  )
}

export function MobileVirtualCoach(props: Partial<Props>) {
  const { isPhone } = useMediaQueries()
  if (!isPhone) return null

  const defaultProps = {
    layoutVariant: props.layoutVariant ?? 'fullscreen',
    triggerVariant: props.triggerVariant ?? 'navitem',
  }

  return <VirtualCoach {...defaultProps} />
}

export function TabletAndDesktopVirtualCoach(props: Props) {
  const { isPhone } = useMediaQueries()
  if (isPhone) return null

  return <VirtualCoach {...props} />
}

export function DesktopVirtualCoach(props: Props) {
  const { isDesktop } = useMediaQueries()
  if (!isDesktop) return null

  return <VirtualCoach {...props} />
}
