import { useNavigate, useParams } from 'react-router-dom'
import { Container, Wrapper } from './styles'
import { Navbar } from './Navbar/Navbar'
import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { behaviourType } from 'builder/modules/interview/types'
import { StartContent } from './StartContent/StartContent'

export interface Params {
  behaviour?: behaviourType
}
export const Start = () => {
  const { behaviour }: Params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    async function userIsLogged() {
      const response = await fetch('/api/app/account', {
        cache: 'no-store',
      })

      if (response.status === 200) {
        const url = behaviour
          ? `/interview-preparation/new/${behaviour}`
          : '/interview-preparation/new'
        return navigate(url)
      }

      trackInternalEvent('visit_test_interview', {
        label: 'interview_prep',
        parameter: behaviour || 'default',
      })
    }

    userIsLogged()
  }, [])

  return (
    <Wrapper>
      <Navbar currentStep={1} />
      <Container>
        <StartContent behaviour={behaviour} />
      </Container>
    </Wrapper>
  )
}
