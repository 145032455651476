import { useCallback } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { TooltipV2 } from 'builder/components/Tooltip'
import { SelectValue } from 'builder/components/Select/types'
import { SelectContainer, SelectLabel, CustomSelect } from '../../styles'
import { useTemplateCustomization } from '../../hooks/useTemplateCustomization'
import { useUpdateTemplateSettings } from '../../hooks/useUpdateTemplateSettings'
import { Container } from './styles'

const TRANSLATION = 'builder.resume_editor.document_customization.sidebar.layout_tab'

export const LayoutCustomization = () => {
  const { i18n } = useI18n()
  const {
    letterFormatOptions,
    supportsLayoutFormats,
    selectedLayoutFormatOption,
    templateName,
    templatesSettings,
  } = useTemplateCustomization()

  const { updateTemplateSettings } = useUpdateTemplateSettings()

  const handleChangeLayoutFormat = useCallback(
    (value: SelectValue) => {
      if (!templateName || typeof value !== 'string' || !templatesSettings) return
      updateTemplateSettings({
        templatesSettings,
        templateName,
        settingsName: 'format',
        value,
      })
      trackInternalEvent('change_page_size_format', {
        old_value: selectedLayoutFormatOption,
        new_value: value,
        template: templateName,
      })
    },
    [templateName, templatesSettings, updateTemplateSettings, selectedLayoutFormatOption],
  )

  const handlePageSizeSelectionClick = () => {
    trackInternalEvent('click_resume_page_size_menu', {
      template: templateName,
    })
  }

  return (
    <Container>
      <TooltipV2
        value={i18n.t(`${TRANSLATION}.format_customization_warning`)}
        width={220}
        multiline={true}
        position="bottom"
        isActive={!supportsLayoutFormats}
      >
        <SelectContainer>
          <SelectLabel>{i18n.t(`${TRANSLATION}.format`)}</SelectLabel>
          <CustomSelect
            selected={selectedLayoutFormatOption}
            onSelect={handleChangeLayoutFormat}
            options={letterFormatOptions}
            optionsListClassname="paper-format"
            buttonOnClick={handlePageSizeSelectionClick}
            disabled={!supportsLayoutFormats}
            useNativeSelectOnMobile={false}
          />
        </SelectContainer>
      </TooltipV2>
    </Container>
  )
}
