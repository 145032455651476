import { useRef, useMemo, useCallback } from 'react'
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperClass } from 'swiper'
import findIndex from 'lodash/findIndex'
import { useSelector, useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { DocumentTypes } from 'builder/modules/constants'
import { selectors, actions } from 'builder/modules/resumeEditor'
import { templateGroups } from 'builder/utils/templateGroups'
import { Store } from 'builder/modules/store'
import { formatImageUrl } from 'builder/utils/formatImageUrl'
import { ColorSelection } from '../ColorSelection/ColorSelection'
import { useTemplateCustomization } from '../../hooks/useTemplateCustomization'
import {
  Container,
  TemplateSelectionMobileContainer,
  TemplateContainer,
  Template,
  Templates,
  TemplatePreview,
  BadgeWrapper,
  PlanBadges,
  FormatBadges,
} from './styles'

interface TemplateSelectionMobileProps {
  type: DocumentTypes
}

export const TemplateSelectionMobile = ({ type }: TemplateSelectionMobileProps) => {
  const dispatch = useDispatch()
  const templateListRef = useRef<HTMLDivElement | null>(null)
  const templatesGallery = useRef<SwiperClass | null>(null)
  const editorState = useSelector((state: Store) => state.generalEditor)
  const locale = useSelector(selectors.locale)
  const templates = templateGroups(editorState, type, locale)?.[0].templates

  const { currentTemplate } = useTemplateCustomization()

  const initialSlide = useMemo(() => {
    return findIndex(templates, { id: currentTemplate?.id })
  }, [currentTemplate, templates])

  const handleTemplateChange = useCallback(
    (swiper: SwiperClass) => {
      const { activeIndex = 0 } = swiper
      const { id } = templates[activeIndex]
      dispatch(actions.updateTemplate({ id }))
      trackInternalEvent('change_resume_template', { template: id })
    },
    [dispatch, templates],
  )

  return (
    <Container>
      <TemplateSelectionMobileContainer ref={templateListRef}>
        <Templates>
          <ReactSwiper
            onSwiper={swiper => (templatesGallery.current = swiper)}
            initialSlide={initialSlide}
            slidesPerView="auto"
            spaceBetween={0}
            centeredSlides={true}
            slideToClickedSlide={true}
            onSlideChange={handleTemplateChange}
          >
            {templates?.map((template: { id: string; name: string; thumbnail: string }) => (
              <SwiperSlide key={template.id}>
                <TemplateContainer>
                  <TemplatePreview
                    isActive={currentTemplate?.id === template.id}
                    image={formatImageUrl(template.thumbnail, { width: 154 })}
                  />
                  <Template>{template.name}</Template>
                </TemplateContainer>
              </SwiperSlide>
            ))}
          </ReactSwiper>
          <BadgeWrapper>
            {!currentTemplate?.premium && <PlanBadges badges={['Free']} />}
            <FormatBadges badges={currentTemplate?.supportedFormats || []} />
          </BadgeWrapper>
        </Templates>
      </TemplateSelectionMobileContainer>
      <ColorSelection type={type} isScrolled={true} />
    </Container>
  )
}
