import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { i18n } from 'builder/utils/i18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { FileInput } from 'builder/components/PhotoUploadField/styles'
import { MouseClickEvent } from 'builder/modules/ui'
import { ALLOWED_FILES } from 'builder/components/FillResumeModal/constants'
import ErrorLogger from 'builder/services/ErrorLogger'
import {
  Candidate,
  CandidatePayload,
  ResumeDetails,
  actions as resumeDistributionActions,
  selectors,
} from 'builder/modules/resumeDistribution'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'
import { FetchStatuses } from 'builder/modules/constants'
import Spinner from 'builder/components/Spinner'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'
import { baseClient } from 'builder/modules/apiClient'
import {
  Container,
  Title,
  CountryBanner,
  CountryText,
  TwoGrid,
  CardText,
  UploadImage,
  UploadCardTitle,
  CardLabel,
  SpinnerWrapper,
} from './styles'

import UploadResumeImg from './assets/img/upload_resume.png'
import SelectResumeModal from './components/SelectResumeModal'
import { base64ToFile, parseFormData } from './utils'
import { convertFormDataToObject } from './components/JobPreferencesForm/utils'
import { ResumeSelect } from './components/ResumeSelect/ResumeSelect'

export const TRANSLATION = 'builder.resume_distribution.landing'

const ResumeDistributionView = () => {
  const { isPhone } = useMediaQueries()
  const [query] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { getHost } = useWebsiteHost()

  const [selectResumeModal, setSelectResumeModal] = useState<boolean>(false)
  const candidate = useTypedSelector(selectors.candidate)
  const candidateFetchStatus = useTypedSelector(selectors.candidateFetchStatus)
  const candidatePostStatus = useTypedSelector(selectors.candidatePostStatus)
  const candidatePatchStatus = useTypedSelector(selectors.candidatePatchStatus)
  const candidateResumePostStatus = useTypedSelector(selectors.candidateResumePostStatus)
  const isSubscriptionActive = useTypedSelector(selectors.isSubscriptionActive)
  const userAccountData = useTypedSelector(userSelectors.userData)
  const candidateResumeFailed = useTypedSelector(selectors.candidateResumeFailed)
  const serverFailed = useTypedSelector(selectors.serverFailed)
  const submitResumeQueryParam = query.get('submit_resume')

  const handleUploadClick = (event: MouseClickEvent) => {
    event.stopPropagation()
    trackInternalEvent('rd_submit_resume', {
      website_host: getHost,
      label: 'upload',
    })
  }

  const handleSelectResume = () => {
    trackInternalEvent('rd_submit_resume', {
      website_host: getHost,
      label: 'builder',
    })
    setSelectResumeModal(true)
  }

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedResume = event.target.files?.[0]
    if (selectedResume) {
      dispatch(resumeDistributionActions.setResumeDetails(selectedResume))
      dispatch(resumeDistributionActions.parseResumeForPrefill(selectedResume))
      navigate('/resume-distribution/edit')
    }
  }

  const parseFile = async () => {
    const serializedFormFile = localStorage.getItem('resume-distribution-file')
    const serializedFormFileName = localStorage.getItem('resume-distribution-file-name')

    if (serializedFormFile && serializedFormFileName) {
      const file = await base64ToFile(serializedFormFile, serializedFormFileName)
      return file
    }
  }

  const parseDataToSubmit = async () => {
    const serializedFormData = localStorage.getItem('resume-distribution')
    return parseFormData(serializedFormData)
  }

  const clearLocalData = () => {
    localStorage.removeItem('resume-distribution')
    localStorage.removeItem('resume-distribution-file')
    localStorage.removeItem('resume-distribution-file-name')
  }

  const submitCandidate = async () => {
    const formData = await parseDataToSubmit()
    if (!formData) return

    const file = await parseFile()
    if (file) {
      formData.delete('resume_file')
      formData.append('resume_file', file)
    }
    dispatch(resumeDistributionActions.postCandidate(formData))
    clearLocalData()
  }

  const editCandidate = async (candidate: Candidate) => {
    try {
      const formData = await parseDataToSubmit()
      if (!formData) return

      const payload = convertFormDataToObject<CandidatePayload>(formData)

      if (candidate.email === formData.get('email')) {
        payload.email = undefined
      }

      if (!isSubscriptionActive) {
        await baseClient.post('resume-distribution/candidates/subscriptions', {})
      }

      dispatch(resumeDistributionActions.patchCandidate(payload))

      const file = await parseFile()
      if (file) {
        dispatch(resumeDistributionActions.postCandidateResume(file))
      }
      clearLocalData()
    } catch (err) {
      ErrorLogger.log(err)
    }
  }

  const isLoading = () => {
    const isLoadingEditOrPost =
      candidatePostStatus === FetchStatuses.loading ||
      candidatePatchStatus === FetchStatuses.loading ||
      candidateResumePostStatus === FetchStatuses.loading

    return (
      (submitResumeQueryParam && isLoadingEditOrPost) ||
      candidateFetchStatus === FetchStatuses.notAsked ||
      candidateFetchStatus === FetchStatuses.loading
    )
  }

  const onLoad = async () => {
    if (submitResumeQueryParam && userAccountData?.hasPremiumFeatures) {
      const parsedData = await parseDataToSubmit()
      if (!parsedData) {
        navigate('/resume-distribution/dashboard', { replace: true })
      }

      if (
        candidatePostStatus === FetchStatuses.loaded ||
        candidateResumePostStatus === FetchStatuses.loaded ||
        candidatePatchStatus === FetchStatuses.loaded
      ) {
        navigate('/resume-distribution/dashboard', { replace: true })
      }

      if (candidateResumeFailed) {
        navigate('/resume-distribution/invalid-document', { replace: true })
        dispatch(resumeDistributionActions.setCandidateResumeFailed(false))
      }

      if (serverFailed) {
        navigate('/resume-distribution/server-error', { replace: true })
        dispatch(resumeDistributionActions.setServerFailed(false))
      }

      if (candidateFetchStatus === FetchStatuses.failed) {
        submitCandidate()
      }

      if (candidateFetchStatus === FetchStatuses.loaded && candidate) {
        editCandidate(candidate)
      }

      return
    }

    if (candidateFetchStatus === FetchStatuses.loaded) {
      dispatch(resumeDistributionActions.setFetchCandidateStatus(FetchStatuses.notAsked))
      navigate('/resume-distribution/dashboard', { replace: true })
    }
  }

  useEffect(() => {
    dispatch(resumeDistributionActions.fetchCandidate())
    dispatch(
      resumeDistributionActions.setPersonalDetailsForm({
        firstName: '',
        lastName: '',
        email: '',
        jobTitle: '',
        linkedin: '',
        edit_file_name: '',
      }),
    )
  }, [dispatch])

  useEffect(() => {
    if (candidateFetchStatus === FetchStatuses.failed) {
      trackInternalEvent('rd_welcome_screen', { website_host: getHost })
    }
    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    candidate,
    candidatePostStatus,
    candidateFetchStatus,
    candidatePatchStatus,
    candidateResumePostStatus,
    candidateResumeFailed,
    serverFailed,
  ])

  if (isLoading()) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    )
  }

  return (
    <>
      <Container>
        <Title>{i18n.t(`${TRANSLATION}.${isPhone ? 'phone_title' : 'title'}`)}</Title>
        <CountryBanner>
          <CountryText>{i18n.t(`${TRANSLATION}.available_in_us`)}</CountryText>
        </CountryBanner>
        <TwoGrid>
          <CardLabel htmlFor="file-resume-upload">
            <UploadImage src={UploadResumeImg} />
            <UploadCardTitle>{i18n.t(`${TRANSLATION}.upgrade_now`)}</UploadCardTitle>
            <CardText>{i18n.t(`${TRANSLATION}.upgrade_text`)}</CardText>
            <FileInput
              type="file"
              onClick={handleUploadClick}
              onChange={handleFileInputChange}
              accept={ALLOWED_FILES}
              id="file-resume-upload"
            />
          </CardLabel>
          <ResumeSelect onCardClick={handleSelectResume} />
        </TwoGrid>
      </Container>
      {selectResumeModal && (
        <SelectResumeModal
          isDocumentLoaded
          onClose={() => setSelectResumeModal(false)}
          submitSelectedResume={(resumeDetails: ResumeDetails) => {
            dispatch(resumeDistributionActions.setResumeDetails(resumeDetails))
            navigate('/resume-distribution/edit')
          }}
        />
      )}
    </>
  )
}

export default ResumeDistributionView
