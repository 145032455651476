import { TargetLocation, TargetRole } from '../careerProfile'
import { DocumentTypes } from '../constants'
import { LocationForm, IJobPreferencesForm } from '../resumeDistribution'

export type PercentOfDaysWillingToTravel = '0' | '20' | '50' | '100'
export enum WorkingTypes {
  office = 'office',
  remote = 'remote',
  hybrid = 'hybrid',
}
export type SalaryPlan = 'monthly' | 'annually'
export type ExpectedStartWork = 'immediate' | 'one_month' | 'three_months' | 'six_months'

export type JobPreferenceAttributes = {
  expectedSalary?: number
  currentSalary?: number
  currentCurrency: string
  expectedSalaryPlan: SalaryPlan
  currentSalaryPlan: SalaryPlan
  expectedCurrency: string
  expectedWorkingTypes: Array<WorkingTypes>
  openForBusinessTrips?: boolean
  willingToRelocate: boolean
  willingToRelocateAtYourOwnCost: boolean
  expectedStartWork: ExpectedStartWork
  fullyVaccinatedAgreementAgainstCovid?: boolean
  hasRestrictionLimiting?: boolean
  openToRemoteOpportunities?: boolean
}

export type Profile = {
  id: number
  firstName: string
  lastName: string
  currentJobTitle: string
  gender: string
  resumeUrl: string
  race: string
  location: string
  fullAddress: string
  email: string
  phoneNumber: string
  targetRoles: TargetRole[]
  countriesAuthorizedToWorkIn: { value: string; label: string }[]
  socialLinksAttributes: Array<{
    title: string
    link: string
  }>

  jobPreferenceAttributes?: JobPreferenceAttributes
  coursesAttributes: Array<{
    course: string
    institution: string
    dateFrom: string
    dateUntil: string
  }>
  autoApplyDataCompleted: boolean
  targetLocations: TargetLocation[]
  resumeId: number | null
  resumeType: DocumentTypes | null
  resumeUploadedAt: string
  profilePicture: string
  userType: string
  autoTailoring: boolean
  autoTailoringCl: boolean
  workExperiencesAttributes: []
}

export type AutoApplyForm = Omit<Profile, 'profilePicture' | 'userType'>

export type AutoApplyStore = {
  form: AutoApplyForm
  uploadedResume?: File
  resumeDistributionForm: ResumeDistributionForm
}

export type ResumeDistributionForm = {
  location: LocationForm
  jobPreferences: IJobPreferencesForm
}
