import React from 'react'

export default function HeaderIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.51605 4.87706C6.64507 4.37692 7.35534 4.37692 7.48435 4.87706L7.74678 5.89437C7.79223 6.07058 7.92983 6.20817 8.10604 6.25363L9.12334 6.51605C9.62349 6.64507 9.62349 7.35534 9.12334 7.48435L8.10604 7.74678C7.92983 7.79223 7.79223 7.92983 7.74678 8.10604L7.48435 9.12334C7.35534 9.62349 6.64507 9.62349 6.51605 9.12334L6.25363 8.10604C6.20817 7.92983 6.07058 7.79223 5.89437 7.74678L4.87706 7.48435C4.37692 7.35534 4.37692 6.64507 4.87706 6.51605L5.89437 6.25363C6.07058 6.20817 6.20817 6.07058 6.25363 5.89437L6.51605 4.87706Z"
        fill="#7A82F5"
      />
      <path
        d="M13.0371 6.13379C13.2064 5.71912 13.7936 5.71912 13.9629 6.13379L15.5949 10.1311C15.6457 10.2555 15.7445 10.3543 15.8689 10.4051L19.8662 12.0371C20.2809 12.2064 20.2809 12.7936 19.8662 12.9629L15.8689 14.5949C15.7445 14.6457 15.6457 14.7445 15.5949 14.8689L13.9629 18.8662C13.7936 19.2809 13.2064 19.2809 13.0371 18.8662L11.4051 14.8689C11.3543 14.7445 11.2555 14.6457 11.1311 14.5949L7.13379 12.9629C6.71912 12.7936 6.71912 12.2064 7.13379 12.0371L11.1311 10.4051C11.2555 10.3543 11.3543 10.2555 11.4051 10.1311L13.0371 6.13379Z"
        fill="#7A82F5"
        stroke="#7A82F5"
        strokeWidth="1.4"
      />
    </svg>
  )
}
