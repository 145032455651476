import { useDispatch } from 'react-redux'
import { TemplateSettings, UserDocumentTemplateId } from 'packages/types'
import camelCase from 'lodash/camelCase'
import { actions } from 'builder/modules/resumeEditor'

type UpdateTemplateSettingsParams<
  T extends UserDocumentTemplateId,
  S extends keyof TemplateSettings[T],
> = {
  templatesSettings: TemplateSettings
  templateName: T
  settingsName: S
  value: TemplateSettings[T][S]
}

export const useUpdateTemplateSettings = () => {
  const dispatch = useDispatch()

  const updateTemplateSettings = <
    T extends UserDocumentTemplateId,
    S extends keyof TemplateSettings[T],
  >({
    templatesSettings,
    templateName,
    settingsName,
    value,
  }: UpdateTemplateSettingsParams<T, S>) => {
    const convertedTemplateName = camelCase(templateName)
    const updatedValue: TemplateSettings = {
      ...templatesSettings,
      [convertedTemplateName]: {
        ...templatesSettings[convertedTemplateName],
        [settingsName]: value,
      },
    }

    dispatch(
      actions.updateSimpleField({
        name: 'templateSettings',
        value: updatedValue,
        debounce: true,
      }),
    )
  }

  return {
    updateTemplateSettings,
  }
}
