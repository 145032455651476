import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'
import Button, { ButtonSize, ButtonTheme } from 'builder/components/Button'

export const UploadButton = styled.label`
  display: flex;
  width: fit-content;
  min-width: 132px;
  height: 36px;
  padding: var(--2XS, 8px) var(--XS, 12px);
  position: relative;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--3XS, 4px);
  border: 1px solid ${Colors.Neutral20};
  transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
  background-color: ${Colors.White};
  color: ${Colors.Neutral90};
  ${Typography.Body};
  ${FontWeights.Medium};

  &:hover {
    transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
    border: 1px solid ${Colors.Blue50};
    background-color: ${Colors.White};
    color: ${Colors.Blue50};
  }
`

export const ResumeSelectionContainer = styled.div`
  margin: 0 0 24px;
  display: flex;
  gap: 18px;
  width: 100%;
  padding: 15px;
  flex-shrink: 0;
  border-radius: var(--2XS, 8px);
  border: 1px solid ${Colors.Neutral15};

  ${Media.Phone`
    position: relative;
    height: 128px;
  `};
`

export const SelectLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  position: relative;
  max-width: 244px;
`

export const SelectResumeTitle = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral90};
  margin-bottom: 2px;
`

export const SelectResumeDate = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  margin-bottom: 18px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${Sizes['2XS']};
`

export const PreviewButton = styled(Button).attrs({ size: ButtonSize.small })`
  padding: ${Sizes['2XS']} ${Sizes.XS} ${Sizes['2XS']} ${Sizes['2XS']};
  span {
    display: flex;
    gap: ${Sizes['3XS']};
  }
`

export const ChangeResumeButton = styled(Button).attrs({
  size: ButtonSize.small,
  theme: ButtonTheme.ghost,
})``

export const SelectRight = styled.div`
  display: flex;
  text-align: center;
  position: relative;
  gap: 12px;
  overflow: hidden;
  flex-shrink: 0;

  ${Media.Phone`
      position: absolute;
      right: 30px;
  `};
`

export const DocumentPreviewContainer = styled.div`
  flex-shrink: 0;
  width: 104px;
  max-height: 104px;

  ${Media.Phone`
    width: 104px;
    height: 100px;  
    margin-bottom: 0;
  `};
`

export const RightBottonShadow = styled.div`
  width: 200px;
  height: 25px;
  flex-shrink: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  position: absolute;
  top: 90px;
`
