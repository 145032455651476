import { useEffect, useState, useRef, useCallback, Fragment } from 'react'

import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Resume } from '@rio/types'
import { trackInternalEvent } from '@rio/tracking'

import { AsyncAutosuggest, createSuggestionsApiFetcher } from 'builder/components/AsyncAutosuggest'
import AvatarEditorModal from 'builder/components/AvatarEditorModal'
import EditorField from 'builder/components/EditorField'
import EditorRow from 'builder/components/EditorRow'
import Foldable from 'builder/components/Foldable'
import PhotoUploadField from 'builder/components/PhotoUploadField'
import SectionTitle from 'builder/components/SectionTitle'
import {
  SectionBodyFoldableContainer,
  SectionHeader,
  useFoldableSectionScrollFix,
} from 'builder/components/Section'
import { TunerSectionTooltips } from 'builder/components/Tuner'
import { TextField } from 'builder/components/TextField'

import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import FocusManager from 'builder/services/FocusManager'

import { actions as uiActions } from 'builder/modules/ui'
import {
  actions as editorActions,
  selectors as editorSelectors,
  AvatarUploadPayload,
  SectionNames,
  AvailableAISuggestions,
} from 'builder/modules/resumeEditor'
import { AISuggestionsToggler } from 'builder/components//AiFeatures/Suggestions'
import { useInvalidateGetRecommendationJobQuery } from '../FindJob/JobAlertPage/hooks/useGetRecommendationJob'
import { Container, ShowButton, ShowButtonText, ShowButtonIcon, AdditionalFields } from './styles'

type Props = {
  resume: Resume
  isFoldableSectionsEnabled?: boolean
}

export const PersonalDetailsSection = (props: Props) => {
  const { resume, isFoldableSectionsEnabled } = props
  const dispatch = useDispatch()

  const { i18n } = useI18n()
  const templates = useTypedSelector(editorSelectors.templates)
  const isUploadingPhoto = useTypedSelector(state => state.resumeEditor.isUploadingPhoto)
  const isOnline = useTypedSelector(state => state.application.isOnline)
  const openedSection = useTypedSelector(editorSelectors.openedSection)
  const isOpened = openedSection === SectionNames.details
  const foldableProps = { isFoldableSectionsEnabled, isOpened }

  const { invalidate: invalidateRecommendationsQuery } = useInvalidateGetRecommendationJobQuery()
  const [isTitleEdited, setIsTitleEdited] = useState(false)
  const [oldJobTitle, setOldJobTitle] = useState(resume.position)

  const container = useRef<HTMLDivElement | null>(null)
  const [areAdditionalFieldsVisible, setAdditionalFieldsVisibility] = useState(false)
  const [isAvatarEditorVisible, setAvatarEditorVisibility] = useState(false)

  const { locale, sectionTitles } = resume
  const currentTemplate = templates.find(template => template.id === resume.template)
  const supportsPhoto = currentTemplate ? currentTemplate.supportsPhoto : false
  const { isPhone } = useMediaQueries()

  useEffect(() => {
    if (!isTitleEdited) setOldJobTitle(resume.position)
  }, [resume.position, isTitleEdited, oldJobTitle])

  useFoldableSectionScrollFix({
    elementRef: container,
    isOpened,
    isDisabled: !isFoldableSectionsEnabled,
  })

  // Connect to FocusManager
  useEffect(() => {
    const focus = (name: string) => {
      const targetField = container.current?.querySelector<HTMLInputElement>(`[name=${name}]`)
      if (targetField) targetField.focus()
    }

    const focusableFieldNames: Array<keyof Resume> = ['position', 'email', 'phoneNumber']
    focusableFieldNames.forEach(name => FocusManager.subscribe(name, () => focus(name)))
    return () => focusableFieldNames.forEach(name => FocusManager.unsubscribe(name))
  }, [])

  // Disable photo uploader window if user is offline
  const handleTogglePhotoUploader = useCallback(() => {
    if (!isOnline) return dispatch(uiActions.setConnectionLostSnackbarOpen(true))
    setAvatarEditorVisibility(value => !value)
  }, [dispatch, isOnline])

  // Handle avatar editor payload
  const handleUploadPhoto = useCallback(
    (payload: AvatarUploadPayload) => {
      dispatch(editorActions.uploadPhoto(payload))
      trackInternalEvent('change_avatar')
      setAvatarEditorVisibility(false)
    },
    [dispatch],
  )

  // Clear resume avatar
  const handleDeletePhoto = useCallback(() => {
    if (!window.confirm(i18n.t('builder.resume_editor.delete_photo_confirm'))) return
    dispatch(editorActions.deletePhoto())
    trackInternalEvent('delete_avatar')
  }, [dispatch, i18n])

  // Handle input values update
  const handleSimpleFieldUpdate = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      if (name === 'position') setIsTitleEdited(true)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(editorActions.updateSimpleField({ name: name as any, value, debounce: true }))
      const invalidateRecommendations = ['countryName', 'city', 'position'].includes(name)
      if (invalidateRecommendations) {
        invalidateRecommendationsQuery()
      }
    },
    [dispatch],
  )

  // Set custom section name
  const handleSectionRename = useCallback(
    (value: string | null) => {
      dispatch(editorActions.renameSection({ id: SectionNames.details, value }))
    },
    [dispatch],
  )

  const handleToggle = useCallback(() => {
    dispatch(editorActions.openSection(isOpened ? null : SectionNames.details))
  }, [dispatch, isOpened])

  return (
    <Fragment>
      <Container ref={container} {...foldableProps}>
        {/* Section header */}
        <SectionHeader {...foldableProps} onClick={handleToggle}>
          <SectionTitle
            title={i18n.t('builder.resume_editor.personal_details')}
            customTitle={sectionTitles.details}
            onRename={handleSectionRename}
            renamable
            {...foldableProps}
          />
        </SectionHeader>

        <SectionBodyFoldableContainer {...foldableProps}>
          <EditorRow>
            {/* Job title */}
            <EditorField>
              <AsyncAutosuggest
                name="position"
                fetchItems={createSuggestionsApiFetcher('job-title', { limit: 4, locale })}
                value={resume.position || ''}
                onChange={handleSimpleFieldUpdate}
              >
                {inputProps => (
                  <TextField
                    {...inputProps}
                    placeholder={i18n.t('builder.resume_editor.job_title_placeholder')}
                    label={i18n.t('builder.resume_editor.job_title')}
                    hint={i18n.t('builder.resume_editor.job_title_hint')}
                    badge={<AISuggestionsToggler type={AvailableAISuggestions.jobTitle} />}
                  />
                )}
              </AsyncAutosuggest>
            </EditorField>

            {/* Avatar */}
            <EditorField>
              <PhotoUploadField
                imageUrl={resume.avatar.blank ? '' : resume.avatar.thumbUrl}
                isUploading={isUploadingPhoto}
                isLocked={!supportsPhoto}
                onUpload={handleUploadPhoto}
                onDelete={handleDeletePhoto}
                onClick={isPhone ? null : handleTogglePhotoUploader}
              />
              {isAvatarEditorVisible && !isPhone && (
                <AvatarEditorModal
                  avatar={resume.avatar}
                  onSubmit={handleUploadPhoto}
                  onClose={handleTogglePhotoUploader}
                />
              )}
            </EditorField>
          </EditorRow>

          <EditorRow>
            {/* First name */}
            <EditorField>
              <TextField
                label={i18n.t('builder.resume_editor.first_name')}
                value={resume.firstName || ''}
                name="firstName"
                onChange={handleSimpleFieldUpdate}
              />
            </EditorField>

            {/* Last name */}
            <EditorField>
              <TextField
                label={i18n.t('builder.resume_editor.last_name')}
                value={resume.lastName || ''}
                name="lastName"
                onChange={handleSimpleFieldUpdate}
              />
            </EditorField>
          </EditorRow>

          <EditorRow>
            {/* Email */}
            <EditorField>
              <TextField
                label={i18n.t('builder.resume_editor.email')}
                type="email"
                value={resume.email || ''}
                name="email"
                onChange={handleSimpleFieldUpdate}
              />
            </EditorField>

            {/* Phone */}
            <EditorField>
              <TextField
                label={i18n.t('builder.resume_editor.phone')}
                type="phone"
                value={resume.phoneNumber || ''}
                name="phoneNumber"
                onChange={handleSimpleFieldUpdate}
              />
            </EditorField>
          </EditorRow>

          <EditorRow>
            {/* Country */}
            <EditorField>
              <AsyncAutosuggest
                highlightedQuery
                name="countryName"
                fetchItems={createSuggestionsApiFetcher('country', { locale })}
                value={resume.countryName || ''}
                onChange={handleSimpleFieldUpdate}
              >
                {inputProps => (
                  <TextField {...inputProps} label={i18n.t('builder.resume_editor.country')} />
                )}
              </AsyncAutosuggest>
            </EditorField>

            {/* City */}
            <EditorField>
              <AsyncAutosuggest
                highlightedQuery
                fetchItems={createSuggestionsApiFetcher('city', {
                  locale,
                  country: resume.countryName || '',
                })}
                name="city"
                value={resume.city || ''}
                onChange={handleSimpleFieldUpdate}
              >
                {inputProps => (
                  <TextField {...inputProps} label={i18n.t('builder.resume_editor.city')} />
                )}
              </AsyncAutosuggest>
            </EditorField>
          </EditorRow>

          {/* Additional fields */}
          <Foldable isOpen={areAdditionalFieldsVisible}>
            <AdditionalFields>
              <EditorRow>
                {/* Address */}
                <EditorField>
                  <TextField
                    label={i18n.t('builder.resume_editor.address')}
                    value={resume.address || ''}
                    name="address"
                    onChange={handleSimpleFieldUpdate}
                  />
                </EditorField>

                {/* ZIP Code */}
                <EditorField>
                  <TextField
                    label={i18n.t('builder.resume_editor.postal_code')}
                    value={resume.postalCode || ''}
                    name="postalCode"
                    onChange={handleSimpleFieldUpdate}
                  />
                </EditorField>
              </EditorRow>

              <EditorRow>
                {/* Driving License */}
                <EditorField>
                  <TextField
                    label={i18n.t('builder.resume_editor.driving_license')}
                    hint={i18n.t('builder.resume_editor.driving_license_hint')}
                    value={resume.drivingLicense || ''}
                    name="drivingLicense"
                    onChange={handleSimpleFieldUpdate}
                  />
                </EditorField>

                {/* Nationality */}
                <EditorField>
                  <AsyncAutosuggest
                    name="nationality"
                    fetchItems={createSuggestionsApiFetcher('nationality', { locale })}
                    value={resume.nationality || ''}
                    onChange={handleSimpleFieldUpdate}
                  >
                    {inputProps => (
                      <TextField
                        {...inputProps}
                        label={i18n.t('builder.resume_editor.nationality')}
                        hint={i18n.t('builder.resume_editor.nationality_hint')}
                      />
                    )}
                  </AsyncAutosuggest>
                </EditorField>
              </EditorRow>

              <EditorRow>
                {/* Birth place */}
                <EditorField>
                  <TextField
                    label={i18n.t('builder.resume_editor.birth_place')}
                    value={resume.birthPlace || ''}
                    name="birthPlace"
                    onChange={handleSimpleFieldUpdate}
                  />
                </EditorField>

                {/* Birth date */}
                <EditorField>
                  <TextField
                    label={i18n.t('builder.resume_editor.birth_date')}
                    hint={i18n.t('builder.resume_editor.birth_date_hint')}
                    value={resume.birthDate || ''}
                    name="birthDate"
                    onChange={handleSimpleFieldUpdate}
                  />
                </EditorField>
              </EditorRow>
            </AdditionalFields>
          </Foldable>

          {/* Additional fields expand button */}
          <ShowButton onClick={() => setAdditionalFieldsVisibility(value => !value)}>
            <ShowButtonText>
              {areAdditionalFieldsVisible
                ? i18n.t('builder.resume_editor.hide_details')
                : i18n.t('builder.resume_editor.show_details')}
            </ShowButtonText>
            <ShowButtonIcon data-open={areAdditionalFieldsVisible} />
          </ShowButton>

          <TunerSectionTooltips sectionId={SectionNames.details} />
        </SectionBodyFoldableContainer>
      </Container>
    </Fragment>
  )
}
