import styled, { css } from 'styled-components'

export const Content = styled.div<{ duration: number }>`
  height: 0;
  opacity: 0;
  overflow: hidden;
  ${({ duration }) =>
    duration &&
    css`
      transition: height ${duration}ms ease, opacity ${duration}ms ease;
    `};
`
