import { useI18n } from 'builder/hooks/useI18n'
import { useGetPlanPrice } from 'builder/hooks/useGetPlanPrice'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'

import ModalOverlay from '../ModalOverlay'
import { useAutoApplyUpsellModal } from './useAutoApplyUpsellModal'
import autoApplyUpsellVideo from './assets/auto-apply-animation.mp4'
import * as Styles from './styles'

const TRANSLATION = 'builder.auto_apply.upsell_modal'

export const AutoApplyUpsellModal: React.FC = () => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const { features } = useFeaturesConfig()
  const {
    isOpen,
    isFreeUser,
    isPostPremiumUser,
    planName,
    handleCloseModal,
    handlePayment,
    isTrialUser,
  } = useAutoApplyUpsellModal(true)
  const { recurringAmount, planAmount, isLoading } = useGetPlanPrice({
    plan: planName,
    fetchPrice: isOpen,
  })
  const variant = features.autoApplyUpsellModal || 'A'

  const getPriceButton = () => {
    if (variant === 'E') return
    if (isLoading) return <Styles.PriceLoader type="white" />
    if (isFreeUser || isPostPremiumUser) return recurringAmount()

    return (
      <>
        <Styles.SlashedPrice>{recurringAmount()}</Styles.SlashedPrice> {planAmount()}
      </>
    )
  }

  const getPriceCopy = () => {
    const isPaidUser = !(isFreeUser || isPostPremiumUser) && !isTrialUser

    if (variant === 'E') {
      if (isPaidUser) {
        return i18n.t(`${TRANSLATION}.cta_caption_premium_e`, {
          price: planAmount(),
          recurringPrice: recurringAmount(),
        })
      }

      return i18n.t(`${TRANSLATION}.cta_caption_e`, {
        price: isFreeUser || isPostPremiumUser ? recurringAmount() : planAmount(),
      })
    }

    return i18n.t(`${TRANSLATION}.cta_caption`, { price: recurringAmount() })
  }

  if (!isOpen) return null

  return (
    <ModalOverlay fullScreen={isPhone} onClose={handleCloseModal}>
      <Styles.RootModalContainer>
        <Styles.ModalSection>
          <Styles.UpsellTextContainer>
            <Styles.UpsellHeaderContainer>
              <Styles.UpsellBadge>{i18n.t(`${TRANSLATION}.badge_text`)}</Styles.UpsellBadge>
              <Styles.UpsellTitle $fullWidth={!['a', 'b'].includes(variant)}>
                {i18n.t(`${TRANSLATION}.title.${variant}`)}
              </Styles.UpsellTitle>
              <Styles.UpsellDescription>
                {i18n.t(`${TRANSLATION}.description.${variant}`)}
              </Styles.UpsellDescription>
            </Styles.UpsellHeaderContainer>

            <Styles.UpsellFeaturesContainer>
              <Styles.UpsellFeatureTitle>
                {i18n.t(`${TRANSLATION}.feature_title`)}
              </Styles.UpsellFeatureTitle>
              <Styles.UpsellFeatures>
                <Styles.UpsellFeature>
                  {i18n.t(`${TRANSLATION}.features.1.${variant}`)}
                </Styles.UpsellFeature>
                <Styles.UpsellFeature>{i18n.t(`${TRANSLATION}.features.2`)}</Styles.UpsellFeature>
                <Styles.UpsellFeature>{i18n.t(`${TRANSLATION}.features.3`)}</Styles.UpsellFeature>
              </Styles.UpsellFeatures>
              <Styles.InfoContainer>
                <Styles.InfoIcon />
                <Styles.InfoText>{i18n.t(`${TRANSLATION}.info`)}</Styles.InfoText>
              </Styles.InfoContainer>
            </Styles.UpsellFeaturesContainer>
          </Styles.UpsellTextContainer>

          <Styles.ButtonContainer>
            <Styles.CTAContainer>
              <Styles.UpgradeButton onClick={handlePayment}>
                {i18n.t(`${TRANSLATION}.cta_text${variant === 'E' ? '_e' : ''}`)} {getPriceButton()}
              </Styles.UpgradeButton>
              <Styles.CTACaption>
                {isLoading ? (
                  <Styles.PriceLoader />
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: getPriceCopy() }} />
                )}
              </Styles.CTACaption>
            </Styles.CTAContainer>
          </Styles.ButtonContainer>
        </Styles.ModalSection>

        <Styles.ModalMediaSection>
          <Styles.CloseButtonContainer>
            <Styles.Close onClick={handleCloseModal}>
              <Styles.CloseIcon />
            </Styles.Close>
          </Styles.CloseButtonContainer>

          <Styles.Video autoPlay muted playsInline loop>
            <source src={autoApplyUpsellVideo} type="video/mp4" />
          </Styles.Video>
        </Styles.ModalMediaSection>
      </Styles.RootModalContainer>
    </ModalOverlay>
  )
}
