import { Component } from 'react'
import { Transition } from 'react-transition-group'
import { reflow } from 'builder/utils/reflow'
import { Content } from './styles'

type Props = {
  duration?: number
  isOpen?: boolean
  unmountOnExit?: boolean
}

class Foldable extends Component<Props> {
  handleEnter = (node: HTMLElement) => {
    node.style.height = '0'
    node.style.overflowY = 'hidden'
    node.style.opacity = '0'
  }

  handleEntering = (node: HTMLElement) => {
    node.style.height = `${node.scrollHeight}px`
    node.style.opacity = '1'
  }

  handleEntered = (node: HTMLElement) => {
    node.style.overflow = 'visible'
    node.style.height = 'auto'
  }

  handleExiting = (node: HTMLElement) => {
    node.style.overflowY = 'hidden'
    node.style.height = `${node.scrollHeight}px`
    reflow(node)
    node.style.height = '0'
    node.style.opacity = '0'
  }

  render() {
    const { isOpen, duration = 200, unmountOnExit = true } = this.props

    return (
      <Transition
        in={isOpen}
        timeout={200}
        onEnter={this.handleEnter}
        onEntering={this.handleEntering}
        onEntered={this.handleEntered}
        onExiting={this.handleExiting}
        unmountOnExit={unmountOnExit}
        appear
      >
        <Content duration={duration}>{this.props.children}</Content>
      </Transition>
    )
  }
}

export default Foldable
