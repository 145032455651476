import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import { TRLogo } from 'builder/components/Icon/20x20'

export const Card = styled.div`
  width: 448px;
  height: 400px;
  flex-shrink: 0;
  border-radius: var(--S, 16px);
  border: 1px solid ${Colors.Neutral15};
  transition: box-shadow 0.2s ease-in;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;

  &:hover {
    box-shadow: 0 16px 64px -8px rgba(15, 56, 113, 0.18), 0 6px 8px -2px rgba(15, 56, 113, 0.02),
      0 0 1px 0 rgba(15, 56, 113, 0.32);
    transition: box-shadow 0.2s ease-in;
  }

  ${Media.Tablet`
    width: 352px;
    height: 400px;
  `}

  ${Media.Phone`
    width: 335px;
    height: 306px;
  `}
`

export const CardTitle = styled.div`
  width: 272px;
  ${Typography.S};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  z-index: 22;
  margin-bottom: 8px;
  text-align: center;
`

export const SelectResumeOneImage = styled.img`
  width: 155.354px;
  height: 220px;
  object-fit: cover;
  position: absolute;
  top: 30px;
  right: 119.65px;
  z-index: 10;
  filter: drop-shadow(0px 16px 64px rgba(15, 56, 113, 0.18))
    drop-shadow(0px 6px 8px rgba(15, 56, 113, 0.02))
    drop-shadow(0px 0px 1px rgba(15, 56, 113, 0.32));

  ${Media.Tablet`
    right: 71.65px;
  `}

  ${Media.Phone`
    width: 121.322px;
    height: 171.806px;
    top: 31px;
    right: 85.82px;
  `};
`

export const SelectResumeTwoImage = styled.img`
  width: 154.354px;
  height: 186px;
  object-fit: cover;
  position: absolute;
  z-index: 1;
  top: 63px;
  left: 92.77px;
  right: 119.65px;
  transform: rotate(-5deg);
  filter: drop-shadow(0px 2px 4px rgba(15, 56, 113, 0.08))
    drop-shadow(0px 1px 2px rgba(15, 56, 113, 0.12))
    drop-shadow(0px 0px 1px rgba(15, 56, 113, 0.32));

  ${Media.Tablet`
    left: 55.77px;
  `}

  ${Media.Phone`
    width: 129.629px;
    height: 165.061px;  
    top: 43px;
    left: 69.82px;
  `};
`

export const HollowPlaceholder = styled.div`
  height: 260px;

  ${Media.Phone`
    height: 198px;
  `};
`

export const BlurRegion = styled.div`
  width: 400px;
  height: 88px;
  position: absolute;
  bottom: 135px;
  z-index: 20;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 20%, #fff 100%);

  ${Media.Tablet`
    width: 350px;
  `}

  ${Media.Phone`
    width: 330px;
  `}
`

export const WhiteRegion = styled.div`
  width: 400px;
  height: 94px;
  flex-shrink: 0;
  background: ${Colors.White};
  position: absolute;
  z-index: 21;
  bottom: 47px;

  ${Media.Tablet`
    width: 350px;
  `}

  ${Media.Phone`
    width: 330px;
  `}
`

export const CardText = styled.div<{ $isTrCard?: boolean }>`
  width: 256px;
  z-index: 22;
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  text-align: center;

  ${Media.Phone`
    width: 281px;
  `};

  ${props => props.$isTrCard && 'width: 195px;'}
`

export const SkeletonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

export const SkeletonImageContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: -15px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${Media.Phone`
    margin-top: 27px;
  `};
`

export const SkeletonImage = styled.div`
  height: 270px;
  width: 201px;
  background: ${Colors.Neutral5};

  ${Media.Tablet`
    width: 166px;
  `};

  ${Media.Phone`
    width: 162px;
    height: 257px;
  `};
`

export const SkeletonInfo = styled.div`
  position: absolute;
  bottom: 75px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: var(--Spacing-S, 16px);
  background: ${Colors.White};

  ${Media.Phone`
    bottom: 0;
  `};
`

export const SkeletonHeader = styled.div`
  height: 44px;
  width: 248px;
  background: ${Colors.Neutral5};
`

export const SkeletonDescription = styled.div`
  width: 145px;
  height: 24px;
  background: ${Colors.Neutral5};
`

export const SkeletonFadeResume = styled.div`
  position: absolute;
  width: 250px;
  height: 88px;
  margin-top: 22px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  z-index: 3;
  left: 50%;
  transform: translateX(-50%);

  ${Media.Phone`
    margin-top: 0;
  `};
`

export const FakeResume = styled.div`
  position: relative;
  padding-top: 55px;
  width: 100%;
  height: 238px;
  overflow: hidden;
  margin-bottom: 15px;

  ${Media.Phone`
    padding-top: 25px;
    height: 180px;
    margin-bottom: unset;
  `};
`

export const FadeResume = styled.div`
  position: absolute;
  width: 400px;
  height: 88px;
  bottom: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  z-index: 3;
  left: 50%;
  transform: translateX(-50%);

  ${Media.Phone`
    width: 300px;
    height: 68px;
  `};
`

export const TrImage = styled.img`
  width: 201px;
  height: 284.641px;
  border-radius: 4px;
  box-shadow: 0px 16px 64px rgba(15, 56, 113, 0.18), 0px 6px 8px rgba(15, 56, 113, 0.02),
    0px 0px 1px rgba(15, 56, 113, 0.32);

  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  ${Media.Tablet`
    width: 166px;
  `};

  ${Media.Phone`
    width: 162px;
  `};
`

export const TrResumeLabel = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: ${Colors.Neutral5};
  border: 1px solid ${Colors.Blue40};
  padding: 8px;
  border-radius: 8px;

  ${Media.Phone`
    padding: 7px 8px;
  `}
`

export const TrResumeLabelIcon = styled(TRLogo)``
export const TrResumeLabelText = styled.div`
  ${Typography.Caption}
  ${FontWeights.Regular}
`
