import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'

export const NoPreviewContainer = styled.div`
  background: ${Colors.Neutral10};
  width: 100%;

  ${Media.Tablet`
    padding: 0px 16px 32px 16px;
  `}
`

export const NoPreviewBackground = styled.div`
  background: ${Colors.White};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 72px 35px 141px 35px;
`

export const NoPreviewText = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
  text-align: center;

  ${Typography.S};
  ${FontWeights.Medium};
`

export const NoPreviewImg = styled.img`
  width: 162px;
  height: 172px;

  ${Media.Phone`
     width: 98px;
     height: 104px;
  `}
`
