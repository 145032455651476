import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { AutoApplyForm } from 'builder/modules/autoApply/types'
import { selectors } from 'builder/modules/autoApply/autoApplyModule'

import * as SimpleForm from 'builder/components/SimpleForm'
import { Routes, TotalPageCount } from '../../constants'
import { Container, FillWarning, LayoutContainer, Title, TitleWrapper } from '../styles'
import { ConfirmModal } from '../ConfirmModal/ConfirmModal'
import { usePatchForm } from '../../hooks/usePatchForm'
import { usePrompt } from '../../hooks/usePrompt'
import { useStepsConfig } from '../../hooks/useStepsConfig'
import { TailorResumeForm } from './TailorResumeForm'
import { FormContainer } from './styles'

const TRANSLATION = 'builder.auto_apply.form.tailor_resume'
type TailorResume = Pick<AutoApplyForm, 'autoTailoring'>

export const TailorResumeContainer = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const stepsConfig = useStepsConfig()
  const formState = useSelector(selectors.form)

  const form = useForm<TailorResume>({
    mode: 'onChange',
    defaultValues: { autoTailoring: !!formState.resumeId },
  })
  const { mutateAsync: patchForm, isLoading } = usePatchForm()
  const { isPromptVisible, handleCancel, handleConfirm } = usePrompt({
    when: true,
    ignoreParentRoute: ['auto-apply', 'preview', 'tr-documents'],
  })

  const onNextStep = async () => {
    if (
      (!formState?.workExperiencesAttributes?.length || !formState.resumeId) &&
      form.getValues('autoTailoring')
    ) {
      return
    }

    const payload = form.getValues()
    await patchForm(payload)

    trackInternalEvent('auto_tailoring_toggle', {
      label: 'questionnaire job search',
      parameter: payload.autoTailoring ? 'on' : 'off',
    })

    const step = payload.autoTailoring ? Routes.TAILOR_RESUME : Routes.COVER_LETTER
    navigate(`/auto-apply/${stepsConfig[step].next}`)
  }

  const onBackClick = () => {
    form.clearErrors()
    navigate(`/auto-apply/${stepsConfig[Routes.TAILOR_RESUME].prev}`)
  }

  return (
    <LayoutContainer>
      <Container>
        <TitleWrapper>
          <Title>{i18n.t(`${TRANSLATION}.title`)}</Title>
          <FillWarning>{i18n.t(`${TRANSLATION}.sub_title`)}</FillWarning>
        </TitleWrapper>
        <FormContainer>
          <SimpleForm.Form
            submitButtonText={i18n.t(`${TRANSLATION}.next`)}
            onBackClick={onBackClick}
            onSubmit={form.handleSubmit(onNextStep)}
            isPending={isLoading}
            processIndicator={{
              totalStep: TotalPageCount,
              currentStep: stepsConfig[Routes.TAILOR_RESUME].order,
            }}
          >
            <TailorResumeForm form={form} />
          </SimpleForm.Form>
        </FormContainer>
      </Container>
      {isPromptVisible && <ConfirmModal onClose={handleCancel} onConfirm={handleConfirm} />}
    </LayoutContainer>
  )
}
