import { useCallback, useMemo, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { Icon24, Icon20 } from 'builder/components/Icon'
import { DocumentTypes } from 'builder/modules/constants'
import {
  Container,
  TabContainer,
  ContentContainer,
  CustomTab,
  TabLabelContainer,
  IconContainer,
  CustomTabs,
} from './styles'
import { TabType, Tab } from './types'
import { TemplateSelection, TextCustomization, LayoutCustomization } from './'

interface ResumeSidebarProps {
  type: DocumentTypes
}

export const ResumeSidebar = ({ type }: ResumeSidebarProps) => {
  const { i18n } = useI18n()
  const [selectedTab, setSelectedTab] = useState<TabType>(TabType.templates)

  const tabs: Tab[] = useMemo(
    () => [
      {
        label: (
          <TabLabelContainer>
            <IconContainer>
              <Icon24.Grid />
            </IconContainer>
            {i18n.t('builder.resume_editor.document_customization.sidebar.templates_tab.header')}
          </TabLabelContainer>
        ),
        id: TabType.templates,
        content: <TemplateSelection type={type} />,
      },
      {
        label: (
          <TabLabelContainer>
            <IconContainer>
              <Icon24.FontSize />
            </IconContainer>
            {i18n.t('builder.resume_editor.document_customization.sidebar.text_tab.header')}
          </TabLabelContainer>
        ),
        id: TabType.text,
        content: <TextCustomization />,
      },
      {
        label: (
          <TabLabelContainer>
            <IconContainer>
              <Icon20.Documents />
            </IconContainer>
            {i18n.t('builder.resume_editor.document_customization.sidebar.layout_tab.header')}
          </TabLabelContainer>
        ),
        id: TabType.layout,
        content: <LayoutCustomization />,
      },
    ],
    [i18n, type],
  )

  const selectedTabContent = useMemo(
    () => tabs.find(tab => tab.id === selectedTab)?.content,
    [tabs, selectedTab],
  )

  const handleTabClick = useCallback((id: TabType) => {
    setSelectedTab(id)
    trackInternalEvent('change_customization_tab', {
      label: id,
    })
  }, [])

  const tabElements = useMemo(
    () =>
      tabs.map(tab => (
        <CustomTab
          key={tab.id}
          isActive={tab.id === selectedTab}
          onClick={() => handleTabClick(tab.id)}
        >
          {tab.label}
        </CustomTab>
      )),
    [tabs, selectedTab, handleTabClick],
  )

  return (
    <Container>
      <TabContainer>
        <CustomTabs>{tabElements}</CustomTabs>
      </TabContainer>
      <ContentContainer>{selectedTabContent}</ContentContainer>
    </Container>
  )
}
