import { memo } from 'react'
import { TippyProps } from '@tippyjs/react'
import { BaseTooltipProps } from '../../types'
import { transformMultiline } from '../../utils'
import { TippyTooltip } from './styles'

type Props = TippyProps &
  BaseTooltipProps & {
    // for better back-capability with v1 Tooltip component
    position?: TippyProps['placement']
    isActive?: boolean
  }

/**
 * Tooltip based on Tippy.js
 */
export const TooltipV2 = memo((props: Props) => {
  const {
    value = '',
    multiline = false,
    width,
    textAlign = 'left',
    children,
    position = 'top',
    isActive = true,
    ...rest
  } = props

  const body = transformMultiline(value)

  return isActive ? (
    <TippyTooltip
      maxWidth={width}
      content={body}
      placement={position}
      $isMultiline={multiline}
      $textAlign={textAlign}
      $width={width}
      // this number is selected to tooltips able to appear
      // on top of the modals with ModalOverlay (with z-index: 12000)
      zIndex={13000}
      {...rest}
    >
      {children}
    </TippyTooltip>
  ) : (
    children || null
  )
})
