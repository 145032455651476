import { useLocation } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'

const ALLOWED_ROUTES = ['/resumes', '/job-search', '/tr-documents']

export function useTopResumeWelcomeModal() {
  const { search, pathname } = useLocation()
  const { features } = useFeaturesConfig()

  const trWelcomeModal = features?.trWelcomeModal
  const isV2 = trWelcomeModal === 'test_v2'
  const twWelcomeModalWasSeen = !!localStorage.getItem('twWelcomeModalWasSeen')

  const isModalActive = useMemo(() => {
    const showTrWelcomeModal = trWelcomeModal === 'test_v1' || trWelcomeModal === 'test_v2'
    const trWelcomeParam = new URLSearchParams(search).get('tr_welcome') === 'true'
    const showModalOnAllowedRoutes = ALLOWED_ROUTES.includes(pathname)

    return (
      showTrWelcomeModal && !twWelcomeModalWasSeen && trWelcomeParam && showModalOnAllowedRoutes
    )
  }, [trWelcomeModal, twWelcomeModalWasSeen, search, pathname])

  const [showTopResumeWelcomeModal, setShowTopResumeWelcomeModal] = useState(isModalActive)

  useEffect(() => {
    if (showTopResumeWelcomeModal) {
      trackInternalEvent('see_new_welcome_modal')
    }
  }, [showTopResumeWelcomeModal])

  const closeModal = () => {
    trackInternalEvent('close_new_welcome_modal')
    setShowTopResumeWelcomeModal(false)
    localStorage.setItem('twWelcomeModalWasSeen', 'true')
  }

  return {
    showTopResumeWelcomeModal,
    isV2,
    closeModal,
  }
}
