import { useParams } from 'react-router-dom'

import { DocumentTypes } from 'builder/modules/constants'
import { ResumePreview } from 'builder/views/AutoApply/components/Documents/ResumePreview/ResumePreview'
import { CoverLetterPreview } from 'builder/views/AutoApply/components/Documents/CoverLetterPreview/CoverLetterPreview'

import * as Styles from './styles'

type Params = {
  id: string
  type: DocumentTypes
}

export const DocumentPreviewView = () => {
  const { id, type } = useParams<Params>()

  return (
    <Styles.PageContainer>
      {type === DocumentTypes.resume ? <ResumePreview id={Number(id)} /> : <CoverLetterPreview />}
    </Styles.PageContainer>
  )
}
