import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Sizes from 'builder/styles/sizes'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { ModalClose } from 'builder/components/ModalClose'
import Icon from 'builder/components/Icon'
import Button, { ButtonTheme } from 'builder/components/Button'

export const Modal = styled.div`
  display: flex;
  position: relative;

  ${Media.Phone`
    padding: 0 16px;
  `}
`

export const Body = styled.div<{ $isV2: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${Sizes.L} ${Sizes.L} 0 ${Sizes.L};
  overflow: auto;
  max-height: 570px;

  ${Media.Phone`
    padding: ${Sizes.L} ${Sizes.M} 0 ${Sizes.M};
  `}

  ${props =>
    props.$isV2 &&
    `
    max-height: unset;
  `}
`

export const Items = styled.div<{ $isV2: boolean }>`
  display: flex;
  flex-direction: column;
  padding-bottom: ${Sizes.L};

  ${Media.Phone`
    padding-bottom: ${Sizes.M};
  `}

  ${props =>
    props.$isV2 &&
    `
    overflow: auto;
    max-height: 420px;
  `}
`

export const Container = styled.div`
  display: flex;
  background-color: ${Colors.White};
  border-radius: ${Sizes.S};
  max-width: 544px;
  width: 100%;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  justify-content: center;
`

export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
`

export const CloseButton = styled(ModalClose)`
  position: absolute;
  top: 32px;
  right: 32px;

  ${Media.Phone`
    top: 20px;
    right: 20px;
  `}
`

export const LogosContainer = styled.div<{ $isV2: boolean }>`
  display: flex;
  gap: ${Sizes['2XS']};
  margin-top: ${Sizes.XS};

  ${Media.Phone`
    gap: ${Sizes['3XS']};
  `}

  ${props =>
    props.$isV2 &&
    `
    display: none;
  `}
`

export const LogoContainer = styled.div`
  border-radius: 12px;
  background-color: ${Colors.Neutral5};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${Sizes['3XS']};
  padding: ${Sizes.S} 0;
  flex: 1;

  ${Media.Phone`
    padding: ${Sizes.XS};
  `}
`

export const LogoTitle = styled.span`
  ${Typography.Caption};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
  text-align: center;
`

export const Star = styled.img`
  width: 43px;
  height: 46px;

  ${Media.Phone`
    width: 32px;
    height: 32px;
  `}
`

export const Documents = styled.img`
  width: 42px;
  height: 41px;

  ${Media.Phone`
    width: 32px;
    height: 32px;
  `}
`

export const Kite = styled.img`
  width: 48px;
  height: 47px;

  ${Media.Phone`
    width: 32px;
    height: 32px;
  `}
`

export const ItemsTitle = styled.span`
  ${Typography.Body};
  color: ${Colors.Neutral90};
  margin-top: ${Sizes.M};
`

export const ItemContainer = styled.div<{ $isV2: boolean }>`
  display: flex;
  gap: ${Sizes['2XS']};
  margin-top: ${Sizes.XS};

  ${props =>
    props.$isV2 &&
    `
    background-color: ${Colors.Neutral5};
    border-radius: ${Sizes.XS};
    padding: ${Sizes.S} ${Sizes.XL} ${Sizes.S} ${Sizes.S};
  `}
`

export const TickContainer = styled.div`
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.Blue10};
  padding: 4px;

  ${Media.Phone`
    width: 24px;
    height: 24px;
  `}
`

export const Tick = styled(Icon.TickLarge)`
  color: ${Colors.Blue50};

  ${Media.Phone`
    width: 16px;
    height: 16px;
  `}
`

export const ItemDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes['4XS']};
`

export const ItemTitle = styled.span`
  ${Typography.Body};
  color: ${Colors.Neutral80};
  ${FontWeights.DemiBold};
`

export const ItemDescription = styled.p`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
`

export const Footer = styled.div`
  padding: ${Sizes.M};
  display: flex;
  gap: ${Sizes.S};
  box-shadow: 0px 2px 4px -1px rgba(15, 20, 30, 0.16), 0px 8px 20px -4px rgba(15, 20, 30, 0.24);
  justify-content: flex-end;
  align-items: center;
`

export const CTA = styled(Button)`
  ${({ theme }) =>
    theme === ButtonTheme.ghost &&
    css`
      background: none;
      color: ${Colors.Blue50};
      padding: 0;
      border: none;
      box-shadow: none;
      text-decoration: none;
      font-weight: ${FontWeights.Medium};

      &:hover,
      &:focus-visible {
        text-decoration: underline;
        color: ${Colors.Blue60};
        box-shadow: none;
      }

      &:active {
        color: ${Colors.Blue70};
        box-shadow: none;
      }
    `}
`
