import { useRef, useState, useMemo } from 'react'
import throttle from 'lodash/throttle'
import startCase from 'lodash/startCase'
import { trackInternalEvent, trackMarketingEvent } from '@rio/tracking'
import { useDispatch } from 'react-redux'
import { Resume } from 'packages/types'
import {
  actions as coRegActions,
  selectors as coRegSelectors,
} from 'builder/modules/coRegistration'
import { FORMATS } from 'builder/modules/constants'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { useConfig } from 'builder/hooks/useConfig'
import { actions as uiActions } from 'builder/modules/ui'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'
import { getUpdatedTemplateAndFormat } from 'builder/utils/getTemplates'
import { isEmptySection } from 'builder/modules/resumeEditor/autoTailoringRules'
import { actions as resumeActions, isEmptyField, SectionNames } from 'builder/modules/resumeEditor'
import { useAutoTailoringAppConfig } from 'builder/hooks/useAutoTailoringAppConfig'
import templateHeightFactorSelector from '../Helper/utils/templateHeightFactorSelector'
import { PreviewRefProps } from '../PreviewPanel'
import { shouldOpenPlansPageAbandonment } from '../AbandonmentModal/utils'
import { IS_BUILDER_DOWNLOAD_AUTO_TAILOR_MODAL_SHOWN } from '../PreviewPanel/components/DownloadMenuLayout/DownloadMenuLayout'
import { DocumentPreviewHookProps, DocumentPreviewHookResult } from './types'

export const useDocumentPreview = (props: DocumentPreviewHookProps): DocumentPreviewHookResult => {
  const { type, currentDocument, editorState, onDownload, updateSimpleField, onClose } = props

  const isReadyToCoReg = useAppSelector(coRegSelectors.isResumeReady)
  const isCoRegPopupOpen = useAppSelector(coRegSelectors.isPopupOpen)
  const toggleCoRegPopup = coRegActions.togglePopup
  const openCoReg = coRegActions.open

  const dispatch = useDispatch()
  const config = useConfig()
  const plansPageAbandonment = config?.features.plansPageAbandonment || false
  const showOneDownloadButton = !!(config?.features.downloadButtonLayout === 'one_button')
  const userData = useTypedSelector(userSelectors.userData)

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPagesNumber, setTotalPagesNumber] = useState<number>(1)
  const [isSheetVisible, setIsSheetVisible] = useState<boolean>(false)
  const [isActionMenuOpen, setIsActionMenuOpen] = useState<boolean>(false)
  const [width, setWidth] = useState<number>(0)
  const [height, setHeight] = useState<number>(0)

  const contentRef = useRef<HTMLDivElement | null>(null)
  const previewBoxRef = useRef<HTMLDivElement | null>(null)
  const previewRef = useRef<PreviewRefProps>(null)
  const downloadButtonActions = config?.features.downloadButtonActions
  const isDataLoaded = currentDocument && editorState.isLoaded
  const { isAutoTailoringEnabled } = useAutoTailoringAppConfig()
  const isNewCustControlsEnabled =
    config?.features.newCustControls === 'true' || config?.features.newCustControls === 'true_new'

  const handlePreviewResize = throttle(() => {
    if (!previewBoxRef.current) return
    const width = previewBoxRef.current.offsetWidth
    const height = width / templateHeightFactorSelector(currentDocument)
    setWidth(width)
    setHeight(height)

    if (!previewRef.current) return
    previewRef.current.resize(width)
  }, 50)

  const isResume = useMemo(() => {
    return type === 'resume'
  }, [type])

  const isDataMissed = useMemo(() => {
    if (isResume) {
      const isWorkExperienceEmpty = isEmptySection(
        currentDocument as Resume,
        SectionNames.workExperiences,
      )
      const isProfileEmpty = isEmptyField(currentDocument as Resume, SectionNames.profile)
      return isWorkExperienceEmpty && isProfileEmpty
    } else {
      return false
    }
  }, [currentDocument, isResume])

  const handleDownloadDocument = ({ format = FORMATS.pdf }) => {
    const { updatedTemplate, updatedFormat } = getUpdatedTemplateAndFormat(
      showOneDownloadButton,
      type,
      currentDocument.template as string,
      format,
    )

    // Track in Google Analytics
    trackMarketingEvent('Preview Modal', `Download ${startCase(type)}`, {
      eventLabel: updatedFormat,
    })
    // Track in ClickHouse
    trackInternalEvent(`click_download_${type}_button`, {
      format: updatedFormat,
      source: `full_screen_${type}_preview`,
      template: updatedTemplate,
    })

    const wasAutoTailorDownloadModalShown = localStorage.getItem(
      IS_BUILDER_DOWNLOAD_AUTO_TAILOR_MODAL_SHOWN,
    )
    if (
      (downloadButtonActions === 'at_banner_link' || downloadButtonActions === 'at_banner') &&
      isAutoTailoringEnabled &&
      !isDataMissed &&
      isResume &&
      !wasAutoTailorDownloadModalShown
    ) {
      onClose()
      if (downloadButtonActions === 'at_banner') {
        dispatch(resumeActions.setFeaturingAutoTailorModalOpenOnDownload(true))
      }
      dispatch(resumeActions.setOpenOptimizerModal(true))
      dispatch(
        resumeActions.setResumeDownloadData({
          id: currentDocument.id,
          type,
          format: updatedFormat,
          source: 'builder',
          template: updatedTemplate,
        }),
      )
      localStorage.setItem(IS_BUILDER_DOWNLOAD_AUTO_TAILOR_MODAL_SHOWN, 'true')
      return
    }

    if (
      window.location.pathname.includes('/resumes') &&
      userData?.registeredAt &&
      shouldOpenPlansPageAbandonment(
        userData.registeredAt,
        plansPageAbandonment || false,
        userData.showAbandonmentFlows,
      )
    ) {
      trackInternalEvent('show_abandonment_popup', { source: 'dashboard', type: 'plans' })
      dispatch(
        resumeActions.setResumeDownloadData({
          id: currentDocument.id,
          type,
          format: updatedFormat,
          source: 'builder',
          template: updatedTemplate,
        }),
      )
      dispatch(uiActions.openPlansPageAbandonmentModal())
      return
    }

    onDownload({ id: currentDocument.id, type, format: updatedFormat, template: updatedTemplate })
  }

  const handleColorSelect = (color: string) => {
    updateSimpleField('color', color, true)
  }

  const handleSpacingChange = (spacing: number) => {
    updateSimpleField('spacing', spacing, true)
  }

  const handlePaginationStateChange = (state: { currentPage: number; totalPagesCount: number }) => {
    setCurrentPage(state.currentPage + 1)
    setTotalPagesNumber(state.totalPagesCount)
  }

  const handlePreviousPageClick = () => {
    if (!previewRef.current) return
    previewRef.current.previousPage()
    trackInternalEvent(`change_${type}_preview_page`)
  }

  const handleNextPageClick = () => {
    if (!previewRef.current) return
    previewRef.current.nextPage()
    trackInternalEvent(`change_${type}_preview_page`)
  }

  const handleSheetOpen = () => {
    setIsSheetVisible(true)
  }

  const handleSheetClose = () => {
    setIsSheetVisible(false)
  }

  const handleActionMenuOpen = () => {
    setIsActionMenuOpen(true)
    trackInternalEvent('open_document_action_menu', {
      source: `full_screen_${type}_preview`,
    })
  }

  const handleActionMenuClose = () => {
    setIsActionMenuOpen(false)
  }

  const handleShareClick = () => {
    trackInternalEvent('click_share_button', { source: `full_screen_${type}_preview` })
  }

  // Show the download resume option only for resume and the one button layout
  const showUpdatedMenuLayout = useMemo(
    () => isResume && showOneDownloadButton,
    [isResume, showOneDownloadButton],
  )

  const templates = useMemo(() => {
    const { resumeTemplates, coverLetterTemplates } = editorState
    return isResume ? resumeTemplates : coverLetterTemplates
  }, [editorState, isResume])

  const currentTemplate = useMemo(() => {
    return templates.find(template => template.id === currentDocument.template)
  }, [currentDocument.template, templates])

  const colorSettings = useMemo(() => {
    if (currentTemplate) {
      const templateSettings = currentTemplate.settings
      let selectedColor = currentDocument ? currentDocument.color : null
      if (!selectedColor && templateSettings.color) selectedColor = templateSettings.color.default
      const colors = templateSettings.color ? templateSettings.color.values : []
      return { colors, selectedColor }
    }
    return { colors: [], selectedColor: null }
  }, [currentDocument, currentTemplate])

  return {
    isReadyToCoReg,
    isCoRegPopupOpen,
    toggleCoRegPopup,
    openCoReg,
    currentPage,
    totalPagesNumber,
    isSheetVisible,
    isActionMenuOpen,
    isDataLoaded,
    width,
    height,
    setCurrentPage,
    setTotalPagesNumber,
    setIsSheetVisible,
    setIsActionMenuOpen,
    contentRef,
    previewBoxRef,
    previewRef,
    handlePreviewResize,
    handleDownloadDocument,
    handleColorSelect,
    handlePaginationStateChange,
    handleSpacingChange,
    handlePreviousPageClick,
    handleNextPageClick,
    handleSheetOpen,
    handleSheetClose,
    handleActionMenuOpen,
    handleActionMenuClose,
    handleShareClick,
    isResume,
    templates,
    currentTemplate,
    colorSettings,
    showUpdatedMenuLayout,
    isNewCustControlsEnabled,
  }
}
