import styled, { css, keyframes } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Select from 'builder/components/Select'
import { RadioGroup } from 'builder/components/RadioGroup'
import ModalComponent from 'builder/components/ConfirmModal'
import {
  ButtonsContainer,
  BottomContainer,
  RowsContainer,
} from 'builder/components/SimpleForm/styles'
import Media from 'builder/styles/media'
import DefaultIcon from '../../assets/DefaultIcon.png'

type ToggleIconType = {
  $isActive?: boolean
  $src?: string
  $size: {
    width: number
    height: number
  }
}

const rotation = keyframes`
  from {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }

  to {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
`

export const FormContainer = styled.div`
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 102px;
  }

  ${RowsContainer} {
    padding-bottom: 30px;
  }

  ${ButtonsContainer} {
    margin-top: 0;
  }

  ${BottomContainer} {
    bottom: 0;
    padding: 10px 0 32px;
    background-color: ${Colors.White};
    position: fixed;
    width: 416px;

    ${Media.Phone`
      max-width: 416px;
      width: 100%;
      bottom: 70px;
      left: 50%;
      transform: translateX(-50%);
   `};

    ${css`
      @media (max-width: 420px) {
        width: 100%;
        padding: 10px 20px 24px;
      }
    `}
  }
`

export const Spinner = styled.div`
  position: absolute;
  width: 10px;
  transform: translateX(-50%) translateY(-50%);
  animation: ${rotation} 0.5s infinite linear;
  top: 10px;
  right: 10px;
`

export const Salary = styled.div`
  display: flex;
  gap: var(--size-2xs);
`

export const CurrencySelect = styled(Select)`
  width: 100px;
`

export const SalaryPlanSelect = styled(Select)`
  width: 125px;
`
export const SmallTitle = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Caption};
  ${FontWeights.Regular};
  padding-bottom: 5px;
`

export const TravelRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
`

export const Courses = styled.div`
  display: flex;
  gap: var(--size-xs);
  flex-direction: column;
`

export const CourseContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--size-s);
  padding: 0 var(--size-s) var(--size-s) var(--size-s);
`

export const Course = styled.div`
  border: 1px solid ${Colors.Neutral15};
  border-radius: 4px;
  background-color: ${Colors.White};
`

export const CourseTitle = styled.div`
  color: ${Colors.Neutral90};
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  text-transform: capitalize;
`

export const CourseDates = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Caption};
  ${FontWeights.Regular};
`

export const StartEndDate = styled.div`
  display: flex;
  gap: var(--size-xs);
`
export const ResumeBox = styled.div`
  border: 1px solid ${Colors.Neutral15};
  border-radius: var(--size-2xs);
  padding: var(--size-s);
  margin-bottom: var(--size-s);
  display: flex;
  gap: var(--size-s);
  justify-content: space-between;
`

export const ResumeBoxLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--size-m);
  justify-content: space-between;
`

export const ResumeBoxRight = styled.div`
  width: 104px;
  img {
    width: 104px;
    height: 106px;
  }
`

export const FileInput = styled.input`
  display: none;
`

export const ResumeName = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
`

export const ResumeDate = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Caption};
  ${FontWeights.Regular};
`

export const ResumeNameAndDate = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--size-3xs);
`
export const Required = styled.span`
  color: ${Colors.Red50};
`

export const SelectInfoText = styled.div`
  margin-top: 4px;
  color: ${Colors.Neutral50};

  ${Typography.Caption};
  ${FontWeights.Regular};
`

export const ToggleCardImage = styled.div<ToggleIconType>`
  width: ${({ $isActive, $size }) => ($isActive ? `${$size.width}px` : '65px')};
  height: ${({ $isActive, $size }) => ($isActive ? `${$size.height}px` : '56px')};

  background-size: contain;
  background-position: center;
  background-image: url(${({ $isActive, $src }) => ($isActive ? $src : DefaultIcon)});
`

export const ErrorText = styled.div`
  margin-top: 8px;
  color: ${Colors.Red50};

  ${Typography.Caption};
  ${FontWeights.Regular};
`

export const InfoText = styled.p`
  color: ${Colors.Blue80};

  ${Typography.Caption};
  ${FontWeights.Regular};
`

export const InfoAction = styled.span`
  cursor: pointer;
  color: ${Colors.Blue50};

  ${Typography.Caption};
  ${FontWeights.Regular};
`

export const Modal = styled(ModalComponent)`
  padding: 32px;
  gap: 32px;

  ${Media.Phone`
    width: 100%;
    padding: 20px;
  `}
`
