import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0px 40px 0px;

  ${Media.Tablet`
    padding: 24px 16px;
  `}
  ${Media.IphoneSE`
     padding: 12px 16px 20px 16px;
  `}
`

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  border-radius: 20px;
  background-color: ${Colors.Blue20};
  color: ${Colors.Blue50};
  padding: 4px 16px 4px 8px;
  ${FontWeights.DemiBold};
  ${Typography.ButtonS};
  cursor: pointer;
  &:hover {
    background-color: ${Colors.Blue30};
    color: ${Colors.Blue60};
  }
  transition: background-color 0.3s ease;
`

export const ChevronLeft = styled.span`
  transform: rotate(180deg);
`

export const ButtonContainer = styled.div`
  display: flex;

  ${Media.Phone`
     position: fixed;
     left: 16px;
     right: 16px;
     bottom: 16px;
     z-index: 3;
  `}
`

export const ActionButton = styled(Button)`
  flex-grow: 1;
`
