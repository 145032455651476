import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import axios, { AxiosError } from 'axios'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import Button from 'builder/components/Button'
import {
  JobSuggestionType,
  CompanySuggestionType,
  InterviewBehaviour,
  behaviourType,
} from 'builder/modules/interview/types'
import { actions } from 'builder/modules/interview/interviewModule'
import {
  ResponseCreateAccount,
  useCreateAccount,
} from 'builder/views/Interview/hooks/useCreateAccount'
import { useCreateMagicLink } from 'builder/views/Interview/hooks/useCreateMagicLink'
import { TextField } from 'builder/components/TextField'
import { generateQueryString } from 'builder/components/FindJob/utils'
import { isValidEmail, isValidName } from 'builder/views/Interview/utils/validation'
import Checkbox from 'builder/components/Checkbox'
import { CompanyName } from 'builder/views/Interview/hooks/useCompanyNames'
import { useUser } from 'builder/hooks/useUser'
import { JobTitleAsyncAutosuggest } from '../../JobTitleAsyncAutosuggest/JobTitleAsyncAutosuggest'
import {
  Title,
  LeftBottom,
  CustomEditor,
  Subtitle,
  InputSection,
  PillLabel,
  ProgressWrapper,
  ActiveProgressDot,
  ProgressDot,
  LeftTop,
  ButtonWrapper,
  BodyText,
  CheckWrapperDesktop,
  CheckWrapperMobile,
} from './styles'
import { CompanyNameAsyncAutosuggest } from './components/CompanyNameAsyncAutosuggest/CompanyNameAsyncAutosuggest'
import { SignUpCopyright } from './components/SignUpCopyright/SignUpCopyright'

const TRANSLATION = 'builder.interview_prep_view.start_screen'

interface Props {
  titleLabel: string
  subtitleLabel: string
  confirmButtonLabel: string
  behaviour: behaviourType
}

export const InterviewStartPage = ({
  titleLabel,
  subtitleLabel,
  confirmButtonLabel,
  behaviour,
}: Props) => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const showCompanyField = behaviour === InterviewBehaviour.AMBITIOUS

  const [jobTitle, setJobTitle] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [selectedCompany, setSelectedCompany] = useState<CompanyName>()
  const [showError, setShowError] = useState(false)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const [selectedJobTitle, setSelectedJobTitle] = useState<JobSuggestionType>()
  const [selectedCheck, setSelectedCheck] = useState(true)

  const [showMagicLinkText, setShowMagicLinkText] = useState(false)

  const { mutateAsync: createAccount } = useCreateAccount()
  const { mutateAsync: createMagicLink } = useCreateMagicLink()

  const [step, setStep] = useState('initial')
  const [isLoading, setIsLoading] = useState(false)

  const user = useUser()

  const isMissingRequiredFields = () => {
    if (step === 'initial') {
      return !selectedJobTitle || (showCompanyField && !companyName)
    }
    if (step === 'email' || step === 'magic_link') {
      return !isValidName(name) || !isValidEmail(email) || !selectedCheck
    }
    return false
  }

  const onJobSuggestionSelected = (suggestion: JobSuggestionType) => {
    setSelectedJobTitle(suggestion)
    setJobTitle(suggestion.text)
    dispatch(actions.setJobTitleId(suggestion.id))
    dispatch(actions.setJobTitle(suggestion.text))
  }

  const onCompanySuggestionSelected = (suggestion: CompanySuggestionType) => {
    setCompanyName(suggestion.text)
    setSelectedCompany(suggestion)
  }

  const generateRedirectPageLink = () => {
    const params = {
      jobTitleId: selectedJobTitle?.id,
      jobTitleText: selectedJobTitle?.text,
      companyName: companyName || undefined,
      isRealCompany: !!selectedCompany,
      typeOfQuestions: behaviour,
      companyLogo: selectedCompany?.logo,
    }
    const queryStrings = generateQueryString(params)

    return `/interview-preparation/ready?${queryStrings}`
  }

  const redirectToReadyPage = () => {
    return navigate(generateRedirectPageLink(), { replace: true })
  }

  const createNewAccount = async () => {
    try {
      setIsLoading(true)
      const hasInterviewPrepDiscount =
        new URLSearchParams(window.location.search).get('plan_price_discount') === 'true'

      const response = await createAccount({
        email,
        firstName: name,
        interviewPrepDiscount: hasInterviewPrepDiscount,
      })

      if (response.success) {
        navigate(generateRedirectPageLink(), { replace: true })
        return true
      }
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        (error as AxiosError<ResponseCreateAccount>).response?.data?.errorCode === 'email_taken'
      ) {
        return false
      } else {
        throw error
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleMagicLink = async () => {
    const isNewAccount = await createNewAccount()

    trackInternalEvent('click_generate_interview', {
      label: 'interview_prep',
      parameter: 'log in',
    })

    if (!isNewAccount) {
      setIsLoading(true)

      await createMagicLink({
        email: email,
        redirect_to: `/app${generateRedirectPageLink()}`,
      })

      setIsLoading(false)

      navigate(`/interview-preparation/start/page/check-your-inbox?email=${email}`, {
        replace: true,
      })
    }
  }

  const onConfirmationAction = async () => {
    const hasMissingValue = isMissingRequiredFields()
    setShowError(hasMissingValue)

    if (hasMissingValue) {
      return
    }

    switch (step) {
      case 'initial':
        setStep('email')
        setShowError(false)
        trackInternalEvent('click_generate_interview', {
          label: 'interview_prep',
          parameter: 'welcome',
        })

        return
      case 'email':
        setShowMagicLinkText(false)

        const isNewAccount = await createNewAccount()

        if (!isNewAccount) {
          setShowMagicLinkText(true)
        }

        trackInternalEvent('click_generate_interview', {
          label: 'interview_prep',
          parameter: 'email',
        })
    }
  }

  const renderBody = () => {
    switch (step) {
      case 'initial':
        return (
          <>
            <Title>{titleLabel}</Title>
            <Subtitle>{subtitleLabel}</Subtitle>
            <CustomEditor>
              <InputSection>
                <JobTitleAsyncAutosuggest
                  jobTitle={jobTitle}
                  onChange={e => setJobTitle(e.target.value)}
                  onChangeSuggestion={onJobSuggestionSelected}
                  hasError={showError && selectedJobTitle === undefined}
                  trackingParameter="test"
                  placeholder={i18n.t(`${TRANSLATION}.placeholder_job_title`)}
                />
                {showCompanyField && (
                  <CompanyNameAsyncAutosuggest
                    companyName={companyName}
                    onChange={e => {
                      setCompanyName(e.target.value)
                      setSelectedCompany(undefined)
                    }}
                    onChangeSuggestion={onCompanySuggestionSelected}
                    hasError={showError && !selectedCompany}
                    placeholder={i18n.t(`${TRANSLATION}.placeholder_company`)}
                  />
                )}
              </InputSection>
            </CustomEditor>
          </>
        )
      case 'email':
        return (
          <>
            <Title>{i18n.t(`${TRANSLATION}.second_step_title`)}</Title>
            <Subtitle>{i18n.t(`${TRANSLATION}.second_step_subtitle`)}</Subtitle>
            <CustomEditor>
              <InputSection>
                <TextField
                  label={i18n.t(`${TRANSLATION}.name`)}
                  onChange={e => setName(e.target.value)}
                  error={showError && !isValidName(name) && i18n.t(`${TRANSLATION}.error_name`)}
                />

                <TextField
                  label={i18n.t(`${TRANSLATION}.email`)}
                  type="email"
                  onChange={e => setEmail(e.target.value)}
                  error={showError && !isValidEmail(email) && i18n.t(`${TRANSLATION}.error_email`)}
                />

                {showMagicLinkText && (
                  <div>
                    <BodyText>{i18n.t(`${TRANSLATION}.email_registered`)}</BodyText>
                    <BodyText>{i18n.t(`${TRANSLATION}.sign_in_link`)}</BodyText>
                  </div>
                )}
              </InputSection>
            </CustomEditor>
          </>
        )
    }
  }

  return (
    <>
      <LeftTop>
        <PillLabel>{i18n.t(`${TRANSLATION}.sample_interview`)}</PillLabel>
        {renderBody()}
      </LeftTop>
      <LeftBottom>
        {step === 'email' && (
          <CheckWrapperMobile>
            <Checkbox
              label={<SignUpCopyright />}
              checked={selectedCheck}
              onChange={() => setSelectedCheck(prevState => !prevState)}
              invalid={showError && !selectedCheck}
            />
          </CheckWrapperMobile>
        )}
        <ButtonWrapper>
          <ProgressWrapper>
            <ActiveProgressDot />
            <ProgressDot />
          </ProgressWrapper>
          <Button
            onClick={
              user
                ? redirectToReadyPage
                : showMagicLinkText
                ? handleMagicLink
                : onConfirmationAction
            }
            isLoading={isLoading}
            disabled={isLoading}
            isDisabled={showError && isMissingRequiredFields()}
          >
            {showMagicLinkText ? i18n.t(`${TRANSLATION}.log_in`) : confirmButtonLabel}
          </Button>
        </ButtonWrapper>

        {step === 'email' && (
          <CheckWrapperDesktop>
            <Checkbox
              label={<SignUpCopyright />}
              checked={selectedCheck}
              onChange={() => setSelectedCheck(prevState => !prevState)}
              invalid={showError && !selectedCheck}
            />
          </CheckWrapperDesktop>
        )}
      </LeftBottom>
    </>
  )
}
