import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import { Icon56 } from 'builder/components/Icon'
import * as SimpleForm from 'builder/components/SimpleForm'
import { FileInput } from 'builder/components/PhotoUploadField/styles'
import SelectResumeModal from 'builder/views/ResumeDistributionView/components/SelectResumeModal'

import { MouseClickEvent } from 'builder/modules/ui'
import { DocumentTypes, FetchStatuses } from 'builder/modules/constants'
import { actions } from 'builder/modules/autoApply/autoApplyModule'
import { BriefDocumentType, selectors, actions as documentActions } from 'builder/modules/panel'
import { AutoApplyForm } from 'builder/modules/autoApply/types'
import { useSelectResume } from '../../hooks/useSelectResume'
import { useUploadResume } from '../../hooks/useUploadResume'
import { useStepsConfig } from '../../hooks/useStepsConfig'
import { ALLOWED_FILES, StartCompleteScreenRoute } from '../../constants'

import { FillWarning, Title, TitleWrapper, FormContainer } from '../styles'
import {
  Box,
  UploadResumeImage,
  BoxWrapper,
  Container,
  SelectionTitle,
  Description,
  UploadFromDevice,
  FadeUpload,
  UploadResumeContainer,
  InfoSection,
  InfoSectionIcon,
  InfoSectionDescription,
  InfoSectionDescriptionBold,
  Wrapper,
} from './styles'
import { TRANSLATION } from './constants'
import { ResumeSelection } from './ResumeSelection'

export const ResumeSelectView = () => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const stepsConfig = useStepsConfig()

  const documentsStatus = useTypedSelector(selectors.documentsStatus)
  const trDocuments = useTypedSelector(store =>
    selectors.documentsByType(store, DocumentTypes.trDocument),
  )

  const onResumeSuccess = (data: AutoApplyForm) => {
    dispatch(actions.updateForm(data))
  }

  const { mutateAsync: selectResume } = useSelectResume(onResumeSuccess)
  const { mutateAsync: uploadResume } = useUploadResume(undefined, onResumeSuccess)
  const [showResumeSelect, setShowResumeSelect] = useState(false)

  const onNextStep = () => {
    navigate(`/auto-apply/${stepsConfig[StartCompleteScreenRoute.RESUME_SELECT].next}`)
  }

  const handleUploadClick = (event: MouseClickEvent) => {
    event.stopPropagation()
    trackInternalEvent('submit_resume', {
      label: 'total_job_search_solution',
      type: 'upload',
    })
  }

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedResume = event.target.files?.[0]
    if (selectedResume) {
      await uploadResume(selectedResume)
      onNextStep()
    }
  }

  const onBuilderResumeSelect = (resumeDetails: BriefDocumentType) => {
    let type: 'app' | 'TR'
    if (resumeDetails.type === DocumentTypes.trDocument) {
      type = 'TR'
      selectResume({ trResumeId: resumeDetails.id })
    } else {
      type = 'app'
      selectResume({ resumeId: resumeDetails.id })
    }

    trackInternalEvent('submit_resume', { type, label: 'total_job_search_solution' })
    onNextStep()
  }

  useEffect(() => {
    trackInternalEvent('aa_resume_selection', {
      label: 'total_job_search_solution',
    })
    dispatch(documentActions.fetchDocuments())
  }, [dispatch])

  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <Title>{i18n.t(`${TRANSLATION}.title`)}</Title>
          <FillWarning>{i18n.t(`${TRANSLATION}.sub_title`)}</FillWarning>
        </TitleWrapper>
        <FormContainer>
          <SimpleForm.Form>
            <BoxWrapper>
              <ResumeSelection
                isLoading={documentsStatus === FetchStatuses.loading}
                hasTrDocuments={!!trDocuments.length}
                setShowResumeSelect={setShowResumeSelect}
              />
              <Box>
                <UploadFromDevice htmlFor="auto-apply-file-resume-upload">
                  <UploadResumeContainer>
                    <FadeUpload />
                    <UploadResumeImage>
                      <Icon56.UploadResume />
                    </UploadResumeImage>
                  </UploadResumeContainer>
                  <SelectionTitle withMargin>
                    {i18n.t(`${TRANSLATION}.upload_resume`)}
                  </SelectionTitle>
                  <Description withMargin>{i18n.t(`${TRANSLATION}.existing_resume`)}</Description>
                  <FileInput
                    type="file"
                    onClick={handleUploadClick}
                    onChange={handleFileInputChange}
                    accept={ALLOWED_FILES}
                    id="auto-apply-file-resume-upload"
                  />
                </UploadFromDevice>
              </Box>
            </BoxWrapper>
          </SimpleForm.Form>
        </FormContainer>
        <InfoSection>
          <InfoSectionIcon />
          <InfoSectionDescription>
            {i18n.t(`${TRANSLATION}.time`)}
            <InfoSectionDescriptionBold>
              {i18n.t(`${TRANSLATION}.time_minutes`)}
            </InfoSectionDescriptionBold>
          </InfoSectionDescription>
        </InfoSection>
      </Container>
      {showResumeSelect && (
        <SelectResumeModal
          title={i18n.t(`${TRANSLATION}.modal_title`)}
          onClose={() => setShowResumeSelect(false)}
          submitSelectedResume={onBuilderResumeSelect}
          isTotalJobSearchFlow
          isDocumentLoaded
        />
      )}
    </Wrapper>
  )
}
