import { useLayoutEffect, useRef, useState, useCallback, useMemo } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { reflow } from 'builder/utils/reflow'
import { Icon24, Icon20 } from 'builder/components/Icon'
import { LayoutCustomization, TextCustomization } from '../ResumeSidebar'
import { TemplateSelectionMobile } from '../ResumeSidebar/components/TemplateSelectionMobile/TemplateSelectionMobile'
import {
  Overlay,
  Sheet,
  TabLabelContainer,
  IconContainer,
  TabContainer,
  CustomTab,
  CustomTabs,
  ContentContainer,
} from './styles'
import { Tab, TemplateCustomizationSheetProps, TabType } from './types'

export const TemplateCustomizationSheet = ({ onClose, type }: TemplateCustomizationSheetProps) => {
  const { i18n } = useI18n()
  const [isVisible, setVisible] = useState(false)
  const overlay = useRef<HTMLDivElement | null>(null)
  const [selectedTab, setSelectedTab] = useState<TabType>(TabType.templates)

  const tabs: Tab[] = useMemo(
    () => [
      {
        label: (
          <TabLabelContainer>
            <IconContainer>
              <Icon24.Grid />
            </IconContainer>
            {i18n.t('builder.resume_editor.document_customization.sidebar.templates_tab.header')}
          </TabLabelContainer>
        ),
        id: TabType.templates,
        content: <TemplateSelectionMobile type={type} />,
      },
      {
        label: (
          <TabLabelContainer>
            <IconContainer>
              <Icon24.FontSize />
            </IconContainer>
            {i18n.t('builder.resume_editor.document_customization.sidebar.text_tab.header')}
          </TabLabelContainer>
        ),
        id: TabType.text,
        content: <TextCustomization />,
      },
      {
        label: (
          <TabLabelContainer>
            <IconContainer>
              <Icon20.Documents />
            </IconContainer>
            {i18n.t('builder.resume_editor.document_customization.sidebar.layout_tab.header')}
          </TabLabelContainer>
        ),
        id: TabType.layout,
        content: <LayoutCustomization />,
      },
    ],
    [i18n, type],
  )

  const handleOverlayClick = useCallback(
    event => onClose && event.target === overlay.current && onClose(),
    [onClose],
  )

  useLayoutEffect(() => {
    if (overlay.current) reflow(overlay?.current)
    setVisible(true)
  }, [])

  const handleTabClick = useCallback((id: TabType) => {
    setSelectedTab(id)
    trackInternalEvent('change_customization_tab', {
      label: id,
    })
  }, [])

  const selectedTabContent = useMemo(
    () => tabs.find(tab => tab.id === selectedTab)?.content,
    [tabs, selectedTab],
  )

  const tabElements = useMemo(
    () =>
      tabs.map(tab => (
        <CustomTab
          key={tab.id}
          isActive={tab.id === selectedTab}
          onClick={() => handleTabClick(tab.id)}
        >
          {tab.label}
        </CustomTab>
      )),
    [tabs, selectedTab, handleTabClick],
  )

  return (
    <Overlay ref={overlay} onClick={handleOverlayClick}>
      <Sheet isVisible={isVisible}>
        <TabContainer>
          <CustomTabs>{tabElements}</CustomTabs>
        </TabContainer>
        <ContentContainer>{selectedTabContent}</ContentContainer>
      </Sheet>
    </Overlay>
  )
}
