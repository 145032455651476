import styled, { css, keyframes } from 'styled-components'
import Colors from 'builder/styles/colors'
import Shadows from 'builder/styles/shadows'
import Sizes from 'builder/styles/sizes'
import Media from 'builder/styles/media'
import { Typography, FontWeights } from 'builder/styles/typography'

const scrollbar = css`
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`
export const ChatBotContainer = styled.div`
  position: fixed;
  background: ${Colors.White};
  border: 1px solid ${Colors.Neutral10};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  ${Shadows.lightWithBorder.high};
  z-index: 99999;
  overflow: hidden;

  &.default {
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 544px;
    height: 620px;
  }

  &.floating {
    bottom: 15px;
    right: 24px;
    width: 544px;
    min-height: 547px;
    max-height: 95%;
    height: auto;
  }

  &.fullscreen {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    overflow: hidden;
  }
`
export const ChatBotForm = styled.form`
  position: relative;
  height: 100%;
  max-width: 736px;
  margin: 0 auto;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`
export const ChatBotHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0;
  padding: 24px 16px 16px 16px;
`
export const Title = styled.h2`
  font-family: 'TT Commons', system-ui, sans-serif;
  ${Typography.Subhead};
  ${FontWeights.Medium};
`
export const ChatBotBody = styled.div`
  position: relative;
  flex: 1;
  overflow-y: auto;
  padding: 0 16px 16px 16px;
  height: 100%;
  ${scrollbar}
`
export const Message = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  margin-bottom: 10px;

  .assistant {
    background-color: ${Colors.Indigo10};
    color: ${Colors.Neutral90};
    padding: 8px 12px;
    border-radius: 12px;
    align-self: flex-start;

    a {
      color: ${Colors.Indigo50};
    }
  }

  .user {
    background-color: ${Colors.Neutral5};
    color: ${Colors.Neutral90};
    padding: 8px 12px;
    border-radius: 12px;
    align-self: flex-end;
    margin-left: auto;
  }
`
export const ChatBotFooter = styled.div`
  position: relative;
  padding: 0 16px 24px 16px;
`
export const PromptInput = styled.input`
  border: 1px solid ${Colors.Indigo10};
  width: 100%;
  height: 64px;
  border-radius: 16px;
  padding-top: ${Sizes['2XS']};
  padding-bottom: ${Sizes['2XS']};
  padding-left: ${Sizes.M};
  padding-right: ${Sizes['2XL']};

  &:focus {
    outline: ${Colors.Indigo50} auto 1px;
  }
`
export const SubmitButton = styled.button`
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  cursor: pointer;
`
export const FlexBox = styled.div<{ gap?: string; justifyContent?: string; alignItems?: string }>`
  display: flex;
  gap: ${({ gap }) => gap || '0'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
`
const SpinKeyframe = keyframes`
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
`
export const SpinAnimation = styled.span`
  svg {
    width: 100%;
    height: 100%;
    animation: ${SpinKeyframe} 2s linear infinite;
  }
  img {
    animation: ${SpinKeyframe} 2s linear infinite;
  }
`
export const Avatar = styled.span<{ visibility?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${Colors.Indigo10};
  flex-shrink: 0;
  padding: 5px;
  visibility: ${({ visibility }) => visibility || 'visible'};
`
export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${Colors.Red50};
  background: ${Colors.Red10};
  border: 1px solid ${Colors.Red10};
  border-radius: 16px;
  padding: 16px;
  ${Typography.Caption};
`
export const Trigger = styled.button`
  background-color: ${Colors.White};
  color: ${Colors.Neutral70};
  border: 2px solid ${Colors.Indigo10};
  padding: ${Sizes['2XS']} ${Sizes.M};
  cursor: pointer;
  z-index: 999;
  ${FontWeights.Regular};
  ${Shadows.lightWithBorder.high};

  &:hover {
    border-color: ${Colors.Indigo50};
  }

  &.default {
    position: fixed;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 400px;
    height: 64px;
    border-radius: 16px;

    & img {
      width: 24px;
      height: 24px;
    }
  }

  &.minimal {
    background-color: ${Colors.White};
    position: fixed;
    bottom: 15px;
    right: 24px;
    width: 72px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    ${Media.Phone`bottom: 100px;`}

    & img {
      width: 32px;
      height: 32px;
    }
  }

  &.navitem {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    ${Media.Phone`bottom: 100px;`}

    & img {
      width: 24px;
      height: 24px;
    }
  }
`
export const IconWrapper = styled.span<{ fill?: string; stroke?: string; hidden?: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'inline-block')};
  border: none;
  cursor: pointer;

  svg path {
    fill: ${({ fill }) => fill || 'currentColor'};
    stroke: ${({ stroke }) => stroke || 'none'};
    transition: fill 0.3s ease;
  }

  &:hover svg path {
    fill: ${Colors.Blue50};
    stroke: ${({ stroke }) => (stroke ? Colors.Blue50 : 'none')};
  }
`
export const ScrollIndicator = styled.button`
  position: sticky;
  background: white;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid ${Colors.Neutral10};
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  ${Shadows.lightWithBorder.low};
`

// Markdown styles
export const Link = styled.a`
  color: ${Colors.Indigo50};
  ${FontWeights.Medium};

  &:hover {
    color: ${Colors.Indigo70};
  }
`
export const Strong = styled.strong`
  ${FontWeights.Medium};
`
export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 8px 0 8px 36px;
  padding: 0;
`
export const Heading = styled.h3`
  margin-top: 20px;
  margin-bottom: 8px;
  ${FontWeights.Medium};
`
