import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { Modal } from './styles'

export const TRANSLATION = 'builder.auto_apply.confirm_modal'

type Props = {
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmModal = ({ onClose, onConfirm }: Props) => {
  const { i18n } = useI18n()

  const onCloseModal = () => {
    trackInternalEvent('tjss_closing_modal_clicking_close_button', {
      label: 'total_job_search_solution',
    })
    onClose()
  }

  const onCancel = () => {
    trackInternalEvent('tjss_closing_modal_clicking_cancel', {
      label: 'total_job_search_solution',
    })
    onClose()
  }

  const onConfirmModal = () => {
    trackInternalEvent('tjss_closing_modal_clicking_yes', {
      label: 'total_job_search_solution',
    })
    onConfirm()
  }

  const onInit = () => {
    trackInternalEvent('tjss_closing_modal', {
      label: 'total_job_search_solution',
    })
  }

  return (
    <Modal
      title={i18n.t(`${TRANSLATION}.title`)}
      description={i18n.t(`${TRANSLATION}.content`)}
      cancelButton={i18n.t(`${TRANSLATION}.cancel`)}
      successButton={i18n.t(`${TRANSLATION}.confirm`)}
      onInit={onInit}
      onClose={onCloseModal}
      onCancel={onCancel}
      onSuccess={onConfirmModal}
    />
  )
}
