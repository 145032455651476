import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import Icon from 'builder/components/Icon'
import { ActionMenu, Action, ActionMenuPosition } from 'builder/components/ActionMenu'
import { actions, selectors } from 'builder/modules/coRegistration'
import { actions as uiActions } from 'builder/modules/ui'
import { DocumentTypes, FORMATS } from 'builder/modules/constants'

export interface Props {
  documentId: number
  documentType: DocumentTypes
  position?: ActionMenuPosition
  isTRdocument?: boolean
  isResumeType: boolean
  onClose: () => void
  onEdit: () => void
  isDocxFormat?: boolean
  onDownload: (args: { id: number; type: DocumentTypes; format: FORMATS; source: string }) => void
}

export const TRDocumentActionMenu = ({
  documentId,
  documentType,
  onClose,
  onDownload,
  onEdit,
  position,
  isDocxFormat,
  isResumeType,
}: Props) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const isCoRegSupported = useSelector(selectors.isSupported)
  const isReadyToCoReg = useSelector(selectors.isResumeReady)
  const isResume = documentType === DocumentTypes.resume

  const handleOpenCoReg = useCallback(
    () =>
      dispatch(actions.open({ resumeId: documentId, source: 'additional_functionality_button' })),
    [dispatch, documentId],
  )

  const handleDownloadDocument = useCallback(
    ({ format = FORMATS.tr_pdf }) => {
      onDownload({
        id: documentId,
        type: documentType,
        format,
        source: 'additional_functionality_button',
      })
    },
    [onDownload, documentId, documentType],
  )

  const handleCombineClick = useCallback(() => {
    const resumeId = documentType === DocumentTypes.resume ? documentId : null
    const coverLetterId = documentType === DocumentTypes.coverLetter ? documentId : null

    dispatch(uiActions.openMergeModal({ resumeId, coverLetterId }))
    trackInternalEvent('open_documents_merging_modal')
  }, [dispatch, documentType, documentId])

  const menuActions: Array<Action | false> = useMemo(() => {
    const docxDownLoadAction = {
      text: i18n.t('builder.dashboard.download_docx'),
      icon: <Icon.FileWord />,
      onClick: () => handleDownloadDocument({ format: FORMATS.tr_doc }),
    }

    const resumeActions = [
      isResumeType && {
        text: i18n.t('builder.resume_editor.edit'),
        icon: <Icon.Edit />,
        onClick: onEdit,
      },
      isCoRegSupported && {
        divider: true,
        text: i18n.t('builder.coreg.dropdown_action'),
        icon: <Icon.Share />,
        marked: isReadyToCoReg,
        onClick: handleOpenCoReg,
      },
    ]

    const coverLetterActions: Action[] = [
      {
        text: i18n.t('builder.resume_editor.combine_with'),
        icon: <Icon.Combine />,
        onClick: handleCombineClick,
      },
    ]

    return [
      isDocxFormat ? docxDownLoadAction : false,
      ...(isResume ? resumeActions : coverLetterActions),
    ]
  }, [
    i18n,
    onEdit,
    isCoRegSupported,
    isReadyToCoReg,
    handleOpenCoReg,
    handleCombineClick,
    isDocxFormat,
    isResume,
    handleDownloadDocument,
    documentId,
    isResumeType,
  ])

  return (
    <ActionMenu
      {...{
        position,
        onClose,
      }}
      actions={menuActions}
    />
  )
}
