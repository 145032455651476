import { useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchRecommendations } from '../../utils'

export const useGetRecommendationJob = (enabled: boolean, onlyAutoApplyJobs = false) => {
  return useQuery(
    ['useGetRecommendationJob'],
    async () => {
      const { jobs, stats } = await fetchRecommendations(0, {
        onlyAutoApplyJobs,
        onlyRemoteJobs: false,
        postedWithinDays: 0,
        radius: 0,
      })
      return { jobs, stats }
    },
    {
      enabled,
      useErrorBoundary: false,
    },
  )
}

export const useInvalidateGetRecommendationJobQuery = () => {
  const queryClient = useQueryClient()

  const invalidate = () => {
    queryClient.invalidateQueries(['useGetRecommendationJob'])
  }

  return { invalidate }
}
