import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Button from 'builder/components/Button'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { gradient } from 'builder/styles/keyframes'

const { White, Neutral10, Neutral15, Blue20, Blue30, Blue50, Blue60 } = Colors

export const PageContainer = styled.div`
  background: ${Neutral10};
  display: flex;
  justify-content: center;
  min-height: 100vh;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 928px;
  ${Media.Tablet`
    width: 100%;
  `}
`

/* ---------- Header Styles ---------- */
export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0px 40px 0px;
  ${Media.Tablet`
    padding: 24px 16px;
  `}
  ${Media.IphoneSE`
     padding: 12px 16px 20px 16px;
  `}
`

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  border-radius: 20px;
  background-color: ${Blue20};
  color: ${Blue50};
  padding: 4px 16px 4px 8px;
  ${FontWeights.DemiBold};
  ${Typography.ButtonS};
  cursor: pointer;
  &:hover {
    background-color: ${Blue30};
    color: ${Blue60};
  }
  transition: background-color 0.3s ease;
`

export const ChevronLeft = styled.span`
  transform: rotate(180deg);
`

export const DownloadMenu = styled.div`
  display: flex;
  ${Media.Phone`
     position: fixed;
     left: 16px;
     right: 16px;
     bottom: 16px;
  `}
`

export const DownloadButton = styled(Button)`
  flex-grow: 1;
`

export const Actions = styled.div`
  position: relative;
  display: flex;
  margin-left: 8px;
`
export const ActionsButton = styled(Button)`
  width: 48px;
  padding: 0;
`

/* ---------- Resume Loader Styles ---------- */
export const LoaderContainer = styled.div`
  animation: ${gradient} 1s linear infinite;
  background: ${Colors.Neutral10};
  background: linear-gradient(to right, ${Neutral10} 5%, ${Neutral15} 15%, ${Neutral10} 25%);
  width: 100%;
  margin-bottom: 32px;
`

export const Loader = styled.img`
  height: 100%;
  width: 100%;
`

/* ---------- Resume Preview Styles ---------- */
export const ResumeImg = styled.img`
  margin-bottom: 32px;
  width: 100%;
  background-color: white;
`

/* ---------- No Preview Styles ---------- */
export const NoPreviewContainer = styled.div`
  background: ${Neutral10};
  width: 100%;
  ${Media.Tablet`
    padding: 0px 16px 32px 16px;
  `}
`

export const NoPreviewBackground = styled.div`
  background: ${White};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 72px 35px 141px 35px;
`

export const NoPreviewText = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
  text-align: center;
  ${Typography.S};
  ${FontWeights.Medium};
`

export const NoPreviewImg = styled.img`
  width: 162px;
  height: 172px;
  ${Media.Phone`
     width: 98px;
     height: 104px;
  `}
`

export const NoPreviewDownload = styled(DownloadButton)`
  ${Media.Phone`
     display: none;
  `}
`

export const ActionButton = styled(Button)``
