export const removeNull = (obj = {}) =>
  Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null))

export const getHost = (kind?: string, name?: string) => {
  if (kind === 'RIO') {
    return 'Resume.io'
  } else if (kind === 'CIO') {
    return 'Career.io'
  } else if (kind === 'TR') {
    return 'Top Resume'
  } else {
    return name
  }
}
