import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { CoverLetter, ExpertDoc } from 'packages/types'
import { Icon20 } from 'builder/components/Icon'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { BriefResume, selectors, actions, BriefExpertDoc } from 'builder/modules/panel'
import ModalOverlay from 'builder/components/ModalOverlay'
import { i18n } from 'builder/utils/i18n'
import { DocumentPreview } from 'builder/components/Panel/Document/styles'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import {
  DESKTOP_PREVIEW_WIDTH,
  PHONE_PREVIEW_WIDTH,
} from 'builder/components/Panel/Document/constants'
import { formatDate } from 'builder/utils/formatDate'
import { DocumentTypes } from 'builder/modules/constants'
import CreateResumeImg from '../../assets/img/createResume.jpg'
import * as Styled from './styles'

interface IProps {
  onClose: () => void
  onCreateResume?: () => void
  submitSelectedResume: (_: BriefResume | BriefExpertDoc) => void
  isTotalJobSearchFlow?: boolean
  title?: string
  viewOnly?: DocumentTypes.resume | DocumentTypes.trDocument
  isDocumentLoaded?: boolean
}

const SelectResumeModal = ({
  onClose,
  onCreateResume,
  submitSelectedResume,
  isTotalJobSearchFlow,
  title,
  viewOnly,
  isDocumentLoaded,
}: IProps) => {
  const { isPhone } = useMediaQueries()
  const dispatch = useDispatch()

  const resumeList = useTypedSelector(store =>
    selectors.documentsByType(store, DocumentTypes.resume),
  )
  const trDocuments = useTypedSelector(store =>
    selectors.documentsByType(store, DocumentTypes.trDocument),
  )
  const [selectedResumeDetails, setSelectedResumeDetails] = useState<BriefResume | null>(null)

  useEffect(() => {
    if (!isDocumentLoaded) {
      dispatch(actions.fetchDocuments())
    }
  }, [dispatch, isDocumentLoaded])

  const handleClose = () => {
    onClose()
  }

  const previewUrl = useCallback(
    document => {
      return formatDocumentPreviewUrl(document, {
        size: isPhone ? PHONE_PREVIEW_WIDTH : DESKTOP_PREVIEW_WIDTH,
      })
    },
    [isPhone],
  )

  const getThumbnailImg = (document: BriefResume | CoverLetter | ExpertDoc) => {
    if (document.type === DocumentTypes.trDocument && document?.thumbnail?.url) {
      return document?.thumbnail?.url
    }
    return previewUrl(document)
  }

  const getDocumentName = (document: BriefResume | CoverLetter | ExpertDoc) => {
    if (document.type === DocumentTypes.trDocument && document?.title) {
      return document?.title
    }
    return document.name || i18n.t('builder.dashboard.untitled')
  }

  const isExpertDocThumbnailPreview = (document: BriefResume | CoverLetter | ExpertDoc) => {
    if (document.type === DocumentTypes.trDocument && !document?.thumbnail?.url) {
      return true
    }
    return false
  }

  const isResumeSelected = (resume: BriefResume | CoverLetter | ExpertDoc) => {
    return selectedResumeDetails?.id === resume?.id && selectedResumeDetails?.type === resume.type
  }

  const handleResumeClick = (selectedResume: BriefResume) => {
    setSelectedResumeDetails(selectedResume)
  }

  const handleConfirm = () => {
    if (selectedResumeDetails) {
      submitSelectedResume(selectedResumeDetails)
      onClose()
    }
  }

  const handleCreateResumeClick = () => {
    if (isTotalJobSearchFlow) {
      trackInternalEvent('click_new_resume', {
        label: 'total_job_search_solution',
      })
    }
    if (onCreateResume) {
      onCreateResume()
    }
    dispatch(actions.createDocument({ type: DocumentTypes.resume }))
  }

  const renderResumeCard = (resume: BriefResume | CoverLetter | ExpertDoc, index: number) => {
    return (
      <Styled.ResumeCard
        key={`resume-${index}`}
        isSelected={isResumeSelected(resume)}
        onClick={() => handleResumeClick(resume as BriefResume)}
      >
        {resume.type === DocumentTypes.trDocument && (
          <Styled.TrResumeLabel>
            <Styled.TrResumeLabelIcon />
            <Styled.TrResumeLabelText>
              {i18n.t(`builder.resume_distribution.landing.modal.tr_banner`)}
            </Styled.TrResumeLabelText>
          </Styled.TrResumeLabel>
        )}
        <Styled.DocumentPreviewContainer>
          <DocumentPreview
            showUnavailablePreview={isExpertDocThumbnailPreview(resume)}
            src={getThumbnailImg(resume)}
          />
        </Styled.DocumentPreviewContainer>
        <Styled.DescriptionResumeCard>
          <Styled.NameResume>
            {getDocumentName(resume)}, {resume?.jobTitle}
          </Styled.NameResume>
          <Styled.DateResume>
            {i18n.t(`builder.resume_distribution.landing.modal.updated_at`, {
              dateString: formatDate(
                new Date(resume?.updatedAt || resume?.createdAt),
                'dd MMM, HH:mm',
              ),
            })}
          </Styled.DateResume>
        </Styled.DescriptionResumeCard>
      </Styled.ResumeCard>
    )
  }

  const renderResumes = () => {
    if (viewOnly === DocumentTypes.resume) {
      return <>{resumeList?.map((resume, index: number) => renderResumeCard(resume, index))}</>
    }
    if (viewOnly === DocumentTypes.trDocument) {
      return <>{trDocuments?.map((resume, index: number) => renderResumeCard(resume, index))}</>
    }
    return (
      <>
        {trDocuments?.map((resume, index: number) => renderResumeCard(resume, index))}
        {resumeList?.map((resume, index: number) => renderResumeCard(resume, index))}
      </>
    )
  }

  return (
    <>
      <ModalOverlay
        onClick={handleClose}
        backgroundOpacity={0.6}
        overlayFadeDuration={350}
        contentSlideDuration={350}
      >
        <Styled.Container>
          <Styled.Close onClick={handleClose}>
            <Icon20.Close />
          </Styled.Close>
          <Styled.Title>
            {title || i18n.t(`builder.resume_distribution.landing.modal.title`)}
          </Styled.Title>
          <Styled.ResumeList>
            {renderResumes()}
            <Styled.ResumeCard
              key="resume-create"
              isSelected={false}
              onClick={handleCreateResumeClick}
            >
              <Styled.DocumentPreviewContainer>
                <Styled.CreateResumeImage alt="create resume" src={CreateResumeImg} />
              </Styled.DocumentPreviewContainer>
              <Styled.DescriptionResumeCard>
                <Styled.NameResume>
                  {i18n.t(`builder.resume_distribution.landing.modal.create_resume`)}
                </Styled.NameResume>
              </Styled.DescriptionResumeCard>
            </Styled.ResumeCard>
          </Styled.ResumeList>
          <Styled.Footer>
            <Styled.CloseButton onClick={handleClose}>
              {i18n.t(`builder.resume_distribution.landing.modal.cancel`)}
            </Styled.CloseButton>
            <Styled.ConfirmButton onClick={handleConfirm}>
              {i18n.t(`builder.resume_distribution.landing.modal.confirm`)}
            </Styled.ConfirmButton>
          </Styled.Footer>
        </Styled.Container>
      </ModalOverlay>
    </>
  )
}

export default SelectResumeModal
