import { trackInternalEvent } from '@rio/tracking'
import { TooltipV2 } from 'builder/components/Tooltip'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTemplateCustomization } from '../../hooks/useTemplateCustomization'
import { useTextCustomization } from '../../hooks/useTextCustomization'
import { useUpdateTemplateSettings } from '../../hooks/useUpdateTemplateSettings'
import { SliderContainer, Slider, ValueContainer, Value } from './styles'
import { SliderHandle } from './SliderHandle'

// Converts the value to a user-friendly format [S - M - L]
const prettify = (value: number): string => {
  if (value === 0) return 'S'
  if (value === 0.5) return 'M'
  if (value === 1) return 'L'
  throw new Error('Invalid value')
}

export const SIZE = {
  min: 0,
  max: 1,
  step: 0.5,
}

export const SizeSlider = () => {
  const { supportsNewCustomization, templatesSettings, templateName } = useTemplateCustomization()
  const { size } = useTextCustomization()
  const { updateTemplateSettings } = useUpdateTemplateSettings()
  const { i18n } = useI18n()
  const { isAboveTablet } = useMediaQueries()

  const handleSizeChange = (sizeNew: number) => {
    if (!supportsNewCustomization) return
    if (!templateName || typeof sizeNew !== 'number' || !templatesSettings) return
    updateTemplateSettings({
      templatesSettings,
      templateName,
      settingsName: 'fontSize',
      value: sizeNew,
    })
    trackInternalEvent('change_resume_font_size', {
      old_value: prettify(size),
      new_value: prettify(sizeNew),
      template: templateName,
    })
  }

  return (
    <TooltipV2
      value={i18n.t(
        'builder.resume_editor.document_customization.sidebar.text_tab.size_customization_warning',
      )}
      width={250}
      multiline={true}
      position="bottom"
      isActive={!supportsNewCustomization}
    >
      <SliderContainer>
        {!isAboveTablet && (
          <ValueContainer>
            <Value>{prettify(0)}</Value>
          </ValueContainer>
        )}
        <Slider
          disabled={!supportsNewCustomization}
          defaultValue={0}
          value={size as number}
          step={SIZE.step}
          min={SIZE.min}
          max={SIZE.max}
          handle={SliderHandle}
          onChange={handleSizeChange}
          dots
        />
        {!isAboveTablet && (
          <ValueContainer>
            <Value>{prettify(1)}</Value>
          </ValueContainer>
        )}
        {isAboveTablet && (
          <ValueContainer>
            <Value>{prettify(size as number)}</Value>
          </ValueContainer>
        )}
      </SliderContainer>
    </TooltipV2>
  )
}
