import { useLocation, useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { Fragment } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import ModalOverlay from 'builder/components/ModalOverlay'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { useUser } from 'builder/hooks/useUser'
import { ButtonTheme } from 'builder/components/Button'
import {
  Container,
  Title,
  CloseButton,
  Modal,
  LogosContainer,
  LogoContainer,
  LogoTitle,
  Star,
  Documents,
  Kite,
  ItemsTitle,
  ItemContainer,
  TickContainer,
  Tick,
  ItemDescriptionContainer,
  ItemTitle,
  ItemDescription,
  Footer,
  Body,
  Items,
  CTA,
} from './styles'
import STAR from './assets/star.png'
import KITE from './assets/kite.png'
import DOCUMENTS from './assets/documents.png'
import { useTopResumeWelcomeModal } from './useTopResumeWelcomeModal'

/**
 * Dynamic cases for rendering CTA on the TopResumeWelcomeModal.
 *
 * Case 1:
 * If the user has a professionally written document and has access to resume distribution and
 * user is landed on /resumes or /tr-documents pages.
 * Then this case will be called: {showCase1}
 *
 * Case 2:
 * If the user has a professionally written document but does not have access to resume
 * distribution and user is landed on /resumes or /tr-documents pages.
 * Then this case will be called: {showCase2}
 *
 * Case 3:
 * If the user does not have a professionally written document and user is landed on /resumes or
 * /tr-documents pages.
 * Then this case will be called: {showCase3}
 *
 * Case 4:
 * If the user is landed on /job-search page.
 * Then this case will be called: {showCase4}
 */

export const TopResumeWelcomeModal = () => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const { showTopResumeWelcomeModal, isV2, closeModal } = useTopResumeWelcomeModal()

  const user = useUser()
  const hasResumeDistribution = user?.scopes?.resumeDistribution

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { panel } = useAppSelector(store => store)
  const { trDocuments, documents } = panel
  const resumeByExpert = Object.values(trDocuments).find(
    trDocument => trDocument.doc_type === 'resume',
  )
  const backupResume = documents.find(
    document => document.type === 'resume' && document.name?.toLowerCase().includes('refresh'),
  )

  const showCase1 = !!resumeByExpert && hasResumeDistribution && pathname === '/resumes'
  const showCase2 = !!resumeByExpert && !hasResumeDistribution && pathname === '/resumes'
  const showCase3 = !resumeByExpert && pathname === '/resumes'
  const showCase4 = pathname === '/job-search'

  if (!showTopResumeWelcomeModal) return null

  return (
    <ModalOverlay
      overlayFadeDuration={300}
      contentSlideDuration={300}
      onClick={closeModal}
      fullScreen={isPhone}
    >
      <Modal>
        <Container>
          <Body $isV2={isV2}>
            <Title>{i18n.t('builder.panel.topresume_welcome_modal.title')}</Title>
            <LogosContainer $isV2={isV2}>
              <LogoContainer>
                <Star src={STAR} />
                <LogoTitle>
                  {i18n.t('builder.panel.topresume_welcome_modal.logo_one.line_one')}
                </LogoTitle>
                <LogoTitle>
                  {i18n.t('builder.panel.topresume_welcome_modal.logo_one.line_two')}
                </LogoTitle>
              </LogoContainer>
              <LogoContainer>
                <Documents src={DOCUMENTS} />
                <LogoTitle>
                  {i18n.t('builder.panel.topresume_welcome_modal.logo_two.line_one')}
                </LogoTitle>
                <LogoTitle>
                  {i18n.t('builder.panel.topresume_welcome_modal.logo_two.line_two')}
                </LogoTitle>
              </LogoContainer>
              <LogoContainer>
                <Kite src={KITE} />
                <LogoTitle>
                  {i18n.t('builder.panel.topresume_welcome_modal.logo_three.line_one')}
                </LogoTitle>
                <LogoTitle>
                  {i18n.t('builder.panel.topresume_welcome_modal.logo_three.line_two')}
                </LogoTitle>
              </LogoContainer>
            </LogosContainer>

            <ItemsTitle>{i18n.t('builder.panel.topresume_welcome_modal.items_title')}</ItemsTitle>

            <Items $isV2={isV2}>
              <ItemContainer $isV2={isV2}>
                <TickContainer>
                  <Tick />
                </TickContainer>
                <ItemDescriptionContainer>
                  <ItemTitle>
                    {i18n.t('builder.panel.topresume_welcome_modal.item_one.title')}
                  </ItemTitle>
                  <ItemDescription>
                    {i18n.t('builder.panel.topresume_welcome_modal.item_one.description')}
                  </ItemDescription>
                </ItemDescriptionContainer>
              </ItemContainer>

              <ItemContainer $isV2={isV2}>
                <TickContainer>
                  <Tick />
                </TickContainer>
                <ItemDescriptionContainer>
                  <ItemTitle>
                    {i18n.t('builder.panel.topresume_welcome_modal.item_two.title')}
                  </ItemTitle>
                  <ItemDescription>
                    {i18n.t('builder.panel.topresume_welcome_modal.item_two.description')}
                  </ItemDescription>
                </ItemDescriptionContainer>
              </ItemContainer>

              <ItemContainer $isV2={isV2}>
                <TickContainer>
                  <Tick />
                </TickContainer>
                <ItemDescriptionContainer>
                  <ItemTitle>
                    {i18n.t('builder.panel.topresume_welcome_modal.item_three.title')}
                  </ItemTitle>
                  <ItemDescription>
                    {i18n.t('builder.panel.topresume_welcome_modal.item_three.description')}
                  </ItemDescription>
                </ItemDescriptionContainer>
              </ItemContainer>

              <ItemContainer $isV2={isV2}>
                <TickContainer>
                  <Tick />
                </TickContainer>
                <ItemDescriptionContainer>
                  <ItemTitle>
                    {i18n.t('builder.panel.topresume_welcome_modal.item_four.title')}
                  </ItemTitle>
                  <ItemDescription>
                    {i18n.t('builder.panel.topresume_welcome_modal.item_four.description')}
                  </ItemDescription>
                </ItemDescriptionContainer>
              </ItemContainer>

              <ItemContainer $isV2={isV2}>
                <TickContainer>
                  <Tick />
                </TickContainer>
                <ItemDescriptionContainer>
                  <ItemTitle>
                    {i18n.t('builder.panel.topresume_welcome_modal.item_five.title')}
                  </ItemTitle>
                  <ItemDescription>
                    {i18n.t('builder.panel.topresume_welcome_modal.item_five.description')}
                  </ItemDescription>
                </ItemDescriptionContainer>
              </ItemContainer>

              <ItemContainer $isV2={isV2}>
                <TickContainer>
                  <Tick />
                </TickContainer>
                <ItemDescriptionContainer>
                  <ItemTitle>
                    {i18n.t('builder.panel.topresume_welcome_modal.item_six.title')}
                  </ItemTitle>
                  <ItemDescription>
                    {i18n.t('builder.panel.topresume_welcome_modal.item_six.description')}
                  </ItemDescription>
                </ItemDescriptionContainer>
              </ItemContainer>

              <ItemContainer $isV2={isV2}>
                <TickContainer>
                  <Tick />
                </TickContainer>
                <ItemDescriptionContainer>
                  <ItemTitle>
                    {i18n.t('builder.panel.topresume_welcome_modal.item_seven.title')}
                  </ItemTitle>
                  <ItemDescription>
                    {i18n.t('builder.panel.topresume_welcome_modal.item_seven.description')}
                  </ItemDescription>
                </ItemDescriptionContainer>
              </ItemContainer>

              <ItemContainer $isV2={isV2}>
                <TickContainer>
                  <Tick />
                </TickContainer>
                <ItemDescriptionContainer>
                  <ItemTitle>
                    {i18n.t('builder.panel.topresume_welcome_modal.item_eight.title')}
                  </ItemTitle>
                  <ItemDescription>
                    {i18n.t('builder.panel.topresume_welcome_modal.item_eight.description')}
                  </ItemDescription>
                </ItemDescriptionContainer>
              </ItemContainer>

              <ItemContainer $isV2={isV2}>
                <TickContainer>
                  <Tick />
                </TickContainer>
                <ItemDescriptionContainer>
                  <ItemTitle>
                    {i18n.t('builder.panel.topresume_welcome_modal.item_nine.title')}
                  </ItemTitle>
                  <ItemDescription>
                    {i18n.t('builder.panel.topresume_welcome_modal.item_nine.description')}
                  </ItemDescription>
                </ItemDescriptionContainer>
              </ItemContainer>
            </Items>
          </Body>
          <Footer>
            {showCase1 && (
              <Fragment>
                <CTA
                  size="small"
                  theme={ButtonTheme.ghost}
                  onClick={() => {
                    trackInternalEvent('click_cta_new_welcome_modal', { cta: 'view_resume' })
                    closeModal()
                    navigate(`/resume-distribution`)
                  }}
                >
                  {i18n.t('builder.panel.topresume_welcome_modal.activate_resume_distribution')}
                </CTA>

                <CTA
                  size="small"
                  onClick={() => {
                    trackInternalEvent('click_cta_new_welcome_modal', { cta: 'view_resume' })
                    closeModal()
                    navigate(`/tr-documents/${resumeByExpert.id}`)
                  }}
                >
                  {i18n.t('builder.panel.topresume_welcome_modal.resume_by_expert')}
                </CTA>
              </Fragment>
            )}

            {showCase2 && (
              <CTA
                onClick={() => {
                  trackInternalEvent('click_cta_new_welcome_modal', { cta: 'view_resume' })
                  closeModal()
                  navigate(`/tr-documents/${resumeByExpert.id}`)
                }}
              >
                {i18n.t('builder.panel.topresume_welcome_modal.resume_by_expert')}
              </CTA>
            )}

            {showCase3 && (
              <CTA
                onClick={() => {
                  trackInternalEvent('click_cta_new_welcome_modal', { cta: 'view_resume' })
                  closeModal()
                  navigate(backupResume ? `/resumes/${backupResume.id}/edit` : '/resumes')
                }}
              >
                {i18n.t('builder.panel.topresume_welcome_modal.view_resume')}
              </CTA>
            )}

            {showCase4 && (
              <CTA
                onClick={() => {
                  trackInternalEvent('click_cta_new_welcome_modal', { cta: 'view_resume' })
                  closeModal()
                }}
              >
                {i18n.t('builder.panel.topresume_welcome_modal.explore_recommended_jobs')}
              </CTA>
            )}
          </Footer>
        </Container>
        <CloseButton onClick={closeModal} />
      </Modal>
    </ModalOverlay>
  )
}
